import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: spacing(4),
  },
}));

const SectionTitle = ({
  className,
  title,
  iconImg: Icon,
  variant,
  textClassName,
  iconSize,
}) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.main, className)}>
      {Icon ? <Icon className={classNames(classes.icon, iconSize)} /> : null}
      <Typography className={textClassName} variant={variant}>
        {title}
      </Typography>
    </div>
  );
};

SectionTitle.defaultProps = {
  className: '',
  iconImg: null,
  variant: 'h3',
  textClassName: '',
  iconSize: '',
};

SectionTitle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  iconImg: PropTypes.object,
  variant: PropTypes.string,
  textClassName: PropTypes.string,
  iconSize: PropTypes.string,
};

export default SectionTitle;
