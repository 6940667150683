import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    color: primary.bluish4,
  },
  backContainer: {
    display: 'flex',
  },
  back: {
    cursor: 'pointer',
    color: primary.blue4,
    textTransform: 'capitalize',
  },
  verticalDivider: {
    margin: spacing(0, 3),
    lineHeight: '14px',
  },
  routes: {
    display: 'flex',
  },
  route: {
    color: primary.bluish4,
    textTransform: 'capitalize',
  },
  hoverRoute: {
    '&:hover': {
      cursor: 'pointer',
      color: primary.blue4,
    },
  },
  routeDivider: {
    margin: spacing(0, 2),
  },
}));

const Breadcrumbs = ({
  className,
  translations,
  routes,
  onHandleBack,
  onRouteClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.main, className)}>
      <div className={classes.backContainer} onClick={onHandleBack}>
        <Typography variant="body2" className={classes.back}>
          {`< ${translations.back}`}
        </Typography>
        <div className={classes.verticalDivider}>|</div>
      </div>
      {routes.length > 0 &&
        routes.map((route, i) => {
          const isLast = i === routes.length - 1;
          const routeClasses = classNames(classes.route, {
            [classes.hoverRoute]: !isLast,
          });
          return (
            <div key={`route_${i}`} className={classes.routes}>
              <Typography
                variant="body2"
                className={routeClasses}
                onClick={isLast ? () => {} : () => onRouteClick(route.text)}
              >
                {route.text}
              </Typography>
              {!isLast && <span className={classes.routeDivider}>&gt;</span>}
            </div>
          );
        })}
    </div>
  );
};

Breadcrumbs.defaultProps = {
  className: '',
  onRouteClick: () => {},
};

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  onHandleBack: PropTypes.func.isRequired,
  routes: PropTypes.array.isRequired,
  onRouteClick: PropTypes.func,
};

export default Breadcrumbs;
