import { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  TextField,
  FormHelperText,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search-dark.svg';
import { useCustomEffect } from '../../../utility/hooks';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  main: {
    maxWidth: 410,
  },
  searchRoot: {
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish7}`,
    color: primary.bluish1,
    borderRadius: 44,
    '&$focused': {
      border: `1px solid ${primary.blue1}`,
    },
  },
  rootFormField: {
    border: `1px solid ${primary.bluish6}`,
    borderRadius: 4,
  },

  focused: {},
}));

const DELAY = 500;

const Search = ({
  className,
  label,
  placeholder,
  value,
  isFormField,
  disabled,
  onChange,
}) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  let inputTimer = null;

  useCustomEffect(() => {
    inputRef.current.value = value;
  }, [value]);

  const handleChange = event => {
    clearTimeout(inputTimer);
    inputTimer = setTimeout(() => onChange(event.target.value), DELAY);
  };

  const renderAdornment = () => (
    <InputAdornment position="start">
      <SearchIcon />
    </InputAdornment>
  );

  return (
    <div className={classNames(classes.main, className)}>
      {label && <FormHelperText>{label}</FormHelperText>}
      <TextField
        placeholder={placeholder}
        onChange={handleChange}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classNames(classes.searchRoot, {
              [classes.rootFormField]: isFormField,
            }),
            focused: classes.focused,
          },
          startAdornment: renderAdornment(),
        }}
        inputRef={inputRef}
        disabled={disabled}
        fullWidth
      />
    </div>
  );
};

Search.defaultProps = {
  className: null,
  placeholder: '',
  disabled: false,
  isFormField: false,
  label: '',
  value: '',
};

Search.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  isFormField: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default Search;
