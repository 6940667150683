import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  link: {
    color: primary.blue1,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 0,
    '&:hover p': {
      color: primary.blue1,
      fontFamily: 'ProximaNova-Bold',
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));

const CustomLink = ({
  className,
  customTextClass,
  customDisabledClass,
  id,
  text,
  disabled,
  handleClick,
}) => {
  const classes = useStyles();

  return (
    <button
      id={id}
      type="button"
      className={classNames(classes.link, className)}
      disabled={disabled}
      onClick={!disabled ? handleClick : () => {}}
    >
      <Typography
        variant="body2"
        className={classNames(customTextClass, {
          [customDisabledClass]: disabled,
        })}
      >
        {text}
      </Typography>
    </button>
  );
};

CustomLink.defaultProps = {
  className: '',
  id: undefined,
  disabled: false,
  customTextClass: '',
  customDisabledClass: '',
};

CustomLink.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  customTextClass: PropTypes.string,
  customDisabledClass: PropTypes.string,
};

export default CustomLink;
