export const prepareTeamsOverviewHeader = labels => {
  const { manager, oneOnOne, actionPlans, metrics } = labels;
  return [
    {
      id: 1,
      title: manager.label,
      align: 'center',
      minWidth: 160,
      maxWidth: '1fr',
      subheaders: [
        {
          id: 1,
          dataKey: 'manager',
          cellClass: 'managerCell',
        },
      ],
    },
    {
      id: 2,
      title: oneOnOne.label,
      align: 'center',
      maxWidth: '2fr',
      subheaders: [
        {
          id: 1,
          title: oneOnOne.subLabels.direct,
          dataKey: 'directReports',
          align: 'center',
          isHighlighted: true,
          maxWidth: '1fr',
        },
        {
          id: 2,
          title: oneOnOne.subLabels.withoutOneOnOne,
          dataKey: 'noOneOnOne',
          align: 'center',
          isHighlighted: true,
          isDisabled: true,
          maxWidth: '1fr',
        },
        {
          id: 3,
          title: oneOnOne.subLabels.negativeImpressions,
          dataKey: 'lowImpression',
          align: 'center',
          minWidth: 150,
          isHighlighted: true,
          maxWidth: '1fr',
          tooltipMessage: oneOnOne.tooltips.negativeImpressions,
        },
      ],
    },
    {
      id: 3,
      title: actionPlans.label,
      align: 'center',
      maxWidth: '1fr',
      subheaders: [
        {
          id: 1,
          title: actionPlans.subLabels.toDo,
          dataKey: 'toDo',
          align: 'center',
          isHighlighted: true,
          maxWidth: '1fr',
        },
        {
          id: 2,
          title: actionPlans.subLabels.overdue,
          dataKey: 'overdue',
          align: 'center',
          isHighlighted: true,
          isWarning: true,
          maxWidth: '1fr',
        },
      ],
    },
    {
      id: 4,
      title: metrics.label,
      align: 'center',
      maxWidth: '2fr',
      subheaders: [
        {
          id: 1,
          title: metrics.subLabels.coverage,
          dataKey: 'coverage',
          align: 'center',
          maxWidth: '1fr',
          tooltipMessage: metrics.tooltips.coverage,
        },
        {
          id: 2,
          title: metrics.subLabels.learning,
          dataKey: 'learning',
          align: 'center',
          maxWidth: '1fr',
          tooltipMessage: metrics.tooltips.learning,
        },
        {
          id: 3,
          title: metrics.subLabels.impression,
          dataKey: 'impression',
          align: 'center',
          maxWidth: '1fr',
          tooltipMessage: metrics.tooltips.impression,
        },
      ],
    },
  ];
};

export const prepareTeamsOverviewResults = (
  overviewResults,
  metrics,
  renderManager,
  renderMetric,
  renderTooltip
) => {
  const noValue = '-';

  return overviewResults.map((result, index) => {
    const {
      direct_reports,
      no_1_1,
      low_impression,
      ap_overdue,
      ap_todo,
      ...rest
    } = result;

    return {
      id: result.id,
      manager: renderManager(rest),
      directReports: direct_reports
        ? renderTooltip(result.id, direct_reports, true)
        : noValue,
      noOneOnOne: no_1_1 ? renderTooltip(result.id, no_1_1) : noValue,
      lowImpression: low_impression
        ? renderTooltip(result.id, low_impression, false, true)
        : `${low_impression}%`,
      toDo: ap_todo || noValue,
      overdue: ap_overdue || noValue,
      coverage: renderMetric(metrics[index]?.coverage),
      learning: renderMetric(metrics[index]?.enrolment, 'secondary'),
      impression: renderMetric(metrics[index]?.impression, 'ternary'),
    };
  });
};

export const getDetailsTooltipTitle = (
  labels,
  isDirectReports = false,
  isLowImpressed = false
) => {
  if (isDirectReports) {
    return labels.direct;
  }
  if (isLowImpressed) {
    return labels.negativeImpressions;
  }

  return labels.withoutOneOnOne;
};
