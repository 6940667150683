import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomLink from '../customLink';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  resetLink: {
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
    textAlign: 'right',
    transition: 'text-shadow .3s',
    '&:focus': {
      outline: 'none',
    },
    '&:hover p': {
      color: primary.bluish1,
      textShadow: `.05em 0 0 ${primary.bluish1}`,
    },
  },
  text: {
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
    letterSpacing: 1,
  },
}));

const BackLink = ({ className, id, history, title, to }) => {
  const classes = useStyles();

  return (
    <CustomLink
      id={id}
      className={classNames(classes.resetLink, className)}
      customTextClass={classes.text}
      text={title}
      handleClick={() => history.push(to)}
    />
  );
};
BackLink.defaultProps = {
  className: '',
  id: undefined,
};

BackLink.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export default BackLink;
