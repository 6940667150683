import { useCallback, memo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import { Fade, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AttributesPerformanceSubgroup from './attributesPerformanceSubgroup';
import { isObjectEmpty } from '../../../../utility/helpers';
import { getAttributeGroups } from './config';
import SectionTitle from '../../sectionTitle';
import { ReactComponent as SelfIcon } from '../../../../assets/icons/vector_blue.svg';
import { ReactComponent as PeerIcon } from '../../../../assets/icons/ellipse_black.svg';
import { ReactComponent as ComparePeopleDarkIcon } from '../../../../assets/icons/compare-dark.svg';
import CustomButton from '../../customButton';
import ConditionalTooltip from '../../conditionalTooltip';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    boxSizing: 'border-box',
    width: '100%',
    position: 'relative',
  },
  performanceHeader: {
    display: 'grid',
    gridTemplateColumns: '189px 580px 131px',
  },
  selfButton: {
    marginRight: spacing(1),
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '714px 62px 62px 62px',
    marginTop: 20,
  },
  block: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  label: {
    color: primary.bluish4,
    lineHeight: '20px',
    marginBottom: spacing(2),
  },
  buttons: {
    alignItems: 'center',
    display: 'flex',
  },
  button: {
    cursor: 'pointer',
    opacity: 0.5,
    height: 22,
    color: primary.black,
    fontSize: 12,
    lineHeight: '12px',
    borderColor: primary.black,
    minHeight: 22,
  },
  selfIcon: {
    '& > path': {
      fill: primary.black,
    },
  },
  clickedButton: {
    opacity: 1,
  },
  compareReportButton: {
    padding: 0,
    color: primary.bluish1,
    height: 24,
    fontSize: 12,
    width: 121,
  },
}));

const AttributesPerformance = ({
  className,
  translations,
  coreAttributes,
  nicheAttributes,
  hasSendSurveyButton,
  hasUserLevel,
  isSendSurveyDisabled,
  hasCompareReportButton,
  onRedirectToCompareReport,
  onSendSurvey,
}) => {
  const classes = useStyles();

  const [canShowPeer, setCanShowPeer] = useState(true);
  const [canShowSelf, setCanShowSelf] = useState(true);

  const attributeGroups = getAttributeGroups(
    hasUserLevel,
    coreAttributes,
    nicheAttributes
  );

  const handleGoToCompareReport = useCallback(() => {
    onRedirectToCompareReport(nicheAttributes.concat(coreAttributes));
  }, [onRedirectToCompareReport, nicheAttributes, coreAttributes]);

  const handleSendSurvey = useCallback(
    (attributeId, isSelf) => () => {
      onSendSurvey(attributeId, isSelf);
    },
    [onSendSurvey]
  );

  return (
    <div className={classNames(classes.main, className)}>
      <TransitionGroup>
        <>
          <div className={classes.performanceHeader}>
            <SectionTitle title={translations.attributes} variant="h4" />
            <div className={classes.buttons}>
              <ConditionalTooltip message={translations.self} addTooltip>
                <CustomButton
                  className={classNames(classes.button, classes.selfButton, {
                    [classes.clickedButton]: canShowSelf,
                  })}
                  type="addWithTextRounded"
                  startIcon={<SelfIcon className={classes.selfIcon} />}
                  onClick={() => setCanShowSelf(prevVal => !prevVal)}
                >
                  {translations.selfAssessment}
                </CustomButton>
              </ConditionalTooltip>
              <ConditionalTooltip message={translations.peer} addTooltip>
                <CustomButton
                  className={classNames(classes.button, {
                    [classes.clickedButton]: canShowPeer,
                  })}
                  type="addWithTextRounded"
                  startIcon={<PeerIcon />}
                  onClick={() => setCanShowPeer(prevVal => !prevVal)}
                >
                  {translations.extrinsicAssessment}
                </CustomButton>
              </ConditionalTooltip>
            </div>
            {hasCompareReportButton && (
              <CustomButton
                className={classes.compareReportButton}
                type="textRoundedSmall"
                startIcon={<ComparePeopleDarkIcon />}
                onClick={handleGoToCompareReport}
              >
                {translations.compareReport}
              </CustomButton>
            )}
          </div>
          <div className={classes.header}>
            <div />
            <div className={classes.block}>
              <Typography
                variant="body2"
                component="h4"
                className={classes.label}
              >
                {translations.self}
              </Typography>
            </div>
            <div className={classes.block}>
              <Typography
                variant="body2"
                component="h4"
                className={classes.label}
              >
                {translations.extrinsic}
              </Typography>
            </div>
            <div className={classes.block}>
              <Typography
                variant="body2"
                component="h4"
                className={classes.label}
              >
                {translations.range}
              </Typography>
            </div>
          </div>
          {attributeGroups.map((group, index) => {
            if (isObjectEmpty(group)) return null;

            return (
              <Fade
                key={`group_item_${index}`}
                in={!isObjectEmpty(group)}
                unmountOnExit
              >
                <div>
                  <AttributesPerformanceSubgroup
                    translations={translations}
                    attributes={group.attributes}
                    hasSendSurveyButton={hasSendSurveyButton}
                    isSendSurveyDisabled={isSendSurveyDisabled}
                    onSendSurvey={handleSendSurvey}
                    canShowPeer={canShowPeer}
                    canShowSelf={canShowSelf}
                  />
                </div>
              </Fade>
            );
          })}
        </>
      </TransitionGroup>
    </div>
  );
};

AttributesPerformance.defaultProps = {
  className: undefined,
  coreAttributes: [],
  nicheAttributes: [],
  isSendSurveyDisabled: false,
};

AttributesPerformance.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  coreAttributes: PropTypes.array,
  nicheAttributes: PropTypes.array,
  hasSendSurveyButton: PropTypes.bool.isRequired,
  isSendSurveyDisabled: PropTypes.bool,
  hasUserLevel: PropTypes.bool.isRequired,
  onSendSurvey: PropTypes.func.isRequired,
  hasCompareReportButton: PropTypes.bool.isRequired,
  onRedirectToCompareReport: PropTypes.func.isRequired,
};

export default memo(AttributesPerformance);
