import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomModal from '../customModal';
import ListWithTooltip from '../listWithTooltip';
import PeoplePicker from '../peoplePicker';
import { getFormattedUsers, isArrayEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  usersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 147,
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: -20,
    height: '100%',
    width: 8,
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
  avatars: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  userAvatar: {
    borderBottom: `1px solid ${primary.bluish7}`,
    paddingBottom: spacing(2),
    marginBottom: spacing(2),
    width: '100%',
    '&:last-of-type': {
      borderBottom: 'none',
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
  avatarWrapper: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  avatarCaption: {
    textAlign: 'left',
    width: 'calc(100% - 32px)',
  },
  userLabel: {
    marginLeft: 0,
    width: '100%',
  },
  usersToReviewWrapper: {
    borderBottom: `1px solid ${primary.bluish7}`,
    paddingBottom: spacing(6),
    marginBottom: spacing(6),
  },
  listLabel: {
    color: primary.bluish1,
    lineHeight: '18px',
    marginBottom: spacing(2),
  },
  userMoreItems: {
    backgroundColor: 'transparent',
    fontSize: 16,
    lineHeight: '20px',
    padding: spacing(0, 2),
    marginTop: spacing(0.5),
    maxWidth: 'fit-content',
  },
  reviewers: {
    minHeight: 200,
    marginBottom: spacing(6),
  },
}));

const AddReviewersDialog = ({
  translations,
  reviewers,
  existingReviewers,
  usersToReview,
  attributes,
  users,
  isOpened,
  isScheduled,
  onAdd,
  onClose,
  onConfirm,
}) => {
  const classes = useStyles();

  const renderReviewers = () => (
    <div className={classes.reviewers}>
      <PeoplePicker
        translations={translations.fieldLabels}
        preselectedUsers={reviewers}
        options={users}
        blacklistedUsers={existingReviewers}
        onSelect={onAdd}
        formattedUsers={getFormattedUsers(users, true)}
        hasScrollbar
        hasSelectAll
        showSelection
        showDirectReports
      />
    </div>
  );

  const renderItemsList = (label, items, isUserList = false) => (
    <div className={classNames({ [classes.usersToReviewWrapper]: isUserList })}>
      <ListWithTooltip
        labelClass={classes.listLabel}
        moreItemsClass={classes.userMoreItems}
        label={label}
        items={items}
        asyncListProps={{
          position: 'top',
        }}
        isUserList={isUserList}
      />
    </div>
  );

  return (
    <CustomModal
      title={isScheduled ? translations.titleSchedule : translations.title}
      description={
        isScheduled
          ? translations.descriptionSchedule
          : translations.description
      }
      closeButtonLabel={translations.close}
      confirmButtonLabel={
        isScheduled ? translations.confirmSchedule : translations.confirm
      }
      isConfirmDisabled={isArrayEmpty(reviewers)}
      isOpened={isOpened}
      onClose={onClose}
      onConfirm={onConfirm}
      isMedium
    >
      <div>
        {renderReviewers()}
        {!isArrayEmpty(usersToReview) &&
          renderItemsList(translations.usersToReview, usersToReview, true)}
        {renderItemsList(translations.attributes, attributes)}
      </div>
    </CustomModal>
  );
};

AddReviewersDialog.defaultProps = {
  usersToReview: [],
  reviewers: [],
  existingReviewers: [],
};

AddReviewersDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  usersToReview: PropTypes.arrayOf(PropTypes.shape({})),
  reviewers: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  attributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isScheduled: PropTypes.bool.isRequired,
  existingReviewers: PropTypes.arrayOf(PropTypes.number),
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
export default AddReviewersDialog;
