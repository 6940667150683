import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  noData: {
    color: primary.bluish4,
  },
}));

const NoDataText = ({ className, text, variant }) => {
  const classes = useStyles();

  return (
    <Typography
      variant={variant}
      className={classNames(classes.noData, className)}
    >
      {text}
    </Typography>
  );
};

NoDataText.defaultProps = {
  className: '',
  variant: 'body1',
};

NoDataText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

export default NoDataText;
