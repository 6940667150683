import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import ActionButton from '../actionButton';
import Tooltip from '../tooltip';
import { ReactComponent as PersonalAttributeIcon } from '../../../assets/icons/private-attribute-icon.svg';
import { ReactComponent as TextReplyIcon } from '../../../assets/icons/text_snippet.svg';
import { useTranslations } from '../../../utility/useTranslations';
import { getPersonalAttributeTooltipText } from '../../../utility/uiUtils';
import { APP_COMPONENTS } from '../../../constants/pages';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    backgroundColor: ({ color, isTextReply }) =>
      isTextReply || !color ? primary.bluish8 : color,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0, 2),
    height: 28,
    transition: 'background-color .25s ease',
    maxWidth: '100%',
    minWidth: 0,
  },
  selectableAttribute: {
    cursor: 'pointer',
  },
  hasRemove: {
    padding: spacing(0, 1, 0, 2),
  },
  attribute: {
    lineHeight: '20px',
    justifySelf: 'start',
    maxWidth: '100%',
  },
  textReplyAttribute: {
    border: ({ color }) => `4px solid ${color}`,
  },
  button: {
    flexShrink: 0,
    width: 24,
    height: 24,
    marginLeft: spacing(1),
  },
  icon: {
    width: 16,
    height: 16,
  },
  attributeIcon: {
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    marginLeft: spacing(1),
    marginTop: spacing(-0.25),
    '& svg': {
      '& g, & path': {
        fill: primary.bluish5,
      },
    },
    '& g, & path': {
      fill: primary.bluish5,
    },
  },
  attributeIconSelected: {
    '& svg': {
      '& g, & path': {
        fill: primary.bluish1,
      },
    },
    '& g, & path': {
      fill: primary.bluish1,
    },
  },
  dragButton: {
    width: 22,
    height: 22,
    flexShrink: 0,
    marginRight: spacing(1),
    '& button': {
      width: 22,
      height: 22,
    },
  },
  dragIcon: {
    width: 8,
    height: 14,
  },
  dragTooltipLabel: {
    fontFamily: 'ProximaNova-Regular',
  },
}));

const AttributeChip = ({
  customClass,
  style,
  color,
  name,
  isTextReply,
  attributeNumber,
  attribute,
  isSelectable,
  isSelected,
  isAttributeDraggable,
  dragRef,
  dragProps,
  hasRemove,
  onSelect,
  onRemove,
}) => {
  const classes = useStyles({ color, isTextReply });
  const translations = useTranslations(APP_COMPONENTS.ATTRIBUTE_CHIP);

  const handleRemove = useCallback(
    () => onRemove(attribute),
    [attribute, onRemove]
  );

  const handleSelect = useCallback(() => {
    if (isSelectable) {
      onSelect(attribute);
    }
  }, [attribute, isSelectable, onSelect]);

  return (
    <div
      ref={dragRef}
      className={classNames(
        classes.root,
        {
          [classes.textReplyAttribute]: isTextReply,
          [classes.hasRemove]: hasRemove,
          [classes.selectableAttribute]: isSelectable,
        },
        customClass
      )}
      {...(style ? { style } : {})}
      {...(dragProps ? { ...dragProps.draggableProps } : {})}
      onClick={handleSelect}
    >
      {isAttributeDraggable && (
        <ActionButton
          className={classes.dragButton}
          iconClass={classes.dragIcon}
          tooltipLabelClass={classes.dragTooltipLabel}
          type={ACTION_BUTTON_TYPES.DRAG}
          tooltipText={translations.dragTooltip}
          {...(dragProps ? { ...dragProps.dragHandleProps } : {})}
        />
      )}
      <TextBoxWithTooltip
        variant="subtitle2"
        className={classes.attribute}
        text={name}
        itemNumber={attributeNumber}
      />
      {attribute.text_reply_questions && (
        <Tooltip
          customIconClass={classNames(classes.attributeIcon, {
            [classes.attributeIconSelected]: isSelected,
          })}
          text={translations.openEndedAttribute}
          icon={TextReplyIcon}
        />
      )}
      {attribute.is_private && (
        <Tooltip
          customIconClass={classNames(classes.attributeIcon, {
            [classes.attributeIconSelected]: isSelected,
          })}
          text={
            attribute.created_by?.id
              ? getPersonalAttributeTooltipText(
                  translations.userPersonalAttribute,
                  attribute.created_by
                )
              : translations.personalAttribute
          }
          icon={PersonalAttributeIcon}
        />
      )}
      {hasRemove && (
        <ActionButton
          className={classes.button}
          iconClass={classes.icon}
          type={ACTION_BUTTON_TYPES.CLOSE}
          size="small"
          onClickHandler={handleRemove}
        />
      )}
    </div>
  );
};

AttributeChip.defaultProps = {
  customClass: null,
  isTextReply: false,
  hasRemove: false,
  isAttributeDraggable: false,
  isSelected: false,
  isSelectable: false,
  attributeNumber: 0,
  attribute: {},
  dragProps: null,
  style: null,
  color: undefined,
  dragRef: () => {},
  onRemove: () => {},
  name: '',
};

AttributeChip.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
  customClass: PropTypes.string,
  attribute: PropTypes.object,
  isTextReply: PropTypes.bool,
  hasRemove: PropTypes.bool,
  isSelectable: PropTypes.bool,
  isSelected: PropTypes.bool,
  isAttributeDraggable: PropTypes.bool,
  attributeNumber: PropTypes.number,
  dragProps: PropTypes.object,
  dragRef: PropTypes.func,
  onRemove: PropTypes.func,
};

export default memo(AttributeChip);
