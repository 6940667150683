import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as SortIndicator } from '../../../assets/icons/sort-arrow-dark.svg';
import { getAlignItem, getColumnWidth } from '../../../constants/gridTable';
import { orderingString } from '../../../utility/uiUtils';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    main: {
      display: 'grid',
      boxSizing: 'border-box',
      backgroundColor: `${primary.bluish8}`,
    },
    headerCell: {
      boxSizing: 'border-box',
      cursor: 'pointer',
      padding: spacing(1.5, 2),
      textAlign: 'left',
      lineHeight: '24px',
      userSelect: 'none',
      [breakpoints.up('xLg')]: {
        padding: spacing(1.5, 4),
      },
    },
    disabled: {
      cursor: 'default',
    },
    action: {
      cursor: 'default',
    },
    indicatorContainer: {
      display: 'inline-block',
      minWidth: 18,
    },
    sortIcon: {
      display: 'inline-block',
      height: 7,
      width: 8,
      marginLeft: spacing(2),
      transition: 'transform .3s ease',
    },
    reverse: {
      // Used for reversing arrow icons
      '-webkit-transform': 'rotate(180deg)',
      '-moz-transform': 'rotate(180deg)',
      '-o-transform': 'rotate(180deg)',
      '-ms-transform': 'rotate(180deg)',
      transform: 'rotate(180deg)',
      transition: 'transform .3s ease',
    },
  })
);

const GridTableHeader = ({
  customHeaderClass,
  headers,
  initialSort,
  onSort,
}) => {
  const classes = useStyles();

  const [sort, setSort] = useState(initialSort || {});

  const toggleSortDirection = (column, asc) =>
    column === sort?.column ? !asc : initialSort?.asc;

  const handleSort = column => () => {
    if (column.isSortingDisabled || column.isHeaderAction) return;

    const direction = toggleSortDirection(column.rowKey, sort.asc);

    const updatedSort = {
      ...sort,
      column: column.rowKey,
      sortKey: orderingString(
        { ...column, recordKey: column.rowKey },
        direction
      ),
      asc: direction,
    };

    setSort(updatedSort);
    onSort(updatedSort);
  };

  return (
    <div
      className={classNames(
        classes.main,
        'grid-table-header',
        customHeaderClass
      )}
      style={getColumnWidth(headers)}
    >
      {headers.map(header => (
        <Typography
          component="div"
          variant="overline"
          key={`header_cell_${header.id}`}
          className={classNames(
            classes.headerCell,
            {
              [classes.disabled]: header.isSortingDisabled,
              [classes.action]: header.isHeaderAction,
            },
            'header-cell',
            header.headerCellClass
          )}
          style={getAlignItem(header)}
          onClick={handleSort(header)}
        >
          {header.title || ''}
          {!header.isHeaderAction && !header.isSortingDisabled && (
            <span className={classes.indicatorContainer}>
              {sort.column === header.rowKey && (
                <SortIndicator
                  className={classNames(classes.sortIcon, {
                    [classes.reverse]: !sort.asc,
                  })}
                />
              )}
            </span>
          )}
        </Typography>
      ))}
    </div>
  );
};

GridTableHeader.defaultProps = {
  customHeaderClass: null,
};

GridTableHeader.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      rowKey: PropTypes.string.isRequired,
      sortAs: PropTypes.string,
      align: PropTypes.string,
      minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      headerCellClass: PropTypes.string,
      isHeaderAction: PropTypes.bool,
      isSortingDisabled: PropTypes.bool,
    })
  ).isRequired,
  customHeaderClass: PropTypes.string,
  onSort: PropTypes.func.isRequired,
};

export default GridTableHeader;
