import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    border: `1px solid ${primary.bluish1}`,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(1, 2),
    width: 'fit-content',
    height: 26,
  },
}));

const ShareTypeBox = ({ className, label }) => {
  const classes = useStyles();

  return (
    <Typography
      className={classNames(classes.main, className)}
      variant="caption"
    >
      {label}
    </Typography>
  );
};

ShareTypeBox.defaultProps = {
  className: null,
};

ShareTypeBox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default ShareTypeBox;
