import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip, alpha, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    arrowPopper: {
      opacity: 1,
      '&[x-placement*="bottom"] $arrowArrow': {
        top: 0,
        left: 0,
        marginTop: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: `transparent transparent ${primary.white} transparent`,
        },
      },
      '&[x-placement*="top"] $arrowArrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.9em',
        width: '3em',
        height: '1em',
        filter: 'drop-shadow(0px 3px 2px rgba(0,0,0,0.2))',
        '&::before': {
          borderWidth: '1em 1em 0 1em',
          borderColor: `${primary.white} transparent transparent transparent`,
        },
      },
      '&[x-placement*="right"] $arrowArrow': {
        left: 0,
        marginLeft: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
          top: '50%',
          transform: 'translateY(25%)',
          borderWidth: '1em 1em 1em 0',
          borderColor: `transparent ${primary.white} transparent transparent`,
        },
      },
      '&[x-placement*="left"] $arrowArrow': {
        right: 0,
        marginRight: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
          top: '50%',
          transform: 'translateY(25%)',
          borderWidth: '1em 0 1em 1em',
          borderColor: `transparent transparent transparent ${primary.white}`,
        },
      },
    },
    arrowArrow: {
      position: 'absolute',
      fontSize: 7,
      width: '3em',
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    },
    tooltip: {
      boxSizing: 'border-box',
      backgroundColor: primary.white,
      boxShadow: `2px 2px 7px 0px ${alpha(primary.black, 0.17)}`,
      borderRadius: 8,
      color: primary.bluish2,
      position: 'relative',
      textAlign: 'start',
      maxWidth: 329,
      padding: spacing(2, 4),
    },
    surveyTooltip: {
      maxWidth: 250,
      [breakpoints.up('md')]: {
        maxWidth: 329,
      },
    },
  })
);

const ArrowTooltip = ({
  tooltipClassName,
  tooltipPopperClassName,
  children,
  tooltipLabel,
  position,
  survey,
  blue,
  isArrowHidden,
  shouldDisablePortal,
  ...rest
}) => {
  const classes = useStyles();
  const [arrowRef, setArrowRef] = useState(null);

  const handleArrowRef = node => setArrowRef(node);

  return (
    <Tooltip
      {...rest}
      title={
        <>
          {tooltipLabel}
          {!isArrowHidden ? (
            <span className={classes.arrowArrow} ref={handleArrowRef} />
          ) : null}
        </>
      }
      classes={{
        popper: classNames(classes.arrowPopper, tooltipPopperClassName),
        tooltip: classNames(
          classes.tooltip,
          {
            [classes.surveyTooltip]: survey,
          },
          tooltipClassName
        ),
      }}
      placement={position}
      PopperProps={{
        disablePortal: shouldDisablePortal,
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef,
            },
            ...(shouldDisablePortal
              ? {
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: true,
                    boundariesElement: 'viewport',
                  },
                }
              : {}),
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

ArrowTooltip.defaultProps = {
  position: 'top',
  survey: false,
  isArrowHidden: false,
  tooltipClassName: null,
  tooltipPopperClassName: null,
  shouldDisablePortal: false,
};

ArrowTooltip.propTypes = {
  tooltipClassName: PropTypes.string,
  tooltipPopperClassName: PropTypes.string,
  children: PropTypes.element.isRequired,
  position: PropTypes.string,
  tooltipLabel: PropTypes.node.isRequired,
  survey: PropTypes.bool,
  isArrowHidden: PropTypes.bool,
  shouldDisablePortal: PropTypes.bool,
};

export default ArrowTooltip;
