import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import CustomButton from '../customButton';
import ActionButton from '../actionButton';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    dialogPaperWrapper: { display: 'block', position: 'relative' },
    paper: {
      boxSizing: 'border-box',
      borderRadius: 8,
      position: 'absolute',
      minWidth: 300,
      maxWidth: 300,
      minHeight: 248,
      padding: spacing(4),
      margin: 0,
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%) ',
      [breakpoints.up('sm')]: {
        minWidth: 394,
        maxWidth: 394,
        padding: spacing(8),
      },
    },
    large: {
      [breakpoints.up('sm')]: {
        minWidth: 500,
        maxWidth: 500,
        padding: spacing(8),
      },
    },
    titleRoot: {
      marginBottom: spacing(2),
      padding: 0,
      textAlign: 'center',
    },
    title: {
      fontSize: 20,
      lineHeight: '24px',
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      minHeight: 144,
    },
    contentRoot: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: spacing(0, 0, 4, 0),
      flex: '0 1 auto',
      '&:first-child': {
        paddingTop: 0,
      },
    },
    contentDescription: {
      marginTop: spacing(1),
    },
    actionsRoot: {
      justifyContent: 'center',
      padding: 0,
    },
    icon: { width: '24px', height: '24px' },
    centeredText: {
      textAlign: 'center',
    },
    button: {
      backgroundColor: primary.blue1,
      border: 'none',
      fontFamily: 'ProximaNova-Bold',
      fontSize: 16,
      lineHeight: '24px',
      padding: spacing(2, 4),
    },
    closeButton: {
      backgroundColor: primary.white,
      border: `1px solid ${primary.bluish2}`,
      color: primary.bluish2,
      '&:hover': {
        backgroundColor: primary.white,
      },
    },
    warningButton: {
      backgroundColor: primary.red2,
      '&:hover': {
        backgroundColor: primary.red2,
      },
    },
    closeButtonIcon: {
      position: 'absolute',
      right: 16,
      top: 16,
    },
  })
);

const AlertDialog = ({
  translations,
  isOpened,
  isWarning,
  isLarge,
  onConfirm,
  onClose,
}) => {
  const classes = useStyles();
  const {
    title,
    contentMessage,
    contentDescription,
    closeButton,
    confirmButton,
  } = translations;

  return (
    <Dialog
      open={isOpened}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        scrollPaper: classes.dialogPaperWrapper,
        paper: classNames(classes.paper, { [classes.large]: isLarge }),
      }}
      onClose={onClose}
    >
      <div className={classes.contentWrapper}>
        {title && (
          <DialogTitle
            id="alert-dialog-title"
            classes={{ root: classes.titleRoot }}
          >
            <Typography variant="subtitle1" className={classes.title}>
              {title}
            </Typography>
          </DialogTitle>
        )}
        <DialogContent classes={{ root: classes.contentRoot }}>
          {contentMessage && (
            <Typography className={classes.centeredText}>
              {contentMessage}
            </Typography>
          )}
          {contentDescription && (
            <Typography
              className={classNames(
                classes.contentDescription,
                classes.centeredText
              )}
            >
              {contentDescription}
            </Typography>
          )}
        </DialogContent>
      </div>
      <DialogActions classes={{ root: classes.actionsRoot }}>
        {closeButton && (
          <CustomButton
            className={classNames(classes.button, classes.closeButton)}
            type="withTextDarkRounded"
            onClick={onClose}
          >
            {translations.closeButton}
          </CustomButton>
        )}
        {confirmButton && (
          <CustomButton
            className={classNames(classes.button, {
              [classes.warningButton]: isWarning,
            })}
            type="withTextDarkRounded"
            onClick={onConfirm}
          >
            {translations.confirmButton}
          </CustomButton>
        )}
      </DialogActions>
      <ActionButton
        className={classes.closeButtonIcon}
        type={ACTION_BUTTON_TYPES.CLOSE}
        onClickHandler={onClose}
      />
    </Dialog>
  );
};

AlertDialog.defaultProps = {
  translations: {},
  isWarning: false,
  isLarge: false,
};

AlertDialog.propTypes = {
  translations: PropTypes.shape({}),
  isOpened: PropTypes.bool.isRequired,
  isWarning: PropTypes.bool,
  isLarge: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default AlertDialog;
