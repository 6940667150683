import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomButton from '../customButton';
import Menu from './menu';
import SelectedFilters from './selectedFilters';
import { isArray, isArrayEmpty, trimString } from '../../../utility/helpers';
import { AUTOMATION_ID } from '../../../constants/automationId';
import { resetFilters, isDirty } from './config';

const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    alignItems: 'center',
  },
  filterButton: {
    flexShrink: 0,
  },
}));

const Filters = ({
  className,
  translations,
  filters,
  selectedFilters,
  disabled,
  onApplyFilters,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [tempFilters, setTempFilters] = useState(selectedFilters);
  const [search, setSearch] = useState('');

  const handleFilterSearch = value => setSearch(trimString(value));

  const handleClose = e => {
    e.stopPropagation();
    setAnchorEl(null);
    setSelectedSubMenu(null);
    setTempFilters(selectedFilters);
    setSearch('');
  };

  const handleCloseSubMenu = () => setSelectedSubMenu(null);

  const handleOpenPrimaryMenu = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const toggleSubMenu = newSelected => () => {
    setSelectedSubMenu(
      newSelected.id === selectedSubMenu?.id ? null : newSelected
    );
    setTempFilters(selectedFilters);
    setSearch('');
  };

  const handleSelectFilter = selectedFilter => () => {
    const { id, parent, isSingleSelect } = selectedSubMenu;
    const currentSubMenuId = parent?.id || id;
    const selectedFilterIndex = tempFilters[currentSubMenuId].findIndex(
      filter => filter === selectedFilter
    );

    if (isSingleSelect) {
      return setTempFilters(prevFilters => ({
        ...prevFilters,
        [id]: [...(selectedFilter !== null ? [selectedFilter] : [])],
      }));
    }

    if (selectedFilterIndex === -1) {
      return setTempFilters(prevFilters => ({
        ...prevFilters,
        [currentSubMenuId]: [...prevFilters[currentSubMenuId], selectedFilter],
      }));
    }

    setTempFilters(prevFilters => ({
      ...prevFilters,
      [currentSubMenuId]: [
        ...prevFilters[currentSubMenuId].slice(0, selectedFilterIndex),
        ...prevFilters[currentSubMenuId].slice(
          selectedFilterIndex + 1,
          prevFilters[currentSubMenuId].length
        ),
      ],
    }));
  };

  const handleApplyFilters = () => {
    const { id, parent } = selectedSubMenu;
    const currentSubMenuId = parent?.id || id;

    // Check if submenu filters are changed
    if (
      isDirty(selectedFilters[currentSubMenuId], tempFilters[currentSubMenuId])
    ) {
      onApplyFilters(tempFilters);
    }
    handleCloseSubMenu();
  };

  const handleClearFilterCategory = category => {
    const updatedFilters = { ...tempFilters, [category]: [] };

    setTempFilters(updatedFilters);
    onApplyFilters(updatedFilters);
  };

  const handleClearAll = () => {
    const updatedFilters = resetFilters(tempFilters);

    setTempFilters(updatedFilters);
    return onApplyFilters(updatedFilters);
  };

  return (
    <div className={classNames(classes.main, className)}>
      <CustomButton
        id={AUTOMATION_ID.FILTER_BUTTON}
        className={classes.filterButton}
        type="filters"
        onClick={handleOpenPrimaryMenu}
        disabled={disabled}
      >
        {translations.filters}
      </CustomButton>
      <SelectedFilters
        translations={translations}
        filters={filters}
        selectedFilters={selectedFilters}
        onClearCategory={handleClearFilterCategory}
        onClear={handleClearAll}
      />
      {isArray(filters) && !isArrayEmpty(filters) && (
        <Menu
          translations={translations}
          anchorEl={anchorEl}
          menuItems={filters}
          selectedSubMenu={selectedSubMenu}
          selectedFilters={tempFilters}
          searchTerm={search}
          onSelectFilter={handleSelectFilter}
          onFilterSearch={handleFilterSearch}
          onToggleSubMenu={toggleSubMenu}
          onClose={handleClose}
          onApply={handleApplyFilters}
        />
      )}
    </div>
  );
};

Filters.defaultProps = {
  className: null,
  disabled: false,
};

Filters.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  selectedFilters: PropTypes.shape({}).isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Filters;
