import {
  ACTIVE_DEFAULT_NAME,
  DEACTIVATED_DEFAULT_NAME,
  EXTERNAL_DEFAULT_NAME,
} from '../constants/people';
import { getLast12Months } from '../components/shared/customDateRange/config';
import { PARAMS } from '../constants/pages';
import { isArrayEmpty, isObjectEmpty, replaceObjectInList } from './helpers';
import { getStartEndOfDayUtc } from './dateUtils';
import { GROUPED_SURVEY_REPORTING_TYPES } from '../constants/survey';
import { FIELD_TYPES } from '../constants/fieldTypes';

export const getPersonMeasuredAttributesParams = (params, isSelf = false) => {
  const [PeerTeamAndDirect, Self] = GROUPED_SURVEY_REPORTING_TYPES;
  const { SURVEY_TYPE, START_AT, END_AT, START, END } = PARAMS;
  const range = isObjectEmpty(params) ? getLast12Months() : params?.period;

  return {
    [SURVEY_TYPE]: isSelf ? Self.values : PeerTeamAndDirect.values,
    [START_AT]: getStartEndOfDayUtc(range[START]),
    [END_AT]: getStartEndOfDayUtc(range[END], true),
  };
};

export const prepareUserLevelAttributes = userLevelAttributes => {
  const { RANGE_FROM, RANGE_TO } = FIELD_TYPES;

  return userLevelAttributes.reduce(
    (acc, currentAttribute) => [
      ...acc,
      {
        ...currentAttribute.attribute,
        selfScore: null,
        scoreByOthers: null,
        [RANGE_FROM]: currentAttribute.attribute.text_reply_questions
          ? null
          : currentAttribute.range_from,
        [RANGE_TO]: currentAttribute.attribute.text_reply_questions
          ? null
          : currentAttribute.range_to,
        ...(currentAttribute.attribute.text_reply_questions
          ? { isTextReplySelf: false }
          : {}),
        ...(currentAttribute.attribute.text_reply_questions
          ? { isTextReplyByOthers: false }
          : {}),
      },
    ],
    []
  );
};

export const prepareMeasuredAttributes = (
  selfMeasuredAttributes,
  measuredByOthersAttributes,
  levelAttributes = []
) => {
  const attributesByOthers = [...measuredByOthersAttributes];
  const noResult =
    isArrayEmpty(selfMeasuredAttributes) && isArrayEmpty(attributesByOthers);
  const coreAttributes = prepareUserLevelAttributes(levelAttributes);
  if (noResult) return { core: coreAttributes, niche: [] };

  const attributesSelf = selfMeasuredAttributes.reduce(
    (acc, currentAttribute) => {
      const coreAttributeIndex = coreAttributes.findIndex(
        att => att.id === currentAttribute.id
      );

      const attributeByOthersIndex = attributesByOthers.findIndex(
        att => att.id === currentAttribute.id
      );
      const isMeasuredByOthers = attributeByOthersIndex !== -1;

      const scoreByOthers = isMeasuredByOthers
        ? attributesByOthers[attributeByOthersIndex].score
        : null;

      const updatedAttribute = {
        ...currentAttribute,
        selfScore: currentAttribute.score,
        scoreByOthers: isMeasuredByOthers ? scoreByOthers : null,
        ...(currentAttribute.text_reply_questions
          ? { isTextReplySelf: true }
          : {}),
        ...(currentAttribute.text_reply_questions && isMeasuredByOthers
          ? { isTextReplyByOthers: true }
          : {}),
      };
      if (isMeasuredByOthers) {
        attributesByOthers.splice(attributeByOthersIndex, 1);
      }

      if (coreAttributeIndex !== -1) {
        return {
          ...acc,
          core: replaceObjectInList(
            acc.core,
            coreAttributeIndex,
            updatedAttribute
          ),
        };
      }

      return { ...acc, niche: [...acc.niche, updatedAttribute] };
    },
    { core: [...coreAttributes], niche: [] }
  );

  const attributes = attributesByOthers.reduce(
    (acc, currentAttribute) => {
      const coreAttributeIndex = attributesSelf.core.findIndex(
        att => att.id === currentAttribute.id
      );

      const updatedAttribute = {
        ...currentAttribute,
        selfScore: null,
        scoreByOthers: currentAttribute.score,
        ...(currentAttribute.text_reply_questions
          ? { isTextReplyByOthers: true }
          : {}),
      };

      if (coreAttributeIndex !== -1) {
        return {
          ...acc,
          core: replaceObjectInList(
            acc.core,
            coreAttributeIndex,
            updatedAttribute
          ),
        };
      }

      return {
        ...acc,
        niche: [...acc.niche, updatedAttribute],
      };
    },
    {
      core: isArrayEmpty(attributesSelf)
        ? coreAttributes
        : [...attributesSelf.core],
      niche: [...attributesSelf.niche],
    }
  );

  return attributes;
};

export const isActiveUserView = selectedView =>
  selectedView === ACTIVE_DEFAULT_NAME;

export const isDeactivatedUserView = selectedView =>
  selectedView === DEACTIVATED_DEFAULT_NAME;

export const isExternalUserView = selectedView =>
  selectedView === EXTERNAL_DEFAULT_NAME;
