import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import SurveyWelcomeScreen from './surveyWelcomeScreen';
import SurveyHeader from './surveyHeader';
import SurveySteps from './surveySteps';
import ActionButton from '../actionButton';
import { isObjectEmpty } from '../../../utility/helpers';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ breakpoints }) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  closeButton: {
    position: 'fixed',
    top: 10,
    right: 12,
    zIndex: '9999',
    [breakpoints.up('sm')]: {
      top: 15,
      right: 20,
    },
  },
  closeButtonFirstStep: {
    top: 12,
  },
}));

const FillReviewFlow = ({
  translations,
  steps,
  activeStep,
  survey,
  review,
  surveyStep,
  feedbackErrors,
  answerReplyErrors,
  isInlineSurvey,
  isPreview,
  onSubmit,
  onStepChange,
  onGetStarted,
  onAnswerSelect,
  onAnswerChange,
  onSetFeedbackComments,
  onSetFeedbackErrors,
  onSetAnswerReplyErrors,
  onCloseInlineSurvey,
}) => {
  const classes = useStyles();

  const handleGoBack = () => onStepChange(activeStep - 1);

  return (
    <div className={classes.main}>
      {isInlineSurvey && (
        <ActionButton
          className={classNames(classes.closeButton, {
            [classes.closeButtonFirstStep]: isObjectEmpty(review),
          })}
          type={ACTION_BUTTON_TYPES.CLOSE}
          onClickHandler={onCloseInlineSurvey}
        />
      )}
      <SurveyHeader
        translations={translations}
        subject={survey.title}
        isInlineSurvey={isInlineSurvey}
        isAnonymous={survey.isAnonymous}
        hasAnonymousLabel={isObjectEmpty(review)}
        hasAppLogo={isObjectEmpty(review)}
      />
      {!isObjectEmpty(review) ? (
        <SurveySteps
          translations={translations}
          steps={steps}
          activeStep={activeStep}
          review={review}
          survey={survey}
          surveyStep={surveyStep}
          isPreview={isPreview}
          feedbackErrors={feedbackErrors}
          answerReplyErrors={answerReplyErrors}
          onSetFeedbackComments={onSetFeedbackComments}
          onSetAnswerReplyErrors={onSetAnswerReplyErrors}
          onSetFeedbackErrors={onSetFeedbackErrors}
          onAnswerSelect={onAnswerSelect}
          onAnswerChange={onAnswerChange}
          onStepChange={onStepChange}
          onGoBack={handleGoBack}
          onSubmit={onSubmit}
        />
      ) : (
        <SurveyWelcomeScreen
          translations={translations}
          survey={survey}
          onGetStarted={onGetStarted}
        />
      )}
    </div>
  );
};

FillReviewFlow.defaultProps = {
  isInlineSurvey: false,
  isPreview: false,
  feedbackErrors: {},
  answerReplyErrors: {},
  onCloseInlineSurvey: () => {},
  onSetFeedbackErrors: () => {},
  onSetFeedbackComments: () => {},
  onAnswerSelect: () => {},
  onAnswerChange: () => {},
  onSetAnswerReplyErrors: () => {},
};

FillReviewFlow.propTypes = {
  translations: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  survey: PropTypes.shape({}).isRequired,
  review: PropTypes.shape({}).isRequired,
  surveyStep: PropTypes.number.isRequired,
  activeStep: PropTypes.number.isRequired,
  feedbackErrors: PropTypes.shape({}),
  answerReplyErrors: PropTypes.shape({}),
  isInlineSurvey: PropTypes.bool,
  isPreview: PropTypes.bool,
  onCloseInlineSurvey: PropTypes.func,
  onSetFeedbackErrors: PropTypes.func,
  onSetAnswerReplyErrors: PropTypes.func,
  onSetFeedbackComments: PropTypes.func,
  onStepChange: PropTypes.func.isRequired,
  onAnswerSelect: PropTypes.func,
  onAnswerChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onGetStarted: PropTypes.func.isRequired,
};

export default FillReviewFlow;
