import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import Tooltip from '../tooltip';
import { ReactComponent as CheckedIcon } from '../../../assets/icons/check-box-checked.svg';
import { useCustomEffect } from '../../../utility/hooks';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    flexShrink: 0,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  highlightedLabel: {
    fontFamily: 'ProximaNova-Bold',
  },
  labelRootWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  labelRoot: {
    display: 'flex',
    margin: 0,
    flexShrink: 0,
    minHeight: 24,
    alignItems: 'center',
    marginLeft: spacing(-0.5),
    maxWidth: '100%',
  },
  labelText: {
    fontFamily: 'ProximaNova-Regular',
    fontSize: 16,
    lineHeight: '20px',
    color: primary.bluish2,
    marginLeft: spacing(2),
    marginTop: spacing(0.5),
    userSelect: 'none',
    wordBreak: 'break-word',
  },
  labelTextSmall: {
    fontSize: 14,
    lineHeight: '18px',
  },
  labelTextHighlighted: {
    fontFamily: 'ProximaNova-Bold',
  },
  labelTextDisabled: {
    color: `${primary.bluish7} !important`,
    userSelect: 'none',
  },
  checkboxRoot: {
    width: 'auto',
    height: 'auto',
    padding: 0,
  },
  iconWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    padding: spacing(1),
  },
  icon: {
    border: `2px solid ${primary.bluish4}`,
    borderRadius: 4,
    flexShrink: 0,
    width: 14,
    height: 14,
  },
  disabledIcon: {
    border: `2px solid ${primary.bluish7}`,
    backgroundColor: primary.white,
    flexShrink: 0,
  },
  checked: {
    flexShrink: 0,
  },
  disabledChecked: {
    backgroundColor: primary.white,
    '& path': {
      fill: primary.bluish7,
    },
  },
  tooltipIcon: {
    marginLeft: spacing(1),
    width: 14,
    height: 14,
  },
  labelIcon: {
    marginRight: spacing(2),
  },
  endLabelIcon: {
    marginLeft: spacing(2),
  },
  endTooltip: {
    cursor: 'default',
  },
  alignTop: {
    alignItems: 'flex-start',
  },
  labelAlignTop: {
    marginTop: spacing(0.75),
  },
  labelAlignTopSmall: {
    marginTop: spacing(1),
  },
  error: {
    marginTop: 'auto !important',
  },
}));

const CustomCheckbox = ({
  className,
  customCheckBoxRoot,
  customRootClass,
  customCheckboxClass,
  customCheckedCheckboxClass,
  labelTextClass,
  errorClass,
  icon,
  label,
  labelIcon: LabelIcon,
  endLabelIcon: EndLabelIcon,
  hasError,
  errorMessage,
  labelHelp,
  labelText,
  endLabelIconHelp,
  isChecked,
  disabled,
  isControlled,
  shouldTopAlign,
  smallText,
  highlightedText,
  isHighlightedLabel,
  onChange,
}) => {
  const classes = useStyles();
  const Icon = icon || CheckedIcon;

  const [checked, setChecked] = useState(false);

  const toggleCheckbox = checkedUpdated => setChecked(checkedUpdated);

  const onCheckboxCheck = e => {
    if (isControlled && !disabled) {
      e.preventDefault();
      e.stopPropagation();
      setChecked(!checked);
      onChange(!checked);
    }
  };

  useCustomEffect(() => {
    if (isControlled && isChecked !== checked) {
      toggleCheckbox(isChecked);
    }
  }, [isControlled, isChecked]);

  return (
    <FormControl className={classNames(classes.main, className)}>
      {label && (
        <FormLabel
          classes={{
            root: classNames(classes.label, {
              [classes.highlightedLabel]: isHighlightedLabel,
            }),
          }}
        >
          {LabelIcon && <LabelIcon className={classes.labelIcon} />}
          <span>{label}</span>
          {labelHelp && (
            <Tooltip customIconClass={classes.tooltipIcon} text={labelHelp} />
          )}
        </FormLabel>
      )}
      <div className={classes.labelRootWrapper}>
        <FormControlLabel
          classes={{
            root: classNames(
              classes.labelRoot,
              { [classes.alignTop]: shouldTopAlign },
              customRootClass
            ),
            label: labelText
              ? classNames(
                  classes.labelText,
                  {
                    [classes.labelTextSmall]: smallText,
                    [classes.labelAlignTop]: shouldTopAlign,
                    [classes.labelAlignTopSmall]: shouldTopAlign && smallText,
                    [classes.labelTextHighlighted]: highlightedText,
                    [classes.labelTextDisabled]: disabled,
                  },
                  labelTextClass
                )
              : '',
          }}
          label={labelText}
          disabled={disabled}
          control={
            <Checkbox
              classes={{
                root: classNames(classes.checkboxRoot, customCheckBoxRoot),
              }}
              checked={isChecked}
              icon={
                <div
                  className={classNames(
                    classes.iconWrapper,
                    customCheckboxClass
                  )}
                >
                  <div
                    className={classNames(classes.icon, {
                      [classes.disabledIcon]: disabled,
                    })}
                  />
                </div>
              }
              onChange={disabled || isControlled ? () => {} : onChange}
              onClick={onCheckboxCheck}
              checkedIcon={
                <div
                  className={classNames(
                    classes.iconWrapper,
                    customCheckedCheckboxClass
                  )}
                >
                  <Icon
                    className={classNames(classes.checked, {
                      [classes.disabledChecked]: disabled,
                    })}
                  />
                </div>
              }
              disableRipple
            />
          }
        />
        {EndLabelIcon && (
          <Tooltip
            className={classes.endTooltip}
            customIconClass={classes.endLabelIcon}
            text={endLabelIconHelp}
            icon={EndLabelIcon}
          />
        )}
      </div>
      {hasError && errorMessage && (
        <FormHelperText
          className={classNames(classes.error, errorClass)}
          error={hasError}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

CustomCheckbox.defaultProps = {
  className: '',
  customRootClass: '',
  customCheckboxClass: '',
  customCheckedCheckboxClass: '',
  labelTextClass: '',
  errorClass: '',
  label: '',
  labelIcon: null,
  endLabelIcon: null,
  labelHelp: '',
  hasError: false,
  errorMessage: '',
  endLabelIconHelp: '',
  labelText: '',
  isControlled: false,
  disabled: false,
  smallText: false,
  highlightedText: false,
  shouldTopAlign: false,
  isHighlightedLabel: false,
};

CustomCheckbox.propTypes = {
  className: PropTypes.string,
  customRootClass: PropTypes.string,
  customCheckboxClass: PropTypes.string,
  customCheckedCheckboxClass: PropTypes.string,
  labelTextClass: PropTypes.string,
  errorClass: PropTypes.string,
  label: PropTypes.string,
  labelHelp: PropTypes.string,
  labelText: PropTypes.string,
  endLabelIconHelp: PropTypes.string,
  labelIcon: PropTypes.object,
  endLabelIcon: PropTypes.object,
  smallText: PropTypes.bool,
  highlightedText: PropTypes.bool,
  isHighlightedLabel: PropTypes.bool,
  isChecked: PropTypes.bool.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  isControlled: PropTypes.bool,
  shouldTopAlign: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default CustomCheckbox;
