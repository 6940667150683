import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import DraggableScroll from '../../draggableScroll';
import InputField from '../../inputField';
import UserAvatar from '../../userAvatar';
import {
  getPersonShortName,
  replaceObjectInList,
} from '../../../../utility/helpers';
import { validateField } from '../../../../utility/validation';
import { FIELD } from './config';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      marginTop: spacing(2),
    },
    comments: {
      display: 'flex',
      flexDirection: 'column',
    },
    feedback: {
      fontSize: 20,
      lineHeight: '24px',
      marginBottom: spacing(3),
      [breakpoints.up('sm')]: {
        fontSize: 24,
        lineHeight: '28px',
        marginBottom: spacing(6),
      },
    },
    fieldWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: spacing(4),
      [breakpoints.up('sm')]: {
        marginBottom: spacing(6),
      },
    },
    field: {
      border: `1px solid ${primary.bluish6}`,
      borderRadius: 8,
    },
    error: {
      marginBottom: spacing(1),
    },
    fieldGroup: {
      '& textarea': {
        height: 80,
      },
    },
    fieldInputText: {
      fontSize: 14,
      lineHeight: '18px',
      '&::placeholder': {
        fontSize: 14,
        lineHeight: '18px',
      },
      [breakpoints.up('sm')]: {
        fontSize: 16,
        lineHeight: '20px',
        '&::placeholder': {
          fontSize: 16,
          lineHeight: '20px',
        },
      },
    },
    subject: {
      marginBottom: spacing(2),
      [breakpoints.up('sm')]: {
        marginBottom: spacing(4),
      },
    },
    userAvatar: {
      height: 20,
      width: 20,
      fontSize: 7,
      lineHeight: '18px',
      [breakpoints.up('sm')]: {
        fontSize: 14,
        lineHeight: 1,
        height: 32,
        width: 32,
      },
    },
    userLabel: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 12,
      lineHeight: '14px',
      whiteSpace: 'nowrap',
      [breakpoints.up('sm')]: {
        fontSize: 16,
        lineHeight: '18px',
      },
    },
  })
);

const FeedbackForm = ({
  translations,
  initialComments,
  subjects,
  errors,
  isSingleReview,
  isPreview,
  setErrors,
  setComments,
}) => {
  const classes = useStyles();

  const [comments, setUpdatedComments] = useState(initialComments);

  const handleOnChange = (commentIndex, userTracking) => async event => {
    event.persist();
    const { value } = event.target;
    const updatedComment = { comment: value };
    const caretStart = event.target.selectionStart;
    const caretEnd = event.target.selectionEnd;
    const error = await validateField(FIELD, value);
    const updatedComments = replaceObjectInList(
      comments,
      commentIndex,
      updatedComment
    );

    setUpdatedComments(updatedComments);
    setErrors(error, userTracking);
    setComments(updatedComments);
    event.target.setSelectionRange(caretStart, caretEnd);
  };

  return (
    <div className={classes.main}>
      <Typography variant="h3" className={classes.feedback}>
        {translations.title}
      </Typography>
      <DraggableScroll showDesktopScrollbars>
        <div className={classes.comments}>
          {subjects.map((subject, index) => {
            return (
              <div
                key={`review_comment_${subject.id}`}
                className={classNames(classes.fieldWrapper, {
                  [classes.error]: errors[subject.review_tracking],
                })}
              >
                {!isSingleReview && (
                  <UserAvatar
                    className={classes.subject}
                    customAvatarClass={classes.userAvatar}
                    labelClass={classes.userLabel}
                    getCustomCaption={getPersonShortName}
                    user={subject}
                    caption
                  />
                )}
                <InputField
                  className={classNames(classes.field, {
                    [classes.fieldGroup]: !isSingleReview,
                  })}
                  customInputClass={classes.fieldInputText}
                  name={FIELD.name}
                  type={FIELD.type}
                  placeholder={translations.placeholder}
                  onChange={handleOnChange(index, subject.review_tracking)}
                  disabled={isPreview}
                  error={!!errors[subject.review_tracking]}
                  errorMessage={
                    translations.validationMessages?.[
                      errors[subject.review_tracking]
                    ]
                  }
                  value={comments[index]?.comment || ''}
                  rows={FIELD.rows}
                  multiline={FIELD.multiline}
                  fullWidth
                />
              </div>
            );
          })}
        </div>
      </DraggableScroll>
    </div>
  );
};

FeedbackForm.defaultProps = {
  isPreview: false,
};

FeedbackForm.propTypes = {
  translations: PropTypes.object.isRequired,
  subjects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialComments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSingleReview: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  setErrors: PropTypes.func.isRequired,
  setComments: PropTypes.func.isRequired,
};

export default FeedbackForm;
