import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { alpha, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import { ReactComponent as DropdownIcon } from '../../../assets/icons/arrow-drop-down.svg';
import { ReactComponent as DefaultIcon } from '../../../assets/icons/notes.svg';
import { useCustomEffect } from '../../../utility/hooks';
import { ellipsis } from '../../../constants/helperCssRules';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    position: 'relative',
    height: 40,
  },
  dropdownIcon: {
    top: 'calc(50% - 8px)',
    right: 7,
  },
  inputRoot: {
    cursor: 'default',
  },
  selectRoot: {
    padding: spacing(2, 0),
    '& $radioButton': {
      display: 'none',
    },
    '& $menuItemLabel': {
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  name: {
    userSelect: 'none',
  },
  filterIconWrapper: {
    marginRight: spacing(1),
  },
  filterIcon: {
    marginRight: spacing(2),
    marginBottom: spacing(0.25),
  },
  paper: {
    backgroundColor: primary.white,
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish6}`,
    boxShadow: 'none',
    borderRadius: 4,
    filter: `drop-shadow(0px 4px 10px ${alpha(primary.black, 0.2)})`,
    marginTop: spacing(2),
  },
  menu: {
    width: 245,
    padding: 0,
  },
  menuItem: {
    padding: spacing(2, 4),
    '&:hover': {
      backgroundColor: primary.bluish8,
    },
  },
  menuItemActive: {
    backgroundColor: `${primary.bluish8} !important`,
  },
  menuItemContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  menuItemLabel: {
    flexGrow: 1,
    ...ellipsis(),
  },
  radioButton: {
    color: primary.bluish4,
    width: 24,
    height: 24,
  },
  radioChecked: {
    color: `${primary.bluish4} !important`,
  },
}));

const Filter = ({
  className,
  name,
  options,
  value,
  icon: Icon,
  isDisabled,
  onChange,
}) => {
  const classes = useStyles();
  const menuRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);

  useCustomEffect(() => {
    setAnchorEl(menuRef?.current);
  });

  const handleChange = e => {
    if (value !== e.target.value) {
      onChange(e.target.value);
    }
  };

  const renderAdornment = () => (
    <InputAdornment
      classes={{ root: classes.filterIconWrapper }}
      position="start"
    >
      {Icon ? (
        <Icon className={classes.filterIcon} />
      ) : (
        <DefaultIcon className={classes.filterIcon} />
      )}
      {name && (
        <Typography className={classes.name} variant="subtitle2">
          {name}:
        </Typography>
      )}
    </InputAdornment>
  );

  return (
    <div ref={menuRef} className={classNames(classes.main, className)}>
      <TextField
        value={value}
        InputProps={{
          classes: { root: classes.inputRoot },
          startAdornment: renderAdornment(),
          disabled: isDisabled,
        }}
        SelectProps={{
          classes: {
            root: classes.selectRoot,
            select: classes.select,
            icon: classes.dropdownIcon,
          },
          IconComponent: DropdownIcon,
          MenuProps: {
            classes: { list: classes.menu, paper: classes.paper },
            anchorEl,
            getContentAnchorEl: null,
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom',
            },
          },
        }}
        onChange={handleChange}
        select
      >
        {options.map(option => (
          <MenuItem
            key={option.id}
            classes={{
              root: classNames(classes.menuItem, {
                [classes.menuItemActive]: option.id === value,
              }),
            }}
            value={option.id}
          >
            <span className={classes.menuItemContent}>
              <Typography className={classes.menuItemLabel} component="span">
                {option.name}
              </Typography>
              <Radio
                classes={{
                  root: classes.radioButton,
                  checked: classes.radioChecked,
                }}
                checked={option.id === value}
              />
            </span>
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

Filter.defaultProps = {
  className: null,
  value: 0,
  options: [],
  isDisabled: false,
};

Filter.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Filter;
