import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { alpha, makeStyles } from '@material-ui/core';
import ActionButton from '../../../../../actionButton';
import { ACTION_BUTTON_TYPES } from '../../../../../actionButton/config';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    levelRoot: {
      cursor: 'default',
      display: 'grid',
      paddingTop: spacing(2),
      backgroundColor: primary.white,
      alignItems: 'center',
      justifyContent: 'space-between',
      gridTemplateColumns:
        'minmax(30px, 30px) minmax(100px, 761px) minmax(160px, 190px) minmax(90px, 190px) minmax(90px, 190px) minmax(30px, 40px)',
      boxSizing: 'border-box',
      borderBottom: `1px solid ${primary.bluish7}`,
      [breakpoints.up('xLg')]: {
        gridTemplateColumns:
          'minmax(30px, 30px) minmax(100px, 1476px) minmax(160px, 190px) minmax(160px, 190px) minmax(160px, 170px) minmax(60px, 60px)',
      },
      '& > div:first-child': {
        opacity: 0,
        transition: 'opacity .3s ease',
        paddingTop: 0,
        height: '100%',
        alignItems: 'start',
        top: '-1px',
        position: 'relative',
      },
      '&:hover': {
        boxShadow: `0px 2px 8px 0px ${alpha(primary.black, 0.17)}`,
        transition: 'box-shadow .3s ease',
        borderRadius: 8,
        zIndex: 999,
        position: 'relative',
        cursor: ({ isVisible }) => {
          return isVisible ? 'pointer' : 'grabbing';
        },
        '& > div:first-child': {
          opacity: ({ isVisible }) => {
            return isVisible ? 1 : 0;
          },
          transition: 'opacity .3s ease',
          cursor: ({ isVisible }) => {
            return isVisible ? 'pointer' : 'grabbing';
          },
        },
      },
      '&:active': {
        boxShadow: `0px 2px 8px 0px ${alpha(primary.black, 0.17)}`,
        transition: 'box-shadow .3s ease',
        borderRadius: 8,
        zIndex: 999,
        position: 'relative',
        '& > div:first-child': {
          opacity: 1,
          transition: 'opacity .3s ease',
        },
      },
    },
    readOnlyRoot: {
      width: 1081,
      gridTemplateColumns:
        'minmax(0px, 0px) minmax(384px, 1051px) minmax(238px, 348px)',
    },
    headerDisabled: {
      gridTemplateColumns:
        'minmax(0px, 0px) minmax(212px, 1516px) minmax(160px, 190px) minmax(160px, 190px) minmax(160px, 190px) minmax(30px, 40px)',
      [breakpoints.up('xLg')]: {
        gridTemplateColumns:
          'minmax(0px, 0px) minmax(212px, 1541px) minmax(160px, 190px) minmax(160px, 190px) minmax(160px, 170px) minmax(30px, 40px)',
      },
    },
    item: {
      boxSizing: 'border-box',
      padding: spacing(4, 2),
      [breakpoints.up('xLg')]: {
        padding: spacing(4),
      },
    },
    description: {
      minWidth: 0,
      height: '100%',
      padding: spacing(0, 4, 4, 0.5),
    },
    readOnlyDescription: {
      padding: spacing(0, 4, 4, 8),
    },
    dragItemWrapper: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
      padding: spacing(4, 4, 4, 0),
      [breakpoints.up('xLg')]: {
        padding: spacing(4, 4, 4, 0),
      },
    },
    readOnlyDragItem: {
      padding: spacing(0),
    },
    actions: {
      boxSizing: 'border-box',
      cursor: 'default',
      height: '100%',
      padding: spacing(0, 2),
      [breakpoints.up('xLg')]: {
        padding: spacing(0, 2),
      },
    },
    people: {
      width: 'fit-content',
      paddingTop: spacing(18),
      position: 'relative',
      height: '100%',
    },
    dragButton: {
      position: 'relative',
      width: 22,
      height: 22,
      flexShrink: 0,
      marginLeft: spacing(2),
      marginRight: spacing(1.5),
      zIndex: 11,
      overflow: 'visible',
      '& button': {
        width: 22,
        height: 22,
      },
    },
    dragIcon: {
      width: 8,
      height: 14,
    },
    dragTooltipLabel: {
      fontFamily: 'ProximaNova-Regular',
    },
    range: {
      marginLeft: spacing(4),
      height: '100%',
      paddingTop: spacing(18),
      position: 'relative',
    },
    attributes: {
      height: '100%',
      paddingTop: spacing(18),
      position: 'relative',
    },
  })
);

const Level = ({
  translations,
  level,
  dragRef,
  dragProps,
  isReadOnly,
  isAdmin,
  isVisible,
}) => {
  const classes = useStyles({ isVisible });
  const { description, attributes, range, people, actions } = level;

  return (
    <div
      ref={dragRef}
      className={classNames(classes.levelRoot, {
        [classes.readOnlyRoot]: isReadOnly,
        [classes.headerDisabled]: !isAdmin,
      })}
      {...(dragProps ? { ...dragProps.draggableProps } : {})}
    >
      <div
        className={classNames(classes.item, classes.dragItemWrapper, {
          [classes.readOnlyDragItem]: isReadOnly || !isAdmin,
        })}
      >
        {!isReadOnly && isAdmin && (
          <ActionButton
            className={classes.dragButton}
            iconClass={classes.dragIcon}
            tooltipLabelClass={classes.dragTooltipLabel}
            type={ACTION_BUTTON_TYPES.DRAG}
            tooltipText={translations.levelDragTooltip}
            {...(dragProps ? { ...dragProps.dragHandleProps } : {})}
          />
        )}
      </div>
      <div
        className={classNames(classes.item, classes.description, {
          [classes.readOnlyDescription]: isReadOnly || !isAdmin,
        })}
      >
        {description}
      </div>
      {!isReadOnly && (
        <>
          <div className={classes.attributes}>{attributes}</div>
          <div className={classes.range}>{range}</div>
          <div className={classes.people}>{people}</div>
        </>
      )}
      {isAdmin && !isReadOnly && (
        <div className={classes.actions}>{actions}</div>
      )}
    </div>
  );
};

Level.defaultProps = {
  dragRef: () => {},
  dragProps: null,
};

Level.propTypes = {
  translations: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  level: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  dragProps: PropTypes.object,
  dragRef: PropTypes.func,
  isAdmin: PropTypes.bool.isRequired,
};

export default memo(Level);
