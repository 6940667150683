import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonBase, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    boxSizing: 'border-box',
    backgroundColor: primary.white,
    border: `2px solid ${primary.blue2}`,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing(9),
    paddingRight: spacing(8),
    minWidth: 'auto',
    minHeight: 'auto',
    textTransform: 'capitalize',
    height: 35,
    padding: spacing(2.5, 3.5, 2),
    '&:hover': { cursor: 'pointer' },
  },
  leftButton: {
    borderRight: 'none',
    borderRadius: '34px 0 0 34px',
    padding: spacing(2, 4, 2, 4),
    '&$disabledButton': {
      borderRight: 'none',
    },
  },
  rightButton: {
    borderLeft: 'none',
    borderRadius: '0 34px 34px 0',
    padding: spacing(2, 4, 2, 4),
  },
  buttonRounded: {
    border: `1px solid ${primary.bluish5}`,
    height: 40,
  },
  fullWidth: {
    backgroundColor: primary.bluish8,
    border: 'none',
    height: 32,
    borderRadius: 8,
  },
  buttonActive: {
    border: 'none',
    backgroundColor: primary.bluish3,
  },
  buttonLabel: {
    color: primary.bluish1,
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'left',
  },
  buttonLabelFullWidth: {
    color: primary.bluish1,
    fontSize: 16,
    lineHeight: '20px',
    textAlign: 'center',
  },
  buttonLabelActive: {
    color: primary.white,
    fontSize: 16,
    lineHeight: '24px',
  },
  disabledButton: {
    backgroundColor: primary.bluish9,
    border: `1px solid ${primary.bluish6}`,
    height: 40,
  },
  disabledLabel: {
    color: primary.bluish5,
  },
  icon: { marginRight: spacing(4) },
}));

const ToggleButtonItem = ({
  id,
  data,
  isLeftButton,
  isActive,
  isDisabled,
  isFullWidth,
  handleClick,
}) => {
  const classes = useStyles();

  return (
    <ButtonBase
      id={`toggle-item-value-${id}`}
      className={classNames(
        classes.main,
        classes.buttonRounded,
        {
          [classes.leftButton]: isLeftButton,
          [classes.rightButton]: !isLeftButton,
          [classes.fullWidth]: isFullWidth,
          [classes.buttonActive]: isActive,
        },
        { [classes.disabledButton]: isDisabled }
      )}
      disabled={isDisabled}
      onClick={isDisabled ? () => {} : handleClick}
      focusRipple
    >
      {data.icon && (
        <img
          className={classes.icon}
          src={isActive ? data.icon.active : data.icon.default}
          alt={data.label}
        />
      )}
      <Typography
        variant="subtitle1"
        className={classNames(classes.buttonLabel, {
          [classes.buttonLabelActive]: isActive,
          [classes.disabledLabel]: isDisabled,
        })}
      >
        {data.label}
      </Typography>
    </ButtonBase>
  );
};

ToggleButtonItem.defaultProps = {
  id: undefined,
  isActive: false,
  isDisabled: false,
  isLeftButton: false,
  isFullWidth: false,
};

ToggleButtonItem.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isLeftButton: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

export default ToggleButtonItem;
