import { ATTRIBUTE_VIEW_OPTIONS } from '../constants/attributes';

export const hasQuantitativeAttributeScores = attributes =>
  attributes.some(attribute => attribute?.scoreByOthers !== null);

export const getTimelineTableColumns = labels => [
  { label: labels.sentAt },
  { label: labels.survey },
  { label: labels.status },
];

export const getFeedbackTableColumns = labels => [
  { label: labels.sentAt },
  { label: labels.comment },
  { label: labels.reviewer },
];

export const getAnswerWeight = answerWeight => parseFloat(answerWeight);

export const getActiveAttributes = attributes => {
  return attributes.filter(attribute => attribute.is_active);
};

export const getAttributesViewOptions = labels =>
  ATTRIBUTE_VIEW_OPTIONS.map(view => ({
    ...view,
    name: labels[view.key],
  }));
