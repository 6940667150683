import PropTypes from 'prop-types';
import BackLink from '../backLink';
import AuthFormLayout from '../authFormLayout';
import { ReactComponent as TonalityIcon } from '../../../assets/icons/tonality.svg';
import { AUTOMATION_ID } from '../../../constants/automationId';
import fields from './fields';

const LoginFormLayout = props => {
  const { translations, history } = props;

  return (
    <AuthFormLayout
      {...props}
      subtitleVariant="body1"
      icon={TonalityIcon}
      fields={fields}
    >
      <BackLink
        id={AUTOMATION_ID.FORGOTTEN_PASSWORD_BUTTON}
        title={translations.backLink}
        history={history}
        to="/forgotten-password"
      />
    </AuthFormLayout>
  );
};

LoginFormLayout.propTypes = {
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default LoginFormLayout;
