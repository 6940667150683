import PropTypes from 'prop-types';
import {
  Typography,
  ButtonBase,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import ActionButton from '../actionButton';
import { ReactComponent as PlaceholderIcon } from '../../../assets/icons/image-placeholder.svg';
import { isObject } from '../../../utility/helpers';
import { PHOTO_UPLOAD_CONFIG } from '../../../constants/uploadPhotoConfig';
import { validateFileType } from '../../../utility/validation';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    width: '100%',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    marginBottom: spacing(2),
  },
  placeholderContainer: {
    boxSizing: 'border-box',
    cursor: 'pointer',
    border: `1px solid ${primary.bluish1}`,
    borderRadius: 44,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0, 4),
    height: 40,
    userSelect: 'none',
  },
  placeholder: {
    marginLeft: spacing(2),
  },
  imageContainer: {
    borderRadius: 8,
    cursor: 'pointer',
    width: 106,
    height: 79,
  },
  image: {
    borderRadius: 8,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const AddImageField = ({
  label,
  image,
  placeholder,
  errorMessage,
  hasError,
  onAddImage,
}) => {
  const classes = useStyles();
  const { mimeTypes, multiple } = PHOTO_UPLOAD_CONFIG;

  const onAcceptImage = ([file]) => onAddImage(file);

  const onDeleteImage = () => onAddImage(null);

  const renderImage = () => (
    <div className={classes.imageContainer}>
      <img
        className={classes.image}
        src={isObject(image) ? URL.createObjectURL(image) : image}
        alt="course"
      />
    </div>
  );

  const renderPlaceholder = () => (
    <ButtonBase className={classes.placeholderContainer} component="div">
      <PlaceholderIcon />
      <Typography variant="subtitle1" className={classes.placeholder}>
        {placeholder}
      </Typography>
    </ButtonBase>
  );

  return (
    <div className={classes.main}>
      {label && (
        <Typography variant="body2" className={classes.label}>
          {label}
        </Typography>
      )}
      <div className={classes.content}>
        <Dropzone onDropAccepted={onAcceptImage} multiple={multiple}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {image && validateFileType(image, Object.keys(mimeTypes))
                ? renderImage()
                : renderPlaceholder()}
            </div>
          )}
        </Dropzone>
        {image && (
          <ActionButton
            type={ACTION_BUTTON_TYPES.DELETE}
            onClickHandler={onDeleteImage}
          />
        )}
      </div>
      {hasError && errorMessage && (
        <FormHelperText error={hasError}>{errorMessage}</FormHelperText>
      )}
    </div>
  );
};

AddImageField.defaultProps = {
  label: '',
  image: null,
};

AddImageField.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onAddImage: PropTypes.func.isRequired,
};

export default AddImageField;
