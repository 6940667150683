import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import QuestionEntry from '../questionEntry';
import CustomButton from '../customButton';
import AlertDialog from '../alertDialog';
import ConditionalTooltip from '../conditionalTooltip';
import http from '../../../utility/http';
import { getAnswerWeight } from '../../../utility/attribute';
import {
  createSingleQuestion,
  duplicateQuestion,
  reorder,
  isArrayEmpty,
  asyncDebounce,
} from '../../../utility/helpers';
import { ATTRIBUTES } from '../../../constants/attributes';
import { AUTOMATION_ID } from '../../../constants/automationId';
import { api_question } from '../../../constants/apiRoutes';

const useStyles = makeStyles(({ spacing }) => ({
  main: { width: '100%' },
  titleClass: {
    textTransform: 'none',
    marginBottom: spacing(6),
  },
  questions: {
    marginBottom: spacing(10),
  },
  removeSpace: {
    marginBottom: 0,
  },
}));

const ADD_QUESTION_DELAY = 250;

const AddAttributeQuestions = ({
  translations,
  attribute,
  dialogs,
  switchHorizontalWarning,
  questionsLimit,
  setDialogVisibility,
  deleteAttributeQuestion,
  getAttribute,
  updateAttributeQuestion,
  createQuestion,
  reorderQuestions,
  setAttribute,
}) => {
  const classes = useStyles();
  const { confirmQuestionDeletionOpened, confirmQuestionDuplicateOpened } =
    dialogs;

  const [questionId, setQuestionId] = useState(-1);
  const [question, setQuestion] = useState(null);

  const { questions, answer_number, id } = attribute;
  const isVertical = attribute.layout === ATTRIBUTES.verticalLayout;
  const hasDefinedAnswers =
    !attribute[ATTRIBUTES.fields.withTextReplyQuestions];
  const numberOfQuestions = attribute.questions.length;
  const isMaxQuestionsReached = numberOfQuestions >= questionsLimit;

  // constructor(props) {
  //   super(props);

  //   this.onAddNewQuestion = debounce(
  //     this.onAddNewQuestion,
  //     ADD_QUESTION_DELAY
  //   ).bind(this);
  //   this.onUpdateQuestion = this.onUpdateQuestion.bind(this);
  //   this.onDuplicateQuestion = this.onDuplicateQuestion.bind(this);
  //   this.onDeleteQuestion = this.onDeleteQuestion.bind(this);
  //   this.onDragEnd = this.onDragEnd.bind(this);
  // }

  // state = {
  //   questionId: -1,
  //   question: null,
  //   disabledDragItems: [],
  // };

  const onUpdateQuestion = updatedQuestion =>
    updateAttributeQuestion(updatedQuestion);

  const onDuplicateQuestion = currentQuestion => {
    setQuestion(currentQuestion);

    setDialogVisibility({
      dialogName: 'confirmQuestionDuplicate',
      opened: true,
    });
  };

  const handleSaveDuplicate = async () => {
    const { data } = await http.get(api_question(question.id));

    return createQuestion(
      duplicateQuestion(
        data,
        attribute.questions.length + 1,
        attribute.id,
        attribute.with_neutral_answer
      )
    ).then(() => {
      setDialogVisibility({
        dialogName: 'confirmQuestionDuplicate',
        opened: false,
      });
    });
  };

  const onDeleteQuestion = currentQuestionId => {
    setQuestionId(currentQuestionId);
    setDialogVisibility({
      dialogName: 'confirmQuestionDeletion',
      opened: true,
    });
  };

  const onDragEnd = result => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const newOrder = reorder(
      attribute.questions,
      result.source.index,
      result.destination.index
    );

    setAttribute({ questions: newOrder });

    reorderQuestions({
      attributeId: attribute.id,
      orderedIds: { order: newOrder.map(item => item.id) },
    });
  };

  const onAddNewQuestion = () => {
    const emptyQuestion = createSingleQuestion(
      translations.placeholders.questionTitle,
      questions.length + 1,
      answer_number,
      id
    );

    createQuestion(emptyQuestion);
  };

  return (
    <Grid container spacing={0} className={classes.main}>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.titleClass}>
          {translations.questions}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div
                  ref={provided.innerRef}
                  className={classNames(classes.questions, {
                    [classes.removeSpace]: isArrayEmpty(attribute.questions),
                  })}
                >
                  {attribute.questions.map((questionItem, index) => (
                    <Draggable
                      key={questionItem.id}
                      draggableId={questionItem.id.toString()}
                      index={index}
                      isDragDisabled={!attribute.is_editable}
                    >
                      {providedItem => (
                        <QuestionEntry
                          id={`${AUTOMATION_ID.QUESTION}-${index}`}
                          draggableProps={providedItem}
                          forwardedRef={providedItem.innerRef}
                          key={`question_${questionItem.id}`}
                          translations={translations}
                          questionIndex={index}
                          item={questionItem}
                          answerWeights={questionItem.answers?.map(answer =>
                            getAnswerWeight(answer.weight)
                          )}
                          disableActions={!attribute.is_editable}
                          isVertical={isVertical}
                          hasDefinedAnswers={hasDefinedAnswers}
                          hasNeutralAnswer={attribute.with_neutral_answer}
                          isDuplicateDisabled={isMaxQuestionsReached}
                          onDuplicate={onDuplicateQuestion}
                          onDelete={onDeleteQuestion}
                          onSave={onUpdateQuestion}
                          switchHorizontalWarning={switchHorizontalWarning}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <ConditionalTooltip
            addTooltip={isMaxQuestionsReached}
            message={translations.maxNumberOfQuestions}
          >
            <CustomButton
              id={AUTOMATION_ID.ADD_BUTTON}
              type="addWithTextDarkSlimNew"
              disabled={isMaxQuestionsReached}
              onClick={asyncDebounce(onAddNewQuestion, ADD_QUESTION_DELAY)}
              fullWidth
            >
              {translations.newQuestion}
            </CustomButton>
          </ConditionalTooltip>
        </div>
      </Grid>
      <AlertDialog
        translations={translations.deleteQuestionDialog}
        isOpened={confirmQuestionDeletionOpened}
        onClose={() => {
          setDialogVisibility({
            dialogName: 'confirmQuestionDeletion',
            opened: false,
          });
        }}
        onConfirm={() => {
          deleteAttributeQuestion(questionId).then(() => {
            getAttribute(attribute.id).then(() => {
              setDialogVisibility({
                dialogName: 'confirmQuestionDeletion',
                opened: false,
              });
            });
          });
        }}
        isWarning
      />
      <AlertDialog
        translations={translations.duplicateQuestionDialog}
        isOpened={confirmQuestionDuplicateOpened}
        onClose={() => {
          setDialogVisibility({
            dialogName: 'confirmQuestionDuplicate',
            opened: false,
          });
        }}
        onConfirm={handleSaveDuplicate}
      />
    </Grid>
  );
};

AddAttributeQuestions.propTypes = {
  translations: PropTypes.object.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  attribute: PropTypes.object.isRequired,
  updateAttributeQuestion: PropTypes.func.isRequired,
  questionsLimit: PropTypes.number.isRequired,
  reorderQuestions: PropTypes.func.isRequired,
  setAttribute: PropTypes.func.isRequired,
  dialogs: PropTypes.object.isRequired,
  createQuestion: PropTypes.func.isRequired,
  deleteAttributeQuestion: PropTypes.func.isRequired,
  getAttribute: PropTypes.func.isRequired,
  switchHorizontalWarning: PropTypes.func.isRequired,
};

export default AddAttributeQuestions;
