import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Avatar, makeStyles } from '@material-ui/core';
import CustomLink from '../../shared/customLink';
import { ReactComponent as NoAccessIcon } from '../../../assets/icons/domain_disabled.svg';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainAuthorized: {
    boxSizing: 'border-box',
    minHeight: 'calc(100vh - 153px)',
    paddingTop: spacing(12),
  },
  title: {
    marginBottom: spacing(3),
  },
  iconSize: {
    width: 120,
    height: 120,
  },
  avatarSection: {
    marginBottom: '1em',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    width: 160,
    height: 160,
    backgroundColor: primary.pink1,
  },
}));

const UnauthorizedAccessPage = ({ translations, isAuthorized, navigate }) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.main, {
        [classes.mainAuthorized]: isAuthorized,
      })}
    >
      <div className={classes.avatarSection}>
        <Avatar className={classes.avatar}>
          <NoAccessIcon className={classes.iconSize} />
        </Avatar>
      </div>
      <Typography variant="h1" className={classes.title}>
        {translations.pageTitle}
      </Typography>
      <CustomLink
        text={translations.goHome}
        handleClick={() => navigate('/')}
      />
    </div>
  );
};

UnauthorizedAccessPage.defaultProps = {
  isAuthorized: false,
};

UnauthorizedAccessPage.propTypes = {
  translations: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool,
};

export default UnauthorizedAccessPage;
