import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import UserAvatar from '../userAvatar';
import SubmittableInput from '../submittableInput';
import { validateField } from '../../../utility/validation';
import { asyncDebounce, trimString } from '../../../utility/helpers';
import { showSuccessMessage } from '../../../utility/uiUtils';
import { commentField, DELAY_VALIDATION } from './config';

const useStyles = makeStyles(({ spacing }) => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    margin: spacing(8, 0, 10, 0),
  },
  wrapRichText: {
    height: 150,
    alignItems: 'start',
    '& > div': {
      width: 'auto',
      minWidth: 'auto',
    },
  },
  comment: {
    display: 'flex',
    flexGrow: 1,
    marginLeft: spacing(4),
    paddingTop: 2,
  },
}));

const AddComment = ({
  translations,
  currentUser,
  isEdit,
  closeEditComment,
  isRichText,
  handleFocus,
  isFocused,
  isFocusedAdd,
  onSendComment,
  comment,
}) => {
  const classes = useStyles();

  const [error, setError] = useState('');
  const initComment = isEdit ? comment.comment : '';
  const [currentComment, setCurrentComment] = useState(initComment);
  const [isSendDisabled, setIsSendDisabled] = useState(true);

  const resetComment = () => {
    setError('');
    setCurrentComment('');
  };

  const validateComment = value => {
    return validateField(commentField(isRichText), value).then(errorType => {
      return Promise.resolve(setError(errorType));
    });
  };

  const handleChangeComment = value => {
    const isCommentEdited = value.trim() !== comment?.comment?.trim();

    if (!comment?.id) {
      closeEditComment();
    }
    setCurrentComment(value);
    setIsSendDisabled(!isCommentEdited);
    asyncDebounce(validateComment(value), DELAY_VALIDATION, true);
  };

  const handleSend = () => {
    const sanitizedComment = trimString(currentComment);

    return onSendComment(sanitizedComment, isEdit).then(() => {
      const message = isEdit ? translations.update : translations.success;
      showSuccessMessage(message);
      resetComment();
    });
  };

  const handleSave = () => {
    const newComment = trimString(currentComment);

    if (!newComment) return;
    asyncDebounce(validateComment(currentComment), DELAY_VALIDATION, true);

    if (!error) {
      handleSend();
    }
  };

  return (
    <div
      className={classNames(classes.wrap, {
        [classes.wrapRichText]: isRichText && (isFocused || isFocusedAdd),
      })}
    >
      <UserAvatar user={currentUser} medium />
      <div className={classes.comment}>
        <SubmittableInput
          placeholder={translations.placeholder}
          value={currentComment}
          hasError={!!error}
          errorMessage={translations.errorMessages[error]}
          onChange={handleChangeComment}
          onSubmit={handleSave}
          onCancel={closeEditComment}
          isEdit={isEdit}
          isSendDisabled={isSendDisabled}
          isRichText={isRichText}
          handleFocus={handleFocus}
          isFocused={isFocused}
          isFocusedAdd={isFocusedAdd}
        />
      </div>
    </div>
  );
};

AddComment.defaultProps = {
  isEdit: false,
  comment: {},
  closeEditComment: () => {},
  isRichText: false,
  isFocused: false,
  isFocusedAdd: false,
  handleFocus: () => {},
};

AddComment.propTypes = {
  translations: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  isEdit: PropTypes.bool,
  isRichText: PropTypes.bool,
  comment: PropTypes.object,
  closeEditComment: PropTypes.func,
  handleFocus: PropTypes.func,
  isFocused: PropTypes.bool,
  isFocusedAdd: PropTypes.bool,
};

export default AddComment;
