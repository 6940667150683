import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import InputField from '../../inputField';
import CustomCheckbox from '../../customCheckbox';
import UserAvatar from '../../userAvatar';
import CustomButton from '../../customButton';
import { validateField } from '../../../../utility/validation';
import { useCustomEffect } from '../../../../utility/hooks';
import { trimString } from '../../../../utility/helpers';
import { REPLY_FIELD } from './config';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: spacing(4),
      '&:last-of-type': {
        marginBottom: 0,
      },
      [breakpoints.up('sm')]: {
        marginBottom: spacing(6),
      },
    },
    reply: {
      marginBottom: spacing(3),
      [breakpoints.up('sm')]: {
        marginBottom: spacing(6),
      },
    },
    replyError: {
      marginBottom: spacing(1),
    },
    replyInput: {
      borderRadius: 8,
      '& textarea': {
        height: 144,
      },
      [breakpoints.up('sm')]: {
        '& textarea': {
          height: 174,
        },
      },
    },
    replyInputText: {
      fontSize: 14,
      lineHeight: '18px',
      '&::placeholder': {
        fontSize: 14,
        lineHeight: '18px',
      },
      [breakpoints.up('sm')]: {
        fontSize: 16,
        lineHeight: '20px',
        '&::placeholder': {
          fontSize: 16,
          lineHeight: '20px',
        },
      },
    },
    subject: {
      marginBottom: spacing(2),
      [breakpoints.up('sm')]: {
        marginBottom: spacing(4),
      },
    },
    userAvatar: {
      height: 20,
      width: 20,
      fontSize: 7,
      lineHeight: '18px',
      [breakpoints.up('sm')]: {
        fontSize: 14,
        lineHeight: 1,
        height: 32,
        width: 32,
      },
    },
    userLabel: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 12,
      lineHeight: '14px',
      whiteSpace: 'nowrap',
      [breakpoints.up('sm')]: {
        fontSize: 16,
        lineHeight: '18px',
      },
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
    },
    save: {
      padding: spacing(1, 2),
      marginLeft: 'auto',
      '&:disabled': {
        border: `1px solid ${primary.bluish7}`,
      },
      '& span': {
        fontFamily: 'ProximaNova-Bold',
        fontSize: 12,
        lineHeight: '24px',
        letterSpacing: 'normal',
        textTransform: 'capitalize',
      },
      [breakpoints.up('sm')]: {
        padding: spacing(2, 4),
        '& span': {
          fontSize: 16,
          lineHeight: '24px',
        },
      },
    },
    neutralAnswerCheckbox: {
      flexGrow: 1,
    },
    neutralAnswerLabel: {
      fontSize: 12,
      lineHeight: '16px',
      marginLeft: spacing(1),
      [breakpoints.up('sm')]: {
        fontSize: 14,
        lineHeight: '18px',
        marginLeft: spacing(2),
      },
    },
  })
);

const FreeTextAnswer = ({
  translations,
  questionId,
  subject,
  initialAnswer,
  error,
  isSingleReview,
  hasNeutralAnswer,
  isDisabled,
  onSetError,
  onChange,
}) => {
  const classes = useStyles();

  const [answer, setAnswer] = useState(initialAnswer);

  useCustomEffect(
    () => {
      setAnswer(initialAnswer);
      onSetError(null, subject);
    },
    [questionId],
    false
  );

  const handleOnChange = async event => {
    event.persist();
    const { value } = event.target;
    const caretStart = event.target.selectionStart;
    const caretEnd = event.target.selectionEnd;
    const currentError = await validateField(REPLY_FIELD, value);

    setAnswer(prevAnswer => ({
      ...prevAnswer,
      reply: value,
      not_applicable: !!prevAnswer?.not_applicable,
    }));
    onSetError(currentError, subject);
    event.target.setSelectionRange(caretStart, caretEnd);
  };

  const handleSaveReply = () => {
    if (
      !error &&
      trimString(initialAnswer.reply || '') !== trimString(answer.reply || '')
    ) {
      onChange(questionId, subject, answer);
    }
  };

  const handleNeutralAnswerChange = isNeutral => {
    setAnswer(prevAnswer => ({
      ...prevAnswer,
      reply: isNeutral ? translations.neutralAnswer.neutralValue : '',
      not_applicable: isNeutral,
    }));
    onSetError(null, subject);
  };

  return (
    <div className={classes.main}>
      {!isSingleReview && (
        <UserAvatar
          className={classes.subject}
          customAvatarClass={classes.userAvatar}
          labelClass={classes.userLabel}
          user={subject}
          caption
        />
      )}
      <div
        className={classNames(classes.reply, {
          [classes.replyError]: !!error,
        })}
      >
        <InputField
          className={classes.replyInput}
          customInputClass={classes.replyInputText}
          name={REPLY_FIELD.name}
          placeholder={
            answer.not_applicable
              ? translations.neutralAnswer.neutralValue
              : translations[REPLY_FIELD.name].placeholder
          }
          value={answer.reply || ''}
          rows={REPLY_FIELD.rows}
          multiline={REPLY_FIELD.isMultiline}
          error={!!error}
          errorMessage={
            translations[REPLY_FIELD.name].validationMessages?.[error]
          }
          onChange={handleOnChange}
          disabled={isDisabled || answer.not_applicable}
          fullWidth
        />
      </div>
      <div className={classes.footer}>
        {hasNeutralAnswer && (
          <CustomCheckbox
            className={classes.neutralAnswerCheckbox}
            labelTextClass={classes.neutralAnswerLabel}
            isChecked={!!answer.not_applicable}
            labelText={translations.neutralAnswer.text}
            disabled={isDisabled}
            onChange={handleNeutralAnswerChange}
            isControlled
            smallText
          />
        )}
        <CustomButton
          className={classes.save}
          type="addDarkRoundedOutlined"
          showIcon={false}
          disabled={
            !!error ||
            !trimString(answer.reply || '') ||
            trimString(initialAnswer.reply || '') ===
              trimString(answer.reply || '')
          }
          onClick={handleSaveReply}
        >
          {translations.save}
        </CustomButton>
      </div>
    </div>
  );
};

FreeTextAnswer.defaultProps = {
  initialAnswer: {},
  subject: {},
  error: null,
  isSingleReview: false,
  hasNeutralAnswer: false,
  isDisabled: false,
};

FreeTextAnswer.propTypes = {
  translations: PropTypes.shape({}).isRequired,
  subject: PropTypes.shape({}),
  questionId: PropTypes.number.isRequired,
  initialAnswer: PropTypes.shape({}),
  isSingleReview: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  hasNeutralAnswer: PropTypes.bool,
  onSetError: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FreeTextAnswer;
