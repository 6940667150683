import { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, FormHelperText } from '@material-ui/core';
import NotificationCard from '../notificationCard';
import CustomScrollBar from '../customScrollBar';
import { ReactComponent as NoSearchResultsIcon } from '../../../assets/icons/search-results.svg';
import NoImagePlaceholder from '../../../assets/icons/placeholder.png';
import { useCustomEffect } from '../../../utility/hooks';
import { isArrayEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  label: {
    lineHeight: '20px',
    marginBottom: spacing(4),
  },
  main: {
    backgroundColor: primary.bluish9,
    boxSizing: 'border-box',
    width: '100%',
    padding: spacing(4),
    height: 360,
    overflowY: 'auto',
  },
  course: {
    cursor: 'pointer',
    backgroundColor: primary.white,
    boxSizing: 'border-box',
    borderRadius: 8,
    display: 'flex',
    width: '100%',
    height: 114,
    padding: spacing(4),
    marginBottom: spacing(4),
    userSelect: 'none',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  selectedCourse: {
    padding: spacing(3.5),
    border: `2px solid ${primary.blue1}`,
  },
  courseImage: {
    borderRadius: 8,
    height: 'auto',
    width: 106,
    objectFit: 'cover',
    marginRight: spacing(4),
  },
  courseDescription: {
    marginTop: spacing(2),
  },
  truncateText: {
    height: 36,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  noResults: {
    paddingTop: spacing(25),
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: -11,
    height: '100%',
    width: 6,
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
}));

const SelectCourseField = ({
  label,
  courses,
  selectedCourse,
  noResults,
  errorMessage,
  isRequired,
  hasError,
  onSelectCourse,
}) => {
  const classes = useStyles();
  const scrollbarRef = useRef(null);

  useCustomEffect(
    () => {
      scrollbarRef?.current?.scrollToTop();
    },
    [courses],
    false
  );

  const handleSelect = course => () => {
    if (selectedCourse?.id !== course?.id) {
      onSelectCourse(course);
    }
  };

  const renderNoResults = () => (
    <NotificationCard
      className={classes.noResults}
      customIcon={NoSearchResultsIcon}
      title={noResults.title}
      content={noResults.message}
      hasIcon
    />
  );

  const renderCourses = () => (
    <CustomScrollBar
      ref={scrollbarRef}
      customScrollClass={classes.scroll}
      customScrollBarYClass={classes.scrollY}
      verticalScroll
    >
      <>
        {courses.map(course => (
          <div
            key={`course_item_${course.id}`}
            className={classNames(classes.course, {
              [classes.selectedCourse]: course.id === selectedCourse?.id,
            })}
            onClick={handleSelect(course)}
          >
            <img
              className={classes.courseImage}
              src={
                course.image ||
                course.image_240x135 ||
                course.cover_image ||
                NoImagePlaceholder
              }
              alt="course"
            />
            <div>
              <Typography variant="subtitle2" className={classes.truncateText}>
                {course.name || course.title}
              </Typography>
              <Typography
                variant="body2"
                className={classNames(
                  classes.courseDescription,
                  classes.truncateText
                )}
              >
                {course.headline || course.description}
              </Typography>
            </div>
          </div>
        ))}
      </>
    </CustomScrollBar>
  );

  return (
    <div>
      {label && (
        <FormHelperText className={classes.label}>
          {isRequired ? `${label}*` : label}
        </FormHelperText>
      )}
      <div className={classes.main}>
        {!isArrayEmpty(courses) ? renderCourses() : renderNoResults()}
      </div>
      {hasError && errorMessage && (
        <FormHelperText error={hasError}>{errorMessage}</FormHelperText>
      )}
    </div>
  );
};

SelectCourseField.defaultProps = {
  label: '',
  isRequired: false,
  hasError: false,
  errorMessage: '',
  selectedCourse: null,
};

SelectCourseField.propTypes = {
  label: PropTypes.string,
  noResults: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
  isRequired: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  courses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedCourse: PropTypes.shape({}),
  onSelectCourse: PropTypes.func.isRequired,
};

export default SelectCourseField;
