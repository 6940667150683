import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Typography,
  Popper,
  ClickAwayListener,
  Fade,
  makeStyles,
} from '@material-ui/core';
import { TransitionGroup } from 'react-transition-group';
import { forbiddenCharacters, validateLength } from 'utility/validation';
import { ellipsis } from '../../../constants/helperCssRules';
import InputField from '../inputField';
import CustomButton from '../customButton';
import CustomScrollBar from '../customScrollBar';
import ActionButton from '../actionButton';
import {
  isArrayEmpty,
  trimString,
  isValueInList,
} from '../../../utility/helpers';
import { useCustomEffect } from '../../../utility/hooks';
import { customSearch } from '../../../utility/uiUtils';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import ArrowTooltip from '../arrowTooltip';
import ConditionalTooltip from '../conditionalTooltip';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import { setPopperWidth } from '../peoplePicker/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  picker: {
    display: 'flex',
    position: 'relative',
    '& > div': {
      width: '100%',
    },
  },
  placeholder: {
    '& .MuiFormControl-root .MuiInputBase-root': {
      '& > input': {
        ...ellipsis(),
      },
      '& > input::placeholder': {
        color: primary.black,
      },
    },
  },
  popperRoot: {
    boxShadow: 'none',
    borderRadius: 4,
    zIndex: 1,
  },
  popperMain: {
    boxSizing: 'border-box',
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish6}`,
    height: 330,
    borderRadius: 4,
    padding: spacing(4),
    marginTop: spacing(1),
  },
  popperContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  popoverFooter: {
    display: 'flex',
    marginTop: spacing(4),
    paddingTop: 24,
    borderTop: `1px solid ${primary.bluish7}`,
    justifyContent: 'space-between',
    '& .MuiFormControl-root': {
      width: 235,
    },
  },
  item: {
    borderRadius: 4,
  },
  presetItem: {
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2),
    justifyContent: 'space-between',
    '& > p': {
      ...ellipsis(),
    },
  },
  buttonContainer: {
    display: 'flex',
    flexShrink: 0,
    width: 24,
    height: 24,
    alignItems: 'center',
  },
  noResults: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: spacing(25),
  },
  noResultsWithSelectAll: {
    marginTop: spacing(12.5),
  },
  scrollY: {
    top: 11,
    right: -10,
    height: 'calc(100% - 22px)',
    width: 4,
  },
  hover: {
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: primary.bluish8,
    },
  },
  selectButton: {
    marginLeft: 'auto',
  },
  selectedPreset: {
    backgroundColor: primary.bluish9,
    '& > div > p': {
      fontFamily: 'ProximaNova-Bold',
    },
    '& > p': {
      fontFamily: 'ProximaNova-Bold',
    },
  },
}));

const SCROLLBAR_HEIGHT = 200;

const PresetDropdown = ({
  className,
  paperClass,
  translations,
  activePreset,
  options,
  dropdownWidth,
  inputWidth,
  disabledPreset,
  alreadySavedMessage,
  presetPlaceholderName,
  isDisabled,
  handleSavePreset,
  handlePlaceholder,
  handleDelete,
  handleApply,
}) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');
  const [presets, setPresets] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [presetName, setPresetName] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const savePresetTooltip = disabledPreset || alreadySavedMessage;
  const shouldFlip = inputWidth ? anchorEl?.clientWidth < inputWidth : false;

  const handleClose = () => {
    setIsOpen(false);
    setSearch('');
    setPresets([]);
    setHasError(false);
    setErrorMessage('');
    setPresetName('');
    setIsFocused(false);
  };

  const onSetAnchorEl = () => setAnchorEl(inputRef?.current);

  const handleOpen = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const getPreset = (term = '') => {
    const searchTerm = trimString(term);
    const newPresets = customSearch(options, searchTerm);

    return setPresets(newPresets);
  };

  const handleChange = event => {
    const { value } = event.target;

    setSearch(value);
    handleOpen();
    return getPreset(value);
  };

  const handleInputFocus = () => {
    setIsOpen(true);
    setIsFocused(true);
  };

  const handleValidation = name => {
    let message = '';
    message = validateLength(name, 0, 100)
      ? message
      : translations.limitErrorMessage;
    message = forbiddenCharacters(name)
      ? message
      : translations.forbiddenCharacters;
    message = trimString(name) ? message : translations.emptyErrorMessage;
    const alreadyUsed = isValueInList(
      options.map(preset => preset.name),
      trimString(name)
    );

    if (message || alreadyUsed) {
      if (alreadyUsed) {
        setHasError(true);
        setErrorMessage(translations.availableName);
      } else {
        setHasError(true);
        setErrorMessage(message);
      }
      return false;
    }
    if (!message && !alreadyUsed && hasError) {
      setHasError(false);
      return true;
    }
    return true;
  };

  const handlePresetName = e => {
    const name = e.target.value;

    handleValidation(name);
    setPresetName(name);
  };

  const handleSave = () => {
    const isValid = handleValidation(presetName);

    if (isValid) {
      handleClose();
      handlePlaceholder(presetName);
      handleSavePreset(trimString(presetName));
    }
  };

  const applyPreset = preset => {
    handlePlaceholder(preset.name);
    handleApply(preset);
  };

  useCustomEffect(onSetAnchorEl);

  useCustomEffect(
    () => {
      if (isFocused) {
        getPreset();
      }
    },
    [isFocused],
    false
  );

  const renderPresets = () => {
    if (!isOpen) return null;

    if (!isArrayEmpty(presets)) {
      return (
        <TransitionGroup>
          {presets.map(preset => {
            const isPresetSelected = preset?.id === activePreset;
            return (
              <Fade key={preset.id} unmountOnExit appear addEndListener={null}>
                <div className={classes.item}>
                  <div
                    className={classNames(classes.presetItem, classes.hover, {
                      [classes.selectedPreset]: isPresetSelected,
                    })}
                    onClick={() =>
                      isPresetSelected ? null : applyPreset(preset)
                    }
                  >
                    <TextBoxWithTooltip text={preset.name}>
                      <Typography variant="body1">{preset.name}</Typography>
                    </TextBoxWithTooltip>
                    <div className={classes.buttonContainer}>
                      <ArrowTooltip
                        position="top"
                        tooltipLabel={translations.remove}
                      >
                        <ActionButton
                          type={ACTION_BUTTON_TYPES.DELETE_FILL}
                          onClickHandler={e => {
                            e.stopPropagation();
                            if (isPresetSelected) {
                              handlePlaceholder(translations.startSearch);
                            }
                            handleDelete(preset);
                          }}
                        />
                      </ArrowTooltip>
                    </div>
                  </div>
                </div>
              </Fade>
            );
          })}
        </TransitionGroup>
      );
    }
    return (
      <Typography className={classNames(classes.noResults)}>
        {translations.noPresetResults}
      </Typography>
    );
  };

  return (
    <div className={className}>
      <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">
        <div className={classes.picker}>
          <div
            className={classNames({
              [classes.placeholder]:
                presetPlaceholderName !== translations.startSearch,
            })}
            ref={inputRef}
          >
            <InputField
              label={translations.label}
              placeholder={presetPlaceholderName}
              value={search}
              disabled={isDisabled}
              onChange={handleChange}
              onInputFocus={handleInputFocus}
              autoComplete="off"
              fullWidth
            />
          </div>
          <Popper
            className={classNames(classes.popperRoot, paperClass)}
            style={{
              width: anchorEl ? anchorEl.clientWidth : null,
            }}
            anchorEl={anchorEl}
            open={isOpen}
            modifiers={{
              flip: {
                enabled: true,
                options: {
                  altBoundary: true,
                  rootBoundary: 'viewport',
                  allowedAutoPlacements: ['bottom-end'],
                  fallbackPlacements: ['bottom-end'],
                },
                preventOverflow: {
                  enabled: true,
                  altBoundary: true,
                  escapeWithReference: true,
                  boundariesElement: 'viewport',
                },
              },
            }}
            popperOptions={{
              onCreate: ({ instance: { reference, popper } }) =>
                setPopperWidth(reference, popper, dropdownWidth),
              onUpdate: ({ instance: { reference, popper } }) =>
                setPopperWidth(reference, popper, dropdownWidth),
            }}
            placement="bottom-start"
            disablePortal
            keepMounted={!!shouldFlip}
          >
            <div className={classes.popperMain}>
              <div className={classes.popperContent}>
                {isOpen && (
                  <CustomScrollBar
                    customScrollBarYClass={classes.scrollY}
                    scrollBarHeight={SCROLLBAR_HEIGHT}
                    verticalScroll
                    removeScrollX
                  >
                    {renderPresets()}
                  </CustomScrollBar>
                )}
              </div>
              <div className={classes.popoverFooter}>
                <InputField
                  name="name"
                  onChange={handlePresetName}
                  value={presetName}
                  placeholder={translations.inputPlaceholder}
                  error={hasError}
                  errorMessage={errorMessage}
                />
                <ConditionalTooltip
                  message={savePresetTooltip}
                  addTooltip={
                    Boolean(disabledPreset) || Boolean(alreadySavedMessage)
                  }
                >
                  <CustomButton
                    className={classes.selectButton}
                    type="withTextLightRounded"
                    disabled={
                      Boolean(disabledPreset) || Boolean(alreadySavedMessage)
                    }
                    onClick={handleSave}
                  >
                    {translations.saveButtonLabel}
                  </CustomButton>
                </ConditionalTooltip>
              </div>
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
    </div>
  );
};

PresetDropdown.defaultProps = {
  className: undefined,
  paperClass: null,
  disabledPreset: '',
  options: [],
  activePreset: -1,
  alreadySavedMessage: '',
  dropdownWidth: null,
  inputWidth: 0,
  handlePlaceholder: () => {},
};

PresetDropdown.propTypes = {
  className: PropTypes.string,
  paperClass: PropTypes.string,
  activePreset: PropTypes.number,
  dropdownWidth: PropTypes.string,
  inputWidth: PropTypes.number,
  handleApply: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSavePreset: PropTypes.func.isRequired,
  alreadySavedMessage: PropTypes.string,
  translations: PropTypes.object.isRequired,
  disabledPreset: PropTypes.string,
  handlePlaceholder: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

export default PresetDropdown;
