import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CommentItem from './commentItem';
import AddComment from '../addComment';
import AlertDialog from '../alertDialog';
import { ONE_ON_ONE_COMMENT_FIELDS } from '../../../constants/oneOnOne';

const  UsersComments = ({
  className,
  translations,
  currentUser,
  commentId,
  comments,
  isRichText,
  onSendComment,
  canAddComment,
  openEditComment,
  closeEditComment,
  onDeleteComment,
}) => {
  const { CREATOR } = ONE_ON_ONE_COMMENT_FIELDS;

  const [isDeleteCommentOpened, setIsDeleteCommentOpened] = useState(false);
  const [currentCommentId, setCurrentCommentId] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleDeleteComment = commId => {
    setIsDeleteCommentOpened(true);
    setCurrentCommentId(commId);
  };

  const onCancelDelete = () => {
    setIsDeleteCommentOpened(false);
    setCurrentCommentId(null);
  };

  const onDelete = () => {
    onDeleteComment(currentCommentId);
    onCancelDelete();
  };

  const canEditComment = currentComment => canAddComment && currentComment[CREATOR]?.id === currentUser.id;

  const handleFocus = (value = false) => setIsFocused(value);

  return (
    <div className={classNames({ [className]: !canAddComment })}>
      <>
        {canAddComment ? (
          <AddComment
            translations={translations.addComment}
            currentUser={currentUser}
            onSendComment={onSendComment}
            closeEditComment={closeEditComment}
            isRichText={isRichText}
            handleFocus={handleFocus}
            isFocusedAdd={isRichText}
          />
        ) : null}
        {comments.map(comment => (
          <CommentItem
            key={`comment_item_${comment.id}`}
            translations={translations}
            comment={comment}
            canEditComment={canEditComment(comment)}
            currentUser={currentUser}
            onSendComment={onSendComment}
            isEdit={commentId === comment.id}
            onCommentDelete={handleDeleteComment}
            openEditComment={openEditComment}
            closeEditComment={closeEditComment}
            isRichText={isRichText}
            handleFocus={handleFocus}
            isFocused={isFocused}
          />
        ))}
      </>
      <AlertDialog
        isOpened={isDeleteCommentOpened}
        translations={translations.deleteCommentDialog}
        onClose={onCancelDelete}
        onConfirm={onDelete}
        isWarning
      />
    </div>
  );
}

UsersComments.defaultProps = {
  className: undefined,
  isRichText: false,
  closeEditComment: () => {},
};

UsersComments.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  commentId: PropTypes.number.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isRichText: PropTypes.bool,
  canAddComment: PropTypes.bool.isRequired,
  onSendComment: PropTypes.func.isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  openEditComment: PropTypes.func.isRequired,
  closeEditComment: PropTypes.func,
};

export default UsersComments;
