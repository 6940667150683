import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade, makeStyles } from '@material-ui/core';
import CustomButton from '../customButton';
import GridTableHeader from './header';
import GridTableBody from './body';
import { AUTOMATION_ID } from '../../../constants/automationId';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  table: {
    display: 'grid',
  },
  loadMoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: spacing(4),
    width: '100%',
  },
  loadMoreButton: {
    border: `1px solid ${primary.bluish3}`,
    borderRadius: 44,
    color: primary.bluish4,
    lineHeight: '24px',
    justifySelf: 'center',
    padding: spacing(1, 4),
    maxHeight: 32,
  },
}));

const GridTable = ({
  className,
  customHeaderClass,
  translations,
  headers,
  rows,
  initialSort,
  preHeaderActions,
  headerActions,
  hasLoadMore,
  onLoadMore,
  onSort,
  onRowClick,
}) => {
  const classes = useStyles();
  const headerItems = [...preHeaderActions, ...headers, ...headerActions];

  return (
    <div className={classNames(classes.table, className)}>
      <GridTableHeader
        customHeaderClass={customHeaderClass}
        headers={headerItems}
        initialSort={initialSort}
        preHeaderActions={preHeaderActions}
        onSort={onSort}
      />
      <GridTableBody
        headers={headerItems}
        rows={rows}
        onRowClick={onRowClick}
      />
      <Fade in={hasLoadMore} unmountOnExit>
        <div className={classes.loadMoreWrapper}>
          <CustomButton
            id={AUTOMATION_ID.LOAD_MORE_BUTTON}
            type="addWithTextRounded"
            className={classes.loadMoreButton}
            onClick={onLoadMore}
          >
            {translations.loadMore}
          </CustomButton>
        </div>
      </Fade>
    </div>
  );
};

GridTable.defaultProps = {
  className: null,
  translations: {},
  preHeaderActions: [],
  headerActions: [],
  initialSort: {},
  customHeaderClass: null,
  hasLoadMore: false,
  rows: [],
  onRowClick: null,
  onSort: () => {},
  onLoadMore: () => {},
};

GridTable.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.shape({}),
  customHeaderClass: PropTypes.string,
  initialSort: PropTypes.object,
  rows: PropTypes.arrayOf(PropTypes.object),
  preHeaderActions: PropTypes.arrayOf(PropTypes.shape({})),
  headerActions: PropTypes.arrayOf(PropTypes.shape({})),
  hasLoadMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  onSort: PropTypes.func,
  onRowClick: PropTypes.func,
};

export default GridTable;
