import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormControl, FormHelperText, makeStyles } from '@material-ui/core';
import CustomCheckbox from '../customCheckbox';
import Tooltip from '../tooltip';
import { ReactComponent as TooltipIcon } from '../../../assets/icons/tooltip.svg';
import { isObjectEmpty, isArray, isArrayEmpty } from '../../../utility/helpers';
import { ellipsis } from '../../../constants/helperCssRules';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: '100%',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  labelIcon: {
    width: 13,
    height: 13,
    marginLeft: spacing(1),
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxRoot: {
    minHeight: 24,
    width: 'fit-content',
    maxWidth: '100%',
    '&:not(:last-of-type)': {
      marginBottom: spacing(3),
    },
  },
  checkboxLabel: {
    userSelect: 'none',
    maxWidth: 'calc(100% - 24px)',
    ...ellipsis(),
  },
}));

const CheckboxGroup = ({
  customLabelClass,
  checkboxContentClass,
  label,
  labelHelp,
  checkboxItems,
  checkedItems,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.root}>
      {label && (
        <div className={classes.labelContainer}>
          <FormHelperText
            className={classNames(classes.label, customLabelClass)}
          >
            <span>{label}</span>
            {!isObjectEmpty(labelHelp) && (
              <Tooltip
                customIconClass={classes.labelIcon}
                text={labelHelp.tooltipText}
                icon={TooltipIcon}
              />
            )}
          </FormHelperText>
        </div>
      )}
      {isArray(checkboxItems) && !isArrayEmpty(checkboxItems) ? (
        <div className={classNames(classes.content, checkboxContentClass)}>
          {checkboxItems.map(item => (
            <CustomCheckbox
              key={`checkbox_item_${item.id}`}
              customRootClass={classes.checkboxRoot}
              labelTextClass={classes.checkboxLabel}
              labelText={item.label}
              isChecked={checkedItems.includes(item.id)}
              disabled={item.isDisabled}
              onChange={() => onChange(item.id)}
            />
          ))}
        </div>
      ) : null}
    </FormControl>
  );
};

CheckboxGroup.defaultProps = {
  customLabelClass: '',
  label: '',
  labelHelp: {},
  checkboxItems: [],
  checkedItems: [],
  checkboxContentClass: '',
};

CheckboxGroup.propTypes = {
  customLabelClass: PropTypes.string,
  label: PropTypes.string,
  labelHelp: PropTypes.shape({}),
  checkboxItems: PropTypes.arrayOf(PropTypes.shape({})),
  checkboxContentClass: PropTypes.string,
  checkedItems: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
};

export default CheckboxGroup;
