import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import InputField from '../inputField';
import FormLayout from '../formLayout';
import NextButton from '../nextButton';
import { ReactComponent as UnionIcon } from '../../../assets/icons/union.svg';
import { API_DOMAIN } from '../../../utility/http';
import { validateOrganizationName } from '../../../store/modules/signUp';
import {
  isEmpty,
  validateOrganizationUrl,
  validateLength,
  validateFields,
} from '../../../utility/validation';
import { handleCustomHttpError, isObjectEmpty } from '../../../utility/helpers';
import { AUTOMATION_ID } from '../../../constants/automationId';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    main: {
      display: 'grid',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    content: {
      display: 'grid',
      justifyContent: 'center',
    },
    title: {
      marginBottom: spacing(2),
    },
    subtitle: {
      textAlign: 'center',
      justifyContent: 'center',
    },
    input: {
      borderRadius: 32,
      border: `1px solid ${primary.bluish7}`,
      gap: 8,
      height: 40,
      lineHeight: '24px',
      '&.MuiInput-root.Mui-focused': {
        border: `1px solid ${primary.bluish7}`,
      },
      '& > input': {
        borderRadius: 32,
        padding: spacing(1.75, 4, 1.75, 4),
        fontWeight: 400,
        fontFamily: 'ProximaNova-Regular',
        color: primary.bluish1,
        fontSize: 16,
        backgroundColor: primary.white,
      },
    },
    form: {
      display: 'flex',
      minHeight: 40,
      height: 40,
      alignItems: 'baseline',
      '& > p': {
        fontWeight: 600,
        fontFamily: 'ProximaNova-Bold',
        color: primary.black,
        fontSize: 16,
        lineHeight: '22.4px',
        height: 22,
        width: 90,
        margin: 0,
      },
    },
    domain: {
      marginTop: spacing(8),
    },
    subDomain: {
      display: 'flex',
      flexGrow: 1,
      maxWidth: 241,
      marginRight: spacing(1),
      '& .MuiFormControl-root': {
        width: 241,
      },
    },
    nextButton: {
      marginTop: spacing(6),
      marginBottom: 0,
      [breakpoints.up('xLg')]: {
        marginTop: spacing(8),
      },
      [breakpoints.up('xl')]: {
        marginTop: spacing(10),
      },
    },
  })
);

const { WORKSPACE_URL, SUB_DOMAIN_SIGN_IN } = AUTOMATION_ID;

const fields = [
  {
    id: WORKSPACE_URL,
    name: 'subDomain',
    type: 'text',
    label: 'enterWorkspaceSubdomain',
    required: true,
    validators: [
      {
        type: 'required',
        validator: isEmpty,
      },
      {
        type: 'invalid',
        validator: validateOrganizationUrl,
      },
      {
        type: 'maxLength',
        validator: value => validateLength(value, 0, 50),
      },
    ],
  },
];

const FIELD_NAME = 'subDomain';

const EnterOrganizationLoginForm = ({
  translations,
  values,
  errors,
  isSubmitting,
  handleSubmit,
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="row" spacing={0} className={classes.main}>
        <Grid item xs={12} className={classes.content}>
          <FormLayout
            icon={UnionIcon}
            field={{
              ...translations,
              title: translations.enterWorkspaceTitle,
              subtitle: translations.enterWorkspaceSubTitle,
            }}
            titleClass={classes.title}
            subtitleClass={classes.subtitle}
            subtitleVariant="subtitle1"
          >
            <div>
              <div className={classes.form}>
                <div className={classes.subDomain}>
                  {fields.map(field => (
                    <InputField
                      id={field.id}
                      key={field.name}
                      className={classes.input}
                      type={field.type}
                      name={field.name}
                      onChange={handleChange}
                      onBlur={() => {}}
                      value={values[field.name] || ''}
                      placeholder={translations[field.label]}
                      error={!isObjectEmpty(errors)}
                      errorMessage={translations[errors[field.name]]}
                    />
                  ))}
                </div>
                <Typography className={classes.domain}>
                  .{API_DOMAIN}
                </Typography>
              </div>
              <NextButton
                id={SUB_DOMAIN_SIGN_IN}
                isDisabled={isSubmitting || !isObjectEmpty(errors)}
                buttonLabel={translations.continue}
                customClass={classes.nextButton}
              />
            </div>
          </FormLayout>
        </Grid>
      </Grid>
    </form>
  );
};

EnterOrganizationLoginForm.propTypes = {
  translations: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default withFormik({
  mapPropsToValues: () => ({ subDomain: '' }),

  validate: async values => {
    throw await validateFields(fields, values);
  },
  validateOnBlur: false,
  handleSubmit: (values, { props, setSubmitting, setFieldError }) => {
    validateOrganizationName(values[FIELD_NAME])
      .then(({ data }) => {
        setSubmitting(false);

        if (data.exists) {
          const { port, protocol } = window.location;
          const currentPort = port && port.length > 0 ? `:${port}` : '';
          window.location = `${protocol}//${values[FIELD_NAME]}.${API_DOMAIN}${currentPort}/login`;
        } else {
          setFieldError(FIELD_NAME, 'notExist');
        }
      })
      .catch(error => {
        setSubmitting(false);
        handleCustomHttpError(error, props.translations.somethingWentWrong);
      });
  },
})(EnterOrganizationLoginForm);
