import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import CustomModal from '../customModal';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    columnGap: spacing(3.5),
    width: '100%',
    height: 218,
  },
  surveyType: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    border: `2px solid ${primary.bluish4}`,
    borderRadius: 16,
    padding: spacing(4),
    height: '100%',
  },
  icon: {
    marginBottom: spacing(2),
    width: 32,
    height: 32,
  },
  spacingBottom: {
    marginBottom: spacing(2),
  },
  selectedType: {
    border: `2px solid ${primary.blue1}`,
  },
  actionButtons: {
    marginTop: spacing(8),
  },
}));

const SurveyTypeDialog = ({
  translations,
  isOpened,
  surveyTypes,
  selectedType,
  onCancel,
  onSelect,
  onConfirm,
}) => {
  const classes = useStyles();

  return (
    <CustomModal
      customButtonsClass={classes.actionButtons}
      title={translations.title}
      isOpened={isOpened}
      confirmButtonLabel={translations.continue}
      closeButtonLabel={translations.cancel}
      onClose={() => {
        onCancel();
      }}
      onConfirm={onConfirm}
    >
      <div className={classes.content}>
        {surveyTypes.map(sType => {
          const { surveyIcon: Icon, name, description } = sType;

          return (
            <div
              key={`survey_type_${sType.id}`}
              className={classNames(classes.surveyType, {
                [classes.selectedType]: selectedType === sType.id,
              })}
              onClick={onSelect(sType.id)}
            >
              <Icon
                className={classNames(classes.icon, classes.spacingBottom)}
              />
              <div className={classes.spacingBottom}>
                {name.split(' ').map((titlePart, index) => (
                  <Typography
                    key={`title_part_${index}`}
                    variant="h5"
                    component="p"
                  >
                    {titlePart}
                  </Typography>
                ))}
              </div>
              <Typography variant="body2">{description}</Typography>
            </div>
          );
        })}
      </div>
    </CustomModal>
  );
};

SurveyTypeDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  surveyTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedType: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default SurveyTypeDialog;
