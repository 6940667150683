import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import InputField from '../inputField';
import ActionButton from '../actionButton';
import CustomRichTextEditor from '../customRichTextEditor';
import { ReactComponent as SendArrow } from '../../../assets/icons/send-arrow.svg';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  icon: {
    position: 'absolute',
    bottom: 4,
    right: 50,
    cursor: 'pointer',
  },
  cancelIcon: {
    position: 'absolute',
    bottom: 4,
    right: 12,
    cursor: 'pointer',
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'default',
    '& g path': {
      '&:nth-of-type(2)': {
        fill: primary.bluish5,
      },
    },
  },
  inputText: {
    '& > textarea': {
      maxHeight: 134,
    },
  },
  rootRichText: {
    width: '100%',
    height: 50,
  },
  mainRichText: {
    width: '100%',
  },
  textEditor: {
    height: 100,
  },
  richTextButton: {
    position: 'relative',
    right: 0,
    bottom: 0,
  },
  button: {
    justifyContent: 'right',
  },
  buttons: {
    justifyContent: 'space-between',
  },
  buttonBlock: {
    display: 'flex',
    bottom: 94,
    left: 763,
    position: 'relative',
    width: 66,
  },
  customError: {
    top: 148,
  },
}));

const SubmittableInput = ({
  className,
  errorClass,
  label,
  placeholder,
  value,
  errorMessage,
  isEdit,
  hasError,
  isSendDisabled,
  isRichText,
  isFocused,
  isFocusedAdd,
  handleFocus,
  onChange,
  onSubmit,
  onCancel,
}) => {
  const classes = useStyles();

  const renderSubmit = () => (
    <>
      <SendArrow
        className={classNames({
          [classes.icon]: isEdit,
          [classes.cancelIcon]: !isEdit,
          [classes.disabled]: isSendDisabled || hasError,
          [classes.richTextButton]: isRichText,
        })}
        onClick={() => {
          handleFocus();
          onSubmit();
        }}
      />
      {(isEdit || isFocused) && (
        <ActionButton
          className={classNames(classes.cancelIcon, {
            [classes.richTextButton]: isRichText,
          })}
          type={ACTION_BUTTON_TYPES.CLOSE}
          onClickHandler={() => {
            handleFocus();
            onCancel();
          }}
        />
      )}
    </>
  );

  return isRichText && (isFocused || isFocusedAdd) ? (
    <div className={classes.rootRichText}>
      <CustomRichTextEditor
        customEditorClass={classes.textEditor}
        customErrorClass={classes.customError}
        className={classes.mainRichText}
        label={label}
        placeholder={placeholder}
        value={value}
        hasError={hasError}
        errorMessage={errorMessage}
        onUpdateText={val => onChange(val)}
      />
      <div
        className={classNames(classes.buttonBlock, {
          [classes.button]: isFocusedAdd,
          [classes.buttons]: isFocused,
        })}
      >
        {renderSubmit()}
      </div>
    </div>
  ) : (
    <InputField
      className={classNames(classes.inputText, className)}
      customErrorClass={errorClass}
      label={label}
      placeholder={placeholder}
      error={hasError}
      errorMessage={errorMessage}
      value={value}
      onChange={e => onChange(e.target.value)}
      endAdornment={renderSubmit()}
      fullWidth
      multiline
    />
  );
};

SubmittableInput.defaultProps = {
  className: '',
  errorClass: '',
  label: '',
  placeholder: '',
  hasError: false,
  errorMessage: '',
  isEdit: false,
  onCancel: () => {},
  isSendDisabled: false,
  isRichText: false,
  isFocused: false,
  isFocusedAdd: false,
  handleFocus: () => {},
};

SubmittableInput.propTypes = {
  className: PropTypes.string,
  errorClass: PropTypes.string,
  isRichText: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isEdit: PropTypes.bool,
  isSendDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isFocusedAdd: PropTypes.bool,
  handleFocus: PropTypes.func,
};

export default SubmittableInput;
