import PropTypes from 'prop-types';
import AuthFormLayout from '../authFormLayout';
import BackLink from '../backLink';
import { ReactComponent as FlipIcon } from '../../../assets/icons/flip.svg';
import fields from './fields';

const ResetPasswordFormLayout = props => {
  const { translations, history, name, withoutBackLink } = props;

  if (name && name.length > 0) {
    const personalizedTranslations = {
      ...translations,
      title: translations.welcome,
      description: translations.descriptionSetPassword,
    };

    return (
      <AuthFormLayout
        {...props}
        translations={personalizedTranslations}
        fields={fields}
        icon={FlipIcon}
        isPaswordReset
        privacyPolicyCheck
      />
    );
  }
  return (
    <AuthFormLayout {...props} fields={fields} icon={FlipIcon} isPaswordReset>
      {withoutBackLink ? null : (
        <BackLink title={translations.backLink} history={history} to="/login" />
      )}
    </AuthFormLayout>
  );
};

ResetPasswordFormLayout.propTypes = {
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default ResetPasswordFormLayout;
