import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ConditionalTooltip from '../conditionalTooltip';
import LevelBars from '../levelBars';
import DotsMenu from '../dotsMenu';
import AttributeChip from '../attributeChip';
import Tag from '../tag';
import { useCustomEffect } from '../../../utility/hooks';
import { isArrayEmpty, isEllipsisActive } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    boxSizing: 'border-box',
    borderRadius: 4,
    border: `1px solid ${primary.bluish6}`,
    display: 'grid',
    gridColumnGap: 1,
    gridTemplateColumns: 'repeat(2, 1fr)',
    maxWidth: 900,
    minHeight: 334,
  },
  section: {
    boxSizing: 'border-box',
    position: 'relative',
    padding: spacing(6),
    maxWidth: 450,
    '&:first-of-type:after': {
      content: '""',
      borderRight: `1px dashed ${primary.bluish6}`,
      display: 'block',
      position: 'absolute',
      height: '100%',
      top: 0,
      right: -1,
    },
  },
  coverImageWrapper: {
    backgroundColor: primary.bluish5,
    borderRadius: 8,
    marginBottom: spacing(6),
    width: 138,
    height: 105,
  },
  coverImage: {
    borderRadius: 8,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  title: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    marginBottom: spacing(4),
  },
  description: {
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  detailsWrapper: {
    display: 'flex',
  },
  details: {
    flexGrow: 1,
    maxWidth: ({ isReadOnly }) => (isReadOnly ? '100%' : 'calc(100% - 24px)'),
  },
  detailsItem: {
    marginBottom: spacing(6),
  },
  label: {
    color: primary.bluish4,
    marginBottom: spacing(2),
  },
  attributeWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    height: 28,
    padding: spacing(1, 0),
    maxWidth: 'fit-content',
  },
  hasAttribute: {
    padding: 0,
  },
  tagsWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: 28,
    width: '100%',
  },
  tag: {
    margin: spacing(0, 2, 2, 0),
  },
  link: {
    textDecoration: 'none',
  },
  linkText: {
    color: primary.blue1,
  },
  actions: {
    flexShrink: 0,
    width: 24,
    height: 24,
  },
}));

const CourseDetailsCard = ({
  translations,
  course,
  levels,
  actions,
  isReadOnly,
}) => {
  const classes = useStyles();
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  const { title, description, cover_image, level, attribute, tags, url } =
    course;

  const [hasTitleTooltip, setHasTitleTooltip] = useState(false);
  const [hasDescriptionTooltip, setHasDescriptionTooltip] = useState(false);

  const shouldShowTooltips = (titleNode, descriptionNode) => {
    setHasTitleTooltip(!!isEllipsisActive(titleNode, true));
    setHasDescriptionTooltip(isEllipsisActive(descriptionNode, true));
  };

  useCustomEffect(
    () => shouldShowTooltips(titleRef?.current, descriptionRef?.current),
    [course.title, course.description]
  );

  const renderCoverImage = () => (
    <div className={classes.coverImageWrapper}>
      {cover_image && (
        <img
          className={classes.coverImage}
          src={cover_image}
          alt="course cover"
        />
      )}
    </div>
  );

  const renderInfo = (isTitle = true) => (
    <ConditionalTooltip
      addTooltip={isTitle ? hasTitleTooltip : hasDescriptionTooltip}
      message={isTitle ? title : description}
    >
      <Typography
        ref={isTitle ? titleRef : descriptionRef}
        className={classNames({
          [classes.title]: isTitle,
          [classes.description]: !isTitle,
        })}
        variant={isTitle ? 'h5' : 'body2'}
      >
        {isTitle ? title : description}
      </Typography>
    </ConditionalTooltip>
  );

  const renderDetails = () => (
    <div className={classes.detailsWrapper}>
      <div className={classes.details}>
        <div className={classes.detailsItem}>
          <Typography className={classes.label} variant="body2">
            {translations.level}
          </Typography>
          <LevelBars
            noLevelLabel={translations.all}
            level={level}
            levels={levels.slice(1)}
          />
        </div>
        <div className={classes.detailsItem}>
          <Typography className={classes.label} variant="body2">
            {translations.attribute}
          </Typography>
          <div
            className={classNames(classes.attributeWrapper, {
              [classes.hasAttribute]: !!attribute,
            })}
          >
            {attribute ? (
              <AttributeChip name={attribute.name} color={attribute.color} />
            ) : (
              '-'
            )}
          </div>
        </div>
        <div className={classes.detailsItem}>
          <Typography className={classes.label} variant="body2">
            {translations.tags}
          </Typography>
          {!isArrayEmpty(tags) ? (
            <TransitionGroup className={classes.tagsWrapper}>
              {tags.map(tag => (
                <Fade key={`tag_item_${tag.id}`} in>
                  <div className={classes.tag}>
                    <Tag tag={tag} color={tag.category.color} isSelected />
                  </div>
                </Fade>
              ))}
            </TransitionGroup>
          ) : (
            <div className={classes.tagsWrapper}>-</div>
          )}
        </div>
        <div className={classes.detailsItem}>
          <Typography className={classes.label}>{translations.url}</Typography>
          <Link
            className={classes.link}
            to={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography className={classes.linkText} variant="subtitle2">
              {translations.visitCourse}
            </Typography>
          </Link>
        </div>
      </div>
      {!isReadOnly && (
        <DotsMenu className={classes.actions} menuItems={actions} />
      )}
    </div>
  );

  return (
    <div className={classes.main}>
      <div className={classes.section}>
        {renderCoverImage()}
        {renderInfo()}
        {description && renderInfo(false)}
      </div>
      <div className={classes.section}>{renderDetails()}</div>
    </div>
  );
};

CourseDetailsCard.propTypes = {
  translations: PropTypes.object.isRequired,
  course: PropTypes.shape({}).isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default CourseDetailsCard;
