import { useState, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import PageContainer from '../pageContainer';
import AvatarUploadZone from '../avatarUploadZone';
import ShareTypeBox from '../shareTypeBox';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import ArrowTooltip from '../arrowTooltip';
import { ReactComponent as LogoSlack } from '../../../assets/icons/slack-no-text.svg';
import { personSelector } from '../../../store/selectors/personSelector';
import { updateCurrentUserInfo } from '../../../store/modules/auth';
import {
  clearPerson,
  getPerson,
  updatePersonPhoto,
} from '../../../store/modules/person';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../../utility/tagManager';
import {
  canManagePerson,
  checkUserRole,
  isObjectEmpty,
} from '../../../utility/helpers';
import { getPersonFullName } from '../../../utility/uiUtils';
import { sticky } from '../../../constants/helperCssRules';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { getShareTypeLabel, getNavItems } from './config';
import { useCustomEffect } from '../../../utility/hooks';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    position: 'relative',
  },
  pageTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: spacing(5),
    ...sticky(primary.white),
  },
  stickyHorizontalNavigation: {
    position: 'sticky',
    zIndex: 999,
    background: primary.white,
    top: 84,
    paddingBottom: spacing(6),
  },
  avatar: {
    marginRight: spacing(4),
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 600,
  },
  rightSide: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
  shareType: {
    flexShrink: 0,
    marginLeft: spacing(4),
  },
  slackIcon: {
    width: 48,
    height: 48,
  },
}));

const PeoplePageContainer = ({
  translations,
  location,
  auth,
  userId,
  children,
  shouldPassProps,
  navigate,
  dispatch,
  ...rest
}) => {
  const classes = useStyles();
  const { VisitEMP } = EVENT_ACTION_TYPES;

  const user = useSelector(personSelector);

  const [isLoading, setIsLoading] = useState(true);

  const currentLocation = location.pathname;
  const isUserLoaded = !isObjectEmpty(user);
  const navigationItems = useMemo(
    () => getNavItems(translations.pageContainer, auth, userId),
    [translations, auth, userId]
  );

  useCustomEffect(() => {
    getPerson(dispatch, userId).then(({ data }) => {
      const isAssociate = checkUserRole(data.role, ROLES.ASSOCIATE);

      if (isAssociate) {
        return navigate('/not-found', { replace: true });
      }

      setIsLoading(false);
      tagManagerDataLayer(
        VisitEMP.action,
        VisitEMP.name,
        location.pathname.replace(userId, ':id')
      );
    });

    return () => {
      if (
        currentLocation !== `/people/${userId}` &&
        currentLocation !== `/people/${userId}/general-info` &&
        !currentLocation.includes(`/people/${userId}/action-plans`) &&
        currentLocation !== `/people/${userId}/insights` &&
        currentLocation !== `/people/${userId}/my-people` &&
        currentLocation !== `/people/${userId}/notes` &&
        currentLocation !== `/people/${userId}/learning` &&
        !currentLocation.includes(`/people/${userId}/one-on-one`)
      ) {
        return dispatch(clearPerson());
      }
    };
  }, [userId, currentLocation]);

  const onSuccessUpload = files => {
    const formData = new FormData();
    formData.append('avatar', files[0]);

    updatePersonPhoto(dispatch, user.id, formData).then(() => {
      if (user.id === auth.id)
        dispatch(updateCurrentUserInfo({ avatar: user.avatar }));
    });
  };

  const renderPageHeader = () => {
    const canChangeAvatar =
      auth.id === user.id || canManagePerson(auth, user.id);
    const shareTypeLabel = getShareTypeLabel(
      translations.pageContainer,
      auth,
      user
    );

    return (
      <div className={classes.headerTitle}>
        <div className={classes.avatar}>
          <AvatarUploadZone
            translations={translations.pageContainer.avatarUpload}
            user={user}
            disabled={!canChangeAvatar}
            onSuccessHandler={onSuccessUpload}
          />
        </div>
        <TextBoxWithTooltip variant="h1" text={getPersonFullName(user)} />
        {user?.slack_id && (
          <ArrowTooltip
            position="right"
            tooltipLabel={translations.pageContainer.slackTooltip}
          >
            <LogoSlack className={classes.slackIcon} />
          </ArrowTooltip>
        )}
        {shareTypeLabel && (
          <ShareTypeBox className={classes.shareType} label={shareTypeLabel} />
        )}
      </div>
    );
  };

  return (
    <PageContainer
      {...rest}
      translations={translations}
      auth={auth}
      location={location}
      personNavItems={navigationItems}
      user={user}
      headerProps={{
        renderTitle: isUserLoaded ? renderPageHeader : undefined,
      }}
      isLoading={!isUserLoaded}
      shouldPassProps={shouldPassProps}
      navigate={navigate}
      dispatch={dispatch}
    >
      {!isLoading && isUserLoaded && user.id === userId ? (
        children
      ) : (
        <Fragment />
      )}
    </PageContainer>
  );
};

PeoplePageContainer.defaultProps = {
  shouldPassProps: true,
};

PeoplePageContainer.propTypes = {
  translations: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  shouldPassProps: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default PeoplePageContainer;
