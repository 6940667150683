import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CustomModal from '../customModal';
import CustomScrollBar from '../customScrollBar';
import RichTextPreview from '../richTextPreview';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  paper: {
    boxSizing: 'border-box',
    width: 850,
    height: 472,
  },
  contentWrapper: {
    position: 'relative',
    borderTop: `1px solid ${primary.bluish5}`,
    height: '100%',

    '&::before': {
      backgroundColor: primary.bluish5,
      content: '""',
      position: 'absolute',
      top: -1,
      left: -32,
      width: 32,
      height: 1,
    },
    '&::after': {
      backgroundColor: primary.bluish5,
      content: '""',
      position: 'absolute',
      top: -1,
      right: -32,
      width: 32,
      height: 1,
    },
  },
  content: {
    width: 640,
    margin: spacing(9, 'auto', 0, 'auto'),
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 12,
    right: -20,
    height: 'calc(100% - 12px)',
    width: 8,
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
}));

const PreviewDescriptionDialog = ({
  title,
  description,
  isOpened,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <CustomModal
      paperClass={classes.paper}
      isOpened={isOpened}
      title={title}
      onClose={onClose}
      shouldRemoveFooter
    >
      <div className={classes.contentWrapper}>
        <CustomScrollBar
          customScrollBarYClass={classes.scrollY}
          customScrollClass={classes.scroll}
          verticalScroll
          removeScrollX
        >
          <div className={classes.content}>
            <RichTextPreview text={description} />
          </div>
        </CustomScrollBar>
      </div>
    </CustomModal>
  );
};

PreviewDescriptionDialog.defaultProps = {
  description: '',
};

PreviewDescriptionDialog.propTypes = {
  title: PropTypes.string.isRequired,
  isOpened: PropTypes.bool.isRequired,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default PreviewDescriptionDialog;
