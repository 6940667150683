export const ATTRIBUTE_COLORS = [
  '#86D5E4',
  '#85D0E5',
  '#84CAE7',
  '#83C6E9',
  '#82C3EA',
  '#82BFEB',
  '#80BAEC',
  '#80B5EE',
  '#80B2EE',
  '#7FADF0',
  '#7EA9F2',
  '#7EA4F3',
  '#7DA1F5',
  '#7D9DF6',
  '#7E9BF7',
  '#E3CB7A',
  '#E4C97A',
  '#E4C57A',
  '#E6C37A',
  '#E6C27B',
  '#E9C07C',
  '#E9BD7C',
  '#EBBB7D',
  '#EBB97C',
  '#EDB77E',
  '#EEB47E',
  '#EFB27E',
  '#F0B07F',
  '#F2AF80',
  '#F3AD80',
  '#CDFB89',
  '#C8F989',
  '#C4F889',
  '#BFF688',
  '#BBF488',
  '#B5F187',
  '#B1F087',
  '#AEEF87',
  '#A8ED86',
  '#A4EC86',
  '#9FEA86',
  '#9BE885',
  '#96E685',
  '#91E484',
  '#8FE484',
  '#EF9CC2',
  '#EA9AC6',
  '#E798CA',
  '#E196CE',
  '#DB93D2',
  '#D790D4',
  '#D28FD8',
  '#CC8CDC',
  '#C98ADF',
  '#C589E2',
  '#BF87E7',
  '#BB86EB',
  '#B583EE',
  '#B081F3',
  '#AB7EF5',
];

export const ATTRIBUTES = {
  fields: {
    preset: 'preset',
    answersCount: 'answer_number',
    layoutType: 'layout',
    answerItems: 'answer_items',
    withNeutralAnswer: 'with_neutral_answer',
    withZeroValueAnswer: 'with_zero_weight_answer',
    withAdditionalFeedback: 'feedback_field',
    withTextReplyQuestions: 'text_reply_questions',
  },
  verticalLayout: 'VERTICAL',
  horizontalLayout: 'HORIZONTAL',
  answerStyle: {
    textBox: 'TEXT',
    scale: 'SCALE',
  },
  minAnswers: 2,
  maxAnswers: 15,
  answersBreakpoint: 6,
  fieldsStyleOptions: {
    increaseAnswersCount: 'increaseAnswersCount',
    decreaseAnswersCount: 'decreaseAnswersCount',
    toOpenEndedQuestions: 'toOpenEndedQuestions',
    toMultipleChoiceQuestions: 'toMultipleChoiceQuestions',
    addNeutralAnswer: 'addNeutralAnswer',
    removeNeutralAnswer: 'removeNeutralAnswer',
    addAdditionalFeedback: 'addAdditionalFeedback',
    removeAdditionalFeedback: 'removeAdditionalFeedback',
  },
};

export const PLACEHOLDER_ATTRIBUTE = {
  selfScore: '/',
  scoreByOthers: '/',
  max_score: 10,
};

export const SKILLS_MATRIX_ATTRIBUTE_CONFIG = {
  MIN: 1,
  DEFAULT: 10,
  MAX: 30,
};

export const COMPETENCE_MAP_ATTRIBUTE_CONFIG = {
  DEFAULT: 3,
  MIN: 1,
  MAX: 5,
};

export const PEOPLE_INSIGHTS_ATTRIBUTE_CONFIG = {
  DEFAULT: 3,
  MIN: 1,
  MAX: 30,
};

export const ATTRIBUTE_VIEW_OPTIONS = [
  {
    value: true,
    name: 'Active',
    key: 'active',
  },
  {
    value: false,
    name: 'Inactive',
    key: 'inactive',
  },
];
