import PropTypes from 'prop-types';
import fields from './fields';
import BackLink from '../backLink';
import AuthFormLayout from '../authFormLayout';
import { AUTOMATION_ID } from '../../../constants/automationId';
import { ReactComponent as SendIcon } from '../../../assets/icons/send.svg';

const ForgottenPasswordFormLayout = props => {
  const { translations, history } = props;

  return (
    <AuthFormLayout
      {...props}
      subtitleVariant="body1"
      icon={SendIcon}
      fields={fields}
    >
      <BackLink
        id={AUTOMATION_ID.SIGN_IN_BUTTON}
        title={translations.backLink}
        history={history}
        to="/login"
      />
    </AuthFormLayout>
  );
};

ForgottenPasswordFormLayout.propTypes = {
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default ForgottenPasswordFormLayout;
