import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomModal from '../customModal';
import InputField from '../inputField';
import { asyncDebounce, trimString } from '../../../utility/helpers';
import { useCustomEffect } from '../../../utility/hooks';
import { validateField } from '../../../utility/validation';

const DELAY = 300;
const nameID = 'name';
const shortNameID = 'shortName';

const useStyles = makeStyles(({ spacing }) => ({
  description: {
    marginBottom: spacing(4),
  },
  input: {
    marginBottom: spacing(6),
  },
  inputError: {
    marginBottom: spacing(1),
  },
}));

const ChooseNameDialog = ({
  translations,
  title,
  description,
  initialValue,
  initialShortNameValue,
  field,
  isOpened,
  withShortNameInput,
  shortNameField,
  onCancel,
  onSave,
}) => {
  const classes = useStyles();

  const [inputted, setInputted] = useState(initialValue || '');
  const [inputtedShortName, setInputtedShortName] = useState(
    initialShortNameValue || ''
  );
  const [error, setError] = useState('');
  const [errorShortName, setErrorShortName] = useState('');

  const hasError = Boolean(error);
  const hasErrorSN = Boolean(errorShortName);

  const onSetInitialValue = (value, valueShortName) => {
    setInputted(value);
    setInputtedShortName(valueShortName);
  };

  useCustomEffect(
    () => {
      onSetInitialValue(initialValue, initialShortNameValue);
    },
    [initialValue, initialShortNameValue],
    false
  );

  const resetState = () => {
    setInputted('');
    setInputtedShortName('');
    setError('');
    setErrorShortName('');
  };

  const isFormDirty = () => {
    const titleIsDirty = inputted && inputted !== initialValue;

    if (withShortNameInput) {
      const shortNameIsDirty =
        inputtedShortName && inputtedShortName !== initialShortNameValue;
      return titleIsDirty && shortNameIsDirty;
    }
    return titleIsDirty;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateSingleField = useCallback(
    asyncDebounce(value => {
      return validateField(field, value).then(errorType => {
        setError(errorType);
        return errorType;
      });
    }, DELAY),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateShortNameField = useCallback(
    asyncDebounce(value => {
      return validateField(shortNameField, value).then(errorType => {
        setErrorShortName(errorType);
        return errorType;
      });
    }, DELAY),
    []
  );

  const handleChange = event => {
    const {
      target: { value, id },
    } = event;
    const trimmedValue = trimString(value);

    if (id === nameID) {
      setInputted(value);
      validateSingleField(trimmedValue);
    } else {
      const styledValue = value.toUpperCase();
      setInputtedShortName(styledValue);
      validateShortNameField(trimmedValue);
    }
  };

  const handleClose = () => {
    onCancel();
    resetState();
  };

  const handleSave = async () => {
    const trimmedValue = trimString(inputted);
    const hasNameError = await validateSingleField(trimmedValue);
    let hasShortNameError = false;

    if (withShortNameInput) {
      const trimmedValueShortName = trimString(inputtedShortName);
      hasShortNameError = await validateShortNameField(trimmedValueShortName);
    }

    if (!hasNameError && !hasShortNameError) {
      if (isFormDirty()) {
        if (withShortNameInput) {
          const trimmedValueShortName = trimString(inputtedShortName);
          return onSave(trimmedValue, trimmedValueShortName).then(resetState);
        }
        return onSave(trimmedValue).then(resetState);
      }

      handleClose();
    }
  };

  return (
    <CustomModal
      title={title}
      confirmButtonLabel={translations.save}
      closeButtonLabel={translations.cancel}
      isOpened={isOpened}
      onClose={handleClose}
      onConfirm={handleSave}
      isMedium
    >
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography className={classes.description}>{description}</Typography>
        </Grid>
        <Grid item xs={12}>
          <div
            className={classNames(classes.input, {
              [classes.inputError]: hasError,
            })}
          >
            <div>
              <InputField
                id={nameID}
                name={field.name}
                type={field.type}
                placeholder={translations[field.translationKey].placeholder}
                label={translations[field.translationKey].label}
                value={inputted}
                error={hasError}
                errorMessage={translations.errorMessages[error] || ''}
                onChange={handleChange}
                fullWidth
              />
            </div>
          </div>
        </Grid>
        {withShortNameInput ? (
          <Grid item xs={12}>
            <div
              className={classNames(classes.input, {
                [classes.inputError]: hasErrorSN,
              })}
            >
              <div>
                <InputField
                  id={shortNameID}
                  name={shortNameField.name}
                  type={shortNameField.type}
                  placeholder={
                    translations[shortNameField.translationKey].placeholder
                  }
                  label={translations[shortNameField.translationKey].label}
                  value={inputtedShortName}
                  error={hasErrorSN}
                  errorMessage={
                    translations.errorShortNameMessages[errorShortName] || ''
                  }
                  onChange={handleChange}
                  fullWidth
                />
              </div>
            </div>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </CustomModal>
  );
};

ChooseNameDialog.defaultProps = {
  initialValue: '',
  withShortNameInput: false,
  shortNameField: {},
  initialShortNameValue: '',
};

ChooseNameDialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  field: PropTypes.object.isRequired,
  withShortNameInput: PropTypes.bool,
  shortNameField: PropTypes.object,
  initialShortNameValue: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ChooseNameDialog;
