const commonLabels = {
  apply: 'Apply',
  actions: 'Actions',
  all: 'All',
  cancel: 'Cancel',
  ok: 'Ok',
  active: 'Active',
  score: 'Score',
  inactive: 'Inactive',
  answers: 'Answers',
  discard: 'Discard',
  edit: 'Edit',
  save: 'Save',
  saveAndCreate: 'Save&Create',
  reset: 'Reset',
  reminder: 'Send Reminder',
  yes: 'Yes',
  no: 'No',
  search: 'Search',
  none: 'None',
  preview: 'Preview',
  user: 'User',
  moderator: 'Moderator',
  admin: 'Admin',
  associate: 'External associate',
  manager: 'Manager',
  delete: 'Delete',
  back: 'back',
  send: 'Send',
  close: 'Close',
  filters: 'Filters',
  viewAll: 'View all',
  viewLess: 'View less',
  start: 'Start',
  status: 'Status',
  confirm: 'Confirm',
  noOptions: 'No options',
  helpIcon: 'help icon',
  continue: 'Continue',
  private: 'Private',
  personal: 'Personal',
  public: 'Public',
  addFilters: 'Add Filters',
  create: 'Create',
  update: 'Update',
  createdBy: 'Created by',
  date: 'Date',
  description: 'Description',
  sharedNote: 'Shared note',
  duplicate: 'Duplicate',
  next: 'next',
  previous: 'previous',
  finish: 'Finish',
  choose: 'Choose',
  assign: 'Assign',
  add: 'Add',
  me: 'me',
  schedule: 'Schedule',
  enroll: 'Enroll',
  ago: 'ago',
  days: 'days',
  today: 'Today',
  yesterday: 'Yesterday',
  loadMore: 'Load more',
  searching: 'Searching',
  organization: 'Organization',
  noJobTitle: 'No job title',
  level: 'Level',
  title: 'Title',
  sendSurvey: 'Send survey',
  anonymous: 'Anonymous',
  app: 'Kadar',
  submit: 'Submit',
  attribute: 'Attribute',
  attributes: 'Attributes',
  verifyEmail: 'Verify email',
  noResults: 'No results',
  noPresetResults: 'No presets to select',
  noUserResults: 'No users to select',
  people: 'People',
  percentage: 'Percentage',
  employeesName: "Employee's name",
  noSearchResultsMessage: 'No results match your search criteria.',
  period: 'Period',
  viewReport: 'View report',
  compareReport: 'Compare report',
  tags: 'Tags',
  tasks: 'Tasks',
  assignedTo: 'Assigned to',
  note: 'Public note',
  privateNote: 'Private note',
  manageTasks: 'Add/edit tasks',
  recurring: 'Recurring',
  resend: 'Resend',
  dueDate: 'Due date',
  createdByMe: 'Created by me',
  completeness: 'Completeness',
  for: 'For',
  modifiedOn: 'Modified on',
  overdue: 'Overdue',
  courses: 'Courses',
  proposed: 'Proposed',
  propose: 'Propose',
  proposedOn: 'Proposed on',
  requested: 'Requested',
  request: 'Request',
  requestedOn: 'Requested on',
  accept: 'Accept',
  dismiss: 'Dismiss',
  weight: 'Weight',
  manageEmployeeTopic: 'Add/Edit your agenda',
  survey: 'Survey',
  actionPlan: 'Action plan',
  actionPlans: 'Action plans',
  oneOnOne: '1 - 1',
  oneOnOnes: '1 - 1s',
  fill: 'Fill',
  notes: 'Public notes',
  you: 'you',
  share: 'Share',
  type: 'Type',
  createdAt: 'Created at',
  move: 'Move',
  range: 'Range',
  reactivate: 'Reactivate',
  invite: 'Invite',
  reinvite: 'Reinvite',
  deactivate: 'Deactivate',
  revokeAccess: 'Revoke access',
};

const proposedPlanTooltip = {
  en: {
    default: 'Proposed by employee',
    requestedByYou: 'Proposed by you',
  },
};

const requestedOneOnOneTooltip = {
  en: {
    default: 'Requested by Employee',
    requestedByYou: 'Requested by You',
  },
};

const attributeChip = {
  en: {
    openEndedAttribute: 'Open-ended attribute',
    personalAttribute: 'Personal attribute',
    dragTooltip: 'You can drag attributes to rearrange them.',
    userPersonalAttribute:
      "This is [FULL NAME]'s personal attribute. Usage is restricted.",
  },
};

const sidebarNavigation = {
  en: {
    basic: 'Team',
    free: 'Free',
    admins: 'admins',
    moderators: 'moderators',
    users: 'users',
    oneOnOne: commonLabels.oneOnOnes,
    courses: commonLabels.courses,
    attributes: commonLabels.attributes,
    surveys: 'surveys',
    actionPlans: commonLabels.actionPlans,
    upgradePlan: 'Upgrade plan',
    learnMore: 'Learn more',
    freePlan: 'Free plan',
    dashboard: {
      title: 'Dashboard',
    },
    alignment: {
      title: 'Alignment',
      items: {
        actionPlans: 'Action plans',
        myPrivateNotes: 'My private notes',
        oneOnOne: '1 - 1',
      },
    },
    learning: {
      title: 'Learning hub',
    },
    review: {
      title: 'Review',
      items: {
        attributes: 'Attributes',
        surveys: 'Surveys',
      },
    },
    reports: {
      title: 'Reports',
      items: {
        companyOverview: 'Company overview',
        skillsMatrix: 'Skills matrix',
        stats: 'Competence map',
        oneOnOneReport: 'General mood',
        attribute: 'Attribute',
        compare: 'Compare',
        surveySummary: 'Survey summary',
      },
    },
    career: {
      title: 'Career',
      items: {
        jobTitles: 'Job titles',
        leveling: 'Levels and tracks',
      },
    },
    company: {
      title: 'Organization',
      items: {
        orgChart: 'Org Chart',
        people: 'People directory',
        tags: 'Tags',
      },
    },
  },
};

const periodFilter = {
  en: {
    all: 'All time',
    twoMonths: '2 months range',
    sixMonths: '6 months range',
    twelveMonths: '12 months range',
  },
};

const accountTypes = {
  en: {
    user: commonLabels.user,
    moderator: commonLabels.moderator,
    admin: commonLabels.admin,
    associate: commonLabels.associate,
  },
};

const personalAttribute = {
  en: {
    tooltipText:
      "This is [FULL NAME]'s personal attribute. Usage is restricted.",
  },
};

const groupedSurveyOptions = {
  en: {
    peerTeamAndDirect: 'Peer, Team & Direct',
    self: 'Self',
    pulse: 'Pulse',
  },
};

const roleTooltipDescriptions = {
  en: {
    renderRolesTooltip: [
      {
        title: 'User can',
        role: 'User',
        data: [
          'view their profile if Full Transparency is enabled',
          'see and manage tasks for his Action Plans',
          'see their 1-1 meetings history',
          'propose a topic for upcoming 1-1 meeting',
          'browse through Library of courses',
          'enroll themselves to courses',
          'view Organization Chart and list of all employees of the company' +
            ' People page',
        ],
      },
      {
        title: 'Moderator can',
        role: 'Moderator',
        data: [
          'everything that Users can',
          'view and edit profiles of all employees in their reporting branch',
          'add Direct reports and manage their access',
          'create and manage Attributes',
          'send Surveys to their reporting branch',
          'see reports for all Surveys that reviewed their reports',
          'see Job titles, Tracks and Levels',
          'see Tags',
          'create and manage Action Plans',
          'organize 1-1 meetings to their reports',
          'enroll their reports to courses',
          'manage Learning library',
          'add private notes for their reports',
        ],
      },
      {
        title: 'Admin can',
        role: 'Admin',
        data: [
          'everything that Moderators can',
          'manage all Users - invite, deactivate or delete them',
          'send Surveys to anyone in the organization',
          'manage and edit Job Titles, Tracks and Levels',
          'manage and edit Tags',
          'change Permissions to of all users',
          'change Organization Settings',
        ],
      },
      {
        title: 'External associate can',
        role: 'External associate',
        data: [
          'fill out surveys that you send them',
          'not have personal profile',
          'not have access to the app in general, only to surveys',
        ],
      },
    ],
  },
};

const teamNotifications = {
  en: {
    title: 'Updated',
    updatesButtonTooltip: 'View notification',
    markAsRead: 'Mark as read',
    updates: {
      agenda: 'Manager meeting agenda',
      employeeAgenda: 'Employee meeting agenda',
      title: commonLabels.title,
      dueDate: commonLabels.dueDate,
      status: commonLabels.status,
      completeness: commonLabels.completeness,
      tags: commonLabels.tags,
      courses: commonLabels.courses,
      description: commonLabels.description,
      tasks: commonLabels.tasks,
      meetingTime: 'Meeting time',
      meetingDateTime: 'Meeting date and time',
      meetingDate: 'Meeting date',
      recurring: 'Recurring',
      plans: commonLabels.actionPlans,
      sharedNote: commonLabels.sharedNote,
      meetingImpression: 'Impression',
      text: 'Content',
      createdPlan: 'New Action plan has been created.',
      createdOneOnOne: 'New 1 - 1 has been created.',
      createdNote: 'New Note has been created.',
    },
  },
};

const commonFieldValidationMessages = {
  en: {
    availableName: 'Name is already taken.',
    isEmpty: 'This field is required.',
    forbiddenCharacters:
      'Field contains forbidden characters: <, >, [, ], {, }, |, \\',
    maxLength: 'Exceeded max length of 250 characters.',
    somethingWentWrong: 'Oops! Something went wrong.',
    itemName: 'Field can contain only alphanumeric and +, # characters.',
    alphanumeric: 'Field can contain only alphanumeric characters.',
    freemium: 'Free plan limit reached.',
  },
};

const levelingTypes = {
  en: {
    custom: {
      name: 'Start from scratch',
      description:
        'With this option you now have the opportunity to create personalized leveling tables tailored to your specific needs.',
    },
    template: {
      name: 'Templates',
      description:
        'Gain access to a curated collection of the finest examples from' +
        ' renowned companies by Higher-ups. ',
    },
  },
};

const courseLabels = {
  en: {
    level: commonLabels.level,
    attribute: commonLabels.attribute,
    url: 'URL',
    visitCourse: 'See course',
    all: commonLabels.all,
    enrolledUsers: 'Enrolled employees',
    tags: 'Tags',
  },
};

const validationMessages = {
  en: {
    city: {
      invalid: 'City should contain only alphabets.',
      required: 'City is required.',
      minLength: 'Minimum length is 2 characters.',
      maxLength: 'Maximum length is 100 characters.',
    },
    country: {
      invalid: 'Country is invalid.',
      required: 'Country is required.',
    },
    date: {
      invalid: 'Date is invalid.',
      required: 'Date is required.',
    },
    email: {
      invalid: 'Email is invalid.',
      required: 'Email is required.',
      exceededLength: 'Maximum length is 100 characters.',
      existingEmails: 'Email(s) already in use:',
      isUnique: 'Email is not unique.',
      isAvailable: 'Email is already taken.',
    },
    firstName: {
      invalid: 'Name should contain only alphabets.',
      required: 'Name is required.',
      minLength: 'Minimum length is 2 characters.',
      maxLength: 'Maximum length is 50 characters.',
    },
    lastName: {
      invalid: 'Name should contain only alphabets.',
      required: 'Name is required.',
      minLength: 'Minimum length is 2 characters.',
      maxLength: 'Maximum length is 50 characters.',
    },
    level: {
      invalid: 'Level is invalid.',
      required: 'Level is required.',
    },
    phoneHome: {
      invalid: 'Phone number can start with + and can contain only numbers',
      required: 'Phone number is required.',
      minLength: 'Minimum length is 7 characters.',
      maxLength: 'Maximum length is 15 characters.',
    },
    phoneMobile: {
      invalid: 'Phone number can start with + and can contain only numbers.',
      required: 'Phone number is required.',
      minLength: 'Minimum length is 7 characters.',
      maxLength: 'Maximum length is 15 characters.',
    },
    jobTitle: {
      invalid: 'Job title is invalid.',
      required: 'Job title is required.',
    },
    track: {
      required: 'Track is required.',
    },
    framework: {
      required: 'Framework is required.',
    },
    role: {
      invalid: 'Role is invalid.',
      required: 'Role is required.',
      freemiumLimit: commonFieldValidationMessages.en.freemium,
    },
    status: {
      invalid: 'Status is invalid.',
      required: 'Status is required.',
    },
    reportTo: {
      required: 'Reporting to is required.',
      invalid: 'Reporting to is invalid.',
    },
    desiredUrl: {
      required: 'Url is required.',
      invalid: 'Url is invalid.',
      maxLength: 'Maximum length is 50 characters.',
    },
    acceptTerms: {
      invalid: 'You need to accept terms-of-service.',
    },
    name: {
      required: 'Name is required.',
      minLength: 'Attribute name must be at least 3 characters long.',
      maxLength: 'Exceeded max length of 100 characters.',
    },
    description: {
      maxLength: 'Exceeded max length of 200 characters.',
      required: 'Description is required.',
    },
    organization: {
      required: 'Workspace url is required',
      invalid: 'Workspace url is invalid',
      notExist: "Workspace doesn't exist",
      maxLength: 'Maximum length is 50 characters',
    },
  },
};

const iconButtonsTooltip = {
  en: {
    accept: 'Accept',
    dismiss: 'Dismiss',
    resolve: 'Resolve',
    reschedule: 'Reschedule',
  },
};

const authContainer = {
  en: {
    kadar: commonLabels.app,
    description: 'Take your career to another level',
    singUpText: 'New to Kadar?',
    singInText: 'Already have an account?',
    signUpButton: 'Sign up',
    signInButton: 'Sign in',
    notIn: 'Not in',
    changeOrganization: 'Change organization',
    privacy_policy: { name: 'Privacy policy', url: '/privacy-policy' },
    terms_of_service: { name: 'Terms of service', url: '/terms-of-service' },
    copyrightText: `© Kadar ${new Date().getFullYear()}`,
    desktopOnly: 'Currently optimized only for desktop.',
  },
};

const roleDescriptions = {
  en: {
    admin:
      'The Admins have complete access to the whole application and to all employee data, which they can edit and delete.',
    moderator:
      'The Moderators have limited access to the organization and to the data' +
      ' of only their direct reports, which they can edit and delete.',
    user:
      'The Users have limited access to the organization and only to the' +
      ' data regarding themselves, which they can edit and delete.',
  },
};

const alertDialogs = {
  en: {
    actionPlanDelete: {
      title: 'Delete Action plan',
      contentMessage: 'Are you sure you want to delete this action plan?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Action plan successfully deleted.',
    },
    actionPlanProposalDismiss: {
      title: 'Dismiss proposed Action plan',
      contentMessage:
        'Are you sure you want to dismiss this proposed action plan?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Action plan proposal successfully dismissed.',
    },
    actionPointCommentDelete: {
      title: 'Delete comment',
      contentMessage: 'Are you sure you want to delete this comment?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Comment successfully deleted.',
    },
    attributeDelete: {
      title: 'Delete attribute',
      contentMessage: 'Are you sure that you want to delete this attribute?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Attribute successfully deleted.',
    },
    attributeUsedDelete: {
      title: 'Delete attribute',
      contentMessage:
        'This Attribute is used in [X] survey(s) and by' +
        ' deleting it all data, answers and results from survey(s) will be lost.',
      contentDescription:
        'Are you sure that you want to delete this attribute?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Attribute successfully deleted.',
    },
    attributeQuestionDelete: {
      title: 'Confirm question deletion',
      contentMessage: 'Are you sure that you want to delete this question?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Attribute question successfully deleted.',
    },
    attributeQuestionDuplicate: {
      title: 'Question duplication',
      contentMessage: 'Are you sure that you want to duplicate this question?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      duplicateSuccess: 'Attribute question successfully duplicated.',
    },
    courseDelete: {
      title: 'Delete course',
      contentMessage: 'Are you sure you want to delete this course?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Course successfully deleted.',
    },
    courseDisenroll: {
      title: 'Disenroll from course',
      contentMessage: 'Are you sure you want disenroll this user from course? ',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      disenrollSuccess: 'User has been disenrolled from course.',
    },
    jobTitleDelete: {
      title: 'Delete job title',
      contentMessage: 'Are you sure that you want to delete this job title?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Job title successfully deleted.',
    },
    surveySubmit: {
      title: 'Confirm review submission',
      contentMessage: 'Are you sure that you want to submit this review?',
      multipleReviews:
        'Are you sure that you want to submit this review and proceed to' +
        ' the next review?',
      confirmButton: 'Submit',
      closeButton: 'Cancel',
    },
    surveySubmitMultiple: {
      title: 'Confirm review submission',
      contentMessage:
        'Are you sure that you want to submit this review and proceed to' +
        ' the next review?',
      confirmButton: 'Submit',
      closeButton: 'Cancel',
    },
    deleteLevelingTemplate: {
      title: 'Delete leveling system',
      contentMessage:
        'Are you sure that you want to delete this leveling system?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Leveling system successfully deleted.',
    },
    deleteLevelingTrack: {
      title: 'Are you sure that you want to delete this track?',
      contentMessage: 'Levels have been created in this track.',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Leveling track successfully deleted.',
    },
    editLevelingTemplate: {
      title: 'Add leveling system',
      contentMessage:
        'Are you sure that you want to add this leveling system? It will override the existing one.',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      editSuccess: 'Leveling system successfully edited.',
    },
    levelingFrameworkDelete: {
      title: 'Are you sure that you want to delete this framework?',
      contentMessage: 'Levels have been created in this framework.',
      confirmButton: commonLabels.delete,
      closeButton: commonLabels.cancel,
    },
    levelingTrackDelete: {
      title: 'Are you sure that you want to delete this track?',
      contentMessage: 'Levels have been created in this track.',
      confirmButton: commonLabels.delete,
      closeButton: commonLabels.cancel,
    },
    levelingLevelDelete: {
      title: 'Are you sure that you want to delete level?',
      confirmButton: commonLabels.delete,
      closeButton: commonLabels.cancel,
    },
    levelingTrackLevelDelete: {
      title: 'Delete  track level',
      contentMessage: 'Are you sure that you want to delete track level?',
      contentDescription:
        'This track level it’s been used by [EMPLOYEE_NUMBER]' +
        ' active employee(s).',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
    },
    oneOnOneTopicDelete: {
      title: 'Delete 1 - 1 topic',
      contentMessage: 'Are you sure that you want to delete this 1 - 1 topic?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Your meeting agenda topic has been removed.',
    },
    oneOnOneCommentDelete: {
      title: 'Delete comment',
      contentMessage: 'Are you sure you want to delete this comment?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Comment successfully deleted.',
    },
    upcomingMeeting: {
      title: '1 - 1 meeting request',
      contentMessage:
        'You already have an upcoming 1 - 1 with your manager. Do' +
        ' you want to proceed anyway?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
    },
    meetingRequestDelete: {
      title: 'Delete meeting request',
      contentMessage: 'Are you sure you want to delete this meeting request?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Meeting request successfully deleted.',
    },
    oneOnOneRecordDelete: {
      title: 'Delete 1 - 1 record',
      contentMessage: 'Are you sure that you want to delete this 1 - 1 record?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: '1 - 1 record successfully deleted.',
    },
    oneOnOneRequestDismiss: {
      title: 'Dismiss 1 - 1 request',
      contentMessage: 'Are you sure you want to dismiss this 1 - 1 request?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      dismissSuccess: '1 - 1 request successfully dismissed',
    },
    userDelete: {
      title: 'Permanent delete',
      contentMessage:
        'Once you delete this user, all associated data and collected results will be permanently lost.',
      contentDescription: 'Are you sure you want to delete this user?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'User has been successfully deleted.',
    },
    userInvite: {
      title: commonLabels.invite,
      contentDescription:
        'Are you sure you want to send app invitation to this user?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      inviteSuccess: 'User has been successfully invited.',
    },
    userReinvite: {
      title: commonLabels.reinvite,
      contentDescription:
        'Are you sure you want to resend app invitation to this user?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      reinviteSuccess: 'User has been successfully reinvited.',
    },
    userRevokeAccess: {
      title: commonLabels.revokeAccess,
      contentDescription:
        'Are you sure you want to revoke app access to this user?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      revokeSuccess: "User's app access has been successfully revoked.",
    },
    userDeactivate: {
      title: commonLabels.deactivate,
      contentDescription: 'Are you sure you want to deactivate this user?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deactivateSuccess: 'User has been successfully deactivated.',
    },
    userUnshare: {
      title: 'Unshare',
      contentMessage: 'Are you sure that you want to unshare?',
      confirmButton: 'Unshare',
      closeButton: 'Cancel',
      unshareSuccess: 'Successfully unshared.',
    },
    surveyDelete: {
      title: 'Delete survey',
      contentMessage:
        'Once you delete this Survey, all data, answers and results will be permanently lost.',
      contentDescription: 'Are you sure you want to delete this survey?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Survey successfully deleted.',
    },
    scheduledSurveyDelete: {
      title: 'Delete scheduled survey',
      contentMessage: 'Are you sure you want to delete this scheduled survey?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Scheduled survey successfully deleted.',
    },
    draftSurveyDelete: {
      title: 'Delete survey draft ',
      contentMessage: 'Are you sure you want to delete this survey draft?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Survey draft successfully deleted.',
    },
    surveySendReminderAll: {
      title: 'Send reminder',
      contentMessage:
        'Are you sure you want to send reminder to all reviewers?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      sendSuccess: 'Reminder successfully sent.',
    },
    surveyReopenAll: {
      title: 'Reopen survey',
      contentMessage:
        'Are you sure you want to reopen survey for all reviewers?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      success: 'Survey successfully reopened.',
    },
    surveyForceComplete: {
      title: 'Close survey',
      contentMessage: 'Are you sure you want to close this survey?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      completeSuccess: 'Survey successfully closed.',
    },
    tagCategoryDelete: {
      title: 'Delete category',
      contentMessage: 'Are you sure that you want to delete this category?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Tag category successfully deleted.',
    },
    tagDelete: {
      title: 'Delete tag',
      contentMessage: 'Are you sure that you want to delete this tag?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Tag successfully deleted.',
    },
    noteDelete: {
      title: 'Delete note',
      contentMessage: 'Are you sure that you want to delete this note?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Note successfully deleted.',
    },
    questionsIncreaseAnswerCount: {
      title: 'Question style',
      contentMessage:
        'Are you sure you want to change number of answers? New input fields will be added at the end.',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.cancel,
    },
    questionsDecreaseAnswerCount: {
      title: 'Question style',
      contentMessage:
        'Are you sure you want to change number of answers? The last answer in every question will be deleted.',
      contentMessageMultiple:
        'Are you sure you want to change number of answers? Last [X] answers' +
        ' in every question will be deleted.',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.cancel,
    },
    questionsOpenEndedQuestions: {
      title: 'Question style',
      contentMessage:
        'Are you sure you want to change question style? In this case, all answers will be deleted.',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.cancel,
    },
    questionsMultipleChoice: {
      title: 'Question style',
      contentMessage: 'Are you sure you want to change question style?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.cancel,
    },
    questionsNeutralAnswer: {
      title: 'Question style',
      contentMessage:
        'The N/A will be added as an answer option to all questions in the Attribute.',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.cancel,
    },
    questionsRemoveNeutralAnswer: {
      title: 'Question style',
      contentMessage:
        'The N/A will be removed as an answer option from all questions in the Attribute.',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.cancel,
    },
    changeExternalRole: {
      title: 'Change role',
      contentMessage:
        'This action will create an employee profile which will be visible on your invoice.',
      contentDescription: 'Do you want to proceed?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.cancel,
    },
    unshareUserReport: {
      title: 'Survey report access',
      contentMessage:
        'Are you sure that you want to remove this user from Survey report access list.',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess:
        'User successfully removed from Survey report access list.',
    },
    presetDelete: {
      title: 'Delete preset',
      contentMessage: 'Are you sure that you want to delete preset?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Preset successfully deleted.',
    },
    surveyGroupDelete: {
      title: 'Delete group',
      contentMessage: 'Are you sure you want to delete this group?',
      contentDescription: 'All surveys inside group will be preserved.',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Survey group successfully deleted.',
    },
    surveyGroupDuplicate: {
      title: 'Duplicate group',
      contentMessage: 'Are you sure you want to duplicate this group?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      duplicateSuccess: 'Survey group successfully duplicated.',
    },
    surveySummaryReportDelete: {
      title: 'Delete summary report',
      contentMessage:
        'Once you delete this report, it will no longer be accessible to employees with whom it was previously shared.',
      contentDescription:
        'Are you sure you want to delete this summary report?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Survey summary report successfully deleted.',
    },
    removeSurveyFromGroup: {
      title: 'Remove from group',
      contentMessage: 'Are you sure you want to remove this survey from group?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      success: 'Survey successfully removed from group.',
    },
    surveyDuplicate: {
      title: 'Duplicate survey',
      contentMessage: 'Are you sure you want to duplicate this survey?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      duplicateSuccess: 'Survey successfully duplicated.',
    },
    taskStatusChange: {
      title: 'Task status',
      contentMessageCheck:
        'Are you sure that you want to mark this task as finished?',
      contentMessageUncheck:
        'Are you sure that you want to mark this task as unfinished?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      success: 'Task status successfully updated.',
    },
  },
};

const warningDialogs = {
  en: {
    attributeDelete: {
      title: 'Attribute you are trying to delete is being used:',
      levelsSubtitle: 'Levels:',
      closeButton: commonLabels.close,
    },
    tagDelete: {
      title: 'Delete tag',
      contentMessage: 'This tag is being used.',
      contentDescription: 'Are you sure that you want to delete this tag?',
      confirmButton: commonLabels.yes,
      closeButton: commonLabels.no,
      deleteSuccess: 'Tag successfully deleted.',
    },
    tagsDelete: {
      title: 'Following Tag(s) you are trying to delete are being used:',
      closeButton: commonLabels.cancel,
      forceDeleteButton: 'Delete & Save',
    },
  },
};

const neutralAnswer = {
  en: {
    neutral: {
      text: 'Neutral answer',
      shorthand: 'N/A',
      notApplicable: 'Not applicable',
    },
  },
};

const anonymousUser = {
  en: {
    first_name: 'Anonymous',
    last_name: 'User',
  },
};

const employmentInfoHelpText = {
  en: {
    jobTitle: {
      text: "Please choose the employee's Job title.",
    },
    framework: {
      text: "Please choose the employee's Framework.",
    },
    track: {
      text: 'Please enter the Framework first to enable Track selection.',
    },
    level: {
      text: 'Please enter the Track first to enable Level selection.',
    },
    reportTo: {
      text: 'Please choose the user superior.',
    },
    role: {
      text: 'Please choose user role.',
    },
  },
};

const personalInfoHelpText = {
  en: {
    email: {
      text:
        'The email you provide will be used only to enable the employee to' +
        ' receive surveys. They will not be notified.',
    },
  },
};

const peoplePicker = {
  en: {
    allEmployees: 'All employees',
    allAssociates: 'All associates',
    selectButtonLabel: 'Select',
    noUserResults: commonLabels.noUserResults,
    startSearch: 'Start typing ...',
    directReports: 'Direct reports',
  },
};

const fillSurveyFlow = {
  en: {
    subject: 'Survey for',
    groupSurvey: 'Group survey',
    anonymous: commonLabels.anonymous,
    appName: commonLabels.app,
    welcomeMessage: 'Hi, there!',
    numberOfSurveys:
      'The Survey in front of you has [NUMBER_OF_SURVEYS] of questions to fill in.',
    numberOfQuestions:
      'The number of questions in total is [NUMBER_OF_QUESTIONS].',
    description: 'Attribute description',
    oneGroup: 'one group',
    groups: 'groups',
    getStartedButtonLabel: 'Get started',
    nextLabel: commonLabels.next,
    backLabel: commonLabels.back,
    submitLabel: commonLabels.submit,
    answers: {
      save: commonLabels.save,
      weight: commonLabels.weight,
      neutralAnswer: {
        label: neutralAnswer.en.neutral.shorthand,
        text: 'Select "Not applicable" for this answer',
        neutralValue: 'Not applicable',
      },
      freeText: {
        placeholder: 'Write an answer',
        validationMessages: {
          required: commonFieldValidationMessages.en.isEmpty,
          forbiddenCharacters:
            commonFieldValidationMessages.en.forbiddenCharacters,
          maxLength: 'Exceeded max length of 5000 characters',
        },
      },
    },
    submitDialog: { ...alertDialogs.en.surveySubmit },
    submitMultipleDialog: { ...alertDialogs.en.surveySubmitMultiple },
    feedbackForm: {
      title: 'Your general feedback & expectations',
      placeholder: 'Add feedback',
      validationMessages: {
        forbiddenCharacters:
          commonFieldValidationMessages.en.forbiddenCharacters,
        maxLength: 'Exceeded max length of 2500 characters',
      },
    },
  },
};

const customDialogs = {
  en: {
    transferEmployees: {
      title: commonLabels.deactivate,
      description:
        'The user you want to deactivate has direct reports. Please transfer the following employee(s) to a new manager:',
      label: 'New manager',
      placeholder: 'Please select new manager',
      noOptions: commonLabels.noOptions,
      searching: commonLabels.searching,
      save: 'Transfer & Deactivate',
      cancel: commonLabels.cancel,
    },
    manageUserTags: {
      title: 'Manage tags',
      description: ' Add or remove tags for this user',
      selectedTags: 'Selected tags',
      search: {
        label: commonLabels.search,
        placeholder: 'Search tags',
      },
      noTags: {
        title: 'No tags',
        content: 'Tags from this category are already selected',
      },
      save: commonLabels.save,
      cancel: commonLabels.cancel,
      noSelectedTags: 'Selected tags will appear here.',
      noResults: 'There are no search results.',
      successMessage: 'Successfully updated tags',
    },
    addUsersDialog: {
      title: 'Choose what you want to do',
      options: {
        manual: {
          title: 'Add regular user manually',
          description:
            'You can add as many users as you want at' +
            'any time by typing basic info about them.',
        },
        import: {
          title: 'Import regular users from .csv',
          description:
            'You can import a group of your users easily ' +
            'from a .csv file and skip the manual part.',
        },
        associate: {
          title: 'Add External Associates',
          description:
            "External Associates won't have a profile, but you'll be able to send them a Survey to gather feedback.",
        },
      },
      upload: {
        initialMessage: 'Drag and drop csv file here or',
        selectFile: 'select file',
        downloadCsv: 'Download example .csv',
        validUsers: 'Imported users',
        existingUsers: 'Existing users',
        existingUsersTitle: 'Emails already in use',
        errors: 'Errors',
        errorsTitle: 'Error in rows',
      },
      continue: commonLabels.continue,
      cancel: commonLabels.cancel,
    },
    enrollToCourse: {
      title: 'Choose Employee(s) to Enroll to the Course',
      create: commonLabels.enroll,
      cancel: commonLabels.cancel,
      label: 'Select Employee(s)',
      placeholder: "Start typing employee's name(s)",
      description: 'The action will enroll Employee(s) in the course.',
      noOptions: commonLabels.noOptions,
    },
    addCompareWith: {
      title: 'Choose compare with',
      create: commonLabels.save,
      cancel: commonLabels.cancel,
      label: 'Compare with',
      placeholder: 'Start typing employee name',
      noOptions: commonLabels.noOptions,
    },
    bulkEdit: {
      changeReportingTo: 'Change reporting to',
      changeJobTitle: 'Change job title',
      inviteUsers: 'Invite users',
      changeRole: 'Change role',
      changeLevelTrack: 'Change Level/Track',
      inviteUsersSuccess: 'Successfully invited!',
      inviteMessage: {
        following: 'Following ',
        userText: 'user ',
        usersText: 'users ',
        invited: 'will be invited:',
        noInvitation: 'Invitation has already been sent previously to ',
        userHas: ' selected user.',
        usersHave: ' selected users.',
      },
      bulkEdit: 'Bulk actions',
      noOptions: commonLabels.noOptions,
      searching: commonLabels.searching,
      roleChangeForbidden: "Role of this user won't be changed.",
      success: 'Successfull change!',
      fields: {
        jobTitle: 'Job title',
        reportTo: 'Reporting to',
        framework: 'Framework',
        track: 'Track',
        level: 'Level',
        role: 'Role',
        placeholders: {
          jobTitle: 'Select job title',
          reportTo: 'Start typing name...',
          framework: 'Select framework',
          track: 'Select track',
          level: 'Select level',
          role: 'Select role',
        },
      },
      fieldValidations: {
        ...validationMessages.en,
      },
      options: {
        statuses: {
          active: commonLabels.active,
          inactive: commonLabels.inactive,
        },
      },
      labelHelpData: { ...employmentInfoHelpText.en, ...personalInfoHelpText },
    },
    duplicateAttribute: {
      title: 'Duplicate Attribute',
      description:
        'Please provide the name and a short name for your new attribute.',
      name: {
        label: 'Name',
        placeholder: 'Add attribute name ',
      },
      shortName: {
        label: 'Short name',
        placeholder: 'Add attribute short name ',
      },
      save: commonLabels.save,
      cancel: commonLabels.cancel,
      errorMessages: {
        required: commonFieldValidationMessages.en.isEmpty,
        availableName: commonFieldValidationMessages.en.availableName,
        maxNameLength: 'Exceeded max length of 100 characters',
        minLength: validationMessages.en.name.minLength,
        forbiddenCharacters:
          commonFieldValidationMessages.en.forbiddenCharacters,
      },
      errorShortNameMessages: {
        required: commonFieldValidationMessages.en.isEmpty,
        exactLength: 'Short name should be 1 to 5 characters long',
        forbiddenCharacters:
          commonFieldValidationMessages.en.forbiddenCharacters,
        alphanumeric: commonFieldValidationMessages.en.alphanumeric,
        availableShortName: 'Short name is already taken',
      },
    },
    employeeShare: {
      title: 'Share',
      save: commonLabels.save,
      cancel: commonLabels.cancel,
      placeholder: 'Start typing name ...',
      selectUserLabel: 'Select person',
      accessType: 'Access type',
      noOptions: 'No user options',
      searching: commonLabels.searching,
      grantAccessTo: 'Grant access to following users',
      sharedItem: {
        viewer: 'Viewer',
        collaborator: 'Collaborator',
      },
    },
    enrollCourse: {
      title: 'Choose course to enroll',
      create: commonLabels.enroll,
      cancel: commonLabels.cancel,
      search: commonLabels.search,
      courseLabels: { ...courseLabels.en },
    },
    actionPointCommentNotify: {
      title: 'Would you like to notify users about this message?',
      confirm: commonLabels.send,
      close: commonLabels.cancel,
      checkboxGroupLabel: 'Send notifications email to:',
    },
    oneOnOneCommentNotify: {
      title: 'Would you like to notify users about this message?',
      confirm: commonLabels.send,
      close: commonLabels.cancel,
      checkboxGroupLabel: 'Send notifications email to:',
    },
    attributePreview: {
      preview: commonLabels.preview,
      noData: "There aren't any questions to preview.",
      weight: commonLabels.weight,
      neutralAnswer: { ...fillSurveyFlow.en.answers.neutralAnswer },
    },
    sendSurveyNotification: {
      title: 'Send email to reviewers',
      titleSchedule: 'Schedule email to reviewers',
      description:
        'This action will [SEND] email with attribute questions' +
        ' only to the following [REVIEWER].',
      reviewer: 'Reviewer',
      reviewers: 'Reviewers',
      attributes: 'Attributes',
      createdFor: 'Who will be reviewed',
      fillInline: 'Fill survey now',
      fillInlineTooltip:
        'As you are one of the reviewers, you have an option to fill survey now. ' +
        'You will anyhow receive email with survey link, should you decide to fill ' +
        'survey later.',
      send: commonLabels.send,
      schedule: commonLabels.schedule,
      saveDraft: 'Save as draft',
      cancel: commonLabels.cancel,
    },
    surveyType: {
      title: 'Choose survey type',
      cancel: commonLabels.cancel,
      continue: commonLabels.continue,
    },
    surveyTitle: {
      title: 'Rename survey',
      description: 'Please enter a new title for this survey',
      name: {
        label: 'Title',
        placeholder: 'Add survey title ',
      },
      save: commonLabels.save,
      cancel: commonLabels.cancel,
      success: 'Survey title successfully changed.',
      errorMessages: {
        required: commonFieldValidationMessages.en.isEmpty,
        forbiddenCharacters:
          commonFieldValidationMessages.en.forbiddenCharacters,
        maxLength: 'Exceeded max length of 150 characters',
      },
    },
    upgradeRequired: {
      peopleLimit: 'Subscription',
      upgradeRequiredMessage:
        'Subscription expired. Please renew your subscription to restore full functionality.',
      contactAdminMessage:
        'Subscription expired. Please contact your admin to renew the subscription and restore full functionality.',
      upgradeButtonText: 'Upgrade plan',
      close: commonLabels.close,
    },
    limitReached: {
      title: 'Free',
      upgradePlan:
        'Free plan limit reached. Upgrade to Pro plan to get full functionality.',
      contactAdmin:
        'Free plan limit reached. Please contact your admin to upgrade to Pro plan and get full functionality.',
      upgradeButtonText: 'Upgrade plan',
      close: commonLabels.close,
    },
    surveySendReminder: {
      title: 'Send reminder',
      description:
        'This action will send email with reminder only to the following' +
        ' employees',
      withoutNotification:
        "These employees have already submitted the survey, therefore they won't get an email",
      success: 'Reminder successfully sent.',
      confirm: commonLabels.send,
      close: commonLabels.cancel,
    },
    surveyReopen: {
      title: 'Reopen',
      description:
        'This action will reopen survey only for the following employees',
      withoutNotification:
        "These employees haven't submitted the survey yet, therefore they won't get an email",
      success: 'Survey successfully reopened.',
      confirm: commonLabels.send,
      close: commonLabels.cancel,
    },
    addReviewers: {
      title: 'Add new reviewers',
      titleSchedule: 'Schedule new reviewers',
      description:
        'This action will send email with attribute questions only to the' +
        ' following employee reviewers.',
      descriptionSchedule:
        'This action will schedule email with attribute' +
        ' questions only to the following reviewers.',
      usersToReview: 'Who will be reviewed',
      attributes: 'Attributes',
      confirm: commonLabels.send,
      confirmSchedule: commonLabels.schedule,
      close: commonLabels.cancel,
      success: 'Reviewers successfully added.',
      fieldLabels: {
        label: 'Reviewers',
        placeholder: 'Start typing ...',
        all: commonLabels.all,
        addButtonLabel: commonLabels.add,
        noOptions: commonLabels.noOptions,
        ...peoplePicker.en,
      },
    },
    levelingType: {
      title: 'Choose leveling table type',
      cancel: commonLabels.cancel,
      continue: commonLabels.continue,
    },
    addImpression: {
      title: 'Add impression',
      cancel: commonLabels.cancel,
      save: commonLabels.save,
      saveAndCreate: commonLabels.saveAndCreate,
      success: '1 - 1 record impression successfully added',
      meetingImpression: {
        label: 'Impression',
        positive: 'Positive',
        negative: 'Negative',
        info: 'Add impression after 1-1',
      },
      recurring: {
        label: 'Schedule the next 1-1 (optional)',
      },
      notifyAll: 'Send updates by email',
      stopRecurringLabel: 'Stop the recurring',
      validationMessages: {
        errorMessage: commonFieldValidationMessages.en.isEmpty,
        isPast: 'Chosen date is in the past',
      },
    },
    deactivateUsers: {
      title: 'Deactivate these users from Kadar',
      description:
        'Are you sure you want to deactivate these users from the application?',
      success: 'Slack users successfully synced.',
      save: 'Deactivate',
      cancel: commonLabels.cancel,
    },
    manageSurveyGroup: {
      title: {
        create: 'Create group',
        edit: 'Edit group',
      },
      description:
        'The group is there to allow for easier organization and sequencing of surveys through sets.',
      name: {
        label: 'Title',
        placeholder: 'Add group title ',
      },
      save: commonLabels.save,
      cancel: commonLabels.cancel,
      success: {
        create: 'Group successfully added.',
        edit: 'Group title successfully changed.',
      },
      errorMessages: {
        required: commonFieldValidationMessages.en.isEmpty,
        forbiddenCharacters:
          commonFieldValidationMessages.en.forbiddenCharacters,
        maxLength: 'Exceeded max length of 500 characters',
        availableTitle: 'Title is already taken',
      },
    },
    manageGroupItems: {
      title: {
        add: 'Add to group',
        edit: 'Change group',
      },
      description:
        'You can choose a group for this survey and thus create a set to help you better organize all the submitted surveys.',
      labels: {
        group: {
          label: 'Group',
          placeholder: 'Select group',
        },
      },
      save: commonLabels.save,
      cancel: commonLabels.cancel,
      success: {
        create: 'Group successfully added.',
        edit: 'Group successfully changed.',
      },
      validationMessages: {
        required: commonFieldValidationMessages.en.isEmpty,
      },
    },
    aiSuggestions: {
      title: 'Ai-powered learning recomendations:',
      close: commonLabels.close,
    },
    reactivateUser: {
      title: 'Reactivate',
      description: 'Select new manager and reactivate user.',
      label: 'Report to',
      placeholder: 'Please select new manager',
      noOptions: commonLabels.noOptions,
      create: commonLabels.save,
      cancel: commonLabels.cancel,
      reactivateSuccess: 'User has been successfully reactivated.',
    },
    peopleNoteTypes: {
      title: 'Choose type',
      cancel: commonLabels.cancel,
      continue: commonLabels.continue,
      public: {
        name: 'Public',
        description:
          'Visible to everyone with access to this profile (except the user it concerns). A space for managers and team leads to collaborate and share ideas. Use it to share insights, plans and wider context.',
      },
      private: {
        name: 'Private',
        description:
          'Visible only to you. Keep track of your personal insights, thoughts and observations. Don’t let anything slip!',
      },
    },
  },
};

const peoplePageContainer = {
  en: {
    personNavItems: {
      overview: 'Overview',
      career: 'Career',
      insights: 'Insights',
      ownTeam: 'My people',
      team: 'People',
      actionPlans: 'Action plans',
      learning: 'Learning',
      notes: 'Notes',
      generalInfo: 'Info',
      oneOnOne: '1 - 1',
    },
    you: 'You',
    sharedWith: 'Shared with you',
    yourDirectReport: 'Your direct report',
    inYourBranch: 'Your indirect report',
    avatarUpload: {
      change: 'Change',
      photoSizeMessage: "Photo should be up to 5 mb's",
      choosePhoto: 'Choose format jpeg, png or jpg',
    },
    slackTooltip: 'User account is associated with slack account',
  },
};

const visibilityRelations = {
  en: {
    admin: 'Admin',
    forUser: 'For user',
    withUser: 'With user',
    userSharedWith: 'Shared with',
    directReportTo: 'Direct reporting to',
    inUsersBranch: 'Indirect reporting to',
    createdBy: 'Created by',
    organizedBy: 'Organized by',
  },
};

const readLessMoreLabels = {
  read: 'Read',
  less: 'less',
  more: 'more',
};

const surveyResults = {
  en: {
    question: 'Questions',
    answers: 'Answers',
    answerShorthand: 'A',
    questionShorthand: 'Q',
    questionScore: 'Question score',
    anonymousUser: { ...anonymousUser.en },
    completedCountTooltip: 'Completed',
    attributeScore: {
      anonymous: 'This is an average score.',
      default: 'Click to view individual user scores.',
    },
    textReplyNoAverageScore:
      "Open-ended attributes don't have an average score.",
    feedback: {
      title: 'Feedback',
      noResults: 'There is no feedback for this review',
      anonymousUser: { ...anonymousUser.en },
    },
    ...neutralAnswer.en,
    usersFilter: {
      search: commonLabels.search,
      score: commonLabels.score,
      people: commonLabels.people,
      answers: commonLabels.answers,
      titleMatrix: 'Select employees to view their results on the matrix.',
      titleChart: 'Select employees to view their results on the graph.',
      clearAllButtonLabel: 'Clear all',
      disabledUserMessageLimitReached: 'Maximum 6 users can be selected',
      disabledUserMessageUnfinished: "User didn't complete review yet",
      disabledUserMessageUnfinishedTeam:
        "Review isn't completed yet for this user",
    },
    noChartResultsGroup:
      'Results of selected users will be displayed on the chart',
    noChartResultsSingle:
      'Results will be displayed once the review is completed',
    people: commonLabels.people,
    percentage: commonLabels.percentage,
  },
};

const statuses = {
  en: {
    all: 'All',
    toDo: 'To do',
    inProgress: 'In progress',
    done: 'Done',
    draft: 'Draft',
    completed: 'Completed',
    scheduled: 'Scheduled',
    past: 'Past',
    pending: 'Pending',
    upcoming: 'Upcoming',
    started: 'Started',
    submitted: 'Submitted',
    notStarted: 'Not started',
    requested: commonLabels.requested,
    proposed: commonLabels.proposed,
    activeWithoutAccess: 'Active without access',
    activeInvited: 'Active invited',
    activeWithAccess: 'Active with access',
    deactivated: 'Deactivated',
  },
};

const userStatuses = {
  en: {
    actions: {
      invite: commonLabels.invite,
      reinvite: commonLabels.reinvite,
      revokeAccess: commonLabels.revokeAccess,
      reactivate: commonLabels.reactivate,
      deactivate: commonLabels.deactivate,
      delete: commonLabels.delete,
    },
    statuses: { ...statuses.en },
  },
};

const actionPlan = {
  en: {
    title: commonLabels.title,
    improve: 'Improve',
    dueDate: commonLabels.dueDate,
    created: commonLabels.createdBy,
    status: commonLabels.status,
    description: commonLabels.description,
    createdFor: 'For',
    createdOn: 'Created on',
    proposedOn: commonLabels.proposedOn,
    createdBy: commonLabels.createdBy,
    createdByMe: commonLabels.createdByMe,
    tags: commonLabels.tags,
    tasks: commonLabels.tasks,
    preview: commonLabels.preview,
    statuses: { ...statuses.en },
    courses: commonLabels.courses,
    tasksIconTooltip: 'This Action plan has tasks',
    coursesIconTooltip: 'This Action plan has courses',
    completeness: commonLabels.completeness,
    proposed: commonLabels.proposed,
    usersListTooltip: 'Who can see this action plan',
    usersListTitle: 'Users who see this action plan',
    proposeAccept:
      'Would you like to accept this employee proposed Action plan?',
    requested: { ...proposedPlanTooltip.en },
    menuItemLabels: {
      accept: commonLabels.accept,
      edit: commonLabels.edit,
      delete: commonLabels.delete,
      dismiss: commonLabels.dismiss,
      manageTasks: commonLabels.manageTasks,
    },
    actionButtons: { ...iconButtonsTooltip.en },
    visibilityRelations: { ...visibilityRelations.en },
  },
};

const attributeTypes = {
  en: {
    skill: 'Skill',
    engagement: 'Engagement',
    performance: 'Performance',
    oneTime: 'One-Time',
  },
};

const statsOverviewLabels = {
  en: {
    to_do: 'To do',
    in_progress: 'In progress',
    done: 'Done',
    past: 'Past',
    pending: 'Pending',
    upcoming: 'Upcoming',
  },
};

const surveyTypes = {
  self: {
    name: 'Self Assessment',
    description: 'Send this Survey to an employee to review him or herself.',
    explanation:
      'Self assessment survey helps people evaluate their own knowledge, skills, attitudes, and behaviors on a particular topic.',
  },
  team: {
    name: 'Team Review',
    description:
      'Send this Survey to an employee to review his or her team member.',
    explanation:
      'Team review helps one person evaluate a team at the same time, providing objectivity',
  },
  peer: {
    name: 'Peer Review',
    description:
      'Send this Survey to a group of employees to review another employee.',
    explanation:
      "Peer review helps individuals with similar roles and responsibilities assess and provide feedback on each other's work.",
  },
  pulse: {
    name: 'Company Pulse',
    description:
      'Send this Survey to one or more employees to get feedback on general Company' +
      ' Pulse. ',
    explanation:
      'A company pulse survey is a brief, periodic questionnaire' +
      ' used to measure employee engagement and provide insight into the overall health of the organization.',
  },
  direct: {
    name: 'Direct Review',
    description:
      'Send this Survey to get feedback from employee A about employee B.',
    explanation:
      "Direct review helps one person evaluate other's person knowledge," +
      ' skills, and behaviour based on their direct observation of their work.',
  },
};

const surveyReviewersActions = {
  en: {
    sendReminder: 'Send reminder',
    sendReminderAll: 'Send reminder to all',
    reopen: 'Reopen',
    reopenAll: 'Reopen for all',
    closeSurvey: 'Close',
  },
};

const coursesLevels = {
  en: {
    all: 'All',
    beginner: 'Beginner',
    intermediate: 'Intermediate',
    expert: 'Expert',
  },
};

const statusesFilters = {
  en: {
    commonStatuses: {
      active: 'In Progress',
      draft: 'Draft',
      inProgress: 'In Progress',
      completed: 'Completed',
      scheduled: 'Scheduled',
    },
    invitationStatuses: {
      invited: 'Invited',
      notInvited: 'Not Invited',
      joined: 'Joined',
    },
    employeeStatusFilter: {
      active: 'Active',
      inactive: 'Inactive',
    },
    reportToFilter: {
      direct: 'My Direct Reports',
      subUsers: 'All Reports',
      companyView: 'Company View',
    },
  },
};

const actionPlansFilters = {
  en: {
    allUsers: 'None',
    withoutActionPlan: 'Without',
  },
};

const iconAlt = {
  checkIcon: 'Check',
};

const noReports = {
  title: 'No reports available',
  message: 'You can see reports once a survey has been completed.',
  helpText: 'Please click on the button below to send your first survey.',
  buttonText: commonLabels.start,
};

const surveysActionBar = {
  thisWord: 'This',
  needYourFeedback: 'survey needs your feedback.',
  pleaseFill: 'Please fill out this survey to help us assess your coworker(s).',
  sentToYou: 'Sent to you',
  youHave: 'You have',
  pendingSurveys: 'more pending survey(s).',
  widgetName: 'Pending Surveys',
};

const courseActions = {
  en: {
    viewDetails: 'View Details',
    edit: 'Edit',
    deleteAction: 'Delete',
    disenroll: 'Disenroll',
  },
};

const addAttributeDrawerForm = {
  en: {
    save: commonLabels.save,
    add: 'New attribute',
    editDetails: 'Edit details',
    cancel: 'Cancel',
    create: 'Create',
    edit: 'Edit',
    preview: 'Preview',
    saveAndAdd: 'Save and add questions',
    formLabels: {
      name: {
        label: 'Name',
        placeholder: 'Enter name',
      },
      code: {
        label: 'Short name',
        placeholder: 'Enter abbreviation',
      },
      type: {
        label: 'Purpose',
        placeholder: 'Skill',
        noOptions: 'No Options',
      },
      visibility: {
        label: 'Usage',
        labelText:
          'Please select if you want to make this attribute personal and if you would like to restrict the usage of this attribute only to you.',
        labelHelp:
          "Company-wide attributes can be used by all moderators and admins. Personal attributes can be used only by you, and can't be connected to the job titles.",
      },

      status: {
        label: 'Status',
        labelText: 'Please select if you would like inactive status',
        labelHelp:
          'Inactive attributes cannot be assessed by Surveys and are not' +
          " shown in any reports or elsewhere in application. Inactivate attributes that you don't want to see, but don't want to delete.",
      },
      tags: {
        label: 'Tags',
        placeholder: '#Tag',
        creatableOptionLabel: 'Add tag',
      },
      description: {
        label: 'Description',
        placeholder: 'Enter description for your Attribute',
        labelExplanation:
          'Will be visible to person filling a Survey that includes this Attribute.',
      },
      createdBy: { label: commonLabels.createdBy },
      color: {
        label: 'Color',
        tooltip:
          'Add color to the attribute for better organization and overview. Colors assigned to attributes will be visible in different graphs.',
      },
      questionStyle: { label: 'Question style' },
      layout: {
        label: 'Answer type',
        vertical: 'Vertical answer type',
        horizontal: 'Horizontal answer type',
      },
      withNeutralAnswer: {
        fieldLabel: 'Add N/A answer',
        label:
          'Provide respondents an option to mark Not Applicable as an answer.',
      },
      withZeroValueAnswer: {
        fieldLabel: 'Set answer calulation from 0',
        label: 'Provide respondents answers starting with weight zero.',
        labelHelp:
          'Every answer has its own weight (which is displayed on the right side of the answer).' +
          'Weight is used for score calculation in surveys. Adding zero to the respondents answers influences ' +
          'the answers weight distribution. Max weight is 10 and it is always the weight of the last answer.',
      },
      withAdditionalFeedback: {
        fieldLabel: 'Add additional feedback',
        label:
          'Provide respondents an option to leave additional free form feedback after all questions.',
      },
      withTextReplyQuestions: {
        label: 'Answer style',
        off: 'Multiple choice questions',
        on: 'Open-ended questions',
      },
      answerNumber: {
        label: 'Number of answers',
        labelHelp: {
          tooltipText:
            'Pick the number of answers for the question. Each question contains option of an equal number of answers (2-15).',
        },
      },
      questions: { label: 'Questions' },
    },
    validationMessages: {
      availableName: commonFieldValidationMessages.en.availableName,
      required: commonFieldValidationMessages.en.isEmpty,
      forbiddenCharacters: commonFieldValidationMessages.en.forbiddenCharacters,
      maxLength: 'Exceeded max length of 255 characters.',
      maxNameLength: 'Exceeded max length of 100 characters.',
      descriptionMaxLength: 'Exceeded maximum allowed field length.',
      minLength: validationMessages.en.name.minLength,
      itemName: commonFieldValidationMessages.en.itemName,
      availableShortName: 'Short name is already taken.',
      exactLength: 'Short name should be 1 to 5 characters long.',
      attributeUsage: 'Attribute is assigned to level(s).',
    },
  },
};

const addUsers = {
  en: {
    save: commonLabels.save,
    cancel: commonLabels.cancel,
    addUserButton: 'Add new user',
    title: {
      manual: 'Add users manually',
      import: 'Setup imported users from CSV',
      associate: 'Add external associates',
    },
    description: {
      manual:
        'Add users by entering required data. By default, new users are not invited to join. If you want to invite them right away, click on the checkbox.',
      import:
        'Users from your .csv file are listed here. Go through the list, and adjust additional info considering reporting to, role, and the invitation to join.',
      associate:
        "Add users that won't have the profile, but you'll be able to send them a survey to gather data about other users.",
    },

    formLabels: {
      firstName: {
        label: 'First name',
        placeholder: 'First name',
      },
      lastName: {
        label: 'Last name',
        placeholder: 'Last name',
      },
      email: {
        label: 'Email',
        placeholder: 'Email',
      },
      role: {
        label: 'Role',
        placeholder: 'Role',
      },
      reportingTo: {
        label: 'Reporting to',
        placeholder: 'Reporting to',
        noOptions: 'No options',
      },
      invited: {
        label: 'Invited',
      },
      invite: {
        label: 'Invite',
      },
    },
    validationMessages: {
      required: commonFieldValidationMessages.en.isEmpty,
      invalidEmail: 'Email is invalid',
      exceededLengthEmail: 'Maximum length is 100 characters',
      availableEmail: validationMessages.en.email.isAvailable,
      duplicatedEmail: validationMessages.en.email.isUnique,
      minLength: validationMessages.en.firstName.minLength,
      maxLength: validationMessages.en.firstName.maxLength,
      invalidName: validationMessages.en.firstName.invalid,
      freemiumLimit: commonFieldValidationMessages.en.freemium,
    },
    success: {
      add: 'Users added!',
      update: 'Users updated!',
    },
  },
};

const manageActionPlan = {
  en: {
    add: 'New action plan',
    edit: 'Edit action plan',
    proposalTitle: 'Propose new plan',
    acceptProposalTitle: 'Accept plan proposal',
    cancel: commonLabels.cancel,
    save: commonLabels.save,
    ok: commonLabels.ok,
    choose: commonLabels.choose,
    seeMore: 'See more...',
    successAdd: 'New Action plan has been added!',
    successProposal: 'New Action plan proposal has been added!',
    successEdit: 'Action plan has been updated!',
    successEditProposal: 'Action plan proposal has been updated!',
    successAcceptProposal: 'Action plan proposal has been accepted!',
    me: commonLabels.me,
    noOptions: commonLabels.noOptions,
    searching: commonLabels.searching,
    custom: 'Custom',
    formLabels: {
      title: {
        label: commonLabels.title,
        placeholder: 'Enter title',
      },
      user: {
        label: 'This Action plan is for',
        placeholder: "Start typing employee's name",
        noOptions: commonLabels.noOptions,
      },
      courses: {
        addButton: 'Enroll',
        label: 'Courses',
        enrollCourseDialog: { ...customDialogs.en.enrollCourse },
        levels: { ...coursesLevels.en },
      },
      tags: {
        label: 'Tags',
        placeholder: '#Tag',
        creatableOptionLabel: 'Add tag',
      },
      description: {
        label: 'Description',
      },
      dueDate: {
        label: 'Due date',
        placeholder: 'Action plan due date',
      },
      status: {
        label: 'Status',
        placeholder: 'Action plan status',
      },
      notify: {
        label: 'Send email notification to',
      },
      creator: {
        label: 'Person who created action plan',
      },
      createdOn: {
        label: 'Date of action plan creation.',
      },
      completeness: {
        label: commonLabels.completeness,
      },
      manager: {
        label: 'Person who created action plan',
      },
    },
    validationMessages: {
      required: commonFieldValidationMessages.en.isEmpty,
      forbiddenCharacters: commonFieldValidationMessages.en.forbiddenCharacters,
      descriptionMaxLength: 'Exceeded maximum allowed field length.',
      titleMaxLength: 'Exceeded max length of 200 characters',
    },
    actionButtons: {
      edit: commonLabels.edit,
    },
  },
};

const pageHeader = {
  en: {
    jobTitlePlaceholder: commonLabels.noJobTitle,
    successMessages: {
      demoData: 'Demo data is successfully cleared.',
    },
    userMenu: {
      manageSubscription: 'Manage subscription',
      organizationSettings: 'Organization settings',
      slackIntegration: 'Slack integration',
      clearDemoData: 'Clear demo data',
      privacyPolicy: 'Privacy policy',
      releaseNotes: 'Release notes',
      termsOfService: 'Terms of service',
      helpCenter: 'Help center',
      logOut: 'Log out',
    },
  },
};

const compareWidget = {
  notAvailable: 'N/A',
  employee: 'Name',
  average: 'Average',
  noJobTitle: commonLabels.noJobTitle,
  personalAttributeTooltip: personalAttribute.en.tooltipText,
};

const filters = {
  en: {
    filters: commonLabels.filters,
    search: commonLabels.search,
    apply: commonLabels.apply,
    clearAll: 'Clear all',
    labels: {
      active: 'Active',
      attribute: 'Attribute',
      actionPlans: 'Action plans',
      creator: 'Creator',
      reviewers: 'Reviewers',
      subject: 'Subject',
      level: 'Level',
      report: 'Reporting to',
      scope: 'Reporting to',
      status: 'Status',
      position: 'Job title',
      tags: 'Tags',
      track: 'Track',
      trackLevel: 'Track level',
      type: 'Type',
      surveyType: 'Survey type',
      role: 'Role',
      moderator: 'Organized by',
      impression: 'Impression',
      for: commonLabels.for,
      dueDate: commonLabels.dueDate,
    },
  },
};

const multipartPeoplePicker = {
  en: {
    title: 'Add people',
    cancel: commonLabels.cancel,
    save: commonLabels.save,
    filters: { ...filters.en },
    search: 'Search by name',
    allUsers: 'All users',
    clearAllUsers: 'Clear users',
    noSearchResults: commonLabels.noSearchResultsMessage,
  },
};

const manageOneOnOneMessages = {
  en: {
    general: {
      create: '1 - 1 record successfully created',
      edit: '1 - 1 record successfully edited',
      request: '1 - 1 employee request successfully created',
      reschedule: '1 - 1 record successfully rescheduled',
      employeeTopicAdded: 'Your meeting agenda topic has been added.',
      employeeTopicUpdated: 'Your meeting agenda topic has been updated.',
    },
  },
};

const frameworkForm = {
  en: {
    save: commonLabels.save,
    cancel: commonLabels.cancel,
    formLabels: {
      name: {
        label: 'Name',
        placeholder: 'Enter name',
      },
      description: {
        label: 'Description',
        placeholder: 'Write description',
      },
      attributes: {
        label: {
          label: 'Add Attributes',
          attributesLabel: 'Attributes',
          rangeFromLabel: 'Range from',
          rangeToLabel: 'Range to',
        },
        placeholder: 'Add attributes',
      },
      people: {
        label: 'People',
        placeholder: `Employee's name`,
      },
      framework: {
        label: 'Select framework',
        placeholder: '',
      },
      track: {
        label: 'Select track',
        placeholder: '',
      },
      assignUsers: {
        label: 'Assign',
        placeholder: 'Assign users to this level',
      },
    },
    validationMessages: {
      titleMaxLength: 'Exceeded max length of 255 characters',
      levelMaxLength: 'Exceeded max length of 5000 characters',
      required: commonFieldValidationMessages.en.isEmpty,
      forbiddenCharacters: commonFieldValidationMessages.en.forbiddenCharacters,
      uniqueFrameworkName: 'This framework name already exists.',
      requiredRange: 'Range is required.',
      attributeRange: 'Range is not valid.',
    },
  },
};

const calendar = {
  en: {
    name: '1 - 1 meeting with',
    greetingMessage: 'Hi',
    description: 'This is agenda for our 1-1 meeting:',
  },
};

const surveyinfoDescription = {
  en: {
    takeALook: 'Take a look at how',
    usersAbove: 'user(s) above',
    evaluated: 'evaluated',
    selfAssessment: 'Take a look how above user(s) evaluated themselves.',
    companyPulse:
      'Take a look at overall employee engagement, satisfaction,' +
      ' and morale.',
    basedOn: 'This report is based on',
  },
};

const manageTasks = {
  en: {
    cancel: commonLabels.cancel,
    save: commonLabels.save,
    title: commonLabels.manageTasks,
    formLabels: {
      tasks: {
        label: commonLabels.tasks,
        addTaskButton: 'Add new task',
        headers: {
          assignTo: 'Assign to',
          description: 'Description',
        },
        task: {
          labels: {
            description: {
              placeholder: 'Add task description',
            },
            assignTo: {
              placeholder: 'Choose user to assign',
            },
          },
          validationMessages: {
            required: commonFieldValidationMessages.en.isEmpty,
            maxTaskTextLength: commonFieldValidationMessages.en.maxLength,
            forbiddenCharacters:
              commonFieldValidationMessages.en.forbiddenCharacters,
          },
          dragTooltip: 'You can drag tasks to rearrange them.',
        },
      },
    },
    successMessage: 'Tasks have been successfully saved.',
  },
};

const oneOnOneRecords = {
  en: {
    generalImpression: 'Impression',
    note: 'Private note',
    viewPrivateNote: 'Click to view your private note',
    noPrivateNote: 'No private note',
    createdBy: 'Organized by',
    upcoming: 'Upcoming',
    agenda: 'Agenda',
    yes: commonLabels.yes,
    managerAgenda: 'Manager meeting agenda',
    employeeAgenda: 'Employee meeting agenda',
    addYourAgenda: 'Add your topic',
    editYourAgenda: 'Edit your topic',
    edit: commonLabels.edit,
    delete: commonLabels.delete,
    manageTasks: commonLabels.manageTasks,
    createdByMe: 'Organized by me',
    negative: 'Negative',
    neutral: 'Neutral',
    positive: 'Positive',
    requested: { ...requestedOneOnOneTooltip.en },
    description: 'Description',
    calendar: { ...calendar.en },
    impressionTooltip: 'Not visible for [FULL NAME]',
    tags: commonLabels.tags,
    statuses: { ...statuses.en },
    actionButtons: { ...iconButtonsTooltip.en },
    tasksIconTooltip: 'This 1 - 1 has tasks',
    plansIconTooltip: 'This 1 - 1 has associated action plans',
    recurring: commonLabels.recurring,
    usersListTooltip: 'Who can see this 1-1',
    usersListTitle: 'Users who see this 1-1',
    visibilityRelations: { ...visibilityRelations.en },
    notifications: { ...teamNotifications.en },
  },
};

const oneOnOneRecordDialog = {
  en: {
    save: commonLabels.save,
    title: {
      add: 'New 1-1 meeting',
      edit: 'Edit details',
      request: 'Add requested 1 - 1',
      reschedule: 'Reschedule 1-1 meeting',
    },
    formLabels: {
      with: {
        label: 'With',
        placeholder: "Start typing employee's name",
        noOptions: commonLabels.noOptions,
      },
      meetingStatus: {
        labels: { ...statuses.en },
      },
      meetingAgenda: {
        label: 'Meeting agenda',
        placeholder: 'Your custom topic',
      },
      recurring: {
        label: 'Schedule the next 1-1 (optional)',
        placeholder: 'Does not repeat',
      },
      meetingDate: {
        label: 'Date',
      },
      meetingTime: {
        label: 'Time',
      },
      tags: {
        label: 'Tags',
        placeholder: '#Tag',
        creatableOptionLabel: 'Add tag',
      },
      plans: {
        label: 'Associated Action plans',
        placeholder: 'Select Action plans',
      },
      note: {
        label: 'Private note',
        placeholder: 'Add your private note',
        tooltipText: 'This will be visible exclusively to you.',
      },
      meetingImpression: {
        label: 'Impression',
        positive: 'Positive',
        negative: 'Negative',
        info: 'Add impression after 1-1',
      },
      notify: {
        labelText: 'Send updates by email',
      },
    },
    validationMessages: {
      required: commonFieldValidationMessages.en.isEmpty,
      noteMinMaxLength: 'Exceeded maximum allowed field length.',
      customTopicRequired: commonFieldValidationMessages.en.isEmpty,
      minTopicsLength: 'Minimum 1 topic is required',
      maxTopicsLength: 'Maximum 5 topics are allowed',
      customTopicMinMaxLength:
        'Minimum field length is 2 characters and maximum 50.',
      forbiddenCharacters: commonFieldValidationMessages.en.forbiddenCharacters,
    },
  },
};

const surveySummaryForm = {
  en: {
    add: 'Create summary',
    save: commonLabels.save,
    cancel: commonLabels.cancel,
    formLabels: {
      title: {
        label: 'Title',
      },
      description: {
        label: 'Summary',
      },
      reviewsSubtitle: {
        label: 'Reviews',
      },
      reviews: {
        completedCountTooltip: 'Completed',
        attributeScore: {
          anonymous: 'This is an average score.',
          default: 'Click to view individual user scores.',
        },
        textReplyNoAverageScore:
          "Open-ended attributes don't have an average score.",
        usersFilter: {
          search: commonLabels.search,
          score: commonLabels.score,
          people: commonLabels.people,
          answers: commonLabels.answers,
          titleReviewed: 'Select reviewed user(s)',
          titleReviewers: 'Select reviewer(s)',
          clearAllButtonLabel: 'Clear all',
          addAllButtonLabel: 'Select all',
          disabledUserMessageLimitReached: 'Maximum 6 users can be selected',
          disabledUserMessageUnfinished: "User didn't complete review yet",
          disabledUserMessageUnfinishedTeam:
            "Review isn't completed yet for this user",
        },
      },
      isAnonymous: {
        labelText: 'Anonymize reviewers',
        endLabelIconHelp: 'If checked, all reviewers will be anonymized',
      },
      shareWith: {
        label: 'Share with',
        placeholder: 'Select user(s) to share with',
      },
    },
    validationMessages: {
      required: commonFieldValidationMessages.en.isEmpty,
      forbiddenCharacters: commonFieldValidationMessages.en.forbiddenCharacters,
      titleMaxLength: 'Exceeded max length of 150 characters.',
      summaryMaxLength: 'Exceeded maximum allowed field length.',
      reviews: 'Select at least one user.',
    },
    successMessage: 'Survey summary has been successfully created.',
  },
};

const surveySummaryShare = {
  en: {
    add: 'Share summary report',
    save: commonLabels.save,
    cancel: commonLabels.cancel,
    formLabels: {
      shareWith: {
        label: 'Share with',
        placeholder: 'Select user(s) to share with',
      },
    },
    validationMessages: {},
    successMessage: 'Successfully updated shared with.',
  },
};

const manageTopic = {
  en: {
    save: commonLabels.save,
    request: commonLabels.request,
    employeeTopicTitle: 'Your 1 - 1 meeting agenda',
    requestTitle: 'Request 1 - 1 meeting',
    formLabels: {
      name: {
        label: 'Meeting agenda',
        placeholder: 'Add custom topic',
      },
      moderator: {
        label: 'Meeting person',
      },
    },
    validationMessages: {
      required: commonFieldValidationMessages.en.isEmpty,
      forbiddenCharacters: commonFieldValidationMessages.en.forbiddenCharacters,
      topicMaxLength: 'Minimum topic length is 2 and maximum 50 characters',
    },
  },
};

const noteDialog = {
  en: {
    cancel: commonLabels.cancel,
    save: commonLabels.save,
    title: {
      add: 'New public note',
      edit: 'Edit public note',
      addPrivateNote: 'New private note',
      editPrivateNote: 'Edit private note',
    },
    formLabels: {
      note: {
        label: 'Public note',
        placeholder: 'Write your public note here',
      },
      notePrivate: {
        label: 'Private note (Visible only to you)',
        placeholder: 'Write your private note here',
      },
      tags: {
        label: commonLabels.tags,
        placeholder: '#Tag',
        creatableOptionLabel: 'Add tag',
      },
      tasks: {
        label: commonLabels.tasks,
        addTaskButton: 'Add new task',
        headers: {
          assignTo: 'Assign to',
          description: 'Description',
        },
        task: {
          labels: {
            description: {
              placeholder: 'Add task description',
            },
            assignTo: {
              placeholder: 'Choose user to assign',
            },
          },
          validationMessages: {
            required: commonFieldValidationMessages.en.isEmpty,
            maxTaskTextLength: commonFieldValidationMessages.en.maxLength,
            forbiddenCharacters:
              commonFieldValidationMessages.en.forbiddenCharacters,
          },
          dragTooltip: 'You can drag tasks to rearrange them.',
        },
      },
    },
    successMessages: {
      add: 'New Note has been successfully added.',
      edit: 'Note has been successfully updated.',
    },
    validationMessages: {
      required: commonFieldValidationMessages.en.isEmpty,
      noteMinMaxLength: 'Exceeded maximum allowed field length.',
    },
  },
};

const jobTitleAssigning = {
  en: {
    add: 'Assign job title',
    save: commonLabels.save,
    cancel: commonLabels.cancel,
    labelForModerators: 'Assign to (Only your reports are shown)',
    formLabels: {
      assignTo: {
        label: 'Assign to',
        placeholder: 'Select user(s) for assigning the job title',
      },
    },
    validationMessages: {},
    successMessage: 'Successfully updated job title assigning.',
  },
};

const note = {
  en: {
    about: 'About',
    createdOn: 'Created on',
    createdBy: commonLabels.createdBy,
    basedOn: 'Based on',
    note: commonLabels.note,
    privateNote: commonLabels.privateNote,
    tags: commonLabels.tags,
    edit: commonLabels.edit,
    tasks: commonLabels.tasks,
    delete: commonLabels.delete,
    privateNoteTooltip: 'Visible only to you.',
    basedOnOneOnOne: '1 - 1 meeting',
    createdByMe: commonLabels.createdByMe,
    tasksList: {
      taskStatusDialog: { ...alertDialogs.en.taskStatusChange },
    },
    usersListTooltip: 'Who can see this note',
    usersListTitle: 'Users who see this note',
    visibilityRelations: { ...visibilityRelations.en },
    notifications: { ...teamNotifications.en },
  },
};

const usersComments = {
  en: {
    at: 'at',
    edited: 'Edited',
    addComment: {
      placeholder: 'Write a comment',
      errorMessages: {
        required: 'Comment is required',
        forbiddenCharacters:
          commonFieldValidationMessages.en.forbiddenCharacters,
        maxLength: 'Exceeded max length of 1000 characters',
      },
      save: commonLabels.save,
      success: 'Comment successfully sent.',
      update: 'Comment successfully updated.',
      notifyDialog: { ...customDialogs.en.oneOnOneCommentNotify },
    },
    removedUserTooltip: 'This user is no longer available.',
    deleteCommentDialog: { ...alertDialogs.en.oneOnOneCommentDelete },
  },
};

const usersNotes = {
  en: {
    at: 'at',
    edited: 'Edited',
    addComment: {
      placeholder: 'Write a note',
      errorMessages: {
        required: 'Note is required',
        forbiddenCharacters:
          commonFieldValidationMessages.en.forbiddenCharacters,
        maxLength: 'Exceeded max length of 20 000 characters',
      },
      save: commonLabels.save,
      success: 'Note successfully sent.',
      update: 'Note successfully updated.',
      notifyDialog: { ...customDialogs.en.oneOnOneCommentNotify },
    },
    removedUserTooltip: 'This user is no longer available.',
    deleteCommentDialog: { ...alertDialogs.en.noteDelete },
  },
};

export const presets = {
  en: {
    remove: 'Delete preset',
    saveButtonLabel: 'Save',
    label: 'Presets',
    placeholder: 'Preset name',
    inputPlaceholder: 'New preset name',
    noResults: commonLabels.noResults,
    startSearch: 'Start typing ...',
    used: 'Selected filters have already been saved.',
    save: commonLabels.save,
    cancel: commonLabels.cancel,
    limitErrorMessage: 'Exceeded max length of 100 characters.',
    emptyErrorMessage: 'Preset name should not be empty.',
    forbiddenCharacters: commonFieldValidationMessages.en.forbiddenCharacters,
    availableName: 'Preset name is already taken.',
    noPresetResults: commonLabels.noPresetResults,
    presetConditionTooltip:
      'Delete one of presets to make a new one. Maximum number of presets is 10.',
    comparePresetConditionTooltip:
      'Choose user and select survey type / reviewers.',
    attributePresetConditionTooltip:
      'Choose attribute filter and reviewed users / reviewers.',
    oneOnOnePresetConditionTooltip: 'Choose employee(s) from people filter.',
    presetDeleteDialog: { ...alertDialogs.en.presetDelete },
    successMessages: {
      createdPreset: 'Preset is successfully created',
      deletedPreset: 'Preset is successfully deleted',
    },
  },
};

export const translations = {
  en: {
    App: {
      support: 'Support',
      sidebarNavigation: { ...sidebarNavigation.en },
    },
    SignUpPage: {
      formLabels: {
        email: {
          label: 'Email',
          placeholder: 'Email address',
        },
        verification: {
          label: 'Verification code',
        },
        fullName: {
          placeholder: {
            firstName: 'First Name',
            lastName: 'Last Name',
          },
        },
        domainName: {
          placeholder: 'Workspace name',
        },
        password: {
          placeholder: 'Password',
        },
      },
      email: {
        email: commonLabels.email,
        emailPageTitle: 'Create your free account',
        emailPageSubtitle: 'No card needed.',
      },
      verification: {
        title: 'Check your email',
        label: 'Please enter the verification code we sent to:',
      },
      fullName: {
        title: 'What is your name',
      },
      domainName: {
        title: 'Hello, `ello',
        subtitle: "Let's choose your workspace URL",
        domainNameLabel: '.kadar.app',
      },
      password: {
        title: 'Create your password',
        validationLabels: [
          'At least 8 characters',
          'One lowercase character',
          'One uppercase character',
          'One number or symbol',
          'No whitespace',
        ],
      },
      jobTitle: {
        title: "Let's personalize experience for",
        label: 'My role is:',
      },
      incentive: {
        title: 'I want Kadar to help me:',
        label: 'Tick all that apply',
      },
      companySize: {
        title: 'My company size is:',
      },
      preference: {
        title: 'What do you prefer',
      },
      authContainer: { ...authContainer.en },
      somethingWentWrong: commonFieldValidationMessages.en.somethingWentWrong,
      readyInMoment: 'Your organization will be ready in a moment.',
      dontReloadMessage:
        "Please don't reload the page until organization initialization is done.",
      loadingText: 'Finalizing your organization setup...',
      backgroundPlaceholder: 'Background image',
      kadar: commonLabels.app,
      back: commonLabels.back,
      next: commonLabels.next,
      verifyEmail: commonLabels.verifyEmail,
      resend: commonLabels.resend,
      and: 'and',
      disclaimer:
        'We’re committed to your privacy. Kadar uses the information you provide to us to contact you about our relevant content, products, and services. You may unsubscribe from these communications at any time. For more information, check out our',
      validationMessages: {
        codeLength: 'Verification code should contain 6 digits.',
        alreadyTaken: 'Already taken',
        required: commonFieldValidationMessages.en.isEmpty,
        invalidName: validationMessages.en.firstName.invalid,
        minLength: validationMessages.en.firstName.minLength,
        maxLength: validationMessages.en.firstName.maxLength,
        invalidWorkspaceUrl: 'Workspace url is invalid',
        requiredLength: 'At least 8 characters',
        lowercase: 'One lowercase character',
        uppercase: 'One uppercase character',
        whitespace: 'No whitespace',
        typeOfCharacters: 'One number or symbol',
        invalidEmail: 'Email is invalid',
        exceededLengthEmail: 'Maximum length is 100 characters',
      },
    },
    DashboardPage: {
      title: 'Dashboard',
      pageHeader: { ...pageHeader.en },
      toDo: {
        title: 'Things to do',
        noData: {
          title: 'Perfectly cleaned',
          message:
            'All things to do are neatly cleaned up, be diligent in the future as well, the more often you see this message, the easier it will be for you :)',
        },
        survey: {
          title: commonLabels.survey,
          surveyTypes: { ...surveyTypes },
          expiryDate: 'Expiry date',
          fillSurvey: commonLabels.fill,
        },
        planProposal: {
          title: commonLabels.actionPlan,
          proposed: commonLabels.proposed,
          proposedOn: commonLabels.proposedOn,
          accept: commonLabels.accept,
          dismiss: commonLabels.dismiss,
        },
        oneOnOneRequest: {
          title: commonLabels.oneOnOne,
          accept: commonLabels.accept,
          dismiss: commonLabels.dismiss,
          requestedOn: commonLabels.requestedOn,
          statuses: { ...statuses.en },
        },
      },
      tasks: {
        title: 'My Tasks',
        noData: {
          message: 'Once Tasks are assigned to you, they will appear here.',
        },
        noDataFinished: {
          message: "You don't have any unfinished tasks",
        },
        taskStatusDialog: { ...alertDialogs.en.taskStatusChange },
        assignedBy: 'Assigned by',
        you: commonLabels.you,
        statusTooltip: 'Toggle visibility of finished tasks',
        deletedUserTooltip: 'This user is no longer available.',
      },
      courses: {
        title: 'My Courses',
        noData: {
          message: "Courses you're enrolled in will be listed here.",
        },
        aiButtonTooltip:
          'See AI-powered learning recommendations based on your profile!',
        tags: commonLabels.tags,
        statuses: { ...statuses.en },
        successStatus: 'Status has been updated successfully.',
        aiSuggestionsDialog: { ...customDialogs.en.aiSuggestions },
      },
      plans: {
        title: 'My Action plans',
        noData: {
          message: 'Once Action plans are added, they will appear here.',
        },
        proposeButton: commonLabels.propose,
        plan: { ...actionPlan.en },
        manageActionPlan: { ...manageActionPlan.en },
        dismissActionPlanProposalDialog: {
          ...alertDialogs.en.actionPlanProposalDismiss,
        },
      },
      oneOnOne: {
        title: 'My 1-1s',
        noData: {
          message: 'Past and scheduled 1-1 meetings will be visible here.',
        },
        requestButton: commonLabels.request,
        meetingDate: 'Meeting date',
        requestCard: {
          userRequest:
            'Your request has been successfully sent to the manager for approval.',
          expired:
            'Your manager is changed in the meantime. If you want to request' +
            ' 1 - 1 to the new manager, please delete this request and make' +
            ' a new one.',
          expiredWithNoModerator:
            'This request is outdated, delete it if you want to!',
          requested: commonLabels.requested,
        },
        successMessages: {
          meetingRequestAdded: 'Your meeting request has been sent.',
          meetingRequestUpdated:
            'Your meeting request agenda topic has been updated.',
          meetingCreated: '1 - 1 record successfully created',
        },
        upcomingMeetingDialog: { ...alertDialogs.en.upcomingMeeting },
        deleteMeetingRequestDialog: { ...alertDialogs.en.meetingRequestDelete },
        manageTopicForm: { ...manageTopic.en },
        dismissOneOnOneRequestDialog: {
          ...alertDialogs.en.oneOnOneRequestDismiss,
        },
        oneOnOneRecordDialog: { ...oneOnOneRecordDialog.en },
        statuses: { ...statuses.en },
        requested: { ...requestedOneOnOneTooltip.en },
        tasksIconTooltip: 'This 1 - 1 has tasks',
        plansIconTooltip: 'This 1 - 1 has associated action plans',
        recurring: commonLabels.recurring,
        tags: commonLabels.tags,
        calendar: { ...calendar.en },
      },
      myTeam: {
        title: 'My Team',
        noData: {
          message: 'Once you have your team, they will appear here.',
        },
        labels: {
          oneOnOnes: commonLabels.oneOnOnes,
          actionPlans: commonLabels.actionPlans,
          courses: commonLabels.courses,
          notes: commonLabels.notes,
          ...statuses.en,
          total: 'Total',
        },
        tooltipMessages: {
          oneOnOnes: 'Trend chart based on last 6 impressions',
        },
        notifications: {
          markAsRead: 'Mark all as read',
          notificationButtonTooltip: 'Stay up-to-date with recent changes.',
          types: {
            create: 'Created',
            update: 'Updated',
          },
          models: {
            note: commonLabels.note,
            oneOnOne: commonLabels.oneOnOne,
            actionPlan: commonLabels.actionPlan,
          },
        },
      },
    },
    CompanyOverviewPage: {
      pageTitle: 'Company overview',
      pageHeader: { ...pageHeader.en },
      sectionTitles: {
        oneOnOne: '1 - 1 meetings',
        actionPlans: 'Action plans',
        courses: 'Courses',
        overview: {
          title: 'Overview',
          description: 'General overview of all teams',
        },
      },
      tooltipLabels: {
        companyOneOnOne: 'All 1-1s throughout the entire organization.',
        companyActionPlans:
          'All Action plans throughout the entire organization.',
        companyCourses:
          'All learning actions throughout the entire organization.',
        oneOnOne:
          'All 1-1s for selected manager and his reports (all reports included) who were 1-1 organizers.',
        actionPlans:
          "All Action plans for selected manager's reports (all reports included)",
        courses:
          'All learning actions for selected manager and his reports (all reports included).',
      },
      overviewStatusLabels: { ...statsOverviewLabels.en },
      overviewPlaceholders: {
        actionPlansOverview: {
          partOne: 'Create',
          partTwo: 'Action Plans',
        },
        coursesOverview: {
          partOne: 'Assign',
          partTwo: 'Courses',
        },
        oneOnOneOverview: {
          partOne: commonLabels.schedule,
          partTwo: '1-1s',
        },
      },
      periodOptions: { ...periodFilter.en },
      teamOverviewTable: {
        headerLabels: {
          manager: {
            label: commonLabels.manager,
          },
          oneOnOne: {
            label: '1 on 1s',
            subLabels: {
              direct: 'Direct Reports',
              withoutOneOnOne: 'Without 1-1',
              negativeImpressions: 'Negative Impression',
            },
            tooltips: {
              negativeImpressions:
                'Percentage of team members with whom' +
                ' there was 1-1 with a negative impression (score of 1-3)',
            },
          },
          actionPlans: {
            label: 'Action plans',
            subLabels: {
              toDo: 'To-dos',
              overdue: 'Overdue',
            },
          },
          metrics: {
            label: 'Metrics',
            subLabels: {
              coverage: '1-1 coverage',
              learning: 'Learning',
              impression: '1-1 impression',
            },
            tooltips: {
              coverage: 'Percentage of the team covered by 1-1 meetings.',
              learning:
                'Percentage of the team that learning actions are' +
                ' assigned to.',
              impression:
                "How positive general impression of team's 1-1" +
                ' meetings is.',
            },
          },
        },
        noResults: {
          title: 'No overview',
          content:
            'There are no data on any of the teams for the last 60 days.',
        },
        loadButtonLabel: commonLabels.loadMore,
      },
      noJobTitle: commonLabels.noJobTitle,
    },
    PeoplePage: {
      pageTitle: 'People directory',
      pageHeader: { ...pageHeader.en },
      externalUsersViewDescription:
        'View and manage all the People in your organization. External users ARE NOT part of the monthly subscription plan.',
      deactivatedUsersViewDescription:
        'View and manage all the People in your organization. Deactivated users ARE NOT part of the monthly subscription plan.',
      activeUsersViewDescription:
        'View and manage all the People in your organization. Active users ARE part of the monthly subscription plan.',
      addPeopleButton: 'Add people',
      search: 'Search by name',
      organization: commonLabels.organization,
      loadMore: commonLabels.loadMore,
      editUser: 'Edit information',
      noSearchResultsMessage: commonLabels.noSearchResultsMessage,
      columns: {
        checkbox: 'Select All',
        name: 'Name',
        dateOfEmployment: 'Years Employed',
        email: 'Email',
        jobTitle: 'Job title',
        levelAndTrack: 'Level and track',
        lastInquiry: 'Last Inquiry',
        role: 'Role',
        status: 'Status',
        actions: commonLabels.actions,
      },
      role: { ...accountTypes.en },
      roleDescriptions: { ...roleDescriptions.en },
      userStatuses: { ...userStatuses.en },
      reportToFilter: { ...statusesFilters.en.reportToFilter },
      actionPlansFilters: { ...actionPlansFilters.en },
      action: 'Action',
      selectBulkActions: {
        changeReportingTo: 'Change Reporting to',
        changeJobTitle: 'Change Job title',
        inviteUsers: 'Invite users',
        changeLevelTrack: 'Change Level/Track',
        changeRole: 'Change Role',
        applyTags: 'Apply Tags',
      },
      changeExternalRoleDialog: { ...alertDialogs.en.changeExternalRole },
      bulkEditDialog: {
        ...customDialogs.en.bulkEdit,
        ...customDialogs.en.manageUserTags,
      },
      addUsersDialog: { ...customDialogs.en.addUsersDialog },
      addUsersTable: { ...addUsers.en },
      currentUserTooltip: "Can't select yourself!",
      currentUserActionTooltip: "Actions aren't allowed on yourself!",
      inactiveUserTooltip: "Can't select inactive employee!",
      userIsNotReporter: "Employee isn't in your reporters list!",
      userIsNotReporterAction: 'Actions are allowed only for your reporters!',
      externalUser: "Can't select External associate!",
      filters: { ...filters.en },
      invite: 'Invite',
      resendInvite: 'Resend invite',
      deactivate: 'Deactivate',
      userEditSuccess: 'User has been successfully updated.',
      userViewOptions: {
        active: 'Active users view',
        deactivated: 'Deactivated users view',
        external: 'External users view',
      },
      formLabels: {
        status: {
          label: 'Status',
        },
        first_name: {
          label: 'First name',
        },
        last_name: {
          label: 'Last name',
        },
        email: {
          label: 'Email',
        },
        position: {
          label: 'Job title',
        },
        date_of_employment: {
          label: 'Employed',
        },
        framework: {
          label: 'Framework',
        },
        track: {
          label: 'Track',
        },
        level: {
          label: 'Level',
        },
        report_to: {
          label: 'Reporting to',
          noOptions: 'No options',
        },
        personalInformation: {
          label: 'Personal Information',
        },
        employmentInformation: {
          label: 'Employment Information',
        },
        role: {
          ...roleTooltipDescriptions.en,
        },
        permissions: {
          label: 'Permissions',
        },
      },
      validationMessages: {
        required: commonFieldValidationMessages.en.isEmpty,
        invalidEmail: 'Email is invalid',
        exceededLengthEmail: 'Maximum length is 100 characters',
        availableEmail: validationMessages.en.email.isAvailable,
        minLength: validationMessages.en.firstName.minLength,
        maxLength: validationMessages.en.firstName.maxLength,
        invalidName: validationMessages.en.firstName.invalid,
        freemium: commonFieldValidationMessages.en.freemium,
      },
      menuItemLabels: {
        edit: commonLabels.edit,
        delete: commonLabels.delete,
        reactivate: commonLabels.reactivate,
        invite: commonLabels.invite,
        reinvite: commonLabels.reinvite,
        deactivate: commonLabels.deactivate,
        revokeAccess: commonLabels.revokeAccess,
      },
      deleteUserDialog: { ...alertDialogs.en.userDelete },
      inviteUserDialog: { ...alertDialogs.en.userInvite },
      reinviteUserDialog: { ...alertDialogs.en.userReinvite },
      revokeAccessUserDialog: { ...alertDialogs.en.userRevokeAccess },
      deactivateUserDialog: { ...alertDialogs.en.userDeactivate },
      reactivateUserDialog: { ...customDialogs.en.reactivateUser },
      transferEmployeesDialog: { ...customDialogs.en.transferEmployees },
      save: commonLabels.save,
      cancel: commonLabels.cancel,
    },
    PeopleProfilePage: {
      pageContainer: { ...peoplePageContainer.en },
      pageHeader: { ...pageHeader.en },
      description:
        'General info page. Access permission and visibility. ' +
        'Settings who reports to whom. ' +
        'Employment status.',
      descriptionOwnProfile:
        'General info page. Access permission and visibility. ' +
        'Settings who reports to whom. ' +
        'Employment status.',
      edit: 'Edit',
      statusLabel: 'Status',
      personalInfo: 'Personal Information',
      employmentInfo: 'Employment Information',
      tagsInfo: 'Tags',
      organization: commonLabels.organization,
      add: 'Add tags',
      successUpdate: 'User data has been successfully updated.',
      role: { ...accountTypes.en },
      userStatuses: { ...userStatuses.en },
      roleDescriptions: { ...roleDescriptions.en },
      noTags: 'No tags added yet for this user.',
      shareEmployeeDialog: { ...customDialogs.en.employeeShare },
      manageUserTagsDialog: { ...customDialogs.en.manageUserTags },
      tags: {
        description:
          'Tags help you search and filter by specific, common word.' +
          ' Tags are organized in categories such are projects, clients,' +
          ' skills or any other definition of your preference.',
      },
      deleteUserDialog: { ...alertDialogs.en.userDelete },
      inviteUserDialog: { ...alertDialogs.en.userInvite },
      reinviteUserDialog: { ...alertDialogs.en.userReinvite },
      revokeAccessUserDialog: { ...alertDialogs.en.userRevokeAccess },
      deactivateUserDialog: { ...alertDialogs.en.userDeactivate },
      reactivateUserDialog: { ...customDialogs.en.reactivateUser },
      transferEmployeesDialog: { ...customDialogs.en.transferEmployees },
      somethinWentWrong: commonFieldValidationMessages.somethingWentWrong,
      employeeShareSection: {
        title: 'Details shared',
        description:
          'There are two types of access - viewer and collaborator. ' +
          'A viewer can only see information regarding an employee.' +
          ' A collaborator can organize a 1-1 meeting, write private notes,' +
          'assign a Course, or create an Action Plan considering that employee.',
        shareButton: 'Share',
        peopleWithAccess: 'People with access',
        notSharedYet: 'Not explicitly shared with anyone yet.',
        unshareUserDialog: { ...alertDialogs.en.userUnshare },
        sharedItem: {
          viewer: 'Viewer',
          collaborator: 'Collaborator',
        },
        disabledShareActionTooltip: "Can't share deactivated user.",
      },
      editInformation: 'Edit information',
      formLabels: {
        status: {
          label: 'Status',
        },
        first_name: {
          label: 'First name',
        },
        last_name: {
          label: 'Last name',
        },
        email: {
          label: 'Email',
        },
        position: {
          label: 'Job title',
        },
        date_of_employment: {
          label: 'Employed',
        },
        framework: {
          label: 'Framework',
        },
        track: {
          label: 'Track',
        },
        level: {
          label: 'Level',
        },
        report_to: {
          label: 'Reporting to',
          noOptions: 'No options',
        },
        personalInformation: {
          label: 'Personal Information',
        },
        employmentInformation: {
          label: 'Employment Information',
        },
        role: {
          ...roleTooltipDescriptions.en,
        },
        permissions: {
          label: 'Permissions',
        },
      },
      validationMessages: {
        required: commonFieldValidationMessages.en.isEmpty,
        invalidEmail: 'Email is invalid',
        exceededLengthEmail: 'Maximum length is 100 characters',
        availableEmail: validationMessages.en.email.isAvailable,
        minLength: validationMessages.en.firstName.minLength,
        maxLength: validationMessages.en.firstName.maxLength,
        invalidName: validationMessages.en.firstName.invalid,
        freemium: commonFieldValidationMessages.en.freemium,
      },
      editTitle: 'Edit information',
      save: commonLabels.save,
      cancel: commonLabels.cancel,
      personalInformation: {
        title: 'Personal Information',
        fields: {
          userName: 'Full name',
          email: 'Email',
        },
      },
      employmentInformation: {
        title: 'Employment Information',
        fields: {
          date_of_employment: 'Employed',
          position: 'Job title',
          framework: 'Framework',
          track: 'Track',
          level: 'Level',
          report_to: 'Reporting To',
        },
      },
      rolesAndPermissions: {
        title: 'Permissions',
        fields: {
          role: 'Role',
        },
      },
      disabledEditActionTooltip: "Can't edit deactivated user.",
      disabledTagsActionTooltip: "Can't add tags to deactivated user.",
    },
    PeopleCareerPage: {
      pageHeader: { ...pageHeader.en },
      description:
        'Follow up on the career performance for the current position as well as the other career possibilities for this employee.',
      attributeTypes: { ...attributeTypes.en },
      careerInfoSuccess: 'Career info has been successfully updated.',
      pageContainer: { ...peoplePageContainer.en },
      careerPerformance: {
        title: 'Career performance',
        jobTitle: 'Job title',
        attributes: 'Attributes',
        description: 'Description',
        addJobTitle: 'Add job title',
        assignJobTitle: 'Assign job title',
        noJobTitle: "Job title isn't assigned",
        disabledAssignActionTooltip:
          "Can't assign Job title to deactivated user.",
        measuredAttributes: {
          attributes: 'Attributes',
          peer: 'Peer, Team & Direct',
          extrinsicAssessment: 'Extrinsic assessment',
          extrinsic: 'Extrinsic',
          selfAssessment: 'Self assessment',
          self: 'Self',
          range: 'Range',
          openEndedAttribute: 'Open-ended attribute',
          sendSurvey: commonLabels.sendSurvey,
          compareReport: commonLabels.compareReport,
        },
      },
      careerProgress: {
        title: 'Career progress',
        currentLevel: 'Current level',
        nextStage: 'Next stage',
        period: 'Period',
        attributes: 'Attributes',
        range: 'Range',
        score: 'Score',
        noLevel: {
          title: 'To preview career path, add track and level.',
          assignLevel: 'Add',
          addLevel: 'Add level',
          disabledAssignActionTooltip: "Can't add Level to deactivated user.",
        },
      },
      addUserCareerForm: {
        save: commonLabels.save,
        add: 'Career info',
        formLabels: {
          jobTitle: {
            label: 'Job title',
            placeholder: 'Add job title',
            labelHelp: {
              tooltipText: employmentInfoHelpText.en.jobTitle.text,
            },
          },
          framework: {
            label: 'Framework',
            placeholder: 'Add framework',
            labelHelp: {
              tooltipText: employmentInfoHelpText.en.framework.text,
            },
          },
          track: {
            label: 'Track',
            placeholder: 'Add track',
            labelHelp: {
              tooltipText: employmentInfoHelpText.en.track.text,
            },
          },
          level: {
            label: 'Level',
            placeholder: 'Add level',
            labelHelp: {
              tooltipText: employmentInfoHelpText.en.level.text,
            },
          },
        },
        validationMessages: {
          required: commonFieldValidationMessages.en.isEmpty,
        },
      },
    },
    PeopleNotesPage: {
      pageContainer: { ...peoplePageContainer.en },
      pageHeader: { ...pageHeader.en },
      description: 'View all the Notes created for this team member.',
      addNoteButton: 'New note',
      noResults: {
        title: 'No notes yet!',
        message: "Once they're added, they will appear here.",
      },
      inactiveUser: 'Inactive user',
      noteDeleteDialog: { ...alertDialogs.en.noteDelete },
      manageNotesDialog: { ...noteDialog.en },
      noteTypeDialog: { ...customDialogs.en.peopleNoteTypes },
      note: { ...note.en },
      disabledAddActionTooltip: "Can't make Note for deactivated user.",
    },
    PeopleOneOnOnePage: {
      all: commonLabels.all,
      pageContainer: { ...peoplePageContainer.en },
      pageHeader: { ...pageHeader.en },
      description:
        'View all the 1 - 1 meetings organized for this team member.',
      addButtonLabel: 'New 1 - 1',
      filters: { ...filters.en },
      oneOnOneRecords: { ...oneOnOneRecords.en },
      loadMore: commonLabels.loadMore,
      statuses: { ...statuses.en },
      noDataLabel: commonLabels.none,
      noRecords: {
        title: 'Currently, there are no 1 - 1 records for this employee.',
        inactiveUser: 'Inactive user',
        message: 'Click on the button to create the first 1 - 1 record.',
        noSearchResultsMessage: commonLabels.noSearchResultsMessage,
      },
      requestCard: {
        requested: commonLabels.requested,
        scheduleMeeting:
          'Would you like to schedule this meeting as an' +
          ' employee-initiated 1-1?',
        agenda: 'Employee meeting agenda (Added [DATE])',
        actionButtons: { ...iconButtonsTooltip.en },
      },
      successMessages: { ...manageOneOnOneMessages.en },
      oneOnOneRecordDialog: { ...oneOnOneRecordDialog.en },
      deleteOneOnOneRecordDialog: { ...alertDialogs.en.oneOnOneRecordDelete },
      dismissOneOnOneRequestDialog: {
        ...alertDialogs.en.oneOnOneRequestDismiss,
      },
      addImpressionDialog: { ...customDialogs.en.addImpression },
      manageTasksDialog: { ...manageTasks.en },
      disabledAddActionTooltip: "Can't make 1 - 1 with deactivated user.",
    },
    CompareReportPage: {
      title: 'Compare',
      pageHeader: { ...pageHeader.en },
      ...readLessMoreLabels,
      description:
        'Explore the shifting perceptions and evaluations of people attributes scores over time through the lens of specific reviewers.',
      noResults: 'There are no compare scores to display.',
      user: {
        label: 'Created for',
        placeholder: 'Choose user',
      },
      filters: { ...filters.en },
      role: { ...accountTypes.en },
      roleDescriptions: { ...roleDescriptions.en },
      userStatuses: { ...userStatuses.en },
      reportToFilter: { ...statusesFilters.en.reportToFilter },
      compare: {
        surveyType: {
          label: 'Survey type',
        },
        period: commonLabels.period,
        reviewersPicker: {
          addPeopleLabel: 'Add reviewers',
          picker: { ...multipartPeoplePicker.en, title: 'Add reviewers' },
        },
        noAttributeResults: 'There are no attribute scores to display.',
        syncFiltersButton: 'Sync filters with created for user',
        removeCompareWith: 'Remove user',
        compareWithButton: 'Add or change user for comparison',
        addCompareWithDialog: { ...customDialogs.en.addCompareWith },
        surveyOptions: { ...groupedSurveyOptions.en },
        restoreAttributes: 'Restore removed attributes',
        attribute: { ...attributeChip.en },
      },
      presets: { ...presets.en },
    },
    SurveySettingsPage: {
      pageHeader: { ...pageHeader.en },
      anonymousSurvey: `${commonLabels.anonymous} survey`,
      oneTimeSurvey: 'Excluded from statistics',
      seeReportButton: 'See report',
      organization: commonLabels.organization,
      closeSurveyButton: 'Close survey',
      forceCompleteDialog: { ...alertDialogs.en.surveyForceComplete },
      sendReminderAllDialog: { ...alertDialogs.en.surveySendReminderAll },
      sendReminderDialog: { ...customDialogs.en.surveySendReminder },
      surveyReopenAllDialog: { ...alertDialogs.en.surveyReopenAll },
      surveyReopenDialog: { ...customDialogs.en.surveyReopen },
      addReviewersDialog: { ...customDialogs.en.addReviewers },
      statuses: { ...statuses.en },
      surveyTypes: { ...surveyTypes },
      anonymous:
        'Reviewers actions are unavailable in order to provide full anonymity.',
      renameSurveyDialog: { ...customDialogs.en.surveyTitle },
      info: {
        attributes: 'Attributes',
        status: 'Status',
        type: commonLabels.type,
        dateSent: 'Date sent',
        expireAt: 'Expiry',
        scheduledFor: 'Scheduled for',
        createdBy: commonLabels.createdBy,
        reviewedUsers: 'Who will be reviewed',
        completed: 'Completed',
        group: 'Group',
        manageGroupDialog: { ...customDialogs.en.manageGroupItems },
        expiryDialog: {
          title: 'Expiry',
          description: 'Please select new expiry date for this survey',
          formLabels: {
            expiryDate: {
              label: 'Expiry',
              tooltipInfo:
                'Specify an expiration date for your survey. By default, it will remain open for 7 days before closing.',
            },
          },
          close: commonLabels.close,
          save: commonLabels.save,
        },
      },
      reviewers: {
        title: 'Reviewers',
        addButton: 'Add new reviewers',
        addScheduleButton: 'Schedule new reviewers',
        loadMore: commonLabels.loadMore,
        status: 'Status',
        search: commonLabels.search,
        action: 'Action',
        completionStatuses: { ...statuses.en },
        noReviewers: "This survey doesn't have active reviewers.",
        actions: { ...surveyReviewersActions.en },
      },
    },
    SurveyReportPage: {
      pageHeader: { ...pageHeader.en },
      anonymousSurvey: `${commonLabels.anonymous} survey`,
      oneTimeSurvey: 'Excluded from statistics',
      statuses: { ...statuses.en },
      surveyTypes: { ...surveyTypes },
      createSummaryReport: 'Create summary report',
      createSummaryReportDisabled:
        'A summary can be added once the survey is completed and has results.',
      noReport:
        'There are no sufficient information available to prepare this report.',
      surveyTypesDescription: { ...surveyinfoDescription.en },
      surveySummaryForm: { ...surveySummaryForm.en },
      info: {
        status: 'Status',
        type: commonLabels.type,
        dateSent: 'Date sent',
        scheduledFor: 'Scheduled for',
        createdBy: commonLabels.createdBy,
        reviewers: 'Reviewers',
        reviewedUsers: 'Who will be reviewed',
        expire: 'Expiry',
      },
      review: { ...surveyResults.en },
    },
    PeopleActionPlansPage: {
      pageContainer: { ...peoplePageContainer.en },
      pageHeader: { ...pageHeader.en },
      pageDescription:
        'View all the Action plans created for this team member.',
      descriptionOwnProfile: 'View all the Action plans created for me.',
      add: commonLabels.add,
      edit: commonLabels.edit,
      delete: commonLabels.delete,
      discard: commonLabels.discard,
      save: commonLabels.save,
      confirm: 'Confirm',
      cancel: commonLabels.cancel,
      all: commonLabels.all,
      filters: { ...filters.en },
      addFilters: commonLabels.addFilters,
      addActionPlan: 'New action plan',
      description: commonLabels.description,
      noResultsTitle: 'No Action Plans yet!',
      noResultsMessage: "Once they're added, they will appear here.",
      noSearchResultsMessage: commonLabels.noSearchResultsMessage,
      loadMore: commonLabels.loadMore,
      statuses: { ...statuses.en },
      actionPlans: { ...actionPlan.en },
      manageActionPlan: { ...manageActionPlan.en },
      manageTasksDialog: { ...manageTasks.en },
      deleteActionPlanDialog: { ...alertDialogs.en.actionPlanDelete },
      dismissActionPlanProposalDialog: {
        ...alertDialogs.en.actionPlanProposalDismiss,
      },
      disabledAddActionTooltip: "Can't make Action plan for deactivated user.",
    },
    PeopleInsightsPage: {
      pageContainer: { ...peoplePageContainer.en },
      pageHeader: { ...pageHeader.en },
      subtitles: {
        peerAndTeamAndDirect: 'Peer, Team & Direct',
        self: 'Self',
      },
      reportingTypes: { ...groupedSurveyOptions.en },
      description:
        'Dive deep into the scores for each Attribute throughout the time, for selected period. ' +
        'Identify trends for specific Attribute. ' +
        'Below chart, there is a list of freeform feedbacks from people that were reviewing this employee. ' +
        'Timeline on the side shows details of sent surveys.',
      noResults: { ...noReports },
      attributes: 'Attributes',
      noAttributes: 'There are no measured attributes for this employee.',
      periodFilterText: 'Filter surveys by date range',
      tooltipLabels: {
        attribute: 'Attribute',
        questions: 'Questions',
        description: 'Description',
      },
      minAttribute: 'Minimum number of selected attributes is ',
      maxAttribute: 'Maximum number of selected attributes is ',
      noDataForChartMessage: {
        title: 'No measured attributes for selected survey type and period!',
        content: 'Change inputs to gain deeper insights into gathered data.',
      },
      noQuantitativeChartDataMessage: {
        content:
          'There is no quantitative data for the selected survey type and period. ',
      },
      surveyTypeDropdown: 'Survey type',
      groupByDropdown: 'Group by',
      periodLabel: 'Period',
      reminder: commonLabels.reminder,
      reminderSent: 'Reminder successfully sent.',
      anonymous: 'Anonymous',
      completed: 'Completed',
      inProgress: 'In progress',
      responded: 'Responded',
      and: 'and',
      more: 'more',
      none: commonLabels.none,
      timelineTooltip: {
        text: 'List of all surveys sent for this employee.',
      },
      statuses: { ...statuses.en },
      noData: ' No data for selected criteria.',
      personalAttributeTooltip: personalAttribute.en.tooltipText,
      timelineColumns: {
        sentAt: 'Sent at',
        survey: 'Survey',
        status: 'Status',
      },
      feedbackColumns: {
        sentAt: 'Sent at',
        comment: 'Comment',
        reviewer: 'Reviewer',
      },
      feedback: {
        title: 'Feedbacks',
        description: 'From all measured attributes in selected period',
        noData: ' No data for selected criteria.',
      },
      timeline: {
        title: 'Timeline',
        description:
          'List of all surveys sent for this employee in selected period.',
        noData: ' No data for selected criteria.',
      },
    },
    PeopleTeamPage: {
      pageContainer: { ...peoplePageContainer.en },
      pageHeader: { ...pageHeader.en },
      description:
        'Table of people for all direct reports and people who are shared with this employee.',
      descriptionOwnProfile:
        'Table of people for all direct reports and people who are shared with me.',
      directReports: 'direct reports',
      sharedWith: 'Shared with',
      myDirectList: {
        name: 'Name',
        noDataTitle: 'No Data',
        noDataDescription: 'There are no direct reports.',
        noJobTitle: commonLabels.noJobTitle,
        viewer: 'Viewer',
        collaborator: 'Collaborator',
      },
      mySharedList: {
        name: 'Name',
        noDataTitle: 'No Data',
        noDataDescription: 'There are no shared employees.',
        noJobTitle: commonLabels.noJobTitle,
        viewer: 'Viewer',
        collaborator: 'Collaborator',
      },
    },
    PageNotFound: {
      pageTitle: '404 error',
      description: 'Where Did That Page Go?',
      goHome: 'Go home',
      pageHeader: { ...pageHeader.en },
    },
    ResendSignUpPage: {
      title: 'Check your email',
      submit: 'Resend Email',
      emailTo:
        "We've sent an email to [EMAIL]. Click the link in the" +
        ' email to confirm your email address and continue with' +
        ' registration.',
      checkIfMissing:
        "If you don't see the email, check other places it \n" +
        'might be, like your junk, spam, or other folders.',
      backLink: 'Return To Login',
      fields: {
        firstName: 'First Name',
        lastName: 'Last Name',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        workEmail: 'Work Email',
        workPhone: 'Work Phone',
        jobTitle: 'Job Title',
        companyName: 'Company Name',
        desiredUrl: 'Desired Url',
      },
      personalInformation: 'Personal Information',
      companyInfo: 'Company Information',
      invalidName: 'Invalid name',
      requiredPassword: 'Password is required',
      repeatPassword: 'Repeat password',
      passwordMismatch: 'Your password and confirmation password do not match.',
      invalidPassword: 'Minimum 6 characters, at least 1 letter and 1 number',
      invalidPhoneNumber: 'Phone number is invalid',
      requiredJobTitle: 'Job title is required',
      invalidJobTitle: 'Job title is invalid',
      requiredCompanyName: 'Company name is required',
      invalidCompanyName: 'Invalid company name',
      requiredDesiredUrl: 'Url is required',
      invalidDesiredUrl: 'Url is invalid',
      agree: 'I agree to',
      and: 'and',
      check: iconAlt.checkIcon,
      checkingRegistrationLink: 'Checking registration link...',
      registrationLinkExpired: 'Registration link has expired!',
      termsOfService: 'Terms of Service',
      privacyPolicy: 'Privacy Policy',
      create: 'Create',
      tooltipText: 'Click check button to validate desired url.',
      acceptTerms: 'You need to accept terms-of-service.',
      isTaken: 'is taken.',
    },
    LoginPage: {
      title: 'Sign in to your organization',
      description: 'Enter your details below.',
      submit: 'Sign in',
      email: 'Email',
      password: 'Password',
      kadar: commonLabels.app,
      invalidEmailAddress: 'Invalid email address',
      invalidPassword: 'Invalid password',
      invalidEmailOrPassword: 'Invalid email address or password',
      backLink: 'Forgot Password',
      continue: 'Continue',
      enterWorkspaceTitle: 'Sign in to your workspace',
      enterWorkspaceSubTitle: "Enter your workspace's URL",
      enterWorkspaceSubdomain: 'Workspace name',
      somethingWentWrong: commonFieldValidationMessages.en.somethingWentWrong,
      authContainer: { ...authContainer.en },
      ...validationMessages.en.organization,
    },
    ForgottenPasswordPage: {
      email: 'Email',
      submit: 'Send email',
      backLink: 'Return to Login',
      title: 'Forgot your password?',
      authContainer: { ...authContainer.en },
      kadar: commonLabels.app,
      description:
        "Just enter your email address and we'll email" +
        ' you the instructions to reset your password.',
      ...validationMessages.en.email,
    },
    ResetLinkSentPage: {
      title: 'Check your Email',
      submit: 'Resend email',
      backLink: 'Return to Login',
      kadar: commonLabels.app,
      resetLink:
        "We've sent an email to [EMAIL]. Click the link in the" +
        ' email to reset your password.',
      checkIfMissing:
        "If you don't see the email, check other places it \n" +
        'might be, like your junk, spam, or other folders.',
      authContainer: { ...authContainer.en },
    },
    ResetPasswordPage: {
      repeatPassword: 'Repeat password',
      password: 'Password',
      kadar: commonLabels.app,
      title: 'Set new password',
      description: 'Please enter your new password.',
      submit: 'Set password',
      backLink: 'Return to Login',
      welcome: 'Welcome to the organization',
      descriptionSetPassword: 'Set your password to join the organization.',
      agree: 'I agree to',
      and: 'and',
      check: iconAlt.checkIcon,
      privacy_policy: { name: 'Privacy policy', url: '/privacy-policy' },
      terms_of_service: { name: 'Terms of service', url: '/terms-of-service' },
      passwordRequirements:
        'Password should contain minimum eight characters without spaces, one uppercase, one lowercase letter and one number or symbol',
      passwordMismatch: 'Your password and confirmation password do not match.',
      authContainer: { ...authContainer.en },
    },
    SurveysPage: {
      ...readLessMoreLabels,
      title: 'Surveys',
      pageHeader: { ...pageHeader.en },
      sendSurvey: 'New survey',
      descriptionPartOne:
        'Surveys are the ways to collect results for a certain Attribute(s) for team member(s).',
      descriptionPartTwo:
        'View and dive deep into each sent Survey, or create new ones.',
      anonymousSurvey: `${commonLabels.anonymous} survey`,
      oneTimeSurvey: 'Excluded from statistics',
      addGroupButton: 'New group',
      surveyTypes: { ...surveyTypes },
      surveyViewOptions: {
        list: 'List view',
        group: 'Group view',
      },
      loadMore: commonLabels.loadMore,
      noOptions: commonLabels.noOptions,
      all: commonLabels.all,
      columns: {
        actions: commonLabels.actions,
        title: 'Title',
        type: 'Type',
        createdBy: 'Creator',
        sentAt: 'Date sent',
        group: 'Group',
        status: 'Status',
        expiry: 'Expiry',
      },
      viewReport: commonLabels.viewReport,
      statuses: { ...statuses.en },
      filters: { ...filters.en },
      organization: commonLabels.organization,
      renameSurveyDialog: { ...customDialogs.en.surveyTitle },
      manageGroupDialog: { ...customDialogs.en.manageSurveyGroup },
      manageGroupItemsDialog: { ...customDialogs.en.manageGroupItems },
      surveyDuplicateDialog: { ...alertDialogs.en.surveyDuplicate },
      surveyGroupDuplicateDialog: {
        ...alertDialogs.en.surveyGroupDuplicate,
      },
      removeFromGroupDialog: { ...alertDialogs.en.removeSurveyFromGroup },
      menuItemLabels: {
        reminder: 'Send reminder',
        renameSurvey: 'Rename',
        delete: 'Delete',
        duplicate: commonLabels.duplicate,
        forceComplete: 'Close',
        fillSurvey: 'Fill survey',
        manageGroup: 'Manage group',
        removeGroup: 'Remove from group',
        reviewAndSend: 'Review and (Send or Schedule)',
      },
      reminderSent: 'Reminder successfully sent.',
      scheduledFor: 'Scheduled for: ',
      close: commonLabels.close,
      customFilterSelection: {
        creator: 'Creator',
        createdFor: 'Created for',
        reviewedBy: 'Reviewed by',
      },
      sectionTitleTooltipText: 'Surveys page',
      searchTooltipText: 'Use Search to find survey!',
      filtersTooltipText: 'Use Filters to narrow down your Search',
      tooltipIcon: 'Tooltip icon',
      searchPlaceholder: 'Search by title',
      defaultEmailMessage:
        'Help us in the review process by answering this survey.',
      createSurveyForm: {
        cancel: commonLabels.cancel,
        save: commonLabels.continue,
        add: 'Create survey',
        columns: {
          preview: commonLabels.preview,
        },
        formLabels: {
          surveyType: {
            label: 'Survey type',
            placeholder: 'Select survey type',
          },
          title: {
            label: 'Survey title',
            placeholder: 'Add survey title',
          },
          sharedTo: {
            label: 'Report access',
            placeholder: "Employee's name",
            noOptions: commonLabels.noResults,
            labelHelp: {
              tooltipText:
                'By default only creator of survey and administrators can see Survey report ' +
                'and answers. Utilize the member list to explicitly grant access to additional ' +
                'users, providing control and customization over survey visibility.',
            },
          },
          attributes: {
            label: 'Add attributes',
            placeholder: 'Add attributes',
            noOptions: commonLabels.noResults,
          },
          selfReview: {
            label: 'Self review for',
            placeholder: commonLabels.employeesName,
            ...peoplePicker.en,
          },
          reviewers: {
            label: 'Reviewers',
            placeholder: commonLabels.employeesName,
            ...peoplePicker.en,
          },
          reviewer: {
            label: 'Reviewer',
            placeholder: commonLabels.employeesName,
            noOptions: commonLabels.noResults,
          },
          whoWillBeReviewed: {
            label: 'Who will be reviewed',
            placeholder: commonLabels.employeesName,
            noOptions: commonLabels.noResults,
            ...peoplePicker.en,
          },
          isAnonymous: {
            labelText: 'Anonymous (min. 6 reviewers)',
            endLabelIconHelp:
              "If checked, you won't be able to see who answered the" +
              ' questionnaire, only the answers.',
          },
          oneTime: {
            labelText: 'Exclude this survey from statistics',
            endLabelIconHelp:
              "If checked, this survey's results won't be part of statistics on User profiles, Competence map and Skill comparisons.",
          },
          schedule: {
            label: 'Schedule',
            tooltipInfo:
              'Activate this option if you wish to schedule a survey',
          },
          email: {
            label: 'Customize email',
            tooltipInfo:
              'Activate this option if you wish to personalize email message or use the default one.',
          },
          emailContent: {
            placeholder: 'Add email message',
          },
          expiry: {
            label: 'Expiry',
            tooltipInfo:
              'Specify an expiration date for your survey. By default, it will remain open for 7 days before closing.',
          },
          people: {
            label: 'People',
          },
          surveyPreview: {
            fillSurvey: { ...fillSurveyFlow.en },
            user: 'Hello User',
            fillSurveyButton: 'Fill survey',
            emailFooter: "Don't let your team melt!",
            disabledButtonTooltip:
              'Once you add required data input survey preview will be available.',
            restart: 'Restart preview',
          },
        },
        validationMessages: {
          required: commonFieldValidationMessages.en.isEmpty,
          forbiddenCharacters:
            commonFieldValidationMessages.en.forbiddenCharacters,
          maxLength: 'Exceeded max length of 150 characters',
          descriptionMaxLength: 'Exceeded max length of 2500 characters',
          emailContentMaxLength: 'Exceeded max length of 500 characters',
          isPast: 'Date is in the past',
        },
      },
      sendSurveyNotificationDialog: {
        ...customDialogs.en.sendSurveyNotification,
      },
      forceCompleteDialog: { ...alertDialogs.en.surveyForceComplete },
      surveyDeleteDialog: { ...alertDialogs.en.surveyDelete },
      scheduledSurveyDeleteDialog: { ...alertDialogs.en.scheduledSurveyDelete },
      draftSurveyDeleteDialog: { ...alertDialogs.en.draftSurveyDelete },
      surveyTypeDialog: { ...customDialogs.en.surveyType },
      surveyGroupDeleteDialog: { ...alertDialogs.en.surveyGroupDelete },
      successMessages: {
        created: 'Survey is successfully created',
      },
      surveysActionBar,
      noResultsTitle: 'No Surveys yet!',
      noGroupResultsTitle: 'No Survey groups yet!',
      noGroupResultsMessage:
        'Once you create some Survey groups, they will appear on this page.',
      noResultsMessage:
        'Once you create some Surveys, they will appear on this page.',
      noSearchResultsMessage: commonLabels.noSearchResultsMessage,
      groupView: {
        loadMoreButton: commonLabels.loadMore,
        createSurveyButton: 'New survey',
        actionTooltips: {
          editTitle: 'Edit group title',
          deleteGroup: 'Delete group',
          duplicateGroup: 'Duplicate group',
        },
        dragTooltip: 'You can drag groups to rearrange them.',
        surveyDragTooltip: 'You can drag surveys to rearrange them.',
      },
    },
    AttributesPage: {
      ...commonLabels,
      ...readLessMoreLabels,
      viewReport: commonLabels.viewReport,
      pageHeader: { ...pageHeader.en },
      title: 'Attributes',
      addAttribute: 'New Attribute',
      description: commonLabels.description,
      descriptionPartOne:
        'Attributes represent a certain measurable trait or' +
        ' skill of an Employee or anything else you would like to evaluate.',
      descriptionPartTwo:
        'Each Attribute is made of a number of questions. Use' +
        ' existing Attributes or create new ones, to be used in Surveys later on.',
      descriptionPartThree:
        'Attributes listed below are suggested examples that you can either use for your organization, edit them or delete.',
      loadMore: 'Load more',
      openEndedAttribute: 'Open-ended attribute',
      personalAttributeTooltip: personalAttribute.en.tooltipText,
      columns: {
        color: 'Color',
        shortName: 'Short name',
        name: 'Name',
        attributeType: 'Purpose',
        isPrivate: 'Usage',
        modifiedOn: 'Modified on',
        timesUsed: 'Times used',
        questions: 'Questions',
        actions: commonLabels.actions,
      },
      attributeTypes: { ...attributeTypes.en },
      filters: { ...filters.en },
      menuItemLabels: {
        edit: 'Edit',
        delete: 'Delete',
        duplicate: commonLabels.duplicate,
        editQuestions: 'Edit questions',
        createQuestions: 'Create questions',
        previewQuestions: 'Preview questions',
      },
      deleteAttributeDialog: { ...alertDialogs.en.attributeDelete },
      deleteUsedAttributeDialog: { ...alertDialogs.en.attributeUsedDelete },
      confirmDeleteAttributeMessageWithSurveys:
        'This attribute is included in [NUMBER] survey(s) and by deleting it information in surveys will be lost.' +
        ' Are you sure that you want to delete attribute?',
      untitledTemplate: 'Untitled template',
      okActionButton: 'Yes',
      cancelActionButton: 'Cancel',
      closeButton: 'Close',
      preview: commonLabels.preview,
      disabledDeleteMessage:
        'You are not able to delete an Attribute which is already in use. You can duplicate it' +
        ' and make the changes you need.',
      chooseNameDialog: { ...customDialogs.en.duplicateAttribute },
      previewAttributeDialog: { ...customDialogs.en.attributePreview },
      statuses: {
        active: commonLabels.active,
        inactive: commonLabels.inactive,
      },
      sectionTitleTooltipText: 'Attributes page',
      searchTooltipText: 'Use Search to find attribute',
      filtersTooltipText: 'Use Filters to narrow down your Search',
      tooltipIcon: 'Tooltip icon',
      searchPlaceholder: 'Search by name',
      addAttributeDrawerForm: {
        ...addAttributeDrawerForm.en,
        questionsForm: {
          warningIcon: 'Warning!',
          questions: 'Questions',
          ...commonFieldValidationMessages.en,
          maxEntryLength: 'Exceeded max length of 500 characters',
          maxAnswerEntryLength: 'Exceeded max length of 1000 characters',
          ...neutralAnswer.en,
          active: commonLabels.active,
          inactive: commonLabels.inactive,
          private: commonLabels.private,
          public: commonLabels.public,
          save: commonLabels.save,
          discard: commonLabels.discard,
          weight: commonLabels.weight,
          newQuestion: 'Add question',
          maxNumberOfQuestions: 'Maximum number of questions is reached.',
          okActionButton: commonLabels.yes,
          placeholders: {
            questionTitle: 'Add question',
            questionPlaceholder: 'Write a question ...',
          },
          cancelActionButton: commonLabels.cancel,
          deleteQuestionDialog: { ...alertDialogs.en.attributeQuestionDelete },
          duplicateQuestionDialog: {
            ...alertDialogs.en.attributeQuestionDuplicate,
          },
          preview: commonLabels.preview,
          draggableTooltip: 'You can drag items to rearrange them.',
          draggableTooltipDisabled:
            'You can not drag items to rearrange them for Attribute which is' +
            ' already in use.',
          answer: 'Answer',
          questionStyleChangeDialog: {
            increaseAnswersCount: {
              ...alertDialogs.en.questionsIncreaseAnswerCount,
            },
            decreaseAnswersCount: {
              ...alertDialogs.en.questionsDecreaseAnswerCount,
            },
            toOpenEndedQuestions: {
              ...alertDialogs.en.questionsOpenEndedQuestions,
            },
            toMultipleChoiceQuestions: {
              ...alertDialogs.en.questionsMultipleChoice,
            },
            addNeutralAnswer: { ...alertDialogs.en.questionsNeutralAnswer },
            removeNeutralAnswer: {
              ...alertDialogs.en.questionsRemoveNeutralAnswer,
            },
          },
          disabledAddQuestion:
            'You are not able to Add Question to Attribute which is already in' +
            ' use.',
          editQuestionDisabled:
            'You are not able to Edit question for Attribute which is already in' +
            ' use.',
          duplicateQuestionDisabled: 'Maximum number of questions is reached.',
          deleteQuestionDisabled:
            'You are not able to Delete question for Attribute which is already in' +
            ' use.',
          presetAnswers: {
            yesNo: 'Yes/No question',
            scaleSmall: '1 to 5 scale',
            scaleBig: '1 to 10 scale',
            lowMid: 'Low, Medium, High',
            lowMidDesc: 'Low, Medium, High with description',
            answerItems: {
              yesNo: ['Yes', 'No'],
              lowMid: ['Low', 'Medium', 'High'],
              lowMidDesc: ['Low', 'Medium', 'High'],
            },
          },
        },
        horizontalMessageWarning:
          "Answer this long won't preview wholly in a survey. Consider changing Question Style to vertical!",
        finish: 'Finish',
      },
      noData: {
        titleActive: 'No active Attributes to show yet!',
        titleInactive: 'No inactive Attributes to show!',
        message:
          'Create your own Attributes now and make the first step towards gathering important data.',
        search: commonLabels.noSearchResultsMessage,
      },
      noResultsTitle: 'No Attributes to show yet!',
      noResultsMessage:
        'Create your own Attributes now and make the first step towards gathering important data.',
      noSearchResultsMessage: commonLabels.noSearchResultsMessage,
      attributeDeleteWarningDialog: { ...warningDialogs.en.attributeDelete },
      addRecommendedLabel: 'Add recommended attributes',
      loadingImportText:
        "Attribute import is in progress. Please don't reload the page until attributes are imported.",
      attributesViewOptions: {
        active: 'Active attributes',
        inactive: 'Inactive attributes',
      },
    },
    TagsPage: {
      title: 'Tags',
      pageHeader: { ...pageHeader.en },
      description:
        'Use them for free-form grouping, and later for filtering on various pages.',
      addCategory: 'Add category',
      search: 'Search by tag',
      usedTagDeleteDialog: { ...warningDialogs.en.tagDelete },
      usedTagsDeleteDialog: { ...warningDialogs.en.tagsDelete },
      addCategoryButton: 'Add category',
      categoryDeleteDialog: { ...alertDialogs.en.tagCategoryDelete },
      tagDeleteDialog: { ...alertDialogs.en.tagDelete },
      noData: {
        noSearch: 'No results match your search criteria.',
        noCategories: {
          title: 'No categories yet!',
          message: 'Once categories are added, they will appear on this page.',
        },
      },
      categories: {
        manageTagsButton: 'Manage tags',
        noTags: {
          title: 'No tags',
          content: 'Currently, there are no tags in this category.',
          message:
            'After adding tags, this category will appear in filters menu.',
        },
        draggableTooltip: 'You can drag categories to rearrange them.',
        previewPeople: 'Preview people associated with tags in this category.',
      },
      successMessages: {
        tagCategory: {
          created: 'Tag category successfully created',
          edited: 'Tag category successfully edited',
        },
        tag: {
          saved: 'Tags successfully saved',
          edited: 'Tag successfully edited',
        },
      },
      manageCategoryForm: {
        save: commonLabels.save,
        cancel: commonLabels.cancel,
        add: 'Add category',
        edit: 'Edit category',
        formLabels: {
          name: {
            label: 'Category',
            placeholder: 'Add category',
          },
          color: {
            label: 'Color',
            tooltip: 'Tags colors tooltip',
          },
          placements: {
            label: 'Filters setup',
            subtext: 'Add this category to filters menu in:',
            helpText:
              'Only category with tags will be visible in filters menu.',
          },
          organizationMenu: {
            label: 'Main menu setup',
            subtext: 'Add this category to',
          },
        },
        validationMessages: {
          required: commonFieldValidationMessages.en.isEmpty,
          availableName: 'Name is already taken',
          maxLength: 'Exceeded 100 characters',
          forbiddenCharacters:
            commonFieldValidationMessages.en.forbiddenCharacters,
        },
      },
      manageTagsForm: {
        save: commonLabels.save,
        cancel: commonLabels.cancel,
        manage: 'Manage tags',
        edit: 'Edit tag',
        formLabels: {
          category: {
            label: 'Category',
          },
          name: {
            label: 'Tag',
            placeholder: 'Add tag',
          },
        },
        validationMessages: {
          requiredTags: 'At least one tag is required',
          required: commonFieldValidationMessages.en.isEmpty,
          availableName: 'Name is already taken',
          availableNameInCategory: 'Name is already taken',
          maxLength: 'Exceeded 50 characters',
          forbiddenCharacters:
            commonFieldValidationMessages.en.forbiddenCharacters,
        },
      },
    },
    TagCategoryPage: {
      title: 'Tag category',
      pageHeader: { ...pageHeader.en },
      viewAll: commonLabels.viewAll,
      viewLess: commonLabels.viewLess,
      noData: "This category doesn't have tags yet.",
    },
    FillSurveyPage: {
      expiredSurveyMessage: 'The survey has ended or expired.',
      ...fillSurveyFlow.en,
    },
    ThankYouPage: {
      title: 'Thank you!',
      messagePart1: 'Thank you for submitting the survey.',
      messagePart2: 'Your response is very important to us.',
      returnToApp: 'Return to app',
      craftYourSurvey: 'Love this survey? Craft your own with',
      kadar: 'Kadar',
    },
    OrganizationPage: {
      title: 'Organizational chart',
      descriptionPartOne: 'Observe the structure of your organization.',
      descriptionPartTwo:
        'Find and take a closer look at a member by clicking on their avatar.',
      pageHeader: { ...pageHeader.en },
    },
    ActionPlansPage: {
      ...readLessMoreLabels,
      loadMore: commonLabels.loadMore,
      pageHeader: { ...pageHeader.en },
      me: commonLabels.me,
      pageTitle: 'Action plans',
      descriptionPartOne:
        'Unlock user potential and achieve goals with our comprehensive Action plans.',
      descriptionPartTwo:
        'Whether aiming for personal development, professional growth, or simply striving to be more organized, our tailored action plans provide a clear and actionable roadmap to success.',
      descriptionPartThree:
        "Customize Action plans to fit user's unique needs and objectives.",
      search: commonLabels.search,
      add: 'Add',
      all: commonLabels.all,
      delete: commonLabels.delete,
      discard: commonLabels.discard,
      save: commonLabels.save,
      confirm: 'Confirm',
      cancel: commonLabels.cancel,
      selectUdemyCourses: 'Please select one of the recommended courses:',
      selectCourse: 'Recommended courses',
      datePlaceholder: 'Set the date',
      columns: {
        actions: commonLabels.actions,
        name: commonLabels.for,
        title: commonLabels.title,
        dueDate: commonLabels.dueDate,
        modifiedOn: commonLabels.modifiedOn,
        status: commonLabels.status,
      },
      filters: { ...filters.en },
      statuses: { ...statuses.en },
      ...commonFieldValidationMessages.en,
      addFilters: commonLabels.addFilters,
      addActionPlan: 'New action plan',
      manageActionPlan: { ...manageActionPlan.en },
      deleteActionPlanDialog: { ...alertDialogs.en.actionPlanDelete },
      dismissActionPlanProposalDialog: {
        ...alertDialogs.en.actionPlanProposalDismiss,
      },
      noJobTitle: commonLabels.noJobTitle,
      noResultsTitle: 'No Action plans yet!',
      noResultsMessage:
        'Once Action plans are added, they will appear on this page.',
      noSearchResultsMessage: commonLabels.noSearchResultsMessage,
      exportTooltip: 'Export all Action plans',
      menuItemLabels: {
        edit: commonLabels.edit,
        delete: commonLabels.delete,
        manageTasks: commonLabels.manageTasks,
        dismiss: commonLabels.dismiss,
        accept: commonLabels.accept,
      },
      manageTasksDialog: { ...manageTasks.en },
      tasksIconTooltip: 'This Action plan has tasks',
      coursesIconTooltip: 'This Action plan has courses',
      progressTooltip: `${commonLabels.completeness} of Action plan`,
      proposedTooltip: { ...proposedPlanTooltip.en },
      proposedStatus: commonLabels.proposed,
      actionButtons: {
        accept: commonLabels.accept,
      },
    },
    ActionPlanDetailsPage: {
      me: commonLabels.me,
      pageHeader: { ...pageHeader.en },
      statuses: { ...statuses.en },
      viewMore: 'View previous activities',
      title: 'Action plan',
      noJobTitle: commonLabels.noJobTitle,
      activity: {
        title: 'Activity',
        at: 'at',
        to: 'to ',
        labels: {
          assigned: 'Assigned this action plan to ',
          comment: 'Wrote',
          description: 'Changed description for this action plan',
          dueDate: 'Changed due date for this action plan from',
          status: 'Changed status of this action plan from',
        },
        addComment: {
          comment: {
            placeholder: 'Write a comment',
          },
          errorMessages: {
            required: 'Comment is required',
            forbiddenCharacters:
              commonFieldValidationMessages.en.forbiddenCharacters,
            maxLength: 'Exceeded max length of 1000 characters',
          },
          save: commonLabels.save,
          success: 'Comment successfully sent.',
          notifyDialog: { ...customDialogs.en.actionPointCommentNotify },
        },
        deleteCommentDialog: { ...alertDialogs.en.actionPointCommentDelete },
      },
      details: {
        title: 'Details',
        actions: {
          discard: commonLabels.discard,
          save: commonLabels.save,
        },
        noValue: commonLabels.none,
        me: commonLabels.me,
        edit: commonLabels.edit,
        dismiss: commonLabels.dismiss,
        ...actionPlan.en,
        manageActionPlan: { ...manageActionPlan.en },
        details: 'Details',
        proposedTooltip: { ...proposedPlanTooltip.en },
        comment: { ...usersComments.en },
        tasksList: {
          taskStatusDialog: { ...alertDialogs.en.taskStatusChange },
        },
        usersListTooltip: 'Who can see this action plan',
        usersListTitle: 'Users who see this action plan',
        pageContainer: { ...peoplePageContainer.en },
        visibilityRelations: { ...visibilityRelations.en },
        notifications: { ...teamNotifications.en },
      },
      manageTasksDialog: { ...manageTasks.en },
      deleteActionPlanDialog: { ...alertDialogs.en.actionPlanDelete },
      dismissActionPlanProposalDialog: {
        ...alertDialogs.en.actionPlanProposalDismiss,
      },
      loadMore: commonLabels.loadMore,
    },
    OneOnOnePage: {
      title: '1 - 1',
      pageHeader: { ...pageHeader.en },
      description: 'Schedule, view, resolve and manage all 1-1 meetings.',
      addOneOnOne: 'New 1 - 1',
      search: commonLabels.search,
      period: {
        label: commonLabels.period,
        placeholder: 'Select period',
      },
      all: commonLabels.all,
      noResults: {
        title: 'There are no 1 - 1 records',
        message: "Once 1 - 1's are added, they will appear on this page.",
        noSearchResults: commonLabels.noSearchResultsMessage,
      },
      loadMore: commonLabels.loadMore,
      columns: {
        actions: commonLabels.actions,
        with: 'With',
        moderator: 'Organized by',
        date: 'Date',
        status: 'Status',
      },
      statuses: { ...statuses.en },
      filters: { ...filters.en },
      menuItemLabels: {
        edit: commonLabels.edit,
        delete: commonLabels.delete,
        dismiss: 'Dismiss',
        manageTasks: commonLabels.manageTasks,
      },
      addImpressionDialog: { ...customDialogs.en.addImpression },
      deleteOneOnOneRecordDialog: { ...alertDialogs.en.oneOnOneRecordDelete },
      dismissOneOnOneRequestDialog: {
        ...alertDialogs.en.oneOnOneRequestDismiss,
      },
      oneOnOneRecordDialog: { ...oneOnOneRecordDialog.en },
      successMessages: { ...manageOneOnOneMessages.en },
      actionButtons: { ...iconButtonsTooltip.en },
      recurring: commonLabels.recurring,
      tasksTooltip: 'This 1 - 1 has tasks',
      plansTooltip: 'This 1 - 1 has associated action plans',
      requested: { ...requestedOneOnOneTooltip.en },
      manageTasksDialog: { ...manageTasks.en },
    },
    OneOnOneDetailsPage: {
      title: '1 - 1',
      pageHeader: { ...pageHeader.en },
      details: 'Details',
      privateNote: 'Private note',
      privateNoteTooltip: 'This will be visible exclusively to you.',
      loadMore: commonLabels.loadMore,
      comment: { ...usersNotes.en },
      record: {
        edit: commonLabels.edit,
        delete: commonLabels.delete,
        manageTasks: commonLabels.manageTasks,
        manageEmployeeTopic: commonLabels.manageEmployeeTopic,
        with: 'With',
        managerAgenda: 'Manager meeting agenda',
        employeeAgenda: 'Employee meeting agenda',
        moderator: 'Organized by',
        description: 'Description',
        privateNote: 'Private note',
        impression: 'Impression',
        impressionTooltip: 'Not visible for [FULL NAME]',
        tags: commonLabels.tags,
        tasks: commonLabels.tasks,
        statuses: { ...statuses.en },
        requested: { ...requestedOneOnOneTooltip.en },
        actionButtons: { ...iconButtonsTooltip.en },
        calendar: { ...calendar.en },
        recurring: commonLabels.recurring,
        associatedPlans: 'Associated Action plans',
        tasksList: {
          taskStatusDialog: { ...alertDialogs.en.taskStatusChange },
        },
        usersListTooltip: 'Who can see this 1-1',
        usersListTitle: 'Users who see this 1-1',
        meetingNotesUsersListTooltip: 'Who can see meeting notes',
        meetingNotesUsersListTitle: 'Users who see meeting notes',
        pageContainer: { ...peoplePageContainer.en },
        visibilityRelations: { ...visibilityRelations.en },
      },
      noPrivateNote: {
        title: 'No private note yet!',
        message: "Once it's added, it will appear here.",
      },
      meetingNotes: 'Meeting notes',
      addImpressionDialog: { ...customDialogs.en.addImpression },
      deleteOneOnOneRecordDialog: { ...alertDialogs.en.oneOnOneRecordDelete },
      deleteOneOnOneTopicDialog: { ...alertDialogs.en.oneOnOneTopicDelete },
      oneOnOneRecordDialog: { ...oneOnOneRecordDialog.en },
      manageTasks: { ...manageTasks.en },
      manageTopicForm: { ...manageTopic.en },
      successMessages: { ...manageOneOnOneMessages.en },
      notifications: { ...teamNotifications.en },
    },
    OneOnOneReportPage: {
      title: 'General mood',
      pageHeader: { ...pageHeader.en },
      descriptionPartOne:
        'Take a look at all relevant 1-1 meetings in the last six months,' +
        ' trends of general mood, impressions, and dates.',
      descriptionPartTwo:
        'Each circle on the report, represent 1 calendar week.',
      loadMore: commonLabels.loadMore,
      peoplePicker: {
        ...peoplePicker.en,
        label: commonLabels.people,
        placeholder: commonLabels.employeesName,
      },
      report: {
        employee: 'Employee',
        today: commonLabels.today,
        meetings: '1 - 1 meetings',
        noMeeting: 'No 1-1 this week.',
      },
      noResults: 'Select people to see report.',
      presets: { ...presets.en },
    },
    CompetenceMapPage: {
      title: 'Competence map',
      description:
        'Analyze and compare the results for a single member or' +
        ' between multiple team members for a chosen Attribute(s).',
      attributes: commonLabels.attributes,
      minAttribute: 'Minimum number of selected attributes is',
      maxAttribute: 'Maximum number of selected attributes is',
      maxSelectedScores: 'Maximum number of selected scores is',
      selectedScore: 'Selected score',
      pageHeader: { ...pageHeader.en },
      range: commonLabels.period,
      personalAttributeTooltip: personalAttribute.en.tooltipText,
      noRecords: 'There are no measured Attributes in the chosen time frame.',
      noScores: 'There is no selected score to display.',
      yAxisLabel: 'Number of employees',
      presets: { ...presets.en },
    },
    UnauthorizedAccessPage: {
      pageTitle: "You don't have permission to access this page.",
      goHome: 'Go home',
      pageHeader: { ...pageHeader.en },
    },
    CourseDetailsPage: {
      title: 'Learning',
      courseDetails: 'Course details',
      enrolledEmployees: 'Enrolled employees',
      noEnrolledEmployees:
        'There are no employees currently enrolled in this course.',
      you: '(you)',
      levels: { ...coursesLevels.en },
      status: filters.en.labels.status,
      all: commonLabels.all,
      pageHeader: { ...pageHeader.en },
      enrollUser: 'Enroll to course',
      noJobTitle: commonLabels.noJobTitle,
      enrolledUsers: 'Enrolled employees',
      enrollmentStatuses: { ...statuses.en },
      disenrollCourseDialog: { ...alertDialogs.en.courseDisenroll },
      deleteCourseDialog: { ...alertDialogs.en.courseDelete },
      courseDetailsCard: { ...courseLabels.en },
      courseActions: { ...courseActions.en },
      editCourseForm: {
        save: commonLabels.save,
        cancel: commonLabels.cancel,
        edit: 'Edit course',
        formLabels: {
          title: {
            label: 'Title',
            placeholder: 'Add title',
          },
          level: {
            label: 'Course level',
          },
          tags: {
            label: 'Tags',
            placeholder: '#Tag',
            creatableOptionLabel: 'Add tag',
          },
          url: {
            label: 'Course URL',
            placeholder: 'Add URL',
          },
          description: {
            label: 'Description',
            placeholder: 'Add description',
          },
          attribute: {
            label: commonLabels.attribute,
            placeholder: 'Add attribute',
          },
          courseImage: {
            label: 'Thumbnail',
            placeholder: 'Add photo',
          },
        },
        validationMessages: {
          required: commonFieldValidationMessages.en.isEmpty,
          forbiddenCharacters:
            commonFieldValidationMessages.en.forbiddenCharacters,
          invalidUrl: 'Enter a valid URL',
          titleMaxLength: 'Exceeded max length of 500 characters',
          descriptionMaxLength: 'Exceeded max length of 2500 characters',
          fileType: 'File type should be one of: jpg, png, jpeg',
          fileSize: 'Exceeded max file size of 5mb',
        },
      },
      enrollToCourseDialog: { ...customDialogs.en.enrollToCourse },
      successMessages: {
        editCourse: 'Course successfully edited',
        enroll: 'user(s) has/have been enrolled to this course.',
        status: 'Status has been updated successfully.',
      },
      noSearchResultsMessage: commonLabels.noSearchResultsMessage,
    },
    CoursesPage: {
      title: 'Learning hub',
      pageDescriptionOne:
        'Library of all courses that are used in this organization.',
      pageDescriptionTwo:
        'Enroll employees to specific Courses and keep track of who is' +
        ' learning what.',
      pageDescriptionThree:
        'Start building your organization library by adding new courses from eLearning platforms.',
      readLessMore: { ...readLessMoreLabels },
      pageHeader: { ...pageHeader.en },
      noJobTitle: commonLabels.noJobTitle,
      addCourse: 'New course',
      search: commonLabels.search,
      usersEnrolledMessage: 'user(s) has been enrolled to this course.',
      levels: { ...coursesLevels.en },
      courseLabels: { ...courseLabels.en },
      sourceTypes: {
        udemy: 'Udemy',
        custom: 'Custom',
      },
      all: commonLabels.all,
      filters: { ...filters.en },
      addCourseForm: {
        save: commonLabels.save,
        cancel: commonLabels.cancel,
        add: 'Add course to library',
        edit: 'Edit course',
        formLabels: {
          source: {
            label: 'Source',
          },
          title: {
            label: 'Title',
            placeholder: 'Add title',
          },
          level: {
            label: 'Course level',
          },
          selectedCourse: {
            label: 'Available courses',
            noResults: {
              title: 'No results',
              message: 'The requested search will appear here',
            },
          },
          tags: {
            label: 'Tags',
            placeholder: '#Tag',
            creatableOptionLabel: 'Add tag',
          },
          search: {
            label: 'Search from Udemy',
            placeholder: 'Search course from Udemy',
          },
          url: {
            label: 'Course URL',
            placeholder: 'Add URL',
          },
          description: {
            label: 'Description',
            placeholder: 'Add description',
          },
          attribute: {
            label: commonLabels.attribute,
            placeholder: 'Add attribute',
          },
          courseImage: {
            label: 'Thumbnail',
            placeholder: 'Add photo',
          },
        },
        validationMessages: {
          required: commonFieldValidationMessages.en.isEmpty,
          forbiddenCharacters:
            commonFieldValidationMessages.en.forbiddenCharacters,
          invalidUrl: 'Enter a valid URL',
          titleMaxLength: 'Exceeded max length of 500 characters',
          descriptionMaxLength: 'Exceeded max length of 2500 characters',
          fileType: 'File type should be one of: jpg, png, jpeg',
          fileSize: 'Exceeded max file size of 5mb',
        },
      },
      courseActions: { ...courseActions.en },
      deleteCourseDialog: { ...alertDialogs.en.courseDelete },
      successMessages: {
        addUdemy: 'New course has been added from Udemy.',
        editCourse: 'Course successfully edited',
        completed: 'Course successfully completed',
      },
      noResultsTitle: 'No courses in the library yet!',
      noResultsMessage:
        'Add courses and enable users to jump into the learning journey.',
      noSearchResultsMessage: commonLabels.noSearchResultsMessage,
      addRecommendedLabel: 'Add recommended courses',
      loadingImportText:
        "Course import is in progress. Please don't reload the page until courses are imported.",
    },
    PeopleCoursesPage: {
      pageContainer: { ...peoplePageContainer.en },
      pageHeader: { ...pageHeader.en },
      pageDescription: 'View all courses enrolled for this team member.',
      descriptionOwnProfile: 'View all courses assigned to you.',
      enrollEmployeeToMoreCourses: 'Enroll to courses',
      noResultsTitle: "This user hasn't been enrolled in any course yet.",
      noResultsMessage:
        "As soon as enrolling occurs, you'll see the list of courses here.",
      noSearchResultsMessage: commonLabels.noSearchResultsMessage,
      search: commonLabels.search,
      levels: { ...coursesLevels.en },
      all: commonLabels.all,
      enrollmentStatuses: { ...statuses.en },
      courseLabels: { ...courseLabels.en },
      courseActions: { ...courseActions.en },
      ...commonFieldValidationMessages.en,
      enrollCourseDialog: { ...customDialogs.en.enrollCourse },
      disenrollDialog: { ...alertDialogs.en.courseDisenroll },
      filters: { ...filters.en },
      successMessages: {
        enrolled: 'User has been enrolled to new course.',
        status: 'Status has been updated successfully.',
      },
      disabledEnrollActionTooltip: "Can't enroll deactivated user.",
    },
    PrivateNotesPage: {
      ...readLessMoreLabels,
      noJobTitle: commonLabels.noJobTitle,
      loadMore: commonLabels.loadMore,
      pageHeader: { ...pageHeader.en },
      title: 'My private notes',
      subtitle: 'Only you can see your private notes',
      noResultsTitle: 'No Private notes yet!',
      noSearchResultsMessage: commonLabels.noSearchResultsMessage,
      noResultsMessage:
        'Once you add your Private notes, they will appear on this page.',
      descriptionSections: {
        first:
          'Private notes are visible only to person that created them - that is: currently logged in user.',
        second:
          'No other user of the application can view private notes that someone else has created.',
        third:
          'This page shows cumulative, all private notes you have written for various people in organization.',
        fourth: "Go to a person's specific Profile page to add/delete them.",
      },
      filters: {
        dateRangeLabel: 'Period',
      },
      item: {
        about: 'About',
      },
      noteDeleteDialog: { ...alertDialogs.en.noteDelete },
      manageNotesDialog: { ...noteDialog.en },
      note: { ...note.en },
    },
    SkillsMatrixPage: {
      title: 'Skills matrix',
      pageHeader: { ...pageHeader.en },
      noJobTitle: commonLabels.noJobTitle,
      imagePlaceholder: 'Select users to compare',
      filters: {
        surveyType: 'Survey type',
        range: 'Period',
        attributes: 'Attributes',
        measuredAttributes: 'Measured attributes',
        minAttribute: 'Minimum number of selected attributes is',
        maxAttribute: 'Maximum number of selected attributes is',
        attributeNoSurveyed:
          'There are no measured Attributes for selected people in the chosen time frame.',
        attributeNoQuantitativeData:
          'There are no quantitative Attributes data for selected people in' +
          ' the' +
          ' chosen time frame.',
        peoplePicker: {
          ...peoplePicker.en,
          label: commonLabels.people,
          placeholder: commonLabels.employeesName,
        },
        presets: { ...presets.en },
      },
      surveyOptions: { ...groupedSurveyOptions.en },
      compareWidget,
    },
    ReleaseNotesPage: {
      title: 'Release notes',
      pageHeader: { ...pageHeader.en },
      version: 'Version',
      releaseNote: 'Release note',
      latest: 'Latest',
      publishedDate: 'Published date',
    },
    OrganizationSettingsPage: {
      title: 'Organization settings',
      name: 'Organization name',
      pageHeader: { ...pageHeader.en },
      required: commonFieldValidationMessages.en.isEmpty,
      minQuestionsNumber: 'Value must be greater or equal to 1',
      questionLimit: 'Max number of question per attribute',
      globalTransparency: {
        label: 'Full Transparency (Users have access to their own profile)',
        description:
          'By allowing employees to see their profile, you are sharing employee gathered data. This applies to users and moderators.',
      },
      levelingTransparency: {
        label: 'Leveling visibility for users',
        description:
          'Allows users to see the complete leveling system set up for the organization.',
      },
      jobTitlesTransparency: {
        label: 'Job titles visibility for users',
        description:
          'Allows users to see the complete job titles system set up for the organization.',
      },
      competenceTransparency: {
        label: 'Competence map visibility for moderators',
        description:
          'Allows moderators to access Competence map and see distribution and average scores of all the users in the organization for all measured attributes',
      },
      submit: commonLabels.save,
      settingSavedMessage:
        'Organization settings has been updated successfully.',
    },
    UpgradeRequired: { ...customDialogs.en.upgradeRequired },
    LimitReached: { ...customDialogs.en.limitReached },
    SlackIntegrationPage: {
      title: 'Slack integration',
      description:
        'Slack makes it easy to quickly find, share and act on information across your tech stack by creating one central place to stay focused and get work done.',
      pageHeader: { ...pageHeader.en },
      connect: 'Connect Kadar with Slack',
      disconnect: 'Disconnect',
      notConnected: 'Not connected',
      connected: 'Connected',
      organization: commonLabels.organization,
      role: { ...accountTypes.en },
      app: commonLabels.app,
      import: {
        syncLabel: 'Slack users',
        descriptionPartOne: 'Users not found in Kadar.',
        descriptionPartTwo: 'Click the button to start importing.',
        buttonLabel: 'Import from Slack',
        noData: {
          title: 'No data',
          content: 'There are no users to import.',
        },
      },
      deactivate: {
        syncLabel: 'Deactivate Kadar users',
        descriptionPartOne: 'Users not active in Slack.',
        descriptionPartTwo: 'Click the button to deactivate them in Kadar.',
        buttonLabel: 'Deactivate',
        noData: {
          title: 'No data',
          content: 'There are no deactivated users.',
        },
      },
      deactivateUsersDialog: { ...customDialogs.en.deactivateUsers },
      retryButtonLabel: 'Retry',
      importSlackUsersTable: {
        add: 'Import from Slack',
        save: commonLabels.save,
        cancel: commonLabels.cancel,
        description:
          'Add users by entering required data. By default, new users are not invited to join. If you want to invite them right away, click on the checkbox.',
        formLabels: {
          firstName: {
            label: 'First name',
          },
          lastName: {
            label: 'Last name',
          },
          email: {
            label: 'Email',
          },
          reportingTo: {
            label: 'Reporting to',
            noOptions: 'No options',
          },
          role: {
            ...roleTooltipDescriptions.en,
          },
          invite: {
            label: 'Invite',
          },
        },
        validationMessages: {
          required: commonFieldValidationMessages.en.isEmpty,
          invalidEmail: 'Email is invalid',
          exceededLengthEmail: 'Maximum length is 100 characters',
          availableEmail: validationMessages.en.email.isAvailable,
          duplicatedEmail: validationMessages.en.email.isUnique,
          minLength: validationMessages.en.firstName.minLength,
          maxLength: validationMessages.en.firstName.maxLength,
          invalidName: validationMessages.en.firstName.invalid,
          freemiumLimit: commonFieldValidationMessages.en.freemium,
        },
      },
      success: {
        import: 'Slack users successfully added.',
        sync: 'Slack users successfully synced.',
      },
      timeLabels: {
        today: commonLabels.today,
        yesterday: commonLabels.yesterday,
        days: commonLabels.days,
        ago: commonLabels.ago,
      },
    },
    PricingPlansPage: {
      pageHeader: { ...pageHeader.en },
      title: 'Plans & Pricing',
      plans: {
        free: {
          name: 'Free',
          description:
            'Ideal for startups and small teams that are just starting out.',
          price: '$0',
          priceDescription: '/forever',
          subscribeButton: {
            default: 'Free plan',
            current: 'Current plan',
          },
          features: [
            {
              text: '1x Admin',
              tooltip:
                'Admins have full access to all features, including billing and user management.',
            },
            {
              text: '2x Managers',
              tooltip:
                'Managers have access to all team management features allowing them to lead their team. They don’t have access to billing and organizational features.',
            },
            {
              text: 'Unlimited Employees',
              tooltip:
                'User roles for your employees. They have access to their own profile, personal growth plans, they can collaborate with their manager, and they can see publicly shared organizational updates.',
            },
            {
              text: 'Unlimited External Associates',
              tooltip:
                'Send out surveys and gather feedback from your clients, associates, and partners. Gain a comprehensive understanding of your company’s performance and overall well-being.',
            },
            {
              text: '30x Action plans',
              tooltip:
                'Outline goals employees need to achieve for job promotions and higher salaries. Track their progress, measure both hard and soft skills.',
            },
            {
              text: '50x 1-1 meetings',
              tooltip:
                'Create a structured process for your 1:1s, ensuring focused, productive meetings that move things forward.',
            },
            {
              text: '1x Leveling frameworks',
              tooltip:
                'Create custom development frameworks for every team and help your employees grow with your company.',
            },
            {
              text: '30x Surveys',
              tooltip:
                'Collect real-time data for a deep understanding of employee engagement. Types of surveys: Self-assessments, Direct review, Team review, Peer review, and Company pulse',
            },
            {
              text: '50x Attributes',
              tooltip:
                'Set both hard and soft skills you want to benchmark your employees against.',
            },
            {
              text: '100x Courses',
              tooltip:
                'Customized learning hub and courses at both team and individual levels.',
            },
            {
              text: '30x Job descriptions',
              tooltip:
                'Your company’s directory with all the job titles and their descriptions.',
            },
          ],
        },
        pro: {
          name: 'Pro',
          description:
            'Perfect for growing businesses and larger companies that want the best.',
          price: '$4',
          priceDescription: '/user/month',
          subscribeButton: {
            default: 'Subscribe',
            current: 'Current plan',
          },
          features: [
            {
              text: 'Unlimited Admins',
              tooltip:
                'Admins have full access to all features, including billing and user management.',
            },
            {
              text: 'Unlimited Managers',
              tooltip:
                'Managers have access to all team management features allowing them to lead their team. They don’t have access to billing and organizational features.',
            },
            {
              text: 'Unlimited Employees',
              tooltip:
                'User roles for your employees. They have access to their own profile, personal growth plans, they can collaborate with their manager, and they can see publicly shared organizational updates.',
            },
            {
              text: 'Unlimited External Associates',
              tooltip:
                'Send out surveys and gather feedback from your clients, associates, and partners. Gain a comprehensive understanding of your company’s performance and overall well-being.',
            },
            {
              text: 'Unlimited Action plans',
              tooltip:
                'Outline goals employees need to achieve for job promotions and higher salaries. Track their progress, measure both hard and soft skills.',
            },
            {
              text: 'Unlimited 1-1 meetings',
              tooltip:
                'Create a structured process for your 1:1s, ensuring focused, productive meetings that move things forward.',
            },
            {
              text: 'Unlimited Leveling frameworks',
              tooltip:
                'Create custom development frameworks for every team and help your employees grow with your company.',
            },
            {
              text: 'Unlimited Surveys',
              tooltip:
                'Collect real-time data for a deep understanding of employee engagement. Types of surveys: Self-assessments, Direct review, Team review, Peer review, and Company pulse',
            },
            {
              text: 'Unlimited Attributes',
              tooltip:
                'Set both hard and soft skills you want to benchmark your employees against.',
            },
            {
              text: 'Unlimited Courses',
              tooltip:
                'Customized learning hub and courses at both team and individual levels.',
            },
            {
              text: 'Unlimited Job descriptions',
              tooltip:
                'Your company’s directory with all the job titles and their descriptions.',
            },
          ],
        },
        business: {
          name: 'Business',
          description:
            'Tailored for enterprises requiring a customized solution.',
          price: 'Custom',
          subscribeButton: {
            default: 'Book 1-1 call',
          },
          featureTitle: 'All in Pro, plus:',
          features: [
            {
              text: 'Custom integrations',
              tooltip:
                'Custom integrations with other tools in your HR stack that make your life simpler.',
            },
            {
              text: 'Dedicated Account Manager',
              tooltip:
                "You'll have a dedicated Kadar expert for your account to help you and hop on a call whenever you need.",
            },
            {
              text: 'Priority support',
              tooltip:
                "Whenever you run into trouble and need our help, we'll prioritize your inquiries.",
            },
          ],
        },
      },
    },
    LevelingPage: {
      title: 'Levels and tracks',
      description: {
        admin:
          'Here you can add, edit or delete tracks and levels. Customize them to represent your organization and teams.',
        read: 'Here you can view the leveling system in your organization.',
      },
      openEndedAttribute: 'Open-ended attribute',
      pageHeader: { ...pageHeader.en },
      noJobTitle: commonLabels.noJobTitle,
      addTemplateButton: 'Add template',
      tableLabels: {
        level: 'Level',
        addLevelButtonLabel: 'New level',
        addTrackButtonLabel: 'New track',
      },
      levelingTypeDialog: { ...customDialogs.en.levelingType },
      levelingTypes: { ...levelingTypes.en },
      addTemplate: {
        save: 'Use Template',
        cancel: commonLabels.cancel,
        add: 'Templates',
        formLabels: {
          preview: {
            noSelection: {
              title: 'Best Leveling Templates',
              content:
                "Creating leveling frameworks from scratch can be tough—we're here to help! On the left, you’ll find a curated collection of leveling templates from top companies. Whether you want to adapt them to align with your organization or use them as-is, these templates are designed to make the process smoother and more efficient.",
            },
            preview: {
              attributes: commonLabels.attributes,
              range: commonLabels.range,
              people: commonLabels.people,
            },
          },
        },
        validationMessages: {
          required: commonFieldValidationMessages.en.isEmpty,
          isAlreadyChosen: 'This template has already been chosen.',
        },
      },
      deleteTemplateDialog: { ...alertDialogs.en.deleteLevelingTemplate },
      deleteFrameworkDialog: { ...alertDialogs.en.levelingFrameworkDelete },
      deleteTrackDialog: { ...alertDialogs.en.levelingTrackDelete },
      deleteLevelDialog: { ...alertDialogs.en.levelingLevelDelete },
      editLevelingTemplate: { ...alertDialogs.en.editLevelingTemplate },
      successMessages: {
        addTemplate: 'Leveling system successfully added.',
        editTemplate: 'Leveling system successfully edited.',
        frameworkSaved: 'Framework name is successfully saved.',
        frameworkUpdated: 'Framework name is successfully updated.',
        trackSaved: 'Track name is successfully saved.',
        trackUpdated: 'Track name is successfully updated.',
        levelUpdated: 'Level data is successfully updated.',
        levelSaved: 'Level data is successfully saved.',
        trackDeleted: 'Track is successfully deleted.',
        frameworkDeleted: 'Framework is successfully deleted.',
        levelDeleted: 'Level is successfully deleted.',
        assignedUsersToLevel: 'Users are successfully assigned.',
        moveTrack: 'Track is successfully moved.',
        moveLevel: 'Level is successfully moved.',
        duplicateLevel: 'Level is successfully duplicated.',
        duplicateTrack: 'Track is successfully duplicated.',
        duplicateFramework: 'Framework is successfully duplicated.',
      },
      noResults: {
        title: 'No Leveling system yet!',
        content:
          'Start from templates, choose one and edit it to fit your needs, or just start from scratch!',
      },
      frameworkGroupView: {
        delete: commonLabels.delete,
        edit: commonLabels.edit,
        move: commonLabels.move,
        duplicate: commonLabels.duplicate,
        loadMoreButton: commonLabels.loadMore,
        attributes: 'Ranged attributes',
        range: commonLabels.range,
        people: commonLabels.people,
        templateSubtitle:
          'Templates based on good examples of the leveling frameworks.',
        track: 'Track',
        level: 'Level',
        createFrameworkButton: 'New Framework',
        createTrackButton: 'New Track',
        createLevelButton: 'New Level',
        frameworkDragTooltip: 'You can drag frameworks to rearrange them.',
        trackDragTooltip: 'You can drag tracks to rearrange them.',
        levelDragTooltip: 'You can drag levels to rearrange them.',
        duplicateFramework: {
          ...frameworkForm.en,
          edit: 'Duplicate Framework',
        },
        moveOrDuplicate: {
          ...frameworkForm.en,
          add: '[LABEL] [RECORD TYPE]',
        },
        addFramework: {
          ...frameworkForm.en,
          add: 'Add Framework',
          edit: 'Edit Framework',
        },
        addTrack: {
          ...frameworkForm.en,
          add: 'Add Track',
          edit: 'Edit Track',
        },
        addLevel: {
          ...frameworkForm.en,
          add: 'Add Level',
          edit: 'Edit Level',
        },
        assignUsersToLevel: {
          ...frameworkForm.en,
          add: 'Assign users to level',
          assignLabel: 'Assign users',
        },
      },
    },
    JobTitlesPage: {
      title: 'Job titles',
      search: commonLabels.search,
      filters: { ...filters.en },
      addRecommendedLabel: 'Add recommended job titles',
      loadingImportText:
        "Job title import is in progress. Please don't reload the page until job titles are imported.",
      description:
        'Customize the list of job titles entirely to represent your' +
        ' organization.',
      descriptionReadOnly:
        'Here you can view the list of all the job titles defined in your organization.',
      pageHeader: { ...pageHeader.en },
      addButtonLabel: 'New job title',
      successMessages: {
        add: 'Job title successfully added',
        edit: 'Job title successfully edited',
      },
      labels: {
        attributes: 'Attributes',
        noAttributes: 'No attributes',
        description: 'Description',
        noDescription: 'No description',
        assignTo: 'Assign to',
        tags: 'Tags',
        preview: commonLabels.preview,
      },
      jobTitleAssigning: { ...jobTitleAssigning.en },
      addJobTitleForm: {
        save: commonLabels.save,
        add: 'New job title',
        edit: 'Edit job title',
        formLabels: {
          name: {
            label: 'Job title',
            placeholder: 'Add job title',
          },
          description: {
            label: 'Description',
            placeholder: 'Add description',
          },
          attributes: {
            label: 'Add attributes',
            placeholder: 'Add attributes',
            noOptions: commonLabels.noOptions,
          },
          tags: {
            label: 'Tags',
            placeholder: '#Tag',
            creatableOptionLabel: 'Add tag',
          },
        },
        validationMessages: {
          availableName: commonFieldValidationMessages.en.availableName,
          required: commonFieldValidationMessages.en.isEmpty,
          forbiddenCharacters:
            commonFieldValidationMessages.en.forbiddenCharacters,
          nameMaxLength: 'Exceeded max length of 255 characters',
          descriptionMaxLength: 'Exceeded maximum allowed field length.',
        },
      },
      deleteJobTitleDialog: { ...alertDialogs.en.jobTitleDelete },
      noResultsTitle: 'No Job Titles yet!',
      noResultsMessage:
        'The first step in career development is defining who is who, so - get started.',
      noSearchResultsMessage: commonLabels.noSearchResultsMessage,
    },
    AttributeReportPage: {
      title: 'Attribute',
      pageHeader: { ...pageHeader.en },
      ...readLessMoreLabels,
      descriptionPartOne:
        'Analyse single attribute through time, and by any subset of reviewers or who was reviewed.',
      descriptionPartTwo:
        'Filter reviewers in order to view scores given only by certain group of people. (Ideal to view results through eyes of high-performers or managers or belonging to certain location).',
      descriptionPartThree:
        'Filter who was reviewed, in order to review scores only given to specific subset. Ideal to compare results given to teams in different locations or in different projects etc).',
      descriptionPartFour: 'Anonymous surveys are excluded from analytics.',
      selectFilters: {
        attribute: {
          label: commonLabels.attribute,
          placeholder: 'Add attribute',
        },
        period: commonLabels.period,
        surveyType: 'Survey type',
      },
      presets: { ...presets.en },
      role: { ...accountTypes.en },
      roleDescriptions: { ...roleDescriptions.en },
      userStatuses: { ...userStatuses.en },
      surveyStatuses: { ...statuses.en },
      reportToFilter: { ...statusesFilters.en.reportToFilter },
      filters: { ...filters.en },
      users: {
        objectsOfReview: {
          title: 'Survey about people',
          description: 'Show only surveys that are about these people.',
        },
        reviewers: {
          title: 'Results by following reviewers',
          description:
            'Show all surveys results and scores by following reviewers.',
        },
      },
      surveyOptions: { ...groupedSurveyOptions.en },
      objectsPicker: {
        addPeopleLabel: 'Add reviewed users',
        picker: { ...multipartPeoplePicker.en, title: 'Add reviewed users' },
      },
      reviewersPicker: {
        addPeopleLabel: 'Add reviewers',
        picker: { ...multipartPeoplePicker.en, title: 'Add reviewers' },
      },
      attributeInfoBar: {
        attributeScore: {
          anonymous: 'This is an average score.',
          default: 'Click to view individual user scores.',
        },
        textReplyNoAverageScore:
          "Open-ended attributes don't have an average score.",
      },
      review: {
        question: 'Questions',
        answers: 'Answers',
        answerShorthand: 'A',
        questionShorthand: 'Q',
        questionScore: 'Question score',
        anonymousUser: { ...anonymousUser.en },
        feedback: {
          title: 'Feedback',
          noResults: 'There is no feedback for this review.',
          anonymousUser: { ...anonymousUser.en },
        },
        ...neutralAnswer.en,
        people: commonLabels.people,
        percentage: commonLabels.percentage,
        details: {
          title: 'Survey reference',
          description:
            'List of all surveys with this attribute for selected period.',
          date: commonLabels.date,
          commentText: commonLabels.description,
          status: commonLabels.status,
        },
      },
      noResults: 'There are no report results to display.',
    },
    SurveySummaryPage: {
      title: 'Survey summary',
      pageHeader: { ...pageHeader.en },
      ...readLessMoreLabels,
      description:
        'Provides comprehensive overview of survey results, designed to give quick insights and detailed analysis at a glance.',
      filters: { ...filters.en },
      surveyTypes: { ...surveyTypes },
      search: 'Search by title',
      sharedWith: 'Shared with',
      noResults: {
        title: 'No Survey summary reports yet!',
        message: 'There are no survey summary reports to display.',
        search: commonLabels.noSearchResultsMessage,
      },
      all: commonLabels.all,
      loadMore: commonLabels.loadMore,
      menuItemLabels: {
        share: commonLabels.share,
        delete: commonLabels.delete,
      },
      deleteReportDialog: { ...alertDialogs.en.surveySummaryReportDelete },
      reportShareDialog: { ...surveySummaryShare.en },
      columns: {
        actions: commonLabels.actions,
        title: commonLabels.title,
        type: commonLabels.type,
        createdBy: commonLabels.createdBy,
        createdAt: commonLabels.createdAt,
        sharedWith: 'Shared with',
      },
    },
    SurveySummaryDetailsPage: {
      pageHeader: { ...pageHeader.en },
      surveyTypes: { ...surveyTypes },
      info: {
        anonymized: 'Anonymized',
        anonymizedInfoTooltip: {
          default:
            'If summary report is anonymized all the reviewers will be shown with their identities hidden.',
          managable: 'Toogle between anonymized and original reviewers.',
        },
        createdBy: commonLabels.createdBy,
        createdAt: commonLabels.createdAt,
        type: commonLabels.type,
        reviewers: 'Reviewers',
        reviewer: 'Reviewer',
        reviewedUsers: 'Who were reviewed',
        reviewedUser: 'Who was reviewed',
        yes: commonLabels.yes,
        no: commonLabels.no,
        toggleViewDataTooltip: 'Toggle between anonymized and original data.',
      },
      description: { ...surveyinfoDescription.en },
      reviews: {
        title: 'Measured attributes',
      },
      review: { ...surveyResults.en },
      summaryTitle: 'Summary',
    },
    attributeChip: { ...attributeChip.en },
    selectField: { ...attributeChip.en },
    InstructionGuide: {
      instructionGuideTitle: 'Instruction guide',
      descriptionInstructionGuideOne: `We’re thrilled to have you here! Kadar is designed to empower you with the tools and insights you need to streamline your tasks and boost your productivity. Whether you’re managing projects, tracking progress, or collaborating with your team, we’ve got you covered.`,
      descriptionInstructionGuideTwo:
        'Explore our features, connect with like-minded users, and make the most of your experience. If you have any questions or need assistance, our support team is just a click away.',
      descriptionInstructionGuideThree: `Let’s get started on your journey to greater efficiency!`,
      descriptionInstructionGuideFour: 'Happy exploring!',
      guideTooltipText:
        'This video is a recomendation based on the selected incentives.',
      guideButtons: {
        onboardingYourTeam: 'Onboarding your team',
        welcomeToKadar: 'Welcome to Kadar',
        jobRoles: 'Creating Career paths',
        attributes: 'Defining Competencies',
        reviews: 'Conducting 360',
        skillMetrics: 'Interpreting 360',
        oneOnOne: 'Scheduling and tracking 1:1s',
        actionPlans: 'Setting targets and Action Plans',
        managerInsights: 'Company and employee overview',
        kadarOverview: 'Kadar overview',
        yourProfile: 'Your profile',
      },
    },
  },
};
