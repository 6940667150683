import { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Paper, MenuList, Grow, makeStyles, alpha } from '@material-ui/core';
import Search from '../../../search';
import CustomScrollBar from '../../../customScrollBar';
import CustomButton from '../../../customButton';
import SubMenuItem from './subMenuItem';
import Category from './category';
import { customSearch } from '../../../../../utility/uiUtils';
import { useCustomEffect } from '../../../../../utility/hooks';
import { isArray, isArrayEmpty } from '../../../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  paper: {
    border: `1px solid ${primary.bluish6}`,
    borderRadius: 4,
    boxSizing: 'border-box',
    boxShadow: 'none',
    position: 'absolute',
    left: 261,
    top: 0,
    filter: `drop-shadow(0px 4px 10px ${alpha(primary.black, 0.2)})`,
    width: 245,
  },
  menu: {
    padding: 0,
    paddingRight: spacing(2),
  },
  menuNotSearchable: {
    paddingRight: 0,
    paddingTop: spacing(4),
  },
  scrollY: {
    top: 11,
    height: 'calc(100% - 22px)',
    width: 4,
  },
  searchWrapper: {
    padding: spacing(4, 4, 4, 2),
  },
  applyButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: spacing(2, 4),
    marginTop: 'auto',
  },
  applyButton: {
    color: primary.bluish1,
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish1}`,
    borderRadius: 44,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: spacing(2, 4),
    maxHeight: 40,
  },
}));

const DEFAULT_SCROLLBAR_HEIGHT = 200;
const FILTER_HEIGHT = 40;
const MAX_VISIBLE_FILTERS = 6;

const SubMenu = ({
  translations,
  items,
  selectedFilters,
  shouldRender,
  currentSubMenu,
  searchTerm,
  onSearch,
  onSelect,
  onApply,
}) => {
  const classes = useStyles();
  const scrollBarRef = useRef(null);

  const {
    isSingleSelect,
    isCategorized,
    hasSubmenuHash,
    hasSubmenuColorBox,
    hasCategoryColorBox,
    categoryItemsKey,
    categorySearch,
  } = currentSubMenu;
  const isSearchable = isCategorized || items.length > MAX_VISIBLE_FILTERS;
  const filteredItems = categorySearch
    ? categorySearch(items, searchTerm)
    : customSearch(items, searchTerm);
  const scrollBarHeight = !isSearchable
    ? items.length * FILTER_HEIGHT
    : DEFAULT_SCROLLBAR_HEIGHT;

  useCustomEffect(
    () => {
      scrollBarRef?.current?.scrollToTop();
    },
    [searchTerm, items, currentSubMenu?.id],
    false
  );

  return (
    <Grow in={shouldRender}>
      <Paper
        classes={{
          root: classes.paper,
        }}
      >
        {isSearchable && (
          <div className={classes.searchWrapper}>
            <Search
              placeholder={translations.search}
              onChange={onSearch}
              value={searchTerm}
              isFormField
            />
          </div>
        )}
        <MenuList
          classes={{
            padding: classNames(classes.menu, {
              [classes.menuNotSearchable]: shouldRender && !isSearchable,
            }),
          }}
        >
          <CustomScrollBar
            ref={scrollBarRef}
            customScrollBarYClass={classes.scrollY}
            verticalScroll
            removeScrollX
            scrollBarHeight={scrollBarHeight}
          >
            <>
              {filteredItems.map(item => {
                const { id } = item;
                const isSelected = selectedFilters?.includes(id);

                if (isCategorized) {
                  const shouldRenderCategory =
                    isArray(item[categoryItemsKey]) &&
                    !isArrayEmpty(item[categoryItemsKey]);

                  return (
                    shouldRenderCategory && (
                      <Category
                        key={`category_${id}`}
                        category={item}
                        selectedFilters={selectedFilters}
                        isSingleSelect={isSingleSelect}
                        categoryKey={categoryItemsKey}
                        hasCategoryColorBox={hasCategoryColorBox}
                        hasHash={hasSubmenuHash}
                        hasColorBox={hasSubmenuColorBox}
                        onSelect={onSelect}
                      />
                    )
                  );
                }

                return (
                  <SubMenuItem
                    key={id}
                    id={`sub-menu-item-${id}`}
                    item={item}
                    selectedFilters={selectedFilters}
                    isSelected={isSelected}
                    hasHash={hasSubmenuHash}
                    hasColorBox={hasSubmenuColorBox}
                    isSingleSelect={isSingleSelect}
                    onSelect={onSelect}
                  />
                );
              })}
            </>
          </CustomScrollBar>
        </MenuList>
        {shouldRender && (
          <div className={classes.applyButtonWrapper}>
            <CustomButton
              className={classes.applyButton}
              type="addWithTextRounded"
              onClick={onApply}
            >
              {translations.apply}
            </CustomButton>
          </div>
        )}
      </Paper>
    </Grow>
  );
};

SubMenu.defaultProps = {
  items: [],
  selectedFilters: [],
  searchTerm: '',
};

SubMenu.propTypes = {
  translations: PropTypes.object.isRequired,
  shouldRender: PropTypes.bool.isRequired,
  selectedFilters: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ),
  currentSubMenu: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  searchTerm: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
};

export default SubMenu;
