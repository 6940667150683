import { cloneElement, Children } from 'react';
import PropTypes from 'prop-types';

const VisibleForPermission = ({
  children,
  permission,
  permissions,
  disableOnly,
  additionalCondition,
}) => {
  const renderDisabledChildren = () =>
    Children.toArray(children).map(child => {
      return cloneElement(child, {
        disabled: disableOnly && additionalCondition,
      });
    });

  if (permissions.includes(permission)) {
    if (disableOnly) {
      return renderDisabledChildren();
    }
    return additionalCondition && children;
  } else if (disableOnly && !permissions.includes(permission)) {
    return renderDisabledChildren();
  }

  return null;
};

VisibleForPermission.defaultProps = {
  permission: '',
  permissions: [],
  disableOnly: false,
  additionalCondition: true,
};

VisibleForPermission.propTypes = {
  permission: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string),
  disableOnly: PropTypes.bool,
  additionalCondition: PropTypes.bool,
};

export default VisibleForPermission;
