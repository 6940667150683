import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as AddIcon } from '../../../assets/icons/plus-icon.svg';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    boxSizing: 'border-box',
    border: `1px dashed ${primary.bluish6}`,
    borderRadius: 4,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'border .2s ease',
    width: '100%',
    minWidth: 280,
    height: 221,
    '&:hover': {
      border: `1px dashed ${primary.blue1}`,
    },
  },
  label: {
    marginLeft: spacing(3),
  },
}));

const PlaceholderButton = ({ className, label, onAdd }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.main, className)} onClick={onAdd}>
      <AddIcon />
      <Typography variant="subtitle1" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
};

PlaceholderButton.defaultProps = {
  className: null,
};

PlaceholderButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default PlaceholderButton;
