import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomModal from '../customModal';
import CustomScrollBar from '../customScrollBar';
import CourseCard from '../courseCard';
import Search from '../search';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  coursesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 196,
    maxHeight: 450,
    width: '100%',
  },
  course: {
    width: '100%',
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  enrolledCourse: {
    cursor: 'default',
    '&:hover': {
      border: `1px solid ${primary.bluish6}`,
    },
  },
  selectedCourse: {
    border: `1px solid ${primary.blue1}`,
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: -20,
    height: '100%',
    width: 8,
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
  filteringAndSearch: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(4),
    width: 469,
  },
  search: {
    marginLeft: 'auto',
    width: 180,
  },
}));

const EnrollCourseDialog = ({
  translations,
  isOpened,
  courses,
  levels,
  onCancel,
  onEnroll,
  onSearch,
  getCourseEnrolledUsers,
}) => {
  const classes = useStyles();

  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleSetSelectCourse = course => setSelectedCourse(course || null);

  const onSelectCourse = course => () => {
    if (course.enrollmentStatus) return;

    return handleSetSelectCourse(course);
  };

  const handleClose = () => {
    handleSetSelectCourse();
    onCancel();
  };

  const handleConfirm = () => {
    onEnroll(selectedCourse).then(() => {
      handleSetSelectCourse();
    });
  };

  const handleGetEnrolledUsers = courseId => () =>
    getCourseEnrolledUsers(courseId);

  return (
    <CustomModal
      title={translations.title}
      isOpened={isOpened}
      closeButtonLabel={translations.cancel}
      confirmButtonLabel={translations.create}
      isConfirmDisabled={selectedCourse === null}
      onClose={handleClose}
      onConfirm={handleConfirm}
      isMedium
    >
      <div>
        <div>
          <div className={classes.filteringAndSearch}>
            <Search
              className={classes.search}
              placeholder={translations.search}
              onChange={onSearch}
            />
          </div>
        </div>
        <div className={classes.coursesWrapper}>
          <CustomScrollBar
            customScrollBarYClass={classes.scrollY}
            customScrollClass={classes.scroll}
            passContentHeight
            verticalScroll
            removeScrollX
          >
            <>
              {courses?.map(course => {
                return (
                  <CourseCard
                    key={`course_item_${course.id}`}
                    className={classNames(classes.course, {
                      [classes.selectedCourse]:
                        selectedCourse?.id === course.id,
                      [classes.enrolledCourse]: course.enrollmentStatus,
                    })}
                    translations={translations.courseLabels}
                    course={course}
                    levels={levels}
                    shouldDisablePortal={false}
                    getCourseEnrolledUsers={handleGetEnrolledUsers(course.id)}
                    onCardClick={onSelectCourse(course)}
                    hasEnrolledUsersCount
                    hasCourseUrl
                  />
                );
              })}
            </>
          </CustomScrollBar>
        </div>
      </div>
    </CustomModal>
  );
};

EnrollCourseDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  courses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getCourseEnrolledUsers: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEnroll: PropTypes.func.isRequired,
};

export default EnrollCourseDialog;
