import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    backgroundColor: primary.white,
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '100%',
    paddingBottom: spacing(6),
  },
  nav: {
    borderBottom: `1px solid ${primary.bluish7}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 41,
    width: '100%',
    maxWidth: '100%',
  },
  links: {
    boxSizing: 'border-box',
    display: 'flex',
    height: 40,
  },
  navItem: {
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    borderBottom: '4px solid transparent',
    textDecoration: 'none',
    lineHeight: '20px',
    fontSize: '16px',
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
    textTransform: 'uppercase',
    letterSpacing: 1,
    paddingBottom: spacing(2),
    paddingTop: spacing(2),
    marginRight: spacing(6),
    '&.active': {
      borderBottom: `4px solid ${primary.blue1}`,
    },
  },
}));

const HorizontalNavigation = ({ navItems }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.nav}>
        <div className={classes.links}>
          {navItems.map(navItem => {
            return (
              <NavLink
                className={classes.navItem}
                to={navItem.url}
                key={navItem.name}
                end={navItem.end}
              >
                {navItem.name}
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

HorizontalNavigation.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default HorizontalNavigation;
