import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }) => ({
  main: {
    display: 'flex',
  },
  label: {
    fontFamily: 'ProximaNova-Bold',
  },
  buttons: {
    flexDirection: 'row',
  },
  optionLabelWrapper: {
    fontSize: 14,
    marginRight: spacing(5),
    '& svg:not($optionIcon)': {
      width: 16,
      height: 16,
    },
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  optionLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  optionIcon: {
    marginLeft: spacing(1),
  },
  optionLabelText: {
    marginLeft: spacing(1),
  },
}));

const CustomRadioGroup = ({ className, label, options, value, onChange }) => {
  const classes = useStyles();

  const handleChange = e => {
    const newValue = e.target.value;

    if (newValue !== value) {
      onChange(newValue);
    }
  };

  const renderOptionLabel = option => {
    const { label: optionLabel, icon: Icon } = option;

    return (
      <span className={classes.optionLabel}>
        {Icon && <Icon className={classes.optionIcon} />}
        <Typography
          className={classes.optionLabelText}
          variant="body2"
          component="span"
        >
          {optionLabel}
        </Typography>
      </span>
    );
  };

  return (
    <FormControl
      className={classNames(classes.main, className)}
      component="fieldset"
    >
      {label && (
        <FormLabel classes={{ root: classes.label }} component="legend">
          {label}
        </FormLabel>
      )}
      <RadioGroup
        classes={{ root: classes.buttons }}
        value={value}
        onChange={handleChange}
      >
        {options.map(option => (
          <FormControlLabel
            key={`option_item_${option.value}`}
            classes={{ root: classes.optionLabelWrapper }}
            label={renderOptionLabel(option)}
            value={option.value}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

CustomRadioGroup.defaultProps = {
  className: null,
  label: '',
};

CustomRadioGroup.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomRadioGroup;
