import { isArrayEmpty } from '../../../../utility/helpers';
import { PLACEHOLDER_ATTRIBUTE } from '../../../../constants/attributes';

const hasAttributes = attributes =>
  Object.keys(attributes).some(attKey => !isArrayEmpty(attributes[attKey]));

const ATTRIBUTE_PLACEHOLDER_NUMBER = 3;

export const getAttributeGroups = (
  hasUserLevel,
  coreAttributes,
  nicheAttributes
) => {
  const placeholderAttributes = new Array(ATTRIBUTE_PLACEHOLDER_NUMBER).fill(
    PLACEHOLDER_ATTRIBUTE
  );
  const coreGroup = {};
  const nicheGroup = {};
  const hasCoreAttr = hasAttributes(coreAttributes);
  const hasNicheAttr = hasAttributes(nicheAttributes);

  if (hasUserLevel) {
    if (!hasCoreAttr && !hasNicheAttr) {
      coreGroup.attributes = placeholderAttributes;
    } else {
      coreGroup.attributes = hasCoreAttr ? coreAttributes : [];
      if (hasNicheAttr) {
        nicheGroup.attributes = nicheAttributes;
      }
    }
  } else {
    nicheGroup.attributes = hasNicheAttr
      ? nicheAttributes
      : placeholderAttributes;
  }

  return [coreGroup, nicheGroup];
};
