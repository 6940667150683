import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import ConditionalTooltip from '../conditionalTooltip';
import { ellipsis } from '../../../constants/helperCssRules';
import { getColumnWidth, getAlignItem } from '../../../constants/gridTable';
import { isEllipsisActive } from '../../../utility/helpers';

const COLOR = 'color';
const QUESTIONS = 'questions';
const DESCRIPTION = 'description';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    main: {
      display: 'grid',
    },
    row: {
      borderBottom: `1px solid ${primary.bluish7}`,
      display: 'grid',
      alignContent: 'center',
      transition: 'background-color .3s ease',

      '&:hover': {
        backgroundColor: primary.bluish9,
      },
    },
    rowCell: {
      alignSelf: 'center',
      boxSizing: 'border-box',
      padding: spacing(4, 2),
      color: primary.bluish1,
      position: 'relative',
      ...ellipsis(),

      [breakpoints.up('xLg')]: {
        padding: spacing(4),
      },
    },
    cellValue: {
      ...ellipsis(),
    },
    actionCell: {
      cursor: 'default',
    },
    richTextTooltip: {
      minWidth: 330,
      maxWidth: 660,
    },
    descriptionCell: {
      whiteSpace: 'initial',
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      '& > *': {
        margin: 0,
      },
    },
    cellLink: {
      color: primary.blue1,
    },
    highlightedText: {
      fontFamily: 'ProximaNova-Bold',
    },
    link: {
      textDecoration: 'none',
    },
    isClickable: {
      cursor: 'pointer',
    },
    colorBox: {
      width: 24,
      height: 24,
      borderRadius: 8,
    },
    isAttributeEditable: {
      color: primary.blue1,
    },
  })
);

const GridTableBody = ({ headers, rows, onRowClick }) => {
  const [cellsWithTooltip, setCellsWithTooltip] = useState([]);
  const cellsTooltipRef = useRef([]);
  const classes = useStyles();

  useEffect(() => {
    setCellsWithTooltip(
      cellsTooltipRef.current.map(cell => {
        const cellWithTooltip = {};

        Object.keys(cell).forEach(cellKey => {
          cellWithTooltip[cellKey] = isEllipsisActive(
            cell[cellKey],
            false,
            true
          );
        });

        return cellWithTooltip;
      })
    );
  }, [rows]);

  const handleRowClick = row => event => {
    if (onRowClick) {
      event.preventDefault();
      return onRowClick(row);
    }
  };

  const handleActionCellClick = isActionCell => e => {
    if (isActionCell) {
      e.stopPropagation();
    }
  };

  const getCellRef = (element, rowIndex, rowKey, hasTooltip) => {
    if (hasTooltip) {
      cellsTooltipRef.current[rowIndex] = {
        ...(cellsTooltipRef.current[rowIndex]
          ? {
              ...cellsTooltipRef.current[rowIndex],
              [rowKey]: element,
            }
          : { [rowKey]: element }),
      };
    }
  };

  const renderCellValue = (
    cellValue,
    header,
    rowIndex,
    isHighlighted,
    isLink = false,
    isEditable = false
  ) => {
    const isDescription = DESCRIPTION === header.rowKey;
    
    if (header.rowKey === COLOR) {
      return (
        <div className={classes.colorBox} style={{ background: cellValue }} />
      );
    }

    return (
      <ConditionalTooltip
        addTooltip={
          header.hasConditionalTooltip &&
          cellsWithTooltip?.[rowIndex]?.[header.rowKey]
        }
        tooltipClassName={classNames({
          [classes.richTextTooltip]: isDescription,
        })}
        message={cellValue || ''}
      >
        <Typography
          component="div"
          ref={ref =>
            getCellRef(
              ref,
              rowIndex,
              header.rowKey,
              header.hasConditionalTooltip
            )
          }
          className={classNames(
            'row-cell-value',
            classes.cellValue,
            { [classes.descriptionCell]: isDescription },
            { [classes.cellLink]: isLink },
            {
              [classes.highlightedText]: isHighlighted,
            },
            {
              [classes.isAttributeEditable]: isEditable,
            }
          )}
        >
          {cellValue}
        </Typography>
      </ConditionalTooltip>
    );
  };

  const renderRow = (rowData, header, rowIndex) => {
    const isAttributeQuestions = header.rowKey === QUESTIONS;
    return rowData.cellLinks?.[header.rowKey] ? (
      <Link
        id={header.rowKey}
        key={`row_cell_${rowData.id}_column_${header.id}`}
        className={classNames(
          classes.rowCell,
          classes.link,
          'row-cell',
          header.rowCellClass
        )}
        style={getAlignItem(header)}
        to={rowData.cellLinks?.[header.rowKey]?.to}
        state={rowData.cellLinks?.[header.rowKey]?.state}
      >
        {renderCellValue(
          rowData[header.rowKey],
          header,
          rowIndex,
          header.isHighlighted || rowData.isHighlighted,
          true
        )}
      </Link>
    ) : (
      <div
        id={header.rowKey}
        key={`row_cell_${rowData.id}_column_${header.id}`}
        className={classNames(
          classes.rowCell,
          'row-cell',
          { [classes.actionCell]: header.isActionCell },
          header.rowCellClass
        )}
        style={getAlignItem(header)}
        onClick={handleActionCellClick(header.isActionCell)}
      >
        {renderCellValue(
          rowData[header.rowKey],
          header,
          rowIndex,
          header.isHighlighted || rowData.isHighlighted,
          false,
          isAttributeQuestions && rowData.isEditable
        )}
      </div>
    );
  };

  return (
    <div className={classes.main}>
      {rows.map((row, index) =>
        row.rowLink ? (
          <Link
            id={row.id}
            key={`row_${row.id}_${index}`}
            className={classNames(classes.row, classes.link)}
            style={getColumnWidth(headers)}
            to={row.rowLink}
            onClick={handleRowClick(row)}
          >
            {headers.map(h => renderRow(row, h, index))}
          </Link>
        ) : (
          <div
            id={row.id}
            key={`row_${row.id}_${index}`}
            className={classNames(classes.row, {
              [classes.isClickable]: onRowClick,
            })}
            style={getColumnWidth(headers)}
            onClick={handleRowClick(row)}
          >
            {headers.map(h =>
              renderRow(row, h, index, cellsTooltipRef.current)
            )}
          </div>
        )
      )}
    </div>
  );
};

GridTableBody.defaultProps = {
  onRowClick: null,
};

GridTableBody.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      rowKey: PropTypes.string.isRequired,
      align: PropTypes.string,
      isHighlighted: PropTypes.bool,
      minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      rowCellClass: PropTypes.string,
    })
  ).isRequired,
  onRowClick: PropTypes.func,
};

export default GridTableBody;
