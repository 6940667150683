import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import ConditionalTooltip from '../conditionalTooltip';
import LevelBars from '../levelBars';
import UserCount from '../userCount';
import StatusIndicator from '../statusIndicator';
import DotsMenu from '../dotsMenu';
import AttributeChip from '../attributeChip';
import ListWithTooltip from '../listWithTooltip';
import { isEllipsisActive, isArrayEmpty } from '../../../utility/helpers';
import { useCustomEffect } from '../../../utility/hooks';

const VISIBLE_TAGS = 4;

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    boxSizing: 'border-box',
    borderRadius: 8,
    border: `1px solid ${primary.bluish6}`,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    width: 434,
    minHeight: 180,
    transition: 'border .2s ease',
    '&:hover': {
      border: `1px solid ${primary.blue1}`,
    },
  },
  content: {
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: 'minmax(154px, min-content) minmax(0, 1fr)',
    height: '100%',
    padding: spacing(4, 4, 3),
  },
  contentWithActions: {
    gridTemplateColumns: 'minmax(154px, min-content) minmax(0, 1fr) 28px',
  },
  coverImageWrapper: {
    backgroundColor: primary.bluish5,
    borderRadius: 8,
    flexShrink: 0,
    marginRight: spacing(4),
    width: 138,
    height: 105,
  },
  coverImage: {
    borderRadius: 8,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  courseInfo: {
    width: '100%',
    overflow: 'hidden',
  },
  title: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    marginBottom: spacing(2),
    overflow: 'hidden',
  },
  description: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    marginBottom: spacing(2),
  },
  footer: {
    boxSizing: 'border-box',
    backgroundColor: primary.bluish9,
    borderRadius: '0px 0px 8px 8px',
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    height: 43,
    padding: spacing(0, 4),
  },
  userCount: {
    flexShrink: 0,
    marginLeft: spacing(4),
  },
  attribute: {
    marginLeft: spacing(4),
  },
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    marginLeft: spacing(4),
  },
  link: {
    textDecoration: 'none',
    marginLeft: spacing(1),
  },
  linkText: {
    color: primary.blue1,
  },
  dotsMenu: {
    flexShrink: 0,
    width: 24,
    height: 24,
    marginLeft: spacing(1),
  },
  tagsWrapper: {
    minHeight: 28,
    gridColumn: 'span 3',
  },
  tagsList: {
    gridTemplateColumns: `repeat(${VISIBLE_TAGS}, minmax(0,min-content)) minmax(auto, min-content)`,
    gridColumnGap: spacing(2),
  },
}));

const CourseCard = ({
  className,
  translations,
  course,
  levels,
  actions,
  statuses,
  isStatusSelectable,
  isDisabled,
  hasCourseUrl,
  shouldDisablePortal,
  hasEnrolledUsersCount,
  onChangeStatus,
  getCourseEnrolledUsers,
  onCardClick,
}) => {
  const classes = useStyles();
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  const [hasTitleTooltip, setHasTitleTooltip] = useState(false);
  const [hasDescriptionTooltip, setHasDescriptionTooltip] = useState(false);

  const {
    title,
    description,
    attribute,
    level,
    enrolledUsers,
    url,
    coverImage,
    enrollmentStatus,
    tags,
  } = course;
  const hasActions = !isArrayEmpty(actions);

  const shouldShowTooltips = (titleNode, descriptionNode) => {
    setHasTitleTooltip(!!isEllipsisActive(titleNode, true));
    setHasDescriptionTooltip(isEllipsisActive(descriptionNode, true));
  };

  useCustomEffect(
    () => shouldShowTooltips(titleRef?.current, descriptionRef?.current),
    [course.title, course.description]
  );

  const renderCoverImage = () => (
    <div className={classes.coverImageWrapper}>
      {coverImage && (
        <img
          className={classes.coverImage}
          src={coverImage}
          alt="course cover"
        />
      )}
    </div>
  );

  const renderInfo = (isTitle = true) => (
    <ConditionalTooltip
      addTooltip={isTitle ? hasTitleTooltip : hasDescriptionTooltip}
      message={isTitle ? title : description}
    >
      <Typography
        ref={isTitle ? titleRef : descriptionRef}
        className={classNames({
          [classes.title]: isTitle,
          [classes.description]: !isTitle,
        })}
        variant={isTitle ? 'h5' : 'body2'}
      >
        {isTitle ? title : description}
      </Typography>
    </ConditionalTooltip>
  );

  const renderTags = () => (
    <div className={classes.tagsWrapper}>
      <ListWithTooltip
        listClass={classes.tagsList}
        items={tags}
        asyncListProps={{
          isDisabled: true,
          shouldDisablePortal: false,
        }}
        visibleItemsCount={VISIBLE_TAGS}
        isTagsList
      />
    </div>
  );

  const renderFooter = () => (
    <div className={classes.footer}>
      <LevelBars
        noLevelLabel={translations.all}
        levels={levels}
        level={level}
      />
      {hasEnrolledUsersCount && (
        <UserCount
          className={classes.userCount}
          tooltipTitle={translations.enrolledUsers}
          userCount={enrolledUsers}
          shouldDisablePortal={shouldDisablePortal}
          getUsers={getCourseEnrolledUsers}
          isTooltipDisabled={enrolledUsers === 0}
          isReadOnly
        />
      )}
      {attribute && (
        <AttributeChip
          customClass={classes.attribute}
          name={attribute.name}
          color={attribute.color}
        />
      )}
      {hasCourseUrl && (
        <div className={classes.linkWrapper}>
          <Typography variant="subtitle2">{translations.url}:</Typography>
          <Link
            className={classes.link}
            to={url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            <Typography className={classes.linkText} variant="subtitle2">
              {translations.visitCourse}
            </Typography>
          </Link>
        </div>
      )}
    </div>
  );

  return (
    <div className={classNames(classes.main, className)} onClick={onCardClick}>
      <div
        className={classNames(classes.content, {
          [classes.contentWithActions]: hasActions,
        })}
      >
        {renderCoverImage()}
        <div className={classes.courseInfo}>
          {renderInfo()}
          {description && renderInfo(false)}
          {enrollmentStatus && (
            <StatusIndicator
              status={enrollmentStatus}
              statuses={statuses}
              isSelectable={!isDisabled && isStatusSelectable}
              onSelect={onChangeStatus}
            />
          )}
        </div>
        {hasActions && (
          <DotsMenu className={classes.dotsMenu} menuItems={actions} />
        )}
        {!isArrayEmpty(tags) && renderTags()}
      </div>
      {renderFooter()}
    </div>
  );
};

CourseCard.defaultProps = {
  className: null,
  hasCourseUrl: false,
  hasEnrolledUsersCount: false,
  isStatusSelectable: false,
  isDisabled: false,
  shouldDisablePortal: true,
  statuses: [],
  actions: [],
  onChangeStatus: () => {},
  getCourseEnrolledUsers: () => {},
};

CourseCard.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  course: PropTypes.shape({}).isRequired,
  hasCourseUrl: PropTypes.bool,
  hasEnrolledUsersCount: PropTypes.bool,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({})),
  isStatusSelectable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  shouldDisablePortal: PropTypes.bool,
  onCardClick: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func,
  getCourseEnrolledUsers: PropTypes.func,
};

export default CourseCard;
