import ColorBox from '../../shared/colorBox';
import {
  isActiveUserView,
  isDeactivatedUserView,
  isExternalUserView,
} from '../../../utility/person';
import { ReactComponent as TagsIcon } from '../../../assets/icons/tags-icon.svg';
import { ReactComponent as TrackIcon } from '../../../assets/icons/track-icon.svg';
import { ReactComponent as LevelIcon } from '../../../assets/icons/track-level-icon.svg';
import { ReactComponent as EmployeeStatusIcon } from '../../../assets/icons/employee-status.svg';
import { ReactComponent as ActionPlansIcon } from '../../../assets/icons/assignment_dark.svg';
import { ReactComponent as JobTitleIcon } from '../../../assets/icons/job-title-icon.svg';
import { ReactComponent as ReportToIcon } from '../../../assets/icons/reviewer-icon.svg';
import { ReactComponent as RoleIcon } from '../../../assets/icons/role.svg';
import { ReactComponent as InviteIcon } from '../../../assets/icons/invite_user.svg';
import { ReactComponent as ReinviteIcon } from '../../../assets/icons/reinvite_user.svg';
import { ReactComponent as DeactivateIcon } from '../../../assets/icons/person_off.svg';
import { ReactComponent as ReactivateIcon } from '../../../assets/icons/person_add.svg';
import { ReactComponent as RevokeAccessIcon } from '../../../assets/icons/revoke_user_access.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-outlined.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-dark.svg';
import {
  checkUserRole,
  isArrayEmpty,
  isArraySubset,
  isObjectEmpty,
  trimString,
  getAdmins,
  getModerators,
  getItemById,
  isObject,
} from '../../../utility/helpers';
import {
  getPersonFullName,
  isEmployeeInReporters,
  prepareTracksWithLevels,
} from '../../../utility/uiUtils';
import {
  validateField,
  validateFields,
  isEmpty,
  validateFreemiumManagingRoles,
} from '../../../utility/validation';
import http from '../../../utility/http';
import {
  isPremiumUserCheck,
  isSubscriptionExpiredCheck,
} from '../../../utility/subscriptionHelper';
import { filterTags } from '../../../utility/tagUtils';
import {
  ACCOUNT_TYPES,
  PERMISSIONS,
  ROLES,
} from '../../../constants/rolesAndPermissionList';
import { USER_STATUSES, USER_STATUSES_MAP } from '../../../constants/statuses';
import { BULK_ACTION_OPTIONS } from '../../../constants/bulkActionOptions';
import { API_SUBSCRIPTION } from '../../../constants/apiRoutes';
import {
  ACTION_PLANS_FILTER,
  REPORT_TO_FILTER,
} from '../../../constants/filters';
import { PARAMS } from '../../../constants/pages';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import {
  RESET_PASSWORD_URL,
  FREEMIUM_LIMIT_TYPES,
} from '../../../constants/appConfig';
import {
  USER_INFO,
  USER_FIELDS,
  ADD_USER_OPTION_KEYS,
  ADD_USER_OPTIONS,
  USER_VALIDATIONS,
  USER_VIEW_OPTIONS,
} from '../../../constants/people';

const {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  REPORT_TO,
  JOB_TITLE,
  ROLE,
  FRAMEWORK,
  TRACK,
  LEVEL,
  STATUS,
  EMPLOYMENT_DATE,
} = USER_INFO;
const { MANUAL, ASSOCIATE, IMPORT } = ADD_USER_OPTION_KEYS;
const { ADD_USERS_TABLE, SUBTITLE } = FIELD_TYPES;

const CSV_FILE_USER_FIRST_ROW = 6;

const getStatusFilters = (labels, isEmptyView) => {
  return USER_STATUSES_MAP.reduce((acc, curr) => {
    const isNotSuspended = isEmptyView
      ? curr.id !== USER_STATUSES.DEACTIVATED
      : true;

    return isNotSuspended
      ? [
          ...acc,
          {
            ...curr,
            name: labels[curr.key],
          },
        ]
      : acc;
  }, []);
};

const getReportToFilters = labels => {
  return REPORT_TO_FILTER.map(report => {
    return {
      ...report,
      name: labels[report.translationKey],
    };
  });
};

const getActionPlansFilters = labels => {
  return ACTION_PLANS_FILTER.map(actionPlanFilter => {
    return {
      ...actionPlanFilter,
      name: labels[actionPlanFilter.translationKey],
    };
  });
};

export const getTrackLevelFilters = tracks =>
  tracks.reduce((acc, track) => {
    return [...acc, ...track.levels];
  }, []);

export const getPageFilters = (
  translations,
  jobTitles,
  tracks,
  tagCategories,
  userRoles,
  isUser,
  currentUser,
  hasActionPlansFilter = false,
  quickFilters = [],
  selectedView = ''
) => {
  const {
    ROLE: ROLE_FILTER,
    STATUS: STATUS_FILTER,
    SCOPE,
    JOB_TITLE: JOB_TITLE_FILTER,
    TAGS,
    TRACK: TRACK_FILTER,
    LEVEL: LEVEL_TRACK_FILTER,
    WITHOUT_ACTION_PLANS,
  } = PARAMS;
  const isDeactivatedUsersView = isDeactivatedUserView(selectedView);
  const isEmptyView = isEmpty(selectedView);

  return [
    {
      id: ROLE_FILTER,
      name: translations.filters.labels[ROLE_FILTER],
      icon: RoleIcon,
      items: userRoles,
    },
    ...(isDeactivatedUsersView || isUser
      ? []
      : [
          {
            id: STATUS_FILTER,
            name: translations.filters.labels[STATUS_FILTER],
            icon: EmployeeStatusIcon,
            items: getStatusFilters(
              translations.userStatuses.statuses,
              isEmptyView
            ),
          },
        ]),
    ...(!isUser &&
    !isObjectEmpty(currentUser.reporters) &&
    (!isEmptyView || (isEmptyView && !isDeactivatedUsersView))
      ? [
          {
            id: SCOPE,
            name: translations.filters.labels[SCOPE],
            icon: ReportToIcon,
            isSingleSelect: true,
            items: getReportToFilters(translations.reportToFilter),
          },
        ]
      : []),
    {
      id: JOB_TITLE_FILTER,
      name: translations.filters.labels[JOB_TITLE_FILTER],
      icon: JobTitleIcon,
      items: jobTitles,
    },
    {
      id: TRACK_FILTER,
      name: translations.filters.labels.track,
      icon: TrackIcon,
      items: prepareTracksWithLevels(tracks),
    },
    {
      id: LEVEL_TRACK_FILTER,
      name: translations.filters.labels.trackLevel,
      icon: LevelIcon,
      items: getTrackLevelFilters(tracks),
    },
    ...(hasActionPlansFilter && !isUser
      ? [
          {
            id: WITHOUT_ACTION_PLANS,
            name: translations.filters.labels.actionPlans,
            icon: ActionPlansIcon,
            isSingleSelect: true,
            items: getActionPlansFilters(translations.actionPlansFilters),
          },
        ]
      : []),
    {
      id: TAGS,
      name: translations.filters.labels[TAGS],
      icon: TagsIcon,
      categoryItemsKey: TAGS,
      hasCategoryColorBox: true,
      hasSubmenuHash: true,
      isCategorized: true,
      items: tagCategories,
      categorySearch: filterTags,
    },
    ...(!isArrayEmpty(quickFilters)
      ? quickFilters?.map(filter => {
          return {
            id: filter.name,
            name: filter.name,
            icon: () => {
              return <ColorBox bgColor={filter.color} isSmall />;
            },
            parent: {
              id: TAGS,
            },
            items: filter?.tags,
          };
        }) || {}
      : []),
  ];
};

export const getPreHeaderActions = (checkbox, permissions) => {
  return [
    ...(permissions.includes(PERMISSIONS.canUseBulkEdit)
      ? [
          {
            id: 'action-select-all-users',
            title: checkbox,
            rowKey: 'selectUser',
            isHeaderAction: true,
          },
        ]
      : []),
  ];
};

export const getHeaderActions = (labels, isUser) =>
  isUser
    ? []
    : [
        {
          id: 'actions-column',
          title: labels.actions,
          rowKey: 'actions',
          align: 'center',
          isHeaderAction: true,
          rowCellClass: 'actions-dots-menu',
          minWidth: 60,
          maxWidth: 80,
        },
      ];

export const getTableHeaders = (labels, isUser) => [
  {
    id: 1,
    title: labels.name,
    rowKey: 'name',
    sortAs: `${FIRST_NAME},id`,
    minWidth: 140,
    maxWidth: '1.5fr',
    headerCellClass: 'name-header-cell',
  },
  {
    id: 2,
    title: labels.email,
    rowKey: 'email',
    sortAs: 'email,id',
    maxWidth: '1fr',
    hasConditionalTooltip: true,
  },
  {
    id: 3,
    title: labels.jobTitle,
    rowKey: 'position',
    sortAs: 'position__name,id',
    maxWidth: '1fr',
    hasConditionalTooltip: true,
  },
  {
    id: 4,
    title: labels.levelAndTrack,
    rowKey: 'levelAndTrack',
    sortAs: 'level__name,id',
    maxWidth: '1fr',
    hasConditionalTooltip: true,
  },
  {
    id: 5,
    title: labels.role,
    rowKey: 'role',
    sortAs: 'role,id',
    maxWidth: '1fr',
  },
  ...(!isUser
    ? [
        {
          id: 6,
          title: labels.status,
          rowKey: 'status',
          sortAs: 'status,id',
          minWidth: 158,
          maxWidth: '1fr',
        },
      ]
    : []),
];

export const prepareTableData = (
  isAdmin,
  auth,
  grantedPermissions,
  users,
  userStatuses,
  renderUser,
  renderSelectUser,
  renderDotsMenu,
  renderUserStatus,
  translations,
  seeHimself
) => {
  return users.map(user => {
    const isCurrentUser = user.id === auth.id;
    const canSeeUserProfile =
      (isCurrentUser && seeHimself) ||
      isEmployeeInReporters(user.id, auth.accessibleProfiles) ||
      (isAdmin && user[ROLE] !== ROLES.ASSOCIATE);

    const canUseBulkEdit = grantedPermissions.includes(
      PERMISSIONS.canUseBulkEdit
    );
    const userStatus = getItemById(userStatuses, user[STATUS]);

    return {
      ...(canUseBulkEdit ? { selectUser: renderSelectUser(user) } : {}),
      name: renderUser(user, canSeeUserProfile),
      email: user[EMAIL],
      position: user?.[JOB_TITLE]?.name,
      levelAndTrack:
        user[TRACK] && user[LEVEL]
          ? `${user.level.name} - ${user[TRACK].name}`
          : '',
      role: translations.role[user[ROLE].toLowerCase()],
      id: user.id,
      status:
        user[ROLE] === ROLES.ASSOCIATE ? null : renderUserStatus(userStatus),
      rowLink: canSeeUserProfile && `/people/${user.id}`,
      actions: renderDotsMenu(user, userStatus?.id, isCurrentUser, isAdmin),
    };
  });
};

export const getVisibleEmployees = (employees, currentUser) => {
  const isAdmin = checkUserRole(currentUser.role, ROLES.ADMIN);

  return employees.filter(employee =>
    isAdmin
      ? employee.id !== currentUser.id &&
        employee[STATUS] !== USER_STATUSES.DEACTIVATED &&
        employee[ROLE] !== ROLES.ASSOCIATE
      : isEmployeeInReporters(employee.id, currentUser.reporters)
  );
};

export const areAllUsersChecked = (checkedUsers, employees, currentUser) => {
  const visibleEmployees = getVisibleEmployees(employees, currentUser);

  return (
    !isArrayEmpty(checkedUsers) &&
    !isArrayEmpty(visibleEmployees) &&
    isArraySubset(checkedUsers, visibleEmployees)
  );
};

export const isCheckboxDisabled = (users, currentUser, isAdmin, user = {}) => {
  const employees = isAdmin
    ? users.reduce((acc, currentEmployee) => {
        if (
          currentEmployee.id !== currentUser.id &&
          currentEmployee[STATUS] !== USER_STATUSES.DEACTIVATED &&
          currentEmployee[ROLE] !== ROLES.ASSOCIATE
        ) {
          return { ...acc, [currentEmployee.id]: true };
        }
        return acc;
      }, {})
    : {};

  const whitelistEmployees = isAdmin ? employees : currentUser.reporters;

  return !isObjectEmpty(user)
    ? !isEmployeeInReporters(user.id, whitelistEmployees)
    : !users.some(employee =>
        isEmployeeInReporters(employee.id, whitelistEmployees)
      );
};

export const isMenuDisabled = (userId, currentUser, isAdmin) => {
  if (isAdmin) return false;

  return (
    currentUser.id === userId ||
    !isEmployeeInReporters(userId, currentUser.reporters)
  );
};

export const getBulkActionOptions = labels => {
  return BULK_ACTION_OPTIONS.map(option => {
    return {
      value: option.value,
      label: labels[option.key],
    };
  });
};

export const getCheckboxTooltip = (labels, user, currentUser) => {
  if (user.id === currentUser.id) {
    return labels.currentUserTooltip;
  } else if (user[STATUS] === USER_STATUSES.DEACTIVATED) {
    return labels.inactiveUserTooltip;
  } else if (user[ROLE] === ROLES.ASSOCIATE) {
    return labels.externalUser;
  }

  return labels.userIsNotReporter;
};

export const getDisabledMenuTooltip = (labels, user, currentUser) => {
  if (user.id === currentUser.id) {
    return labels.currentUserActionTooltip;
  }

  return labels.userIsNotReporterAction;
};

export const isCheckboxChecked = (userId, checkedUsers) => {
  return checkedUsers.some(checked => checked.id === userId);
};

export const getUserRoles = (
  labels,
  isAdmin,
  shouldIncludeExternal,
  selectedUser = {},
  shouldExcludeExternal = false
) => {
  const isSelectedUserAdmin = checkUserRole(selectedUser?.[ROLE], ROLES.ADMIN);
  const isSelectedUserAssociate = checkUserRole(
    selectedUser?.[ROLE],
    ROLES.ASSOCIATE
  );
  const roles = ACCOUNT_TYPES.map(atu => {
    return {
      ...atu,
      id: atu.value,
      name: labels.role[atu.label],
      label: labels.role[atu.label],
      description: labels.roleDescriptions[atu.label],
    };
  });

  if (shouldExcludeExternal)
    return roles.filter(role => role.value !== ROLES.ASSOCIATE);

  if (isSelectedUserAssociate) return roles;

  if (isAdmin || isSelectedUserAdmin) {
    if (shouldIncludeExternal) return roles;

    return roles.filter(role => role.value !== ROLES.ASSOCIATE);
  }

  return roles.filter(
    role => role.value !== ROLES.ADMIN && role.value !== ROLES.ASSOCIATE
  );
};

export const getViewDescription = (selectedView, translations) => {
  if (isExternalUserView(selectedView))
    return translations.externalUsersViewDescription;
  if (isDeactivatedUserView(selectedView))
    return translations.deactivatedUsersViewDescription;
  return translations.activeUsersViewDescription;
};

export const getWhitelistedFilters = (filters, selectedView) => {
  const isExternalUsersView = isExternalUserView(selectedView);
  const isActiveUsersView = isActiveUserView(selectedView);

  return isExternalUsersView
    ? {}
    : {
        ...filters,
        ...(isActiveUsersView
          ? {}
          : { [PARAMS.STATUS]: [], [PARAMS.SCOPE]: [] }),
      };
};

export const getInitialUserData = (
  user,
  tracks,
  frameworks,
  reportTo = null
) => {
  const isTrackId = !isObject(user[TRACK]) && user[TRACK];
  const userTrack = isTrackId
    ? tracks.find(track => track.id === user[TRACK])
    : user[TRACK];
  const userFramework = isObject(userTrack)
    ? frameworks.find(framework => framework.id === userTrack.framework)
    : {};

  return {
    [FIRST_NAME]: user[FIRST_NAME],
    [LAST_NAME]: user[LAST_NAME],
    [EMAIL]: user[EMAIL],
    [ROLE]: user[ROLE],
    [JOB_TITLE]: user[JOB_TITLE]?.id,
    [EMPLOYMENT_DATE]: user[EMPLOYMENT_DATE],
    [FRAMEWORK]: userFramework?.id,
    [TRACK]: userTrack?.id || userTrack,
    [LEVEL]: user[LEVEL]?.id || user[LEVEL],
    [REPORT_TO]: reportTo?.id || user[REPORT_TO]?.id,
  };
};

export const getEditUserFields = (
  user,
  isCurrentUserAdmin,
  isEditedUserAdmin,
  isOwnProfile,
  isPremiumUser,
  isSubscriptionExpired
) => {
  const isAssociate = checkUserRole(user[ROLE], ROLES.ASSOCIATE);

  return [
    ...(!isAssociate
      ? [
          {
            name: 'personalInformation',
            type: SUBTITLE,
          },
        ]
      : []),
    {
      ...USER_FIELDS.FIRST_NAME,
    },
    {
      ...USER_FIELDS.LAST_NAME,
    },
    {
      ...USER_FIELDS.EMAIL,
    },
    ...(!isAssociate
      ? [
          {
            name: 'employmentInformation',
            type: SUBTITLE,
          },
        ]
      : []),
    ...(!isAssociate
      ? [
          {
            ...USER_FIELDS.EMPLOYMENT_DATE,
          },
        ]
      : []),
    ...(!isAssociate
      ? [
          {
            ...USER_FIELDS.JOB_TITLE,
          },
        ]
      : []),
    ...(!isAssociate
      ? [
          {
            ...USER_FIELDS.FRAMEWORK,
          },
        ]
      : []),
    ...(!isAssociate
      ? [
          {
            ...USER_FIELDS.TRACK,
          },
        ]
      : []),
    ...(!isAssociate
      ? [
          {
            ...USER_FIELDS.LEVEL,
          },
        ]
      : []),
    ...(!isAssociate
      ? [
          {
            ...USER_FIELDS.REPORT,
            validators: [
              {
                validator: isEmpty,
                type: 'required',
              },
            ],
          },
        ]
      : []),
    ...(!isAssociate
      ? [
          {
            name: 'permissions',
            type: SUBTITLE,
          },
        ]
      : []),
    {
      ...USER_FIELDS.ROLE,
      isDisabled: isOwnProfile || (isEditedUserAdmin && !isCurrentUserAdmin),
      ...(isAssociate
        ? {
            dependants: [
              {
                name: REPORT_TO,
                resetFnc: (prevValue, currentValue) =>
                  currentValue !== ROLES.ASSOCIATE ? null : undefined,
              },
            ],
          }
        : {}),
      ...(!isPremiumUser || isSubscriptionExpired
        ? {
            validators: [
              {
                validator: validateFreemiumManagingRoles,
                type: 'freemium',
                async: true,
              },
            ],
          }
        : {}),
    },
    ...(isAssociate
      ? [
          {
            ...USER_FIELDS.REPORT,
            isDisabledCheck: values =>
              checkUserRole(values[ROLE], ROLES.ASSOCIATE),
            validators: [
              {
                validator: (value, values) =>
                  checkUserRole(values?.role, ROLES.ASSOCIATE) ||
                  isEmpty(value),
                shouldPassFormValues: true,
                type: 'required',
              },
            ],
          },
        ]
      : []),
  ];
};

export const getAddUsersInfo = (labels, selectedOption) => {
  if (selectedOption === MANUAL) {
    return labels.manual;
  }
  if (selectedOption === IMPORT) {
    return labels.import;
  }

  return labels.associate;
};

export const getUserViewOptions = labels =>
  USER_VIEW_OPTIONS.map(view => ({
    ...view,
    name: labels[view.key],
  }));

const getNewUser = (currentUser, isAdmin, selectedOption) => ({
  [FIRST_NAME]: '',
  [LAST_NAME]: '',
  [EMAIL]: '',
  [ROLE]: selectedOption === ASSOCIATE ? ROLES.ASSOCIATE : ROLES.USER,
  ...(selectedOption === MANUAL
    ? {
        [REPORT_TO]: isAdmin ? [] : [currentUser],
        [STATUS]: USER_STATUSES.ACTIVE_WITHOUT_ACCESS,
        activation_url: RESET_PASSWORD_URL,
        client_domain: window.document.location.origin,
      }
    : {}),
});

export const usersValidator = async (
  users,
  columns,
  initialUsers = [],
  rowIndex = -1,
  organizationSettings = {},
  getOrganizationSettings = () => {}
) => {
  const errors = {};
  const isPremiumUser = isPremiumUserCheck(organizationSettings);
  const isSubscriptionExpired =
    isSubscriptionExpiredCheck(organizationSettings);
  const adminsCount = getAdmins(users).length;
  const moderatorsCount = getModerators(users).length;
  const shouldValidateLimits =
    rowIndex === -1 &&
    (!isPremiumUser || isSubscriptionExpired) &&
    (adminsCount !== 0 || moderatorsCount !== 0);
  let limits = {};

  if (shouldValidateLimits) {
    const { data } = await http.get(API_SUBSCRIPTION);
    if (limits.is_active) {
      getOrganizationSettings();
    }
    limits = data;
  }

  for (let i = 0; i < users.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const rowErrors = await validateFields(
      columns,
      users[i],
      initialUsers[i],
      rowIndex !== -1
    );

    if (!rowErrors[EMAIL]) {
      const isEmailDuplicate = users.some(
        (u, index) =>
          index !== i &&
          trimString(u[EMAIL]) &&
          trimString(u[EMAIL]) === users[i][EMAIL]
      );

      if (isEmailDuplicate) {
        rowErrors[EMAIL] = 'duplicatedEmail';
      }
    }

    if (shouldValidateLimits && !rowErrors[ROLE]) {
      if (
        !limits.is_active &&
        users[i][ROLE] === ROLES.ADMIN &&
        limits.usage[FREEMIUM_LIMIT_TYPES.ADMINS] + adminsCount >
          limits.limit[FREEMIUM_LIMIT_TYPES.ADMINS]
      ) {
        rowErrors[ROLE] = 'freemiumLimit';
      }

      if (
        !limits.is_active &&
        users[i][ROLE] === ROLES.MODERATOR &&
        limits.usage[FREEMIUM_LIMIT_TYPES.MODERATORS] + moderatorsCount >
          limits.limit[FREEMIUM_LIMIT_TYPES.MODERATORS]
      ) {
        rowErrors[ROLE] = 'freemiumLimit';
      }
    }

    if (!isObjectEmpty(rowErrors)) {
      errors[i] = rowErrors;
    }
  }

  return !isObjectEmpty(errors) ? errors : null;
};

export const getAddUsersFields = (
  descriptionLabels,
  currentUser,
  organizationMentor,
  selectedOption,
  isAdmin,
  roles,
  reportToOptions,
  importedUsers,
  isUserImport,
  organizationSettings,
  getOrganizationSettings,
  onChangeReportTo,
  onMentorChange
) => {
  let columns = [];
  const reportToInitialValue =
    selectedOption === IMPORT ? getPersonFullName(organizationMentor) : '';

  if (selectedOption === ASSOCIATE) {
    columns = [
      { ...USER_FIELDS.FIRST_NAME },
      { ...USER_FIELDS.LAST_NAME },
      { ...USER_FIELDS.EMAIL },
      { ...USER_FIELDS.ROLE, isDisabled: true },
    ];
  } else {
    columns = [
      { ...USER_FIELDS.FIRST_NAME },
      { ...USER_FIELDS.LAST_NAME },
      { ...USER_FIELDS.EMAIL },
      {
        ...USER_FIELDS.REPORT_TO,
        isDisabled: !isAdmin,
        initialValue: !isAdmin
          ? getPersonFullName(currentUser)
          : reportToInitialValue,
      },
      { ...USER_FIELDS.ROLE },
      { ...USER_FIELDS.INVITED, ...(isUserImport ? { key: 'invite' } : {}) },
    ];
  }

  return [
    {
      name: 'users',
      type: ADD_USERS_TABLE,
      description: getAddUsersInfo(descriptionLabels, selectedOption),
      currentUser,
      isAdmin,
      userAddType: selectedOption,
      columns,
      roles,
      reportToOptions,
      hasInvite: selectedOption !== ASSOCIATE,
      hasAddButton: selectedOption !== IMPORT,
      hasDelete: selectedOption !== IMPORT,
      organizationUserKey: 'organizationUser',
      getNewUser,
      onChangeReportTo,
      onMentorChange,
      usersValidator,
      validators: [
        {
          validator: users =>
            usersValidator(
              users,
              columns,
              importedUsers,
              -1,
              organizationSettings,
              getOrganizationSettings
            ),
          isMultiple: true,
        },
      ],
    },
  ];
};

export const getAddUsersInitialValues = (
  currentUser,
  isAdmin,
  selectedOption,
  organizationMentor,
  importedUsers = []
) => {
  return {
    users: [
      ...(isArrayEmpty(importedUsers)
        ? [getNewUser(currentUser, isAdmin, selectedOption)]
        : []),
      ...importedUsers.map(user => ({
        ...user,
        [REPORT_TO]: [organizationMentor],
      })),
    ],
  };
};

export const prepareUsersForSave = (users, initialUsers = []) => {
  if (!isArrayEmpty(initialUsers)) {
    return users.reduce((acc, user, index) => {
      const preparedUser = {
        id: user.id,
        [FIRST_NAME]: user[FIRST_NAME],
        [LAST_NAME]: user[LAST_NAME],
        [REPORT_TO]: user[REPORT_TO][0].id,
        [STATUS]: user[STATUS],
        [ROLE]: user[ROLE],
      };

      if (initialUsers[index][EMAIL] !== trimString(user[EMAIL])) {
        preparedUser[EMAIL] = user[EMAIL];
      }

      return [...acc, preparedUser];
    }, []);
  }

  return users.map(user => ({
    ...user,
    ...(user[REPORT_TO] ? { [REPORT_TO]: user[REPORT_TO][0].id } : {}),
  }));
};

export const getAddUserOptions = labels =>
  ADD_USER_OPTIONS.map(option => ({
    ...option,
    name: labels[option.key].title,
    description: labels[option.key].description,
  }));

export const getValidImportedUsers = async (users, organizationUser) => {
  const uniqueEmails = new Set();
  const validUsers = [];
  const rowsWithErrors = new Set();

  for (let i = 0; i < users.length; i += 1) {
    const { firstName, lastName, email } = users[i];
    const sanitizedFirstName = trimString(firstName);
    const sanitizedLastName = trimString(lastName);
    const sanitizedEmail = trimString(email);
    if (sanitizedFirstName || sanitizedLastName || sanitizedEmail) {
      // eslint-disable-next-line no-await-in-loop
      const firstNameErrors = await validateField(
        USER_FIELDS.FIRST_NAME,
        sanitizedFirstName
      );
      // eslint-disable-next-line no-await-in-loop
      const lastNameErrors = await validateField(
        USER_FIELDS.LAST_NAME,
        sanitizedLastName
      );
      // eslint-disable-next-line no-await-in-loop
      const emailErrors = await validateField(
        {
          ...USER_FIELDS.EMAIL,
          validators: USER_VALIDATIONS[EMAIL].slice(
            0,
            USER_VALIDATIONS[EMAIL].length - 1
          ),
        },
        sanitizedEmail
      );

      if (!rowsWithErrors.has(i + CSV_FILE_USER_FIRST_ROW) && firstNameErrors) {
        rowsWithErrors.add(i + CSV_FILE_USER_FIRST_ROW);
      }

      if (!rowsWithErrors.has(i + CSV_FILE_USER_FIRST_ROW) && lastNameErrors) {
        rowsWithErrors.add(i + CSV_FILE_USER_FIRST_ROW);
      }

      if (!rowsWithErrors.has(i + CSV_FILE_USER_FIRST_ROW) && emailErrors) {
        rowsWithErrors.add(i + CSV_FILE_USER_FIRST_ROW);
      }

      if (!firstNameErrors && !lastNameErrors && !emailErrors) {
        if (!uniqueEmails.has(sanitizedEmail)) {
          uniqueEmails.add(sanitizedEmail);
          validUsers.push({
            [FIRST_NAME]: sanitizedFirstName,
            [LAST_NAME]: sanitizedLastName,
            [EMAIL]: sanitizedEmail,
            [ROLE]: ROLES.USER,
            [REPORT_TO]: [organizationUser],
            [STATUS]: USER_STATUSES.ACTIVE_WITHOUT_ACCESS,
            activation_url: RESET_PASSWORD_URL,
            client_domain: window.document.location.origin,
          });
        }
      }
    }
  }

  return {
    rowsWithErrors: [...rowsWithErrors],
    validEmails: [...uniqueEmails],
    validUsers,
  };
};

export const getMenuItems = (
  labels,
  user,
  userStatus,
  isCurrentUser,
  isAdmin,
  onEdit,
  onInvite,
  onReinvite,
  onRevokeAccess,
  onDeactivate,
  onReactivate,
  onDelete
) => {
  return [
    {
      type: 'edit',
      label: labels.edit,
      shouldItemRender: () =>
        userStatus !== USER_STATUSES.DEACTIVATED ||
        user[USER_INFO.ROLE] === ROLES.ASSOCIATE,
      action: () => onEdit(user),
      icon: EditIcon,
    },
    {
      type: 'invite',
      label: labels.invite,
      shouldItemRender: () =>
        userStatus === USER_STATUSES.ACTIVE_WITHOUT_ACCESS &&
        user[USER_INFO.ROLE] !== ROLES.ASSOCIATE,
      action: onInvite(user),
      icon: InviteIcon,
    },
    {
      type: 'reinvite',
      label: labels.reinvite,
      shouldItemRender: () => userStatus === USER_STATUSES.ACTIVE_INVITED,
      action: onReinvite(user),
      icon: ReinviteIcon,
    },
    {
      type: 'revokeAccess',
      label: labels.revokeAccess,
      shouldItemRender: () =>
        !isCurrentUser &&
        (userStatus === USER_STATUSES.ACTIVE_WITH_ACCESS ||
          userStatus === USER_STATUSES.ACTIVE_INVITED),
      action: onRevokeAccess(user),
      icon: RevokeAccessIcon,
    },
    {
      type: 'deactivate',
      label: labels.deactivate,
      shouldItemRender: () =>
        !isCurrentUser &&
        userStatus !== USER_STATUSES.DEACTIVATED &&
        user[USER_INFO.ROLE] !== ROLES.ASSOCIATE,
      action: onDeactivate(user),
      icon: DeactivateIcon,
    },
    {
      type: 'reactivate',
      label: labels.reactivate,
      shouldItemRender: () => userStatus === USER_STATUSES.DEACTIVATED,
      action: onReactivate(user),
      icon: ReactivateIcon,
    },
    {
      type: 'delete',
      label: labels.delete,
      shouldItemRender: () =>
        isAdmin &&
        !isCurrentUser &&
        (userStatus === USER_STATUSES.DEACTIVATED ||
          user[USER_INFO.ROLE] === ROLES.ASSOCIATE),
      action: onDelete(user),
      icon: DeleteIcon,
    },
  ];
};
