import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormControl, FormHelperText, makeStyles } from '@material-ui/core';
import '../../../styles/customTimePickerStyle.css';
import TimePicker from 'rc-time-picker-date-fns';
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock-icon.svg';
import { ReactComponent as ClockIconDisabled } from '../../../assets/icons/clock-icon-gray.svg';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  main: {
    position: 'relative',
    zIndex: 9998,

    '& .rc-time-picker': {
      '& .rc-time-picker-input': {
        '&:focus-visible': {
          outline: 'none',
          border: `1px solid ${primary.blue2}`,
        },
      },
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  icon: {
    position: 'absolute',
    left: 11,
    bottom: 10,
    zIndex: -1,
  },
  iconDisabled: {
    zIndex: 0,
  },
  inputError: {
    '& .rc-time-picker-input': {
      border: `1px solid ${primary.red1}`,
    },
  },
  errorMessage: {
    position: 'absolute',
    left: 0,
    bottom: -20,
  },
}));

const CustomTimePicker = ({
  label,
  customRootClass,
  selectedTime,
  minuteStep,
  error,
  errorMessage,
  disabled,
  onChange,
}) => {
  const classes = useStyles();

  const handleOnChange = value => onChange(value);

  return (
    <div
      className={classNames(
        classes.main,
        { [classes.inputError]: error },
        customRootClass
      )}
    >
      {label && (
        <FormControl>
          <FormHelperText className={classes.label}>{label}</FormHelperText>
        </FormControl>
      )}

      <TimePicker
        value={selectedTime}
        showSecond={false}
        minuteStep={minuteStep}
        allowEmpty={false}
        disabled={disabled}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        onChange={handleOnChange}
        use12Hours
      />
      {disabled ? (
        <ClockIconDisabled
          className={classNames(classes.icon, {
            [classes.iconDisabled]: disabled,
          })}
        />
      ) : (
        <ClockIcon className={classes.icon} />
      )}
      {error && errorMessage && (
        <FormHelperText classes={{ root: classes.errorMessage }} error={error}>
          {errorMessage}
        </FormHelperText>
      )}
    </div>
  );
};

CustomTimePicker.defaultProps = {
  disabled: false,
  label: '',
  selectedTime: null,
  customRootClass: '',
  minuteStep: 15,
  error: false,
  errorMessage: '',
};

CustomTimePicker.propTypes = {
  label: PropTypes.string,
  selectedTime: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  customRootClass: PropTypes.string,
  minuteStep: PropTypes.number,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CustomTimePicker;
