import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import SectionTitle from '../sectionTitle';
import NotificationCard from '../notificationCard';
import RichTextPreview from '../richTextPreview';
import AttributeChip from '../attributeChip';
import CustomDateRange from '../customDateRange';
import { ReactComponent as ProgressIcon } from '../../../assets/icons/suitcase-icon.svg';
import { getActionButtons } from './config';
import { isArrayEmpty } from '../../../utility/helpers';
import { PARAMS } from '../../../constants/pages';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  levelsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridGap: 24,
    width: '100%',
    maxWidth: 900,
  },
  level: {
    backgroundColor: primary.bluish8,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(6),
    '&:first-of-type:not($currentLevel)': {
      borderRadius: '4px 0 0 4px',
    },
    '&:last-of-type:not($currentLevel)': {
      borderRadius: '0 4px 4px 0',
      position: 'relative',
    },
  },
  label: {
    lineHeight: '18px',
    marginBottom: spacing(7),
    wordBreak: 'break-word',
    fontSize: 14,
    color: primary.bluish9,
  },
  breadcrumbs: {
    fontFamily: 'ProximaNova-Bold',
    lineHeight: '20px',
    alignItems: 'end',
    display: 'flex',
  },
  levelTitle: {
    lineHeight: '18px',
    marginBottom: spacing(7),
    wordBreak: 'break-word',
    maxWidth: 'calc(100% - 127px)',
  },
  currentLevelBlock: {
    position: 'relative',
    backgroundColor: primary.bluish1,
    borderRadius: 4,
  },
  currentLevel: {
    color: primary.white,
  },
  scoreLabel: {
    justifyContent: 'center',
    display: 'flex',
  },
  nextStageLabel: {
    color: primary.bluish1,
    position: 'absolute',
    right: 23,
    top: 19,
    textTransform: 'uppercase',
    padding: spacing(1, 2, 1, 2),
    borderRadius: 4,
    border: `1px solid ${primary.bluish1}`,
  },
  currentLevelDescription: {
    color: primary.white,
    marginBottom: spacing(5),
    '& span': {
      color: `${primary.white} !important`,
    },
  },
  currentLevelLabel: {
    position: 'absolute',
    right: 23,
    top: 19,
    color: primary.white,
    textTransform: 'uppercase',
    padding: spacing(1, 2, 1, 2),
    borderRadius: 4,
    border: `1px solid ${primary.white}`,
  },
  attributeTable: {
    display: 'grid',
    gridTemplateColumns: '158px 114px 114px',
  },
  attribute: {
    marginBottom: spacing(2),
    width: 185,
  },
  score: {
    lineHeight: '18.2px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(2),
    height: 28,
    justifyContent: 'center',
    textAlign: 'center',
    color: primary.bluish9,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 900,
    marginBottom: spacing(6),
    '& > div': {
      '&:first-child': {
        display: 'grid',
        justifyContent: 'space-between',
      },
    },
  },
  periodFilter: {
    width: 300,
    position: 'relative',
    zIndex: 998,
  },
  range: {
    color: primary.bluish9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rangeValue: {
    width: 14.44,
    lineHeight: '18.2px',
    color: primary.bluish9,
    justifyContent: 'center',
    display: 'flex',
  },
  attributeRangeValue: {
    marginRight: spacing(1),
  },
  attributeRangeMaxValue: {
    marginLeft: spacing(1),
  },
}));

const CareerProgress = ({
  className,
  translations,
  currentLevelId,
  levels,
  core,
  period,
  levelBreadcrumbs,
  hasAddLevelButton,
  hasAssignLevelButton,
  isUserSuspended,
  onAssignLevel,
  onAddLevel,
  handlePeriodChange,
}) => {
  const classes = useStyles();

  return (
    <Fade in appear unmountOnExit>
      <div className={className}>
        <div className={classes.title}>
          <div>
            <SectionTitle title={translations.title} variant="h4" />
            <Typography
              className={classNames(classes.breadcrumbs)}
              variant="body2"
              component="h4"
            >
              {levelBreadcrumbs}
            </Typography>
          </div>
          <CustomDateRange
            className={classes.periodFilter}
            label={translations.period}
            initialRange={{
              startDate: period[PARAMS.START],
              endDate: period[PARAMS.END],
            }}
            startAtKey={PARAMS.START}
            endAtKey={PARAMS.END}
            onChange={handlePeriodChange}
          />
        </div>
        <div>
          {currentLevelId && !isArrayEmpty(levels) ? (
            <div className={classes.levelsWrapper}>
              {levels.map(level => (
                <div
                  key={`level_item_${level.id}`}
                  className={classNames(classes.level, {
                    [classes.currentLevelBlock]: level.isCurrentLevel,
                  })}
                >
                  <Typography
                    className={classNames(classes.levelTitle, {
                      [classes.currentLevel]: level.isCurrentLevel,
                    })}
                    variant="subtitle1"
                    component="h4"
                  >
                    {level.name}
                  </Typography>
                  {level.description && (
                    <RichTextPreview
                      className={classNames({
                        [classes.currentLevelDescription]: level.isCurrentLevel,
                      })}
                      text={level.description}
                    />
                  )}
                  {level.isCurrentLevel && !isArrayEmpty(level.attributes) && (
                    <>
                      <div className={classes.attributeTable}>
                        <Typography
                          className={classNames(classes.label)}
                          variant="subtitle1"
                          component="h4"
                        >
                          {translations.attributes}
                        </Typography>
                        <Typography
                          className={classNames(
                            classes.label,
                            classes.scoreLabel
                          )}
                          variant="subtitle1"
                          component="h4"
                        >
                          {translations.range}
                        </Typography>
                        <Typography
                          className={classNames(
                            classes.label,
                            classes.scoreLabel
                          )}
                          variant="subtitle1"
                          component="h4"
                        >
                          {translations.score}
                        </Typography>
                      </div>
                      {core?.map((attribute, index) => {
                        const { scoreByOthers } = attribute;
                        const userScore = scoreByOthers || '/';

                        return (
                          <div
                            className={classes.attributeTable}
                            key={`${index}_attribute`}
                          >
                            <AttributeChip
                              customClass={classes.attribute}
                              key={`attribute_${attribute.id}`}
                              name={attribute.name}
                              color={attribute.color}
                              attribute={attribute}
                              isTextReply={attribute.text_reply_questions}
                              isSelected
                            />
                            {attribute.text_reply_questions ? (
                              <Typography
                                className={classNames(classes.score)}
                                variant="subtitle2"
                                component="h4"
                              >
                                /
                              </Typography>
                            ) : (
                              <div className={classes.range}>
                                <Typography
                                  variant="subtitle2"
                                  className={classNames(
                                    classes.attributeRangeValue,
                                    classes.rangeValue
                                  )}
                                >
                                  {attribute.range_from}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.range}
                                >
                                  -
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  className={classNames(
                                    classes.attributeRangeMaxValue,
                                    classes.rangeValue
                                  )}
                                >
                                  {attribute.range_to}
                                </Typography>
                              </div>
                            )}
                            {userScore && (
                              <Typography
                                className={classNames(classes.score)}
                                variant="subtitle2"
                                component="h4"
                              >
                                {userScore}
                              </Typography>
                            )}
                          </div>
                        );
                      })}
                    </>
                  )}
                  {level.isCurrentLevel && (
                    <Typography
                      className={classes.currentLevelLabel}
                      variant="subtitle2"
                    >
                      {translations.currentLevel}
                    </Typography>
                  )}
                  {!level.isCurrentLevel && (
                    <Typography
                      className={classNames(classes.nextStageLabel)}
                      variant="subtitle2"
                    >
                      {translations.nextStage}
                    </Typography>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <NotificationCard
              customIcon={ProgressIcon}
              title={translations.noLevel.title}
              actionButtons={getActionButtons(
                translations.noLevel,
                hasAddLevelButton,
                hasAssignLevelButton,
                onAssignLevel,
                onAddLevel,
                isUserSuspended
              )}
              hasIcon
            />
          )}
        </div>
      </div>
    </Fade>
  );
};

CareerProgress.defaultProps = {
  className: null,
  currentLevelId: null,
  isUserSuspended: false,
  levels: [],
};

CareerProgress.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  currentLevelId: PropTypes.number,
  levels: PropTypes.arrayOf(PropTypes.shape({})),
  hasAddLevelButton: PropTypes.bool.isRequired,
  hasAssignLevelButton: PropTypes.bool.isRequired,
  isUserSuspended: PropTypes.bool,
  levelBreadcrumbs: PropTypes.string.isRequired,
  onAssignLevel: PropTypes.func.isRequired,
  onAddLevel: PropTypes.func.isRequired,
  handlePeriodChange: PropTypes.func.isRequired,
  period: PropTypes.object.isRequired,
};

export default CareerProgress;
