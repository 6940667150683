import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import UsersComments from '../../shared/usersComments';
import CustomButton from '../../shared/customButton';
import SectionTitle from '../../shared/sectionTitle';
import OneOnOneRecordDetails from '../../shared/oneOnOneRecordDetails';
import AlertDialog from '../../shared/alertDialog';
import ManageOneOnOne from '../../shared/manageOneOnOne';
import ManageTasks from '../../shared/manageTasks';
import NotificationCard from '../../shared/notificationCard';
import RichTextPreview from '../../shared/richTextPreview';
import ActionButton from '../../shared/actionButton';
import Tooltip from '../../shared/tooltip';
import AddImpressionDialog from '../../shared/addImpressionDialog';
import UpdatesInfo from '../../shared/updatesInfo';
import CustomFormDrawer from '../../shared/customFormDrawer';
import { ReactComponent as AnonymousIcon } from '../../../assets/icons/anonymous-user.svg';
import {
  canManagePerson,
  canSeeEmployeeProfile,
  getObjectToNumberArray,
  isUserProfileAccessible,
  isUserDeactivated,
  isValidParameter,
  replacePropsInObject,
  isArrayEmpty,
} from '../../../utility/helpers';
import http from '../../../utility/http';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../../utility/tagManager';
import { getSeeTypeLabel, showSuccessMessage } from '../../../utility/uiUtils';
import {
  getManageOneOnOneSuccessMessages,
  getOneOnOneTasksAvailableUsers,
  getEmployeeTopicSidebarTranslations,
  getEmployeeTopicFields,
  prepareEmployeeTopicData,
} from '../../../utility/oneOnOne';
import { onSaveCreatableTag } from '../../../utility/tagUtils';
import { validateFreemiumAction } from '../../../utility/subscriptionHelper';
import { getTeamNotificationUpdates } from '../../../utility/teamNotifications';
import { prepareTasksForSave } from '../../../utility/tasks';
import {
  api_one_on_one_comment_update,
  api_one_on_one_comments,
  api_one_on_one_record,
  api_one_on_one_topic,
  api_one_on_one_record_agenda,
  API_ONE_ON_ONE_TOPICS,
  api_user_one_on_one,
  api_one_on_one_tasks,
  api_one_on_one_with_notes_view_permission,
} from '../../../constants/apiRoutes';
import { GENERAL_CATEGORY_ID } from '../../../constants/tags';
import { FREEMIUM_LIMIT_TYPES } from '../../../constants/appConfig';
import {
  DEFAULT_PARAMS,
  INITIAL_TOPIC_DATA,
  RECURRING_TYPES,
} from '../../../constants/oneOnOne';
import { ACTION_BUTTON_TYPES } from '../../shared/actionButton/config';
import UserCount from '../../shared/userCount';
import { ReactComponent as PreviewUserIcon } from '../../../assets/icons/remove_red_eye.svg';

const styles = ({ palette: { primary }, spacing }) => ({
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 30,
    margin: spacing(8, 0, 6, 0),
  },
  subtitle: {
    margin: spacing(8, 0, 4, 0),
  },
  notes: {
    position: 'relative',
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish6}`,
    padding: spacing(8),
    minHeight: 150,
  },
  editDescriptionButton: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  noteLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  privateNoteIcon: {
    marginTop: spacing(3),
    marginLeft: spacing(2),
    width: 14,
    height: 14,
  },
  wrap: {
    margin: spacing(8, 0, 10, 0),
  },
  loadMoreContainer: {
    marginLeft: spacing(16),
  },
  loadMore: {
    border: `1px solid ${primary.bluish3}`,
    borderRadius: 44,
    color: primary.bluish4,
    lineHeight: '24px',
    justifySelf: 'center',
    padding: spacing(1, 4),
    maxHeight: 32,
  },
  userCount: {
    marginLeft: spacing(2),
  },
});

class OneOnOneDetailsPage extends PureComponent {
  state = {
    isLoading: true,
    isManageRecordOpened: false,
    isManageTasksOpened: false,
    isEmployeeTopicOpened: false,
    isDeleteEmployeeTopicOpened: false,
    employeeTopic: INITIAL_TOPIC_DATA,
    isDeleteRecordOpened: false,
    isReschedule: false,
    newRecurring: false,
    isAddImpressionOpened: false,
    page: 1,
    commentId: 0,
  };

  componentDidMount() {
    const {
      matchedOneOnOneId,
      auth,
      getOneOnOneRecord,
      getOneOnOneTopics,
      getTagsCategories,
      getOneOnOneComments,
    } = this.props;

    if (isValidParameter(matchedOneOnOneId)) {
      return Promise.all([
        getOneOnOneComments(matchedOneOnOneId, DEFAULT_PARAMS),
        getOneOnOneRecord(matchedOneOnOneId),
      ]).then(() => {
        const { oneOnOneRecord } = this.props;
        if (oneOnOneRecord.moderator?.id === auth.id) {
          return Promise.all([getTagsCategories(), getOneOnOneTopics()]).then(
            () => this.setState({ isLoading: false })
          );
        }
        return this.setState({ isLoading: false });
      });
    }
  }

  componentWillUnmount() {
    const {
      clearOneOnOneRecord,
      clearOneOnOneTopics,
      clearTagsCategories,
      clearOneOnOneComments,
    } = this.props;

    clearOneOnOneRecord();
    clearOneOnOneTopics();
    clearTagsCategories();
    clearOneOnOneComments();
  }

  onRedirectToRecordsPage = () => {
    const { history } = this.props;

    history.replace('/1-1');
  };

  toggleManageRecord =
    (isReschedule = false) =>
    () => {
      this.setState(prevState => ({
        isManageRecordOpened: !prevState.isManageRecordOpened,
        isReschedule,
        newRecurring: false,
      }));
    };

  onOpenEmployeeTopic = () => {
    const { auth, oneOnOneRecord } = this.props;
    this.setState({
      isEmployeeTopicOpened: true,
      employeeTopic: prepareEmployeeTopicData(oneOnOneRecord, auth.id),
    });
  };

  onCloseEmployeeTopic = () =>
    this.setState({
      isEmployeeTopicOpened: false,
      employeeTopic: INITIAL_TOPIC_DATA,
    });

  onSaveEmployeeTopic = topic => {
    const { translations, oneOnOneRecord, getOneOnOneRecord } = this.props;

    if (topic.id) {
      return http
        .patch(api_one_on_one_topic(topic.id), {
          name: topic.name,
        })
        .then(() => {
          showSuccessMessage(
            translations.successMessages.general.employeeTopicUpdated
          );

          return getOneOnOneRecord(oneOnOneRecord.id);
        });
    }

    return http
      .post(API_ONE_ON_ONE_TOPICS, { name: topic.name })
      .then(({ data }) => {
        http
          .patch(api_one_on_one_record_agenda(topic.meetingId), {
            agenda: [data.id],
          })
          .then(() => {
            showSuccessMessage(
              translations.successMessages.general.employeeTopicAdded
            );
            return getOneOnOneRecord(oneOnOneRecord.id);
          });
      });
  };

  onOpenEmployeeTopicDelete = topic =>
    this.setState({ employeeTopic: topic, isDeleteEmployeeTopicOpened: true });

  onCloseEmployeeTopicDelete = () =>
    this.setState({
      isDeleteEmployeeTopicOpened: false,
    });

  onDeleteCustomTopic = () => {
    const { translations, oneOnOneRecord, getOneOnOneRecord } = this.props;
    const { employeeTopic } = this.state;
    http
      .patch(api_one_on_one_record_agenda(employeeTopic.meetingId), {
        agenda: [],
      })
      .then(() => {
        this.onCloseEmployeeTopicDelete();
        this.onCloseEmployeeTopic();
        showSuccessMessage(
          translations.deleteOneOnOneTopicDialog.deleteSuccess
        );

        return getOneOnOneRecord(oneOnOneRecord.id);
      });
  };

  onCreateTag = async tagName => {
    const { addMultipleTags } = this.props;
    const tags = await onSaveCreatableTag(tagName);
    const [tag] = tags.create;

    addMultipleTags({ categoryId: GENERAL_CATEGORY_ID, tags });

    return tag;
  };

  onSaveRecord = async values => {
    const { history, translations, oneOnOneRecord, getOneOnOneRecord } =
      this.props;
    const { isReschedule, newRecurring } = this.state;
    const { user, hasCustomTopic, customTopic, ...rest } = values;
    const { AddOneOnOne } = EVENT_ACTION_TYPES;
    let newRecordId = null;
    const payload = { ...rest };

    if (isReschedule && oneOnOneRecord?.id && !newRecurring) {
      await http.delete(api_one_on_one_record(oneOnOneRecord.id));
    }

    if (hasCustomTopic) {
      const { data } = customTopic?.id
        ? await http.patch(api_one_on_one_topic(customTopic?.id), {
            name: customTopic?.name,
          })
        : await http.post(API_ONE_ON_ONE_TOPICS, {
            name: customTopic?.name,
          });

      if (data?.id) {
        payload.agenda = [...payload.agenda, data.id];
      }
    }

    if (oneOnOneRecord && !isReschedule && !newRecurring) {
      await http.patch(api_one_on_one_record(oneOnOneRecord.id), {
        ...payload,
        user: user.id,
      });
    } else {
      const { data } = await http.post(api_user_one_on_one(user.id), {
        ...payload,
      });

      newRecordId = data.id;

      tagManagerDataLayer(AddOneOnOne.action, AddOneOnOne.name);
    }

    if (newRecordId) {
      history.replace(`/1-1/${newRecordId}/details`);
    }

    showSuccessMessage(
      getManageOneOnOneSuccessMessages(
        translations.successMessages,
        oneOnOneRecord.id
      )
    );

    return getOneOnOneRecord(newRecordId || oneOnOneRecord.id);
  };

  toggleAddImpression =
    (cb = () => {}) =>
    () =>
      this.setState(
        prevState => ({
          isAddImpressionOpened: !prevState.isAddImpressionOpened,
        }),
        cb
      );

  onSaveImpression = async data => {
    const {
      translations,
      oneOnOneRecord,
      organizationSettings,
      setDialogVisibility,
      getOrganizationSettings,
      getOneOnOneRecord,
    } = this.props;
    const { impression, nextRecurring, stoppedRecurring, notify, tasks } = data;
    const { recurring, tags, agenda, note, plans } = oneOnOneRecord;
    const { AddOneOnOne } = EVENT_ACTION_TYPES;
    const noteText = note ? note.text : '';

    return http
      .patch(api_one_on_one_record(oneOnOneRecord.id), {
        meeting_impression: impression,
        recurring: stoppedRecurring ? RECURRING_TYPES[0].id : recurring,
        user: oneOnOneRecord.user.id,
        tasks: prepareTasksForSave(tasks),
      })
      .then(async () => {
        showSuccessMessage(translations.addImpressionDialog.success);
        this.toggleAddImpression(() => getOneOnOneRecord(oneOnOneRecord.id))();
        if (!stoppedRecurring) {
          const params = {
            meeting_time: nextRecurring,
            recurring: stoppedRecurring ? RECURRING_TYPES[0].id : recurring,
            tags: getObjectToNumberArray(tags),
            plans: getObjectToNumberArray(plans),
            agenda: getObjectToNumberArray(agenda),
            note: noteText,
            user: oneOnOneRecord.user.id,
            notify,
          };
          validateFreemiumAction(
            async () => {
              await http.post(api_user_one_on_one(oneOnOneRecord.user.id), {
                ...params,
              });
              showSuccessMessage(translations.successMessages.general.create);
              tagManagerDataLayer(AddOneOnOne.action, AddOneOnOne.name);
            },
            FREEMIUM_LIMIT_TYPES.ONE_ON_ONE,
            organizationSettings,
            setDialogVisibility,
            getOrganizationSettings
          )();
        }
      }, this.toggleAddImpression());
  };

  onSaveAndCreate = async (impression, tasks) => {
    const {
      translations,
      organizationSettings,
      setDialogVisibility,
      getOrganizationSettings,
      oneOnOneRecord,
      getOneOnOneRecord,
    } = this.props;

    await http
      .patch(api_one_on_one_record(oneOnOneRecord.id), {
        meeting_impression: impression,
        tasks: prepareTasksForSave(tasks),
        user: oneOnOneRecord.user.id,
      })
      .then(() => {
        showSuccessMessage(translations.addImpressionDialog.success);
        getOneOnOneRecord(oneOnOneRecord.id);
        this.toggleAddImpression()();
        validateFreemiumAction(
          () =>
            this.setState({
              isManageRecordOpened: true,
              newRecurring: true,
            }),
          FREEMIUM_LIMIT_TYPES.ONE_ON_ONE,
          organizationSettings,
          setDialogVisibility,
          getOrganizationSettings
        )();
      }, this.toggleAddImpression());
  };

  toggleDeleteRecord =
    (cb = () => {}) =>
    () => {
      this.setState(
        prevState => ({
          isDeleteRecordOpened: !prevState.isDeleteRecordOpened,
        }),
        cb
      );
    };

  onDeleteRecord = () => {
    const { translations, oneOnOneRecord } = this.props;

    return http.delete(api_one_on_one_record(oneOnOneRecord.id)).then(() => {
      showSuccessMessage(translations.deleteOneOnOneRecordDialog.deleteSuccess);
      this.toggleDeleteRecord(this.onRedirectToRecordsPage)();
    }, this.toggleDeleteRecord());
  };

  openEditComment = commentId => {
    this.setState({ commentId });
  };

  closeEditComment = () => {
    this.setState({ commentId: 0 });
  };

  handleSendComment = (comment, isEdit) => {
    const { oneOnOneRecord, getOneOnOneComments } = this.props;
    const { commentId } = this.state;

    if (isEdit) {
      return http
        .patch(api_one_on_one_comment_update(oneOnOneRecord.id, commentId), {
          comment,
        })
        .then(() => {
          this.setState({
            page: DEFAULT_PARAMS.page,
            commentId: 0,
          });
          getOneOnOneComments(oneOnOneRecord.id, DEFAULT_PARAMS);
        });
    }

    return http
      .post(api_one_on_one_comments(oneOnOneRecord.id), { comment })
      .then(() => {
        getOneOnOneComments(oneOnOneRecord.id, DEFAULT_PARAMS);
        this.setState({ page: DEFAULT_PARAMS.page });
      });
  };

  handleDeleteComment = commentId => {
    const { translations, oneOnOneRecord, getOneOnOneComments } = this.props;

    return http
      .delete(api_one_on_one_comment_update(oneOnOneRecord.id, commentId))
      .then(() => {
        showSuccessMessage(
          translations.comment.deleteCommentDialog.deleteSuccess
        );
        this.setState({ page: DEFAULT_PARAMS.page });
        getOneOnOneComments(oneOnOneRecord.id, DEFAULT_PARAMS);
      });
  };

  onLoadMore = () => {
    const { oneOnOneRecord, getOneOnOneComments } = this.props;
    const { page } = this.state;
    const incrementedPage = page + 1;
    const params = replacePropsInObject(DEFAULT_PARAMS, {
      page: incrementedPage,
    });
    this.setState({ page: incrementedPage });
    return getOneOnOneComments(oneOnOneRecord.id, params);
  };

  canAddComment = oneOnOneRecord => {
    const { auth } = this.props;

    return [oneOnOneRecord.moderator?.id, oneOnOneRecord.user.id].includes(
      auth.id
    );
  };

  handleOpenManageTasks = () => this.setState({ isManageTasksOpened: true });

  handleCloseManageTasks = () => this.setState({ isManageTasksOpened: false });

  onSaveTasks = async values => {
    const { translations, auth, getOneOnOneRecord, oneOnOneRecord } =
      this.props;
    const isCreatedForCurrentUser = auth.id === oneOnOneRecord.user.id;
    const tasks = prepareTasksForSave(values.tasks);
    const data = {
      tasks,
      user: oneOnOneRecord.user.id,
    };

    if (isCreatedForCurrentUser) {
      await http.post(api_one_on_one_tasks(oneOnOneRecord.id), data);
    } else {
      await http.patch(api_one_on_one_record(oneOnOneRecord.id), data);
    }
    showSuccessMessage(translations.manageTasks.successMessage);

    return getOneOnOneRecord(oneOnOneRecord.id);
  };

  checkCanManageTask = task => {
    const { auth, oneOnOneRecord } = this.props;

    return oneOnOneRecord.moderator?.id === auth.id || task.user.id === auth.id;
  };

  checkCanManageFormTask = task => {
    const { auth, oneOnOneRecord } = this.props;
    const isCurrentUserModerator = auth.id === oneOnOneRecord.moderator?.id;
    const isNewTask = !task.id;

    return isCurrentUserModerator || task.creator === auth.id || isNewTask;
  };

  handleTaskStatusChange = (record, task) => {
    const { changeOneOnOneTaskStatus } = this.props;

    return changeOneOnOneTaskStatus(record.id, task.id);
  };

  canSeeUserProfileCheck = user => {
    const { auth, organizationSettings } = this.props;

    return canSeeEmployeeProfile(
      auth,
      user.id,
      organizationSettings.global_see_himself
    );
  };

  goToUserProfilePage = userId => this.props.navigate(`/people/${userId}`);

  getUsersWithViewPermition = recordId => () =>
    http.get(api_one_on_one_with_notes_view_permission(recordId));

  handleClearNotifications = () => {
    const { location, navigate } = this.props;

    return navigate(location.pathname, {
      replace: true,
      state: null,
    });
  };

  render() {
    const {
      classes,
      translations,
      location,
      auth,
      organizationSettings,
      oneOnOneRecord,
      topics,
      categories,
      oneOnOneComments,
    } = this.props;
    const {
      isLoading,
      isManageRecordOpened,
      isManageTasksOpened,
      isReschedule,
      isEmployeeTopicOpened,
      employeeTopic,
      newRecurring,
      isDeleteRecordOpened,
      isDeleteEmployeeTopicOpened,
      isAddImpressionOpened,
      commentId,
    } = this.state;
    const { user, note, meeting_time, requested } = oneOnOneRecord;
    const notificationsData = location?.state?.notifications
      ? getTeamNotificationUpdates(
          translations.notifications.updates,
          location.state.notifications.model,
          location.state.notifications.updates
        )
      : null;
    const isUserActive = user && !isUserDeactivated(user);
    const canManageUser = canManagePerson(auth, user?.id);
    const showLoadMore = !!oneOnOneComments?.next;
    const isCreatedByCurrentUser = auth.id === oneOnOneRecord?.moderator?.id;
    const isPendingRequest = requested && meeting_time === null;
    const hasNotifications = !!notificationsData;
    const isUserParticipant = isCreatedByCurrentUser || user?.id === auth.id;

    return (
      <div>
        {!isLoading && (
          <div>
            <div className={classes.heading}>
              <SectionTitle title={translations.details} />
              <UpdatesInfo
                translations={translations.notifications}
                updates={notificationsData}
                isUpdate={location?.state?.notifications?.isUpdate}
                hasUpdates={hasNotifications}
                onClearAll={this.handleClearNotifications}
              />
            </div>
            <OneOnOneRecordDetails
              translations={translations.record}
              record={oneOnOneRecord}
              notifications={location?.state?.notifications}
              canSeeUserProfileCheck={this.canSeeUserProfileCheck}
              goToUserProfilePage={this.goToUserProfilePage}
              isPendingRequest={isPendingRequest}
              isCurrentUserSubject={auth.id === user?.id}
              isCreatedByCurrentUser={isCreatedByCurrentUser}
              isUserActive={isUserActive}
              canManageUser={canManageUser}
              checkCanAccessUserProfile={isUserProfileAccessible(
                auth,
                organizationSettings.global_see_himself
              )}
              checkCanManageTask={this.checkCanManageTask}
              onTaskStatusChange={this.handleTaskStatusChange}
              onManageTasks={this.handleOpenManageTasks}
              onAddImpression={this.toggleAddImpression()}
              onReschedule={this.toggleManageRecord(true)}
              onDeleteRecord={this.toggleDeleteRecord()}
              onEditRecord={this.toggleManageRecord()}
              onEditEmployeeTopic={this.onOpenEmployeeTopic}
            />
            {!isPendingRequest && (
              <>
                {isCreatedByCurrentUser && (
                  <>
                    <div className={classes.noteLabelWrapper}>
                      <SectionTitle
                        className={classes.subtitle}
                        title={translations.privateNote}
                        variant="h5"
                      />
                      <Tooltip
                        customIconClass={classes.privateNoteIcon}
                        text={translations.privateNoteTooltip}
                        icon={AnonymousIcon}
                      />
                    </div>
                    {note ? (
                      <div className={classes.notes}>
                        <RichTextPreview text={note.text} />
                        {isUserActive && (
                          <ActionButton
                            className={classes.editDescriptionButton}
                            type={ACTION_BUTTON_TYPES.EDIT}
                            onClickHandler={this.toggleManageRecord()}
                          />
                        )}
                      </div>
                    ) : (
                      <NotificationCard
                        title={translations.noPrivateNote.title}
                        content={translations.noPrivateNote.message}
                      />
                    )}
                  </>
                )}
                {!isPendingRequest ? (
                  <>
                    {(!isArrayEmpty(oneOnOneComments.results) ||
                      isUserParticipant) && (
                      <div
                        className={classNames(
                          classes.subtitle,
                          classes.noteLabelWrapper
                        )}
                      >
                        <SectionTitle
                          title={translations.meetingNotes}
                          variant="h5"
                        />
                        <UserCount
                          className={classes.userCount}
                          icon={PreviewUserIcon}
                          goToUserProfile={this.goToUserProfilePage}
                          canSeeUserProfile={this.canSeeUserProfileCheck}
                          tooltipTitle={
                            translations.record.meetingNotesUsersListTitle
                          }
                          tooltipLabel={
                            translations.record?.meetingNotesUsersListTooltip
                          }
                          getUsers={this.getUsersWithViewPermition(
                            oneOnOneRecord.id
                          )}
                          shouldHandleTypeLabel
                          handleTypeLabel={employee =>
                            getSeeTypeLabel(
                              translations.record.visibilityRelations,
                              oneOnOneRecord,
                              employee,
                              true
                            )
                          }
                          isPreviewIcon
                          hasUserCount={false}
                        />
                      </div>
                    )}
                    <UsersComments
                      className={classes.wrap}
                      translations={translations.comment}
                      currentUser={auth}
                      comments={oneOnOneComments.results}
                      onSendComment={this.handleSendComment}
                      openEditComment={this.openEditComment}
                      commentId={commentId}
                      onDeleteComment={this.handleDeleteComment}
                      canAddComment={this.canAddComment(oneOnOneRecord)}
                      closeEditComment={this.closeEditComment}
                      isRichText
                    />
                  </>
                ) : null}
                <div className={classes.loadMoreContainer}>
                  {showLoadMore && (
                    <CustomButton
                      className={classes.loadMore}
                      type="addWithTextRounded"
                      onClick={this.onLoadMore}
                    >
                      {translations.loadMore}
                    </CustomButton>
                  )}
                </div>
              </>
            )}
          </div>
        )}
        <ManageOneOnOne
          translations={translations.oneOnOneRecordDialog}
          isOpened={isManageRecordOpened}
          topics={topics}
          record={oneOnOneRecord}
          createdFor={user}
          currentUser={auth}
          isReschedule={isReschedule}
          newRecurring={newRecurring}
          categories={categories}
          onCreateTag={this.onCreateTag}
          onDelete={this.toggleDeleteRecord()}
          onClose={this.toggleManageRecord()}
          onSave={this.onSaveRecord}
        />
        <CustomFormDrawer
          translations={getEmployeeTopicSidebarTranslations(
            translations.manageTopicForm,
            !!employeeTopic?.name
          )}
          isOpened={isEmployeeTopicOpened}
          isInitialValid={!!employeeTopic?.id}
          initialData={employeeTopic}
          hideDelete={!employeeTopic.id}
          fields={getEmployeeTopicFields()}
          onClose={this.onCloseEmployeeTopic}
          onSave={this.onSaveEmployeeTopic}
          onDelete={this.onOpenEmployeeTopicDelete}
        />
        <ManageTasks
          translations={translations.manageTasks}
          isOpened={isManageTasksOpened}
          record={oneOnOneRecord}
          availableUsers={getOneOnOneTasksAvailableUsers(oneOnOneRecord)}
          checkCanManageTask={this.checkCanManageFormTask}
          onClose={this.handleCloseManageTasks}
          onSave={this.onSaveTasks}
        />
        <AddImpressionDialog
          translations={translations.addImpressionDialog}
          isOpened={isAddImpressionOpened}
          currentRecord={isAddImpressionOpened ? oneOnOneRecord : null}
          recordId={isAddImpressionOpened ? oneOnOneRecord.id : null}
          onCancel={this.toggleAddImpression()}
          onConfirm={this.onSaveImpression}
          onSaveAndCreate={this.onSaveAndCreate}
        />
        <AlertDialog
          translations={translations.deleteOneOnOneRecordDialog}
          isOpened={isDeleteRecordOpened}
          onClose={this.toggleDeleteRecord()}
          onConfirm={this.onDeleteRecord}
          isWarning
        />
        <AlertDialog
          translations={translations.deleteOneOnOneTopicDialog}
          isOpened={isDeleteEmployeeTopicOpened}
          onClose={this.onCloseEmployeeTopicDelete}
          onConfirm={this.onDeleteCustomTopic}
          isWarning
        />
      </div>
    );
  }
}

OneOnOneDetailsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  oneOnOneComments: PropTypes.shape({}).isRequired,
  matchedOneOnOneId: PropTypes.string.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  oneOnOneRecord: PropTypes.object.isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getOneOnOneRecord: PropTypes.func.isRequired,
  getOneOnOneTopics: PropTypes.func.isRequired,
  getTagsCategories: PropTypes.func.isRequired,
  addMultipleTags: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  getOrganizationSettings: PropTypes.func.isRequired,
  changeOneOnOneTaskStatus: PropTypes.func.isRequired,
  clearOneOnOneRecord: PropTypes.func.isRequired,
  clearOneOnOneTopics: PropTypes.func.isRequired,
  clearTagsCategories: PropTypes.func.isRequired,
  clearOneOnOneComments: PropTypes.func.isRequired,
};

export default withStyles(styles)(OneOnOneDetailsPage);
