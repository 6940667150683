import { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import RichTextPreview from '../../richTextPreview';
import Info from './info';
import Content from './content';
import { isDateInFuture } from '../../../../utility/dateUtils';
import { onScrollIntoView } from '../../../../utility/uiUtils';

const useStyles = makeStyles(({ palette: { primary }, breakpoints }) => ({
  main: {
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish6}`,
    borderRadius: 4,
    display: 'grid',
    gridTemplateColumns: `minmax(0, 1fr) 230px`,
    width: '100%',
    transition: 'border .2s ease',
  },
  mainMin: {
    gridTemplateColumns: `minmax(195px, 1fr) 140px`,
    [breakpoints.up('xLg')]: {
      gridTemplateColumns: `minmax(210px, 1fr) minmax(115px, 140px)`,
    },
    [breakpoints.up('xl')]: {
      gridTemplateColumns: `minmax(210px, 1fr) minmax(115px, 180px)`,
    },
  },
  clickableCard: {
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${primary.blue1}`,
    },
  },
  disabled: {
    cursor: 'default',
  },
}));

const OneOnOneRecord = ({
  className,
  translations,
  record,
  notifications,
  hasNotifications,
  isNotificationUpdate,
  isMiniCard,
  isDisabled,
  canManageUser,
  onEdit,
  onDelete,
  onManageTasks,
  onCardClickHandler,
  onOpenCustomTopic,
  onReschedule,
  onAddImpression,
  goToUserProfilePage,
  canSeeUserProfileCheck,
  onClearNotifications,
}) => {
  const recordRef = useRef(null);
  const classes = useStyles();
  const { meeting_time, canAccessOneOnOne } = record;
  const isFuture = isDateInFuture(meeting_time);

  useEffect(() => {
    if (recordRef && hasNotifications) {
      onScrollIntoView(recordRef, { block: 'center' });
    }
  }, [hasNotifications]);

  const handleCardClick = useCallback(() => {
    if (!canAccessOneOnOne) return;
    return onCardClickHandler(record);
  }, [record, canAccessOneOnOne, onCardClickHandler]);

  const renderRichTextPreview = text => <RichTextPreview text={text} />;

  return (
    <div
      ref={recordRef}
      className={classNames(
        classes.main,
        {
          [classes.mainMin]: isMiniCard,
          [classes.clickableCard]: record.canAccessOneOnOne,
          [classes.disabled]: !record.canAccessOneOnOne,
        },
        className
      )}
      onClick={handleCardClick}
    >
      <Content
        translations={translations}
        record={record}
        isMiniCard={isMiniCard}
        isFuture={isFuture}
        onOpenCustomTopic={onOpenCustomTopic}
      />
      <Info
        translations={translations}
        goToUserProfilePage={goToUserProfilePage}
        canSeeUserProfileCheck={canSeeUserProfileCheck}
        record={record}
        isDisabled={isDisabled}
        isFuture={isFuture}
        isMiniCard={isMiniCard}
        notifications={notifications}
        hasNotifications={hasNotifications}
        isNotificationUpdate={isNotificationUpdate}
        hasRescheduleButton={!isMiniCard && canManageUser && !isFuture}
        noteLabelComponent={renderRichTextPreview}
        onReschedule={onReschedule}
        onAddImpression={onAddImpression}
        onEdit={onEdit}
        onDelete={onDelete}
        onManageTasks={onManageTasks}
        onClearNotifications={onClearNotifications}
      />
    </div>
  );
};

OneOnOneRecord.defaultProps = {
  className: null,
  notifications: [],
  isMiniCard: false,
  isDisabled: false,
  canManageUser: false,
  hasNotifications: false,
  isNotificationUpdate: false,
  onOpenCustomTopic: () => {},
  onEdit: () => {},
  onDelete: () => {},
  onAddImpression: () => {},
  onCardClickHandler: () => {},
  onManageTasks: () => {},
};

OneOnOneRecord.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.shape({}).isRequired,
  record: PropTypes.shape({}).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.string),
  isNotificationUpdate: PropTypes.bool,
  hasNotifications: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMiniCard: PropTypes.bool,
  canManageUser: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onOpenCustomTopic: PropTypes.func,
  onAddImpression: PropTypes.func,
  onCardClickHandler: PropTypes.func,
  onManageTasks: PropTypes.func,
  goToUserProfilePage: PropTypes.func.isRequired,
  canSeeUserProfileCheck: PropTypes.func.isRequired,
  onClearNotifications: PropTypes.func.isRequired,
};

export default OneOnOneRecord;
