import { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import LoginForm from '../../shared/loginForm';
import EnterOrganizationUrlForm from '../../shared/enterOrganizationLoginForm';
import BackgroundContainer from '../../shared/backgroundContainer';
import http, {
  AUTH,
  TOKEN_TYPE,
  API_DOMAIN,
  API_PROTOCOL,
} from '../../../utility/http';
import StorageService from '../../../utility/storageService';
import { validateOrganizationName } from '../../../store/modules/signUp';
import { setAuthInfo as setAuth } from '../../../store/modules/auth';
import {
  getOrganizationSettings as getGlobalSettings,
  getAllPresets as getGlobalPresets,
  getOrganizationMenuSetup as getGlobalMenuSetup,
} from '../../../store/modules/organizationSettings';
import { handleCustomHttpError } from '../../../utility/helpers';
import { useTranslations } from '../../../utility/useTranslations';
import {
  getSubdomain,
  mergeAccessibleProfilesForUser,
} from '../../../utility/uiUtils';
import { APP_PAGES } from '../../../constants/pages';
import { API_ME } from '../../../constants/apiRoutes';
import { PERMISSIONS_LIST_BY_ROLE } from '../../../constants/rolesAndPermissionList';

const LoginPage = ({ history }) => {
  const translations = useTranslations(APP_PAGES.LOGIN_PAGE);
  const subDomain = getSubdomain(window.location.hostname);

  const [isLoading, setIsLoading] = useState(false);
  const { token } = useParams();
  const dispatch = useDispatch();

  const getOrganizationSettings = useCallback(
    () => getGlobalSettings(dispatch),
    [dispatch]
  );

  const getAllPresets = useCallback(
    () => getGlobalPresets(dispatch),
    [dispatch]
  );

  const getOrganizationMenuSetup = useCallback(
    () => getGlobalMenuSetup(dispatch),
    [dispatch]
  );

  const setAuthInfo = useCallback(data => dispatch(setAuth(data)), [dispatch]);

  const validateOrgName = useCallback(() => {
    setIsLoading(true);

    return validateOrganizationName(subDomain)
      .then(({ data }) => {
        if (data.exists) {
          if (token && token.length > 0) {
            http.defaults.headers.Authorization = `${TOKEN_TYPE} ${token}`;
            // fetch user by token
            return http.get(API_ME).then(user => {
              getOrganizationSettings();
              getOrganizationMenuSetup();
              const authInfo = {
                ...user.data,
                last_login: null,
                access: token,
                permissions: PERMISSIONS_LIST_BY_ROLE[user.data.role],
                accessibleProfiles: mergeAccessibleProfilesForUser(user.data),
              };
              setAuthInfo(authInfo);
              StorageService.set(AUTH, JSON.stringify(authInfo));
              return history.push('/');
            });
          }
          setIsLoading(false);
        } else {
          window.location = `${API_PROTOCOL}://${API_DOMAIN}${
            window.location.port.length > 0 ? `:${window.location.port}` : ''
          }`;
          setIsLoading(false);
        }
      })
      .catch(error => {
        handleCustomHttpError(error, translations.somethingWentWrong);
      });
  }, [
    history,
    subDomain,
    token,
    translations,
    setAuthInfo,
    getOrganizationSettings,
    getOrganizationMenuSetup,
  ]);

  useEffect(() => {
    if (subDomain && subDomain.length > 0) {
      validateOrgName();
    }
  }, [subDomain, validateOrgName]);

  if (isLoading) return null;

  if (subDomain && subDomain.length > 0) {
    return (
      <BackgroundContainer
        translations={translations.authContainer}
        switchWorkspace
        removeTopButton
        subDomain={subDomain}
      >
        <LoginForm
          history={history}
          translations={translations}
          getOrganizationSettings={getOrganizationSettings}
          getAllPresets={getAllPresets}
          getOrganizationMenuSetup={getOrganizationMenuSetup}
          setAuthInfo={setAuthInfo}
        />
      </BackgroundContainer>
    );
  }

  return (
    <BackgroundContainer
      translations={{
        ...translations,
        ...translations.authContainer,
      }}
    >
      <EnterOrganizationUrlForm translations={translations} />
    </BackgroundContainer>
  );
};

LoginPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default memo(LoginPage);
