import { useState } from 'react';
import PropTypes from 'prop-types';
import QuestionStyle from '../questionStyle';
import AddAttributeQuestions from '../addAttributeQuestions';
import { isArrayEmpty } from '../../../utility/helpers';

const AttributeQuestionsForm = ({
  translations,
  values,
  setDialogVisibility,
  dialogs,
  field,
  questionsLimit,
  updateAttribute,
  deleteAllAttributeQuestions,
  createQuestion,
  setAttribute,
  reorderQuestions,
  setAttributeQuestions,
  updateAttributeQuestions,
  updateAttributeQuestion,
  deleteAttributeQuestion,
  getAttribute,
}) => {
  const [horizontalWarnings, setHorizontalWarnings] = useState([]);

  const isWarningOn = !isArrayEmpty(horizontalWarnings);

  const handleHorizontalWarning = questionId => {
    setHorizontalWarnings(prevWarnings => {
      const index = prevWarnings.indexOf(questionId);

      if (index > -1) {
        return prevWarnings.filter(warning => warning !== questionId);
      }
      return [...prevWarnings, questionId];
    });
  };

  return (
    <>
      <QuestionStyle
        key={field.questionsStyleComponent.name}
        fieldData={field.questionsStyleComponent}
        translations={translations}
        setDialogVisibility={setDialogVisibility}
        dialogs={dialogs}
        attribute={values}
        setAttributeQuestions={setAttributeQuestions}
        updateAttributeQuestions={updateAttributeQuestions}
        updateAttribute={updateAttribute}
        deleteAllAttributeQuestions={deleteAllAttributeQuestions}
        isWarningOn={isWarningOn}
      />
      <AddAttributeQuestions
        key={field.attributeQuestions.name}
        translations={translations.questionsForm}
        attribute={values}
        updateAttributeQuestion={updateAttributeQuestion}
        createQuestion={createQuestion}
        questionsLimit={questionsLimit}
        dialogs={dialogs}
        setDialogVisibility={setDialogVisibility}
        setAttribute={setAttribute}
        reorderQuestions={reorderQuestions}
        deleteAttributeQuestion={deleteAttributeQuestion}
        getAttribute={getAttribute}
        switchHorizontalWarning={handleHorizontalWarning}
      />
    </>
  );
};

AttributeQuestionsForm.propTypes = {
  translations: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  dialogs: PropTypes.object.isRequired,
  questionsLimit: PropTypes.number.isRequired,
  updateAttributeQuestion: PropTypes.func.isRequired,
  updateAttribute: PropTypes.func.isRequired,
  reorderQuestions: PropTypes.func.isRequired,
  setAttribute: PropTypes.func.isRequired,
  createQuestion: PropTypes.func.isRequired,
  deleteAttributeQuestion: PropTypes.func.isRequired,
  deleteAllAttributeQuestions: PropTypes.func.isRequired,
  getAttribute: PropTypes.func.isRequired,
  setAttributeQuestions: PropTypes.func.isRequired,
  updateAttributeQuestions: PropTypes.func.isRequired,
};

export default AttributeQuestionsForm;
