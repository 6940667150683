import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  InputAdornment,
  FormControl,
  FormLabel,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Tooltip from '../tooltip';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-right-dark.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar-icon.svg';
import { ReactComponent as CalendarIconDisabled } from '../../../assets/icons/calendar-icon-gray.svg';

const useStyles = makeStyles(({ spacing }) => ({
  calendarIcon: { cursor: 'pointer' },
  arrowLeft: {
    transform: 'rotate(180deg)',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '18px',
    marginBottom: 8,
  },
  highlightedLabel: {
    fontFamily: 'ProximaNova-Bold',
  },
  tooltipIcon: {
    marginLeft: spacing(1),
    width: 14,
    height: 14,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

const CustomDatePicker = ({
  className,
  labelClass,
  label,
  tooltipInfo,
  onChange,
  value,
  placeholder,
  errorMessage,
  error,
  format,
  disabled,
  fullWidth,
  required,
  customErrorClass,
  isIconOnStart,
  isHighlightedLabel,
  ...rest
}) => {
  const classes = useStyles();

  const ADORNMENT_START = 'start';
  const ADORNMENT_END = 'end';
  const POPOVER_OFFSET_START = 40;
  const POPOVER_OFFSET_END = 7;

  const renderAdornment = () => (
    <InputAdornment
      className={classes.calendarIcon}
      position={isIconOnStart ? ADORNMENT_START : ADORNMENT_END}
    >
      {disabled ? <CalendarIconDisabled /> : <CalendarIcon />}
    </InputAdornment>
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl fullWidth={fullWidth}>
        {label && (
          <FormLabel
            classes={{
              root: classNames(
                classes.label,
                {
                  [classes.highlightedLabel]: isHighlightedLabel,
                },
                labelClass
              ),
            }}
          >
            <span>{label}</span>
            {tooltipInfo && (
              <Tooltip
                customIconClass={classes.tooltipIcon}
                text={tooltipInfo}
              />
            )}
          </FormLabel>
        )}
        <DatePicker
          variant="inline"
          className={classNames(className)}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          InputProps={{
            startAdornment: isIconOnStart ? renderAdornment() : null,
            endAdornment: !isIconOnStart ? renderAdornment() : null,
          }}
          InputLabelProps={{
            shrink: true,
            focused: false,
          }}
          PopoverProps={{
            anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            transformOrigin: {
              vertical: 'top',
              horizontal: isIconOnStart
                ? POPOVER_OFFSET_START
                : POPOVER_OFFSET_END,
            },
          }}
          leftArrowIcon={<ArrowIcon className={classes.arrowLeft} />}
          rightArrowIcon={<ArrowIcon />}
          format={format}
          disabled={disabled}
          error={error}
          {...rest}
        />
        {error && errorMessage && (
          <FormHelperText
            className={classNames(customErrorClass)}
            error={error}
          >
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    </MuiPickersUtilsProvider>
  );
};

CustomDatePicker.defaultProps = {
  className: '',
  labelClass: undefined,
  placeholder: '',
  errorMessage: '',
  error: false,
  format: 'dd MMM y',
  label: '',
  tooltipInfo: '',
  disabled: false,
  fullWidth: false,
  isHighlightedLabel: false,
  required: false,
  customErrorClass: '',
  value: '',
  isIconOnStart: false,
};

CustomDatePicker.propTypes = {
  className: PropTypes.string,
  labelClass: PropTypes.string,
  label: PropTypes.string,
  tooltipInfo: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  placeholder: PropTypes.string,
  isHighlightedLabel: PropTypes.bool,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
  format: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  customErrorClass: PropTypes.string,
  isIconOnStart: PropTypes.bool,
};

export default CustomDatePicker;
