import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import UserAvatar from '../userAvatar';
import StatusIndicator from '../statusIndicator';
import ActionButton from '../actionButton';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    backgroundColor: primary.bluish9,
    borderRadius: 8,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2, 4),
    width: '100%',
    height: 64,
  },
  employee: {
    flexGrow: 1,
    marginRight: 'auto',
    maxWidth: 500,
  },
  fullName: {
    fontFamily: 'ProximaNova-Bold',
  },
  disenrollButton: {
    marginLeft: spacing(3),
  },
}));

const EnrolledEmployeeCard = ({
  className,
  translations,
  user,
  status,
  statuses,
  isReadOnly,
  isStatusChangeDisabled,
  canOpenPersonProfile,
  onChangeEnrollmentStatus,
  onDisenroll,
  onGoToCourses,
}) => {
  const classes = useStyles();

  const handleDisenroll = e => {
    e.stopPropagation();
    onDisenroll();
  };

  return (
    <div className={classNames(classes.main, className)}>
      <UserAvatar
        className={classes.employee}
        labelClass={classes.fullName}
        user={user}
        captionDescription={
          user.position ? user.position.name : translations.noJobTitle
        }
        onClickHandler={canOpenPersonProfile ? onGoToCourses : () => {}}
        disabled={!canOpenPersonProfile}
        clickableCaption={canOpenPersonProfile}
        caption
        withDescription
        medium
      />
      <StatusIndicator
        status={status}
        statuses={statuses}
        onSelect={onChangeEnrollmentStatus}
        isSelectable={!isReadOnly && !isStatusChangeDisabled}
      />
      {!isReadOnly && (
        <ActionButton
          className={classes.disenrollButton}
          type={ACTION_BUTTON_TYPES.DELETE}
          onClickHandler={handleDisenroll}
        />
      )}
    </div>
  );
};

EnrolledEmployeeCard.defaultProps = {
  className: null,
  isStatusChangeDisabled: false,
};

EnrolledEmployeeCard.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  user: PropTypes.shape({}).isRequired,
  status: PropTypes.shape({}).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isStatusChangeDisabled: PropTypes.bool,
  onChangeEnrollmentStatus: PropTypes.func.isRequired,
  onGoToCourses: PropTypes.func.isRequired,
  onDisenroll: PropTypes.func.isRequired,
};

export default EnrolledEmployeeCard;
