import { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { primaryPalette } from 'styles/theme';
import classNames from 'classnames';
import TextBoxWithTooltip from '../../../textBoxWithTooltip';
import ActionButton from '../../../actionButton';
import { getProgress } from '../../../../../utility/uiUtils';
import { ACTION_BUTTON_TYPES } from '../../../actionButton/config';
import { ReactComponent as SelfIcon } from '../../../../../assets/icons/vector_blue.svg';
import { ReactComponent as PeerIcon } from '../../../../../assets/icons/ellipse_black.svg';
import { ReactComponent as TextReplyIcon } from '../../../../../assets/icons/text_snippet.svg';
import Tooltip from '../../../tooltip';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  body: {
    display: 'grid',
    gridTemplateColumns: '189px 525px 62px 62px 62px',
  },
  attribute: {
    backgroundColor: 'transparent',
    position: 'relative',
    zIndex: 11,
    minWidth: 0,
    width: '100%',
  },
  progress: {
    backgroundColor: primary.bluish8,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: 9,
    maxWidth: '525px',
    minWidth: 0,
    height: 28,
  },
  progressColor: {
    boxSizing: 'border-box',
    position: 'absolute',
    left: 0,
    top: 0,
    height: 28,
    zIndex: 8,
  },
  attributeScore: {
    color: primary.bluish2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  attributeScoreValue: {
    color: primary.bluish2,
    lineHeight: '18.2px',
    marginRight: spacing(1),
  },
  attributeScoreMaxValue: {
    color: primary.bluish2,
    lineHeight: '18.2px',
    marginLeft: spacing(1),
    width: 14.44,
    justifyContent: 'center',
    display: 'flex',
  },
  openEndedAttribute: {
    width: '100%',
    justifyContent: 'right',
    display: 'flex',
    color: primary.bluish2,
    paddingRight: spacing(2),
  },
  attributeIcon: {
    cursor: 'default',
    display: 'flex',
  },
  rangeFromValue: {
    width: 14.44,
    justifyContent: 'center',
    display: 'flex',
  },
  openEnded: {
    justifyContent: 'center',
  },
  noScore: {
    color: primary.bluish2,
  },
  sendIcon: {
    width: 16,
    height: 16,
  },
  selfIcon: {
    position: 'relative',
    top: '-7px',
    width: 14,
    height: 8,
    transform: 'translate(-42%)',
  },
  peerIcon: {
    position: 'relative',
    top: -4,
    width: 10,
    height: 10,
    transform: 'translate(-40%)',
  },
  attributeBlock: {
    display: 'grid',
    marginTop: spacing(0.5),
    marginBottom: spacing(0.5),
    paddingTop: spacing(0.5),
  },
  attributeLabel: {
    lineHeight: '20px',
    color: primary.black,
  },
  attributeLabelBox: {
    alignItems: 'center',
    display: 'flex',
    paddingRight: spacing(2),
  },
  noValue: {
    color: primary.bluish2,
    lineHeight: '18.2px',
  },
  noSelf: {
    paddingTop: 10,
  },
  noPeer: {
    paddingBottom: 10,
  },
}));

const DEFAULT_NO_RESULT = '/';

const AttributesPerformanceSubgroup = ({
  translations,
  attributes,
  hasSendSurveyButton,
  onSendSurvey,
  isSendSurveyDisabled,
  canShowPeer,
  canShowSelf,
}) => {
  const classes = useStyles();

  const renderSendIcon = (attribute, isScoreByOthers) => {
    return (
      <div className={classes.attributeScore}>
        <ActionButton
          iconClass={classes.sendIcon}
          type={ACTION_BUTTON_TYPES.SEND_ENVELOPE}
          tooltipText={translations.sendSurvey}
          isDisabled={isSendSurveyDisabled}
          onClickHandler={onSendSurvey(attribute.id, !isScoreByOthers)}
        />
      </div>
    );
  };

  const hasScoreValue = score =>
    (score && score !== DEFAULT_NO_RESULT) || score === 0;

  const renderScore = (attribute, isScoreByOthers = false) => {
    const {
      id,
      text_reply_questions,
      selfScore,
      scoreByOthers,
      isTextReplyByOthers,
      isTextReplySelf,
    } = attribute;
    const hasScore = isScoreByOthers
      ? scoreByOthers || scoreByOthers === 0
      : selfScore || selfScore === 0;
    const value = isScoreByOthers ? scoreByOthers : selfScore;
    const showOpenEndedIcon =
      (!isScoreByOthers && isTextReplySelf) ||
      (isScoreByOthers && isTextReplyByOthers);

    if (text_reply_questions && showOpenEndedIcon) {
      if (showOpenEndedIcon) {
        return (
          <div
            className={classNames(classes.attributeScore, classes.openEnded)}
          >
            <Tooltip
              text={translations.openEndedAttribute}
              icon={TextReplyIcon}
            />
          </div>
        );
      }
      return renderSendIcon(attribute, isScoreByOthers);
    }

    if (!hasSendSurveyButton || !id || hasScore) {
      return (
        <div className={classes.attributeScore}>
          {hasScore && (
            <Typography
              variant="subtitle2"
              className={classes.attributeScoreValue}
            >
              {value}
            </Typography>
          )}
        </div>
      );
    }
    return renderSendIcon(attribute, isScoreByOthers);
  };

  const renderRange = attribute => {
    return attribute.range_to && !attribute?.text_reply_questions ? (
      <div className={classes.attributeScore}>
        <Typography
          variant="subtitle2"
          className={classNames(
            classes.rangeFromValue,
            classes.attributeScoreValue
          )}
        >
          {attribute.range_from}
        </Typography>
        <Typography variant="subtitle2" className={classes.attributeScore}>
          -
        </Typography>
        <Typography
          variant="subtitle2"
          className={classes.attributeScoreMaxValue}
        >
          {attribute.range_to}
        </Typography>
      </div>
    ) : (
      <div className={classes.attributeScore}>
        <Typography variant="subtitle2" className={classes.noValue}>
          /
        </Typography>{' '}
      </div>
    );
  };

  const renderAttribute = (attribute, index) => {
    const isTextReply = attribute.text_reply_questions;
    const hasRange = attribute.range_to;
    const hasShowSelf =
      hasScoreValue(attribute.selfScore) && canShowSelf && !isTextReply;
    const hasShowPeer =
      hasScoreValue(attribute.scoreByOthers) && canShowPeer && !isTextReply;

    return (
      <div
        key={`attribute_basic_item_${index}`}
        className={classNames(classes.attributeBlock, {
          [classes.noSelf]: !hasShowSelf,
          [classes.noPeer]: !hasShowPeer,
        })}
      >
        {hasShowSelf ? (
          <div
            className={classes.selfIcon}
            style={{
              left: getProgress(attribute.selfScore),
            }}
          >
            <Tooltip text={translations.self} icon={SelfIcon} />
          </div>
        ) : null}
        <div className={classes.progress}>
          <div
            className={classes.progressColor}
            style={{
              backgroundColor: isTextReply ? 'none' : attribute.color,
              width: '100%',
              border: isTextReply ? `4px solid ${attribute.color}` : 'none',
            }}
          />
          {hasRange && !isTextReply ? (
            <div
              className={classes.progressColor}
              style={{
                backgroundColor: attribute.color,
                filter: 'brightness(80%)',
                width: `${(attribute.range_to - attribute.range_from) * 10}%`,
                left: getProgress(attribute.range_from),
              }}
            />
          ) : null}
          {hasShowSelf ? (
            <div
              className={classes.progressColor}
              style={{
                border: isTextReply ? 'none' : `dotted ${primaryPalette.blue1}`,
                borderWidth: isTextReply ? 0 : '0px 2px 0px 0px',
                left: getProgress(attribute.selfScore),
              }}
            />
          ) : null}
          {hasShowPeer ? (
            <div
              className={classes.progressColor}
              style={{
                border: isTextReply ? 'none' : `dotted ${primaryPalette.black}`,
                borderWidth: isTextReply ? 0 : '0px 2px 0px 0px',
                left: getProgress(attribute.scoreByOthers),
              }}
            />
          ) : null}
          {isTextReply ? (
            <div className={classes.openEndedAttribute}>
              <Tooltip
                customIconClass={classes.attributeIcon}
                text={translations.openEndedAttribute}
                icon={TextReplyIcon}
              />
            </div>
          ) : null}
        </div>
        {hasShowPeer ? (
          <div
            className={classes.peerIcon}
            style={{
              left: getProgress(attribute.scoreByOthers),
            }}
          >
            <Tooltip text={translations.extrinsic} icon={PeerIcon} />
          </div>
        ) : null}
      </div>
    );
  };

  return attributes.map((attribute, index) => {
    return (
      <div className={classes.body} key={`attribute_basic_item_${index}`}>
        <div className={classes.attributeLabelBox}>
          {attribute.id && (
            <TextBoxWithTooltip
              variant="subtitle2"
              className={classes.attributeLabel}
              text={attribute.name}
            />
          )}
        </div>
        {renderAttribute(attribute, index)}
        {renderScore(attribute)}
        {renderScore(attribute, true)}
        {renderRange(attribute)}
      </div>
    );
  });
};

AttributesPerformanceSubgroup.defaultProps = {
  isSendSurveyDisabled: false,
};

AttributesPerformanceSubgroup.propTypes = {
  translations: PropTypes.object.isRequired,
  attributes: PropTypes.array.isRequired,
  hasSendSurveyButton: PropTypes.bool.isRequired,
  isSendSurveyDisabled: PropTypes.bool,
  onSendSurvey: PropTypes.func.isRequired,
  canShowPeer: PropTypes.bool.isRequired,
  canShowSelf: PropTypes.bool.isRequired,
};

export default memo(AttributesPerformanceSubgroup);
