import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import RichTextPreview from 'components/shared/richTextPreview';
import AddComment from '../../addComment';
import DeletedUserAvatar from '../../deletedUserAvatar';
import UserAvatar from '../../userAvatar';
import ActionButton from '../../actionButton';
import { getPersonFullName } from '../../../../utility/uiUtils';
import {
  areEqualInMilliseconds,
  formatDate,
} from '../../../../utility/dateUtils';
import { checkUserRole } from '../../../../utility/helpers';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';
import { ONE_ON_ONE_COMMENT_FIELDS } from '../../../../constants/oneOnOne';
import { ROLES } from '../../../../constants/rolesAndPermissionList';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  itemWrapper: {
    display: 'flex',
    marginBottom: spacing(8),
  },
  contentWrapper: {
    marginLeft: spacing(4),
  },
  content: {
    width: '100%',
  },
  timestamp: {
    color: primary.bluish4,
    marginTop: spacing(3),
    display: 'flex',
    '& > span': {
      fontFamily: 'ProximaNova-Bold',
      marginLeft: spacing(2),
    },
  },
  user: {
    marginLeft: 6,
    '&:nth-of-type(1)': {
      marginLeft: 0,
    },
    '&:nth-of-type(2)': {
      marginRight: 0,
    },
  },
  bold: {
    fontFamily: 'ProximaNova-Bold',
  },
  comment: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  commentActionBlock: {
    display: 'flex',
  },
}));

const DATE_FORMAT = 'dd MMM y';
const TIME_FORMAT = 'HH:mm a';

const CommentItem = ({
  translations,
  currentUser,
  comment,
  isEdit,
  canEditComment,
  isFocused,
  isRichText,
  handleFocus,
  onSendComment,
  closeEditComment,
  openEditComment,
  onCommentDelete,
}) => {
  const classes = useStyles();

  const { CREATOR, CREATED_AT } = ONE_ON_ONE_COMMENT_FIELDS;
  const isAdmin = checkUserRole(currentUser.role, ROLES.ADMIN);
  const editedCommentLabel = areEqualInMilliseconds(
    comment.modified_at,
    comment.created_at
  )
    ? translations.edited
    : '';
  const date = formatDate(comment[CREATED_AT], DATE_FORMAT);
  const time = formatDate(comment[CREATED_AT], TIME_FORMAT);

  const handleDeleteComment = commentId => () => onCommentDelete(commentId);

  const handleEditComment = commentId => () => {
    handleFocus(true);
    return openEditComment(commentId);
  };

  const renderUser = () => (
    <Typography
      component="span"
      className={classNames(classes.user, classes.bold)}
    >
      {getPersonFullName(comment[CREATOR])}
    </Typography>
  );

  const renderTimestamp = () => (
    <Typography className={classes.timestamp} variant="caption">
      {`${date} ${translations.at} ${time}`}
      {editedCommentLabel && <span>{editedCommentLabel}</span>}
    </Typography>
  );

  const renderContent = () => (
    <div className={classes.comment}>
      <RichTextPreview text={comment.comment} />
      <div className={classes.commentActionBlock}>
        {canEditComment && (
          <ActionButton
            type={ACTION_BUTTON_TYPES.EDIT}
            onClickHandler={handleEditComment(comment.id)}
          />
        )}
        {canEditComment || isAdmin ? (
          <ActionButton
            type={ACTION_BUTTON_TYPES.DELETE_FILL}
            onClickHandler={handleDeleteComment(comment.id)}
          />
        ) : null}
      </div>
    </div>
  );

  return (
    <>
      {isEdit ? (
        <AddComment
          translations={translations.addComment}
          currentUser={currentUser}
          onSendComment={onSendComment}
          comment={comment}
          closeEditComment={closeEditComment}
          isRichText={isRichText}
          handleFocus={handleFocus}
          isFocused={isFocused}
          isEdit
        />
      ) : (
        <div className={classes.itemWrapper}>
          {comment.creator ? (
            <UserAvatar user={comment.creator} medium />
          ) : (
            <DeletedUserAvatar
              tooltipText={translations.removedUserTooltip}
              isMedium
            />
          )}
          <div className={classNames(classes.content, classes.contentWrapper)}>
            {renderUser()}
            <span>{renderContent()} </span>
            {renderTimestamp()}
          </div>
        </div>
      )}
    </>
  );
};

CommentItem.defaultProps = {
  isEdit: undefined,
  isRichText: false,
  isFocused: false,
  closeEditComment: () => {},
  handleFocus: () => {},
};

CommentItem.propTypes = {
  translations: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  comment: PropTypes.shape({}).isRequired,
  isRichText: PropTypes.bool,
  isEdit: PropTypes.bool,
  isFocused: PropTypes.bool,
  canEditComment: PropTypes.bool.isRequired,
  onSendComment: PropTypes.func.isRequired,
  onCommentDelete: PropTypes.func.isRequired,
  openEditComment: PropTypes.func.isRequired,
  closeEditComment: PropTypes.func,
  handleFocus: PropTypes.func,
};

export default CommentItem;
