import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionGroup } from 'react-transition-group';
import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-blue.svg';
import { ReactComponent as ClearCategoryIcon } from '../../../../assets/icons/close-orange.svg';
import { isArrayEmpty } from '../../../../utility/helpers';
import { hasSelectedFilters } from '../config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: spacing(4),
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(4),
  },
  label: {
    lineHeight: '24px',
    marginRight: spacing(2),
  },
  countWrapper: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  count: {
    cursor: 'pointer',
    backgroundColor: primary.orange,
    borderRadius: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '18px',
    color: primary.white,
    margin: 0,
    width: 24,
    minWidth: 24,
    height: 24,
    userSelect: 'none',
    '&.fade-enter': {
      opacity: 0,
    },
    '&.fade-exit': {
      opacity: 1,
    },
    '&.fade-enter-active': {
      opacity: 1,
    },
    '&.fade-exit-active': {
      opacity: 0,
    },
    '&.fade-enter-active, &.fade-exit-active': {
      transition: 'opacity 500ms',
    },
  },
  clearCategoryIcon: {
    width: 24,
    height: 24,
  },
  clearFiltersWrapper: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    padding: spacing(1),
    marginLeft: spacing(-1),
    userSelect: 'none',
    position: 'relative',
  },
  clearFiltersLabel: {
    color: primary.blue1,
  },
  touchRipple: {
    backgroundColor: primary.blue1,
  },
}));

const SelectedFilters = ({
  translations,
  filters,
  selectedFilters,
  onClearCategory,
  onClear,
}) => {
  const classes = useStyles();
  const touchRippleRef = useRef(null);

  const [activeCategory, setActiveCategory] = useState('');

  const handleActivateCategory = category => () => setActiveCategory(category);

  const handleDeactivateCategory = () => setActiveCategory('');

  const onRippleStart = e => {
    touchRippleRef?.current?.start(e);
  };

  const onRippleStop = e => {
    touchRippleRef?.current?.stop(e);
  };

  return (
    <TransitionGroup className={classes.main}>
      {filters.map(filter => {
        return (
          selectedFilters[filter.id] &&
          !isArrayEmpty(selectedFilters[filter.id]) && (
            <Fade key={filter.id} unmountOnExit>
              <div className={classes.filter}>
                <Typography className={classes.label} variant="subtitle2">
                  {filter.name}
                </Typography>
                <Typography
                  className={classNames(classes.label, classes.count)}
                  variant="subtitle2"
                  component="div"
                  onMouseEnter={handleActivateCategory(filter.id)}
                  onMouseLeave={handleDeactivateCategory}
                  onClick={() => onClearCategory(filter.id)}
                >
                  {activeCategory === filter.id ? (
                    <ClearCategoryIcon
                      className={classes.clearCategoryIcon}
                      onClick={() => onClearCategory(filter.id)}
                    />
                  ) : (
                    selectedFilters[filter.id].length
                  )}
                </Typography>
              </div>
            </Fade>
          )
        );
      })}
      {hasSelectedFilters(selectedFilters) && (
        <Fade unmountOnExit>
          <div
            className={classes.clearFiltersWrapper}
            onMouseDown={onRippleStart}
            onMouseUp={onRippleStop}
            onClick={onClear}
          >
            <Typography
              className={classNames(classes.label, classes.clearFiltersLabel)}
              variant="subtitle2"
            >
              {translations.clearAll}
            </Typography>
            <CloseIcon />
            <TouchRipple
              ref={touchRippleRef}
              classes={{ child: classes.touchRipple }}
            />
          </div>
        </Fade>
      )}
    </TransitionGroup>
  );
};

SelectedFilters.propTypes = {
  translations: PropTypes.object.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedFilters: PropTypes.shape({}).isRequired,
  onClearCategory: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default SelectedFilters;
