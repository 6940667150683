import { createAction, handleActions } from 'redux-actions';
import { setDialogVisibility } from './dialogs';
import {
  orderByPage,
  removeObjectFromArray,
  replaceObjectInList,
} from '../../utility/helpers';
import http from '../../utility/http';
import { isSubscriptionExpiredCheck } from '../../utility/subscriptionHelper';
import {
  API_ORGANIZATION_SETTINGS,
  API_PRESETS,
  api_page_tag_categories,
} from '../../constants/apiRoutes';
import { PLACEMENTS } from '../../constants/tags';

// ------------------------------------
// Constants
// ------------------------------------

const SET_ORGANIZATION_SETTINGS = 'SET_ORGANIZATION_SETTINGS';
const SET_ORGANIZATION_MENU_SETUP = 'SET_ORGANIZATION_MENU_SETUP';
const EDIT_ORGANIZATION_MENU_SETUP = 'EDIT_ORGANIZATION_MENU_SETUP';
const DELETE_ORGANIZATION_MENU_SETUP = 'DELETE_ORGANIZATION_MENU_SETUP';
const SET_PRESETS = 'SET_PRESETS';
const ADD_PRESET = 'ADD_PRESET';
const DELETE_PRESET = 'DELETE_PRESET';
const SET_ACTIVE_PRESET = 'SET_ACTIVE_PRESET';

const initialState = {
  organizationSettings: {},
  organizationMenuSetup: [],
  presets: {},
};

// ------------------------------------
// Actions
// ------------------------------------

export const setOrganizationSettings = createAction(SET_ORGANIZATION_SETTINGS);
const setOrganizationMenuSetup = createAction(SET_ORGANIZATION_MENU_SETUP);
const setPresets = createAction(SET_PRESETS);
const addPreset = createAction(ADD_PRESET);
const deletePreset = createAction(DELETE_PRESET);
const setActivePreset = createAction(SET_ACTIVE_PRESET);
export const editOrganizationMenuSetup = createAction(
  EDIT_ORGANIZATION_MENU_SETUP
);
export const deleteOrganizationMenuSetup = createAction(
  DELETE_ORGANIZATION_MENU_SETUP
);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_ORGANIZATION_SETTINGS]: (state, { payload }) => {
    return {
      ...state,
      organizationSettings: { ...state.organizationSettings, ...payload },
    };
  },
  [SET_ORGANIZATION_MENU_SETUP]: (state, { payload }) => {
    return {
      ...state,
      organizationMenuSetup: payload,
    };
  },
  [SET_PRESETS]: (state, { payload }) => {
    return {
      ...state,
      presets: orderByPage(payload),
    };
  },
  [ADD_PRESET]: (state, { payload }) => {
    const { presets } = state;
    const { data } = payload;
    const presetLocation = data.location;

    return {
      ...state,
      presets: {
        ...presets,
        [presetLocation]: presets[presetLocation]
          ? [data].concat(presets[presetLocation])
          : [data],
        active: { ...presets.active, [presetLocation]: data.id },
      },
    };
  },
  [DELETE_PRESET]: (state, { payload }) => {
    const { presets } = state;
    const { presetLocation } = payload;
    const filteredPresets = {
      ...presets,
      [presetLocation]: removeObjectFromArray(presets[presetLocation], payload),
    };

    return { ...state, presets: filteredPresets };
  },
  [SET_ACTIVE_PRESET]: (state, { payload }) => {
    const { presets } = state;
    const { id, locationId, isActive } = payload;
    const activePreset = isActive ? id : -1;

    return {
      ...state,
      presets: {
        ...presets,
        active: { ...presets.active, [locationId]: activePreset },
      },
    };
  },
  [EDIT_ORGANIZATION_MENU_SETUP]: (state, { payload }) => {
    const { organizationMenuSetup } = state;
    const index = organizationMenuSetup.findIndex(
      category => category.id === payload.id
    );
    const organizationMenuCategories =
      index === -1
        ? organizationMenuSetup.concat(payload)
        : replaceObjectInList(organizationMenuSetup, index, payload);

    return { ...state, organizationMenuSetup: organizationMenuCategories };
  },
  [DELETE_ORGANIZATION_MENU_SETUP]: (state, { payload }) => {
    const { organizationMenuSetup } = state;
    const organizationMenuCategories = organizationMenuSetup.filter(
      category => category.id !== payload.id
    );

    return { ...state, organizationMenuSetup: organizationMenuCategories };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getAllPresets = dispatch => {
  return http.get(API_PRESETS).then(({ data }) => {
    dispatch(setPresets(data));
  });
};

export const getOrganizationSettings = async dispatch => {
  const { data } = await http.get(API_ORGANIZATION_SETTINGS);
  const isSubscriptionExpired = isSubscriptionExpiredCheck(data);

  if (isSubscriptionExpired) {
    dispatch(
      setDialogVisibility({
        dialogName: 'upgradeRequiredDialog',
        opened: true,
      })
    );
  }

  dispatch(setOrganizationSettings(data));

  return Promise.resolve(isSubscriptionExpired);
};

export const getOrganizationMenuSetup = dispatch => {
  return http
    .get(api_page_tag_categories(PLACEMENTS.ORGANIZATION_MENU.id))
    .then(({ data }) => {
      dispatch(setOrganizationMenuSetup(data));
    });
};

export const addNewPreset = (dispatch, params) => {
  return http.post(API_PRESETS, params).then(data => {
    dispatch(addPreset(data));
  });
};

export const deleteSelectedPreset = (dispatch, presetId, locationId) => {
  return http.delete(`${API_PRESETS}${presetId}/`).then(() => {
    dispatch(deletePreset({ id: presetId, presetLocation: locationId }));
  });
};

export const editPresetActivity = (
  dispatch,
  presetId,
  locationId,
  isActive
) => {
  dispatch(setActivePreset({ id: presetId, locationId, isActive }));
};

export const updateOrganizationSettings = (dispatch, params) => {
  return http.post(API_ORGANIZATION_SETTINGS, params).then(({ data }) => {
    dispatch(setOrganizationSettings(data));
  });
};

export default handleActions(reducers, initialState);
