import stringToColor from 'string-to-color';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import ArrowIcon from '../../../../assets/icons/arrow-down-blue.svg';
import OrganizationIcon from '../../../../assets/icons/apartment.svg';
import {
  getInitials,
  truncateText,
  getPersonFullName,
} from '../../../../utility/uiUtils';
import { isArrayEmpty } from '../../../../utility/helpers';
import { primaryPalette } from '../../../../styles/theme';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  employeeNodeContainer: {
    cursor: 'default',
  },
  nodeWrapper: {
    fill: primary.bluish8,
    strokeWidth: 0,
  },
  nodeWrapperTop: {
    fill: primary.blue6,
  },
  clickableItem: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  directReportsCount: {
    fontFamily: 'ProximaNova-Regular',
    fill: primary.bluish2,
    fontSize: '14px',
    strokeWidth: 0,
    cursor: 'pointer',
  },
  employeeText: {
    fill: primary.bluish2,
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '14px',
    lineHeight: '18px',
    strokeWidth: 0,
    '-webkit-font-smoothing': 'antialiased',
  },
  position: {
    fill: primary.bluish5,
    fontFamily: 'ProximaNova-Regular',
    fontSize: '12px',
    lineHeight: '14px',
    wordBreak: 'break-word',
    strokeWidth: 0,
    '-webkit-font-smoothing': 'antialiased',
  },
  avatarInitials: {
    fontFamily: 'ProximaNova-Regular',
    fontSize: '14px',
    strokeWidth: 0,
    textTransform: 'uppercase',
  },
  directReportsContainer: {
    fill: primary.bluish8,
    strokeWidth: '1px',
    stroke: primary.blue1,
    cursor: 'pointer',
  },
}));

const ChartItem = ({ node, triggerNodeToggle, getChild, onGoToProfile }) => {
  const classes = useStyles();
  const { person } = node;

  const isOrganizationUser = person.report_to === null;
  const hasAvatar = person.avatar !== null || person.slack_image !== null;

  const handleChildrenToggle = () => {
    if (!isArrayEmpty(node.children)) {
      triggerNodeToggle();
    } else {
      getChild(node);
    }
  };

  const handleGoToProfile = () => {
    if (person.hasAccessToProfile && person.report_to !== null) {
      onGoToProfile();
    }
  };

  const getBackgroundColor = () => {
    if (person.report_to === null) {
      return primaryPalette.white;
    } else if (person.avatar !== null) {
      return primaryPalette.blue6;
    }
    return stringToColor(person.id);
  };

  return (
    <g
      className={classes.employeeNodeContainer}
      id={`employee_${person.id}`}
      transform="translate(-95, -50)"
    >
      <defs>
        <clipPath id="photo">
          <circle cx="30px" cy="30px" r="18px" />
        </clipPath>
      </defs>
      <rect
        className={classNames(classes.nodeWrapper, {
          [classes.nodeWrapperTop]: person.report_to === null,
        })}
        rx="4px"
        ry="4px"
        width="186"
        height="120"
      />
      <g className="Employee">
        <g
          transform="translate(65, -30)"
          onClick={handleGoToProfile}
          className={classNames({
            [classes.clickableItem]:
              person.hasAccessToProfile && person.report_to !== null,
          })}
        >
          <circle
            fill={getBackgroundColor(person)}
            pointerEvents="all"
            stroke={primaryPalette.blue1}
            strokeWidth={isOrganizationUser ? 1 : 0}
            cx="30px"
            cy="30px"
            r="18px"
          />
          {!hasAvatar && person.report_to !== null ? (
            <text
              className={classes.avatarInitials}
              fill={primaryPalette.white}
              textAnchor="middle"
              width="32px"
              height="32px"
              x="30"
              y="34"
            >
              {getInitials(person.first_name, person.last_name)}
            </text>
          ) : null}
          {hasAvatar && person.report_to !== null ? (
            <image
              x="12"
              y="12"
              clipPath="url(#photo)"
              height="36px"
              preserveAspectRatio="xMidYMid slice"
              width="36px"
              xlinkHref={person.avatar || person.slack_image}
            />
          ) : null}
          {isOrganizationUser ? (
            <image
              x="18"
              y="18"
              preserveAspectRatio="xMidYMid meet"
              xlinkHref={OrganizationIcon}
            />
          ) : null}
        </g>
        <text
          onClick={handleGoToProfile}
          className={classNames(classes.employeeText, {
            [classes.clickableItem]:
              person.hasAccessToProfile && person.report_to !== null,
          })}
          textAnchor="middle"
          x="93"
          y="50"
        >
          {truncateText(getPersonFullName(person), 24)}
        </text>
        <text className={classes.position} textAnchor="middle" x="93" y="70">
          {person.title ? truncateText(person.title, 30) : ''}
        </text>
      </g>
      {node.hasChild ? (
        <g className="DirectReportsControls" onClick={handleChildrenToggle}>
          <rect
            className={classes.directReportsContainer}
            rx="4px"
            ry="4px"
            width="52px"
            height="24px"
            x="70"
            y="107"
          />
          <image
            className={classes.clickableItem}
            x="105"
            y="115"
            width="10px"
            height="8px"
            preserveAspectRatio="xMidYMid meet"
            xlinkHref={ArrowIcon}
            {...(!node.__rd3t.collapsed && !isArrayEmpty(node.children)
              ? { transform: 'rotate(180, 110, 119)' }
              : {})}
          />
          <text
            className={classes.directReportsCount}
            textAnchor="middle"
            x="90"
            y="123"
          >
            {person.totalReports}
          </text>
        </g>
      ) : null}
    </g>
  );
};

export default ChartItem;
