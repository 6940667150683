import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import InputField from '../inputField';
import CustomButton from '../customButton';
import CustomCheckbox from '../customCheckbox';
import Tooltip from '../tooltip';
import { objectHasProperty } from '../../../utility/helpers';
import fields from './fields';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    maxWidth: 900,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 460,
  },
  field: {
    position: 'relative',
  },
  input: {
    marginBottom: spacing(6),
  },
  inputErrorBorder: {
    border: `1px solid ${primary.red2}`,
  },
  errorMessage: {
    position: 'absolute',
    bottom: 4,
  },
  numberInput: {
    maxWidth: 70,
  },
  save: {
    marginLeft: 'auto',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(3),
  },
  checkboxTextLabel: {
    flexShrink: 0,
    width: '100%',
    maxWidth: 'calc(100% - 24px)',
  },
  helpTooltip: {
    marginTop: spacing(1),
    marginLeft: spacing(2),
  },
}));

const INPUT_TYPE_NUMBER = 'number';
const GLOBAL_TRANSPARENCY = 'global_see_himself';
const LEVELING_TRANSPARENCY = 'users_see_leveling';
const JOB_TITLES_TRANSPARENCY = 'users_see_positions';
const COMPETENCE_MAP_TRANSPARENCY = 'mods_see_competence';

const OrganizationSettingsFormLayout = ({
  translations,
  values,
  errors,
  isValid,
  handleSubmit,

  handleChange,
  handleBlur,

  setFieldValue,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <div>
          {fields.map(field => {
            const hasError = objectHasProperty(errors, field.name);
            const inputClasses = classNames(classes.input, {
              [classes.inputErrorBorder]: hasError,
              [classes.numberInput]: field.type === INPUT_TYPE_NUMBER,
            });
            return (
              <div key={field.name} className={classes.field}>
                <InputField
                  className={inputClasses}
                  customErrorClass={classes.errorMessage}
                  type={field.type}
                  name={field.name}
                  label={translations[field.label]}
                  value={values[field.name] || field.defaultValue}
                  inputProps={field.inputProps}
                  error={hasError}
                  errorMessage={errors[field.name]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </div>
            );
          })}
          <div className={classes.section}>
            <CustomCheckbox
              labelTextClass={classes.checkboxTextLabel}
              labelText={translations.globalTransparency.label}
              isChecked={values[GLOBAL_TRANSPARENCY]}
              onChange={() => {
                setFieldValue(
                  GLOBAL_TRANSPARENCY,
                  !values[GLOBAL_TRANSPARENCY]
                );
              }}
              shouldTopAlign
            />
            <Tooltip
              className={classes.helpTooltip}
              text={translations.globalTransparency.description}
            />
          </div>
          <div className={classes.section}>
            <CustomCheckbox
              labelTextClass={classes.checkboxTextLabel}
              labelText={translations.levelingTransparency.label}
              isChecked={values[LEVELING_TRANSPARENCY]}
              onChange={() => {
                setFieldValue(
                  LEVELING_TRANSPARENCY,
                  !values[LEVELING_TRANSPARENCY]
                );
              }}
              shouldTopAlign
            />
            <Tooltip
              className={classes.helpTooltip}
              text={translations.levelingTransparency.description}
            />
          </div>
          <div className={classes.section}>
            <CustomCheckbox
              labelTextClass={classes.checkboxTextLabel}
              labelText={translations.jobTitlesTransparency.label}
              isChecked={values[JOB_TITLES_TRANSPARENCY]}
              onChange={() => {
                setFieldValue(
                  JOB_TITLES_TRANSPARENCY,
                  !values[JOB_TITLES_TRANSPARENCY]
                );
              }}
              shouldTopAlign
            />
            <Tooltip
              className={classes.helpTooltip}
              text={translations.jobTitlesTransparency.description}
            />
          </div>
          <div className={classes.section}>
            <CustomCheckbox
              labelTextClass={classes.checkboxTextLabel}
              labelText={translations.competenceTransparency.label}
              isChecked={values[COMPETENCE_MAP_TRANSPARENCY]}
              onChange={() => {
                setFieldValue(
                  COMPETENCE_MAP_TRANSPARENCY,
                  !values[COMPETENCE_MAP_TRANSPARENCY]
                );
              }}
              shouldTopAlign
            />
            <Tooltip
              className={classes.helpTooltip}
              text={translations.competenceTransparency.description}
            />
          </div>
        </div>
        <CustomButton
          submit
          type="small"
          disabled={!isValid}
          className={classes.save}
        >
          {translations.submit}
        </CustomButton>
      </form>
    </div>
  );
};

OrganizationSettingsFormLayout.propTypes = {
  translations: PropTypes.object.isRequired,
};

export default OrganizationSettingsFormLayout;
