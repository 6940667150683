import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'react-scrollbars-custom';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  scrollBar: {
    width: '100%',
    height: '100%',
  },
  scrollBarWrapper: {
    width: '100%',
    height: '100%',
  },
  scroller: {
    position: 'absolute',
    overflow: 'scroll',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  scrollBarScroll: {
    position: 'absolute',
    overflow: 'hidden',
    borderRadius: 4,
    background: 'rgba(0, 0, 0, 0.1)',
    userSelect: 'none',
    backgroundColor: 'rgb(201,212,218, .44)',
  },
  scrollBarScrollX: {
    left: 3,
    bottom: -20,
    width: 'calc(100% - 3px)',
    height: 6,
  },
  scrollBarScrollY: {
    top: 3,
    right: 0,
    height: 'calc(100% - 3px)',
    width: 6,
  },
  scroll: {
    backgroundColor: `${primary.blue2}`,
    cursor: 'pointer',
    borderRadius: 4,
    position: 'absolute',
  },
  scrollX: {
    height: '100%',
  },
  scrollY: {
    width: '100%',
  },
}));

const CustomScrollBar = forwardRef(
  (
    {
      customRootClass,
      customWrapperClass,
      customContentClass,
      customScrollerClass,
      customScrollClass,
      customScrollBarYClass,
      customScrollBarXClass,
      children,
      scrollLeft,
      scrollTop,
      scrollBarHeight,
      verticalScroll,
      customScrollXWidth,
      passContentHeight,
      passContentWidth,
      removeScrollX,
      handleScrollPositionChange,
      handleScrollStop,
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <Scrollbar
        ref={ref}
        style={{ height: scrollBarHeight }}
        noDefaultStyles
        disableTracksWidthCompensation
        translateContentSizeYToHolder={passContentHeight}
        translateContentSizeXToHolder={passContentWidth}
        scrollLeft={scrollLeft}
        scrollTop={scrollTop}
        noScrollY={!verticalScroll}
        noScrollX={removeScrollX}
        removeTracksWhenNotUsed
        onScroll={values => {
          handleScrollPositionChange(values);
        }}
        onScrollStop={values => {
          handleScrollStop(values);
        }}
        renderer={props => {
          const { elementRef, ...restProps } = props;
          return (
            <div
              {...restProps}
              ref={elementRef}
              className={classNames(classes.scrollBar, customRootClass)}
            />
          );
        }}
        wrapperProps={{
          renderer: props => {
            const { elementRef, ...restProps } = props;
            return (
              <div
                {...restProps}
                ref={elementRef}
                className={classNames(
                  classes.scrollBarWrapper,
                  customWrapperClass
                )}
              />
            );
          },
        }}
        scrollerProps={{
          renderer: props => {
            const { elementRef, style, ...restProps } = props;
            return (
              <div
                {...restProps}
                ref={elementRef}
                className={classNames(classes.scroller, customScrollerClass)}
              />
            );
          },
        }}
        contentProps={{
          renderer: props => {
            const { elementRef, ...restProps } = props;
            return (
              <div
                {...restProps}
                style={{ display: 'block' }}
                ref={elementRef}
                className={customContentClass}
              />
            );
          },
        }}
        trackXProps={{
          renderer: props => {
            const { elementRef, ...restProps } = props;
            return (
              <div
                {...restProps}
                ref={elementRef}
                style={{ width: customScrollXWidth }}
                className={classNames(
                  classes.scrollBarScroll,
                  classes.scrollBarScrollX,
                  customScrollBarXClass
                )}
              />
            );
          },
        }}
        trackYProps={{
          renderer: props => {
            const { elementRef, ...restProps } = props;
            return (
              <div
                {...restProps}
                ref={elementRef}
                className={classNames(
                  classes.scrollBarScroll,
                  classes.scrollBarScrollY,
                  customScrollBarYClass
                )}
              />
            );
          },
        }}
        thumbXProps={{
          renderer: props => {
            const { elementRef, ...restProps } = props;
            return (
              <div
                {...restProps}
                ref={elementRef}
                className={classNames(
                  classes.scroll,
                  classes.scrollX,
                  customScrollClass
                )}
              />
            );
          },
        }}
        thumbYProps={{
          renderer: props => {
            const { elementRef, ...restProps } = props;
            return (
              <div
                {...restProps}
                ref={elementRef}
                className={classNames(
                  classes.scroll,
                  classes.scrollY,
                  customScrollClass
                )}
              />
            );
          },
        }}
      >
        {children}
      </Scrollbar>
    );
  }
);

CustomScrollBar.displayName = 'CustomScrollBar';

CustomScrollBar.defaultProps = {
  customRootClass: '',
  customWrapperClass: '',
  customScrollerClass: '',
  customScrollClass: '',
  customScrollBarXClass: '',
  customScrollBarYClass: '',
  passContentHeight: false,
  passContentWidth: false,
  scrollLeft: 0,
  scrollTop: undefined,
  verticalScroll: false,
  scrollBarHeight: undefined,
  removeScrollX: false,
  customScrollXWidth: undefined,
  handleScrollStop: () => {},
  handleScrollPositionChange: () => {},
};

CustomScrollBar.propTypes = {
  customRootClass: PropTypes.string,
  customWrapperClass: PropTypes.string,
  customScrollerClass: PropTypes.string,
  customScrollBarXClass: PropTypes.string,
  customScrollBarYClass: PropTypes.string,
  customScrollClass: PropTypes.string,
  children: PropTypes.element.isRequired,
  passContentHeight: PropTypes.bool,
  passContentWidth: PropTypes.bool,
  scrollLeft: PropTypes.number,
  scrollTop: PropTypes.number,
  verticalScroll: PropTypes.bool,
  scrollBarHeight: PropTypes.number,
  handleScrollPositionChange: PropTypes.func,
  removeScrollX: PropTypes.bool,
  customScrollXWidth: PropTypes.string,
  handleScrollStop: PropTypes.func,
};

export default CustomScrollBar;
