import {
  forbiddenCharacters,
  validateLength,
} from '../../../utility/validation';

export const DELAY_VALIDATION = 300;

export const commentField = (isRichText = false) => {
  const maxLength = isRichText ? 20000 : 1000;

  return {
    name: 'comment',
    type: 'text',
    translationKey: 'comment',
    validators: [
      ...(isRichText
        ? []
        : [
            {
              type: 'forbiddenCharacters',
              validator: forbiddenCharacters,
            },
          ]),
      {
        type: 'maxLength',
        validator: value => validateLength(value, 0, maxLength),
      },
    ],
  };
};
