import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import AsyncListTooltip from '../asyncListTooltip';

import { ReactComponent as NewTooltipIcon } from '../../../assets/icons/info.svg';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  icon: {
    marginLeft: spacing(1),
    marginRight: spacing(2),
  },
  tooltip: {
    maxWidth: 402,
    width: 402,
    height: 390,
    maxHeight: 390,
    boxShadow: 'none',
    border: `1px solid ${primary.bluish5}`,
    borderRadius: 8,
    left: 51,
  },
  userRoleTooltip: {
    left: 173,
  },
  moderatorRoleTooltip: {
    left: 98,
  },
  adminRoleTooltip: {
    left: 19,
  },
  associateRoleTooltip: {
    left: -84,
  },
  tooltipLabel: {
    maxHeight: 300,
    height: 300,
    padding: 0,
    marginBottom: 0,
    '& ul': {
      paddingLeft: spacing(8),
      paddingTop: spacing(4),
      margin: 0,
    },
  },
  tooltipHeader: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const RolesPermissionsTooltip = ({ role, roleData, title, order }) => {
  const classes = useStyles();
  const USER_ROLE_TOOLTIP = 1;
  const MODERATOR_ROLE_TOOLTIP = 2;
  const ADMIN_ROLE_TOOLTIP = 3;
  const ASSOCIATE_ROLE_TOOLTIP = 4;

  const renderContent = items => {
    return (
      <Typography component="div" variant="body2">
        <ul>
          {items?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </Typography>
    );
  };

  return (
    <AsyncListTooltip
      labelData={roleData}
      renderLabelItem={renderContent}
      title={title}
      customTooltipClass={classNames(classes.tooltip, {
        [classes.userRoleTooltip]: order === USER_ROLE_TOOLTIP,
        [classes.moderatorRoleTooltip]: order === MODERATOR_ROLE_TOOLTIP,
        [classes.adminRoleTooltip]: order === ADMIN_ROLE_TOOLTIP,
        [classes.associateRoleTooltip]: order === ASSOCIATE_ROLE_TOOLTIP,
      })}
      customTooltipLabelClass={classes.tooltipLabel}
      isDisabled
      isArrowHidden
      isCustomContent
    >
      <Typography variant="body2" className={classes.tooltipHeader}>
        {role}
        <NewTooltipIcon className={classes.icon} />
      </Typography>
    </AsyncListTooltip>
  );
};

RolesPermissionsTooltip.propTypes = {
  role: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  roleData: PropTypes.array.isRequired,
  order: PropTypes.number.isRequired,
};

export default RolesPermissionsTooltip;
