import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormHelperText, makeStyles } from '@material-ui/core';
import ToggleButtonItem from './toggleButtonItem';
import { isArrayEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  labelContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: spacing(2),
  },
  label: {
    marginBottom: 0,
    marginRight: spacing(1),
  },
  buttons: { display: 'flex' },
  icon: {
    cursor: 'pointer',
  },
  fullWidth: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: 10,
    backgroundColor: primary.bluish8,
    boxSizing: 'border-box',
    borderRadius: 8,
    width: '100%',
    padding: spacing(2, 3),
  },
}));

const ToggleButtonSwitch = ({
  className,
  customLabelClass,
  label,
  items,
  value,
  isDisabled,
  isFullWidth,
  onSelect,
}) => {
  const classes = useStyles();
  const MAX_ITEMS = 2;

  const handleSelect = newValue => {
    if (newValue !== value) {
      onSelect(newValue);
    }
  };

  return (
    <div className={className}>
      {label && (
        <div className={classes.labelContainer}>
          <FormHelperText
            classes={{ root: classNames(classes.label, customLabelClass) }}
          >
            {label}
          </FormHelperText>
        </div>
      )}
      {!isArrayEmpty(items) && items.length === MAX_ITEMS && (
        <div
          className={classNames(classes.buttons, {
            [classes.fullWidth]: isFullWidth,
          })}
        >
          {items.map((item, index) => {
            return (
              <ToggleButtonItem
                key={item.value}
                id={item.label.toLowerCase()}
                isLeftButton={index === 0}
                data={item}
                isActive={value === item.value}
                isDisabled={isDisabled}
                isFullWidth={isFullWidth}
                handleClick={() => handleSelect(item.value)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

ToggleButtonSwitch.defaultProps = {
  label: '',
  isDisabled: false,
  className: '',
  customLabelClass: '',
  isFullWidth: false,
};

ToggleButtonSwitch.propTypes = {
  className: PropTypes.string,
  customLabelClass: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
};

export default ToggleButtonSwitch;
