import { memo } from 'react';
import PropTypes from 'prop-types';
import { Fade, makeStyles } from '@material-ui/core';
import AttributesPerformance from './attributesPerformance';

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    marginTop: spacing(8),
  },
}));

const CareerPerformance = ({
  className,
  translations,
  userLevel,
  core,
  niche,
  hasSendSurveyButton,
  hasCompareReportButton,
  onSendSurvey,
  isUserSuspended,
  onRedirectToCompareReport,
}) => {
  const classes = useStyles();

  return (
    <Fade in appear unmountOnExit>
      <div className={className}>
        <div className={classes.content}>
          <AttributesPerformance
            translations={translations.measuredAttributes}
            coreAttributes={core}
            nicheAttributes={niche}
            hasSendSurveyButton={hasSendSurveyButton}
            hasCompareReportButton={hasCompareReportButton}
            isSendSurveyDisabled={isUserSuspended}
            onRedirectToCompareReport={onRedirectToCompareReport}
            onSendSurvey={onSendSurvey}
            hasUserLevel={Boolean(userLevel)}
          />
        </div>
      </div>
    </Fade>
  );
};

CareerPerformance.defaultProps = {
  className: null,
  isUserSuspended: false,
  core: [],
  niche: [],
  userLevel: null,
};

CareerPerformance.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  userLevel: PropTypes.number,
  core: PropTypes.arrayOf(PropTypes.object),
  niche: PropTypes.arrayOf(PropTypes.object),
  hasCompareReportButton: PropTypes.bool.isRequired,
  hasSendSurveyButton: PropTypes.bool.isRequired,
  isUserSuspended: PropTypes.bool,
  onSendSurvey: PropTypes.func.isRequired,
  onRedirectToCompareReport: PropTypes.func.isRequired,
};

export default memo(CareerPerformance);
