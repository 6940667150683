import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatDistance, parseISO } from 'date-fns';
import { Typography, makeStyles } from '@material-ui/core';
import ArrowTooltip from '../arrowTooltip';
import { SURVEY_TYPES_MAP } from '../../../constants/survey';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish1}`,
    borderRadius: 48,
    backgroundColor: primary.white,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: spacing(2),
    transition: 'all .2s ease',
    height: 32,
    width: 48,
    '&:last-of-type': {
      marginRight: 0,
    },
    '&:hover': {
      border: `1px solid ${primary.blue2}`,
      transform: 'scale(1.1)',
    },
  },
  moreItems: {
    cursor: 'default',
    marginRight: 0,
    marginLeft: spacing(2),
  },
  icon: {
    width: 16,
    height: 16,
  },
  tooltip: {
    color: primary.bluish2,
  },
  tooltipContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  tooltipImage: {
    width: 72,
    height: 72,
  },
  dateSent: {
    fontFamily: 'ProximaNova-RegularIt',
  },
  spacingTop: {
    marginTop: spacing(1),
  },
  tooltipTitle: {
    textAlign: 'center',
    marginBottom: spacing(1),
  },
  label: {
    display: 'flex',
    color: primary.bluish4,
    alignItems: 'center',
    textAlign: 'center',
    marginRight: spacing(3),
  },
}));

const MAX_VISIBLE_SURVEYS = 2;

const SurveyActionBar = ({
  className,
  translations,
  surveys,
  onFillInlineSurvey,
}) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.main, className)}>
      <Typography className={classes.label} variant="body2">
        {translations.widgetName}
      </Typography>
      {surveys.slice(0, MAX_VISIBLE_SURVEYS).map(survey => {
        const { surveyIcon: SurveyIcon } = SURVEY_TYPES_MAP[survey.type];

        return (
          <ArrowTooltip
            key={`pending_${survey.id}`}
            tooltipLabel={
              <div className={classes.tooltipContent}>
                <Typography className={classes.tooltipTitle}>
                  {survey.title}
                </Typography>
                <Typography>
                  {translations.thisWord}{' '}
                  <Typography variant="subtitle1" component="span">
                    {survey.type.toLowerCase()}
                  </Typography>{' '}
                  {translations.needYourFeedback}
                </Typography>
                <SurveyIcon className={classes.tooltipImage} />
                <Typography>{translations.pleaseFill}</Typography>
                <Typography
                  className={classNames(classes.dateSent, classes.spacingTop)}
                >
                  {translations.sentToYou}{' '}
                  <Typography
                    variant="subtitle1"
                    component="span"
                    className={classes.dateSent}
                  >
                    {formatDistance(parseISO(survey.sent_at), new Date(), {
                      addSuffix: true,
                    })}
                  </Typography>
                </Typography>
              </div>
            }
            position="bottom"
          >
            <div
              className={classes.item}
              onClick={() => onFillInlineSurvey(survey.id)}
            >
              <SurveyIcon className={classes.icon} />
            </div>
          </ArrowTooltip>
        );
      })}

      {surveys.length > MAX_VISIBLE_SURVEYS ? (
        <ArrowTooltip
          tooltipLabel={
            <Typography variant="body2" className={classes.tooltip}>
              {translations.youHave}{' '}
              <Typography
                variant="subtitle2"
                component="span"
                className={classes.tooltip}
              >
                {surveys.length - MAX_VISIBLE_SURVEYS}
              </Typography>{' '}
              {translations.pendingSurveys}
            </Typography>
          }
        >
          <Typography
            variant="subtitle1"
            className={classNames(classes.item, classes.moreItems)}
          >
            +{surveys.length - MAX_VISIBLE_SURVEYS}
          </Typography>
        </ArrowTooltip>
      ) : null}
    </div>
  );
};

SurveyActionBar.defaultProps = {
  className: null,
  surveys: [],
};

SurveyActionBar.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  surveys: PropTypes.arrayOf(PropTypes.object),
  onFillInlineSurvey: PropTypes.func.isRequired,
};

export default SurveyActionBar;
