import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { ReactComponent as NoLevelIcon } from '../../../assets/icons/star.svg';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    height: 20,
  },
  bars: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  bar: {
    backgroundColor: primary.bluish5,
    boxSizing: 'border-box',
    borderRadius: 2,
    marginRight: spacing(0.5),
    width: 5,
    flexShrink: 0,
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  barActive: {
    backgroundColor: primary.blue1,
  },
  label: {
    color: primary.bluish2,
    marginLeft: spacing(1.5),
  },
}));

const DEFAULT_BAR_HEIGHT = 5;
const MULTIPLIER = 2;

const LevelBars = ({ noLevelLabel, levels, level }) => {
  const classes = useStyles();

  const levelIndex = levels.findIndex(lvl => lvl.id === level);
  const label = levels.find(l => l.id === level)?.name;

  return (
    <div className={classes.main}>
      {label ? (
        <>
          <div className={classes.bars}>
            {levels.map((lvl, index) => (
              <div
                key={`level_bar_${lvl.id}`}
                className={classNames(classes.bar, {
                  [classes.barActive]: index <= levelIndex,
                })}
                style={{ height: DEFAULT_BAR_HEIGHT * (index + MULTIPLIER) }}
              />
            ))}
          </div>
          <Typography className={classes.label} variant="subtitle2">
            {label}
          </Typography>
        </>
      ) : (
        <>
          <NoLevelIcon />
          <Typography className={classes.label} variant="subtitle2">
            {noLevelLabel}
          </Typography>
        </>
      )}
    </div>
  );
};

LevelBars.defaultProps = {
  level: '',
};

LevelBars.propTypes = {
  noLevelLabel: PropTypes.string.isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  level: PropTypes.string,
};

export default LevelBars;
