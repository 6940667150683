import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import CustomButton from '../customButton';
import CustomScrollBar from '../customScrollBar';
import UserAvatar from '../userAvatar';
import ActionButton from '../actionButton';
import Tag from '../tag';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import { isArrayEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  dialogPaperWrapper: { display: 'block', position: 'relative' },
  paper: {
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: spacing(8),
    margin: 0,
    minWidth: 534,
    maxWidth: 534,
    minHeight: 'auto',
    // maxHeight: 400,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%) ',
  },
  titleRoot: {
    marginBottom: spacing(4),
    padding: 0,
  },
  contentRoot: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(4),
    padding: 0,
    overflowY: 'initial',
  },
  itemsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 136,
    overflowY: 'initial',
  },
  subtitleLevel: {
    marginTop: spacing(2),
  },
  actionsRoot: {
    boxSizing: 'border-box',
    borderTop: `1px solid ${primary.bluish7}`,
    padding: spacing(4, 0, 0),
    margin: 0,
  },
  title: {
    wordBreak: 'break-word',
    paddingRight: spacing(8),
  },
  closeButtonIcon: {
    position: 'absolute',
    right: 29,
    top: 28,
  },
  button: {
    backgroundColor: primary.blue1,
    border: 'none',
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: spacing(2, 4),
  },
  closeButton: {
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish2}`,
    color: primary.bluish2,
    '&:hover': {
      backgroundColor: primary.white,
    },
  },
  item: {
    borderBottom: `1px solid ${primary.bluish5}`,
    marginBottom: spacing(1),
    wordBreak: 'break-word',
    paddingBottom: spacing(1),
    '&:last-of-type': {
      border: 'none',
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
  subtitle: {
    marginBottom: spacing(2),
  },
  userAvatar: {
    marginBottom: spacing(2),
    '&:last-of-type': {
      marginBottom: spacing(0),
    },
  },
  userFullName: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 14,
    lineHeight: '18px',
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: -20,
    height: '100%',
    width: 8,
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
}));

const WarningDialog = ({
  translations,
  items,
  isOpened,
  hasAvatar,
  isTagsDelete,
  levels,
  isAssignedToLevels,
  hasForceDelete,
  onClose,
  onForceDelete,
}) => {
  const classes = useStyles();

  const renderItems = itemsData => {
    return itemsData.map(itemData => {
      if (hasAvatar) {
        return (
          <UserAvatar
            key={`item_${itemData.id}`}
            className={classes.userAvatar}
            labelClass={classes.userFullName}
            user={itemData}
            small
            caption
          />
        );
      }

      if (isTagsDelete) {
        return (
          <div key={`item_${itemData.id}`} className={classes.item}>
            <Tag tag={itemData} color={itemData.category.color} />
          </div>
        );
      }

      return (
        <Typography
          key={`item_${itemData.id}`}
          variant="body1"
          className={classes.item}
        >
          {itemData.name || itemData.level}
        </Typography>
      );
    });
  };

  return (
    <Dialog
      open={isOpened}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        scrollPaper: classes.dialogPaperWrapper,
        paper: classes.paper,
      }}
      onClose={onClose}
    >
      <DialogTitle
        id="alert-dialog-title"
        classes={{ root: classes.titleRoot }}
      >
        <Typography className={classes.title} variant="h5" component="div">
          {translations.title}
        </Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentRoot }}>
        {!isArrayEmpty(items) && (
          <div className={classes.itemsWrapper}>
            <CustomScrollBar
              customScrollBarYClass={classes.scrollY}
              customScrollClass={classes.scroll}
              verticalScroll
              removeScrollX
              passContentHeight
              passContentWidth
            >
              <>{renderItems(items)}</>
            </CustomScrollBar>
          </div>
        )}
        {isAssignedToLevels && (
          <Typography
            className={classNames(classes.subtitle, classes.subtitleLevel)}
            variant="subtitle1"
          >
            {translations.levelsSubtitle}
          </Typography>
        )}
        {!isArrayEmpty(levels) && (
          <div className={classes.itemsWrapper}>
            <CustomScrollBar
              customScrollBarYClass={classes.scrollY}
              customScrollClass={classes.scroll}
              verticalScroll
              removeScrollX
              passContentHeight
              passContentWidth
            >
              <>{renderItems(levels)}</>
            </CustomScrollBar>
          </div>
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsRoot }}>
        <CustomButton
          className={classNames(classes.button, classes.closeButton)}
          type="withTextDarkRounded"
          onClick={onClose}
        >
          {translations.closeButton}
        </CustomButton>
        {hasForceDelete && (
          <CustomButton
            className={classes.button}
            type="withTextDarkRounded"
            onClick={() => {
              onForceDelete();
            }}
          >
            {translations.forceDeleteButton}
          </CustomButton>
        )}
      </DialogActions>
      <ActionButton
        className={classes.closeButtonIcon}
        type={ACTION_BUTTON_TYPES.CLOSE}
        onClickHandler={onClose}
      />
    </Dialog>
  );
};

WarningDialog.defaultProps = {
  items: [],
  levels: [],
  hasForceDelete: false,
  hasAvatar: false,
  isTagsDelete: false,
  isAssignedToLevels: false,
  onForceDelete: () => {},
};

WarningDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  hasForceDelete: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  levels: PropTypes.arrayOf(PropTypes.shape({})),
  hasAvatar: PropTypes.bool,
  isTagsDelete: PropTypes.bool,
  isAssignedToLevels: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onForceDelete: PropTypes.func,
};

export default WarningDialog;
