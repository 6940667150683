import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Tree from 'react-d3-tree';
import ChartItem from './chartItem';
import http from '../../../utility/http';
import { api_user_organization } from '../../../constants/apiRoutes';
import { formatUserForOrgChart } from '../../../utility/uiUtils';
import { canSeeEmployeeProfile, checkUserRole } from '../../../utility/helpers';
import { useCustomEffect } from '../../../utility/hooks';
import { ROLES } from '../../../constants/rolesAndPermissionList';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  chartContainer: {
    minHeight: 'calc(100vh - 255px)',
    width: 'calc(100% - 5px)',
    border: `1px solid ${primary.bluish7}`,
    borderRadius: 8,

    '& .rd3t-link': {
      stroke: primary.blue2,
    },
    '& .rd3t-tree-container svg': {
      minHeight: 'calc(100vh - 255px)',
    },
  },
  chartContainerUser: {
    minHeight: 'calc(100vh - 305px)',
    '& .rd3t-tree-container svg': {
      minHeight: 'calc(100vh - 310px)',
    },
  },
}));

const OrganizationChart = ({
  currentUser,
  organizationSettings,
  hierarchyTree,
  setEmployeeRespondents,
  onGoToProfile,
}) => {
  const classes = useStyles();
  const treeContainerRef = useRef(null);

  const [translate, setTranslate] = useState({
    x: 0,
    y: 100,
  });

  const isUser = checkUserRole(currentUser.role, ROLES.USER);

  useCustomEffect(() => {
    const dimensions = treeContainerRef?.current?.getBoundingClientRect();

    if (dimensions) {
      setTranslate(prevTranslate => ({
        ...prevTranslate,
        x: dimensions.width / 2,
      }));
    }
  });

  const handleGoToProfile = id => () => onGoToProfile(id);

  const getChild = async node => {
    const { data } = await http.get(api_user_organization(node.id));
    const responseData = data.map(user =>
      formatUserForOrgChart(
        user,
        canSeeEmployeeProfile(
          currentUser,
          user.id,
          organizationSettings.global_see_himself
        )
      )
    );

    setEmployeeRespondents(node.id, responseData);
  };

  const renderUser = user => {
    return (
      <ChartItem
        node={user.nodeDatum}
        triggerNodeToggle={user.toggleNode}
        onGoToProfile={handleGoToProfile(user.nodeDatum.person.id)}
        getChild={getChild}
      />
    );
  };

  return (
    <div
      ref={treeContainerRef}
      className={classNames(classes.chartContainer, {
        [classes.chartContainerUser]: isUser,
      })}
    >
      <Tree
        data={hierarchyTree}
        renderCustomNodeElement={renderUser}
        orientation="vertical"
        translate={translate}
        pathFunc="step"
        nodeSize={{ x: 186, y: 200 }}
        separation={{ siblings: 1.1, nonSiblings: 2 }}
      />
    </div>
  );
};

OrganizationChart.propTypes = {
  currentUser: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  hierarchyTree: PropTypes.object.isRequired,
  setEmployeeRespondents: PropTypes.func.isRequired,
  onGoToProfile: PropTypes.func.isRequired,
};

export default OrganizationChart;
