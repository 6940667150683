import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { ReactComponent as AppLogo } from '../../../../assets/icons/logo-dark.svg';
import { ReactComponent as AnonymousIcon } from '../../../../assets/icons/anonymous-user.svg';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: spacing(3),
      paddingRight: props => (props.isInlineSurvey ? spacing(7) : 0),
      [breakpoints.up('sm')]: {
        paddingRight: `0px !important`,
        marginBottom: spacing(6),
      },
    },
    headerSecondary: {
      alignItems: 'flex-start',
      marginBottom: spacing(3),
      [breakpoints.up('sm')]: {
        marginBottom: spacing(4),
      },
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
    },
    appLogo: {
      width: 22,
      height: 22,
    },
    appName: {
      marginLeft: spacing(2),
    },
    anonymousSurvey: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: spacing(2),
    },
    anonymousLabel: {
      color: primary.bluish2,
      marginLeft: spacing(2),
    },
    subject: {
      color: primary.bluish4,
      fontSize: 24,
      lineHeight: '28px',
      wordBreak: 'break-word',
      marginBottom: spacing(6),
      [breakpoints.up('sm')]: {
        fontSize: 28,
        lineHeight: '30px',
        marginBottom: spacing(14),
      },
    },
    subjectSecondary: {
      fontSize: 16,
      lineHeight: '18px',
      marginBottom: 0,
      [breakpoints.up('sm')]: {
        fontSize: 18,
        lineHeight: '20px',
      },
    },
  })
);

const SurveyHeader = ({
  className,
  translations,
  subject,
  hasAppLogo,
  isAnonymous,
  hasAnonymousLabel,
}) => {
  const classes = useStyles();

  const renderTitle = () => (
    <Typography
      className={classNames(classes.subject, {
        [classes.subjectSecondary]: !hasAppLogo,
      })}
      variant={!hasAppLogo ? 'h5' : 'h2'}
    >
      {subject
        ? `${translations.subject} ${subject}`
        : translations.groupSurvey}
    </Typography>
  );

  return (
    <div className={className}>
      <div
        className={classNames(classes.header, {
          [classes.headerSecondary]: !hasAppLogo,
        })}
      >
        {hasAppLogo ? (
          <div className={classes.logo}>
            <AppLogo className={classes.appLogo} />
            <Typography className={classes.appName} variant="subtitle1">
              {translations.appName}
            </Typography>
          </div>
        ) : (
          renderTitle()
        )}
        {isAnonymous && (
          <div className={classes.anonymousSurvey}>
            <AnonymousIcon />
            {hasAnonymousLabel && (
              <Typography className={classes.anonymousLabel} variant="caption">
                {translations.anonymous}
              </Typography>
            )}
          </div>
        )}
      </div>
      {hasAppLogo && renderTitle()}
    </div>
  );
};

SurveyHeader.defaultProps = {
  className: '',
  subject: null,
  hasAppLogo: false,
  hasAnonymousLabel: false,
};

SurveyHeader.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  subject: PropTypes.string,
  hasAppLogo: PropTypes.bool,
  isAnonymous: PropTypes.bool.isRequired,
  hasAnonymousLabel: PropTypes.bool,
};

export default SurveyHeader;
