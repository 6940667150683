import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Typography,
  Modal,
  Paper,
  Backdrop,
  makeStyles,
} from '@material-ui/core';
import CustomButton from '../customButton';
import ActionButton from '../actionButton';
import { AUTOMATION_ID } from '../../../constants/automationId';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxSizing: 'border-box',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(8),
    position: 'relative',
    width: 936,
  },
  paperMedium: {
    width: 534,
  },
  paperSmall: {
    width: 366,
  },
  title: {
    marginBottom: spacing(5),
    paddingRight: spacing(8),
  },
  description: {
    marginBottom: spacing(4),
  },
  closeIcon: {
    position: 'absolute',
    right: 28,
    top: 28,
  },
  buttonsWrapper: {
    borderTop: `1px solid ${primary.bluish7}`,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: spacing(6),
    marginTop: spacing(6),
    width: '100%',
  },
  button: {
    borderRadius: 44,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: spacing(2, 4),
    maxHeight: 40,
  },
  confirmButton: {
    boxSizing: 'border-box',
    backgroundColor: primary.blue1,
    border: 'none',
    color: primary.white,
    '&:hover': {
      backgroundColor: primary.blue1,
    },
    '&:disabled': {
      backgroundColor: primary.blue5,
      border: 'none',
    },
  },
  closeButton: {
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish1}`,
    color: primary.bluish1,
  },
  closeButtonSpacing: {
    marginRight: spacing(2),
  },
  saveAndCreateButtonSpacing: {
    marginLeft: spacing(2),
  },
  neutralColor: {
    '&:hover': {
      backgroundColor: primary.white,
    },
  },
}));

const CustomModal = ({
  className,
  paperClass,
  titleClass,
  descriptionClass,
  buttonsWrapperClass,
  closeButtonClass,
  confirmButtonClass,
  isOpened,
  title,
  description,
  closeIconButtonClass,
  children,
  confirmButtonLabel,
  saveAndCreateButtonLabel,
  closeButtonLabel,
  isMedium,
  isSmall,
  shouldRemoveCloseIconButton,
  isConfirmDisabled,
  isCloseDisabled,
  shouldRemoveFooter,
  renderCustomAction,
  onClose,
  onConfirm,
  onSaveAndCreate,
}) => {
  const classes = useStyles();

  return (
    <Modal
      className={classNames(classes.main, className)}
      BackdropComponent={Backdrop}
      open={isOpened}
      onClose={onClose}
    >
      <Paper
        className={classNames(
          classes.paper,
          { [classes.paperMedium]: isMedium, [classes.paperSmall]: isSmall },
          paperClass
        )}
      >
        {title && (
          <Typography
            className={classNames(classes.title, titleClass)}
            variant="h5"
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            className={classNames(classes.description, descriptionClass)}
          >
            {description}
          </Typography>
        )}
        {children}
        {!shouldRemoveFooter && (
          <div
            className={classNames(classes.buttonsWrapper, buttonsWrapperClass)}
          >
            {renderCustomAction()}
            {closeButtonLabel ? (
              <CustomButton
                className={classNames(
                  classes.button,
                  classes.closeButton,
                  {
                    [classes.closeButtonSpacing]:
                      confirmButtonLabel || saveAndCreateButtonLabel,
                  },
                  closeButtonClass
                )}
                type="addWithTextRounded"
                disabled={isCloseDisabled}
                onClick={onClose}
              >
                {closeButtonLabel}
              </CustomButton>
            ) : null}
            {confirmButtonLabel ? (
              <CustomButton
                className={classNames(
                  classes.button,
                  classes.confirmButton,
                  { [classes.closeButton]: saveAndCreateButtonLabel },
                  { [classes.neutralColor]: saveAndCreateButtonLabel },
                  confirmButtonClass
                )}
                type="withTextDarkRounded"
                disabled={isConfirmDisabled}
                onClick={onConfirm}
              >
                {confirmButtonLabel}
              </CustomButton>
            ) : null}
            {saveAndCreateButtonLabel ? (
              <CustomButton
                className={classNames(classes.button, classes.confirmButton, {
                  [classes.saveAndCreateButtonSpacing]: confirmButtonLabel,
                })}
                type="withTextDarkRounded"
                onClick={onSaveAndCreate}
              >
                {saveAndCreateButtonLabel}
              </CustomButton>
            ) : null}
          </div>
        )}
        {!shouldRemoveCloseIconButton && (
          <ActionButton
            id={AUTOMATION_ID.CLOSE_BUTTON}
            className={classNames(classes.closeIcon, closeIconButtonClass)}
            type={ACTION_BUTTON_TYPES.CLOSE}
            onClickHandler={onClose}
          />
        )}
      </Paper>
    </Modal>
  );
};
CustomModal.defaultProps = {
  className: '',
  paperClass: null,
  titleClass: null,
  descriptionClass: null,
  buttonsWrapperClass: null,
  closeButtonClass: null,
  confirmButtonClass: null,
  closeIconButtonClass: null,
  title: '',
  description: '',
  isConfirmDisabled: false,
  isCloseDisabled: false,
  shouldRemoveCloseIconButton: false,
  shouldRemoveFooter: false,
  confirmButtonLabel: '',
  saveAndCreateButtonLabel: '',
  closeButtonLabel: '',
  isMedium: false,
  isSmall: false,
  renderCustomAction: () => {},
  onClose: () => {},
  onConfirm: () => {},
  onSaveAndCreate: () => {},
};

CustomModal.propTypes = {
  className: PropTypes.string,
  paperClass: PropTypes.string,
  titleClass: PropTypes.string,
  descriptionClass: PropTypes.string,
  buttonsWrapperClass: PropTypes.string,
  closeButtonClass: PropTypes.string,
  confirmButtonClass: PropTypes.string,
  closeIconButtonClass: PropTypes.string,
  isOpened: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  confirmButtonLabel: PropTypes.string,
  saveAndCreateButtonLabel: PropTypes.string,
  closeButtonLabel: PropTypes.string,
  isMedium: PropTypes.bool,
  isSmall: PropTypes.bool,
  isConfirmDisabled: PropTypes.bool,
  isCloseDisabled: PropTypes.bool,
  shouldRemoveCloseIconButton: PropTypes.bool,
  shouldRemoveFooter: PropTypes.bool,
  renderCustomAction: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onSaveAndCreate: PropTypes.func,
};

export default CustomModal;
