import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { ResponsiveContainer, PieChart, Pie, Legend, Sector } from 'recharts';
import CustomLegend from './customLegend';
import { prepareChartData } from './config';

const useStyles = makeStyles(({ breakpoints, palette: { primary } }) => ({
  main: {
    width: '100%',

    '& svg g, & svg g:focus, & svg g path:focus': {
      outline: 'none',
    },
  },
  activeCell: {
    cursor: 'default',
  },
  activeCellInteractive: {
    cursor: 'pointer',
  },

  activeCellText: {
    fontFamily: 'Helvetica',
    fontSize: 16,
    lineHeight: '18px',
    fontWeight: 700,

    [breakpoints.up('xl')]: {
      fontSize: 24,
    },
  },
  activeCellPlaceholder: {
    fill: primary.black,
    fontFamily: 'ProximaNova-Semibold',
    fontSize: 10,
    lineHeight: '14px',
    textAlign: 'center',

    [breakpoints.up('xl')]: {
      fontSize: 14,
    },
  },
}));

const CustomDonutChart = ({
  data,
  placeholderText,
  hasNotStartedStatus,
  isInteractive,
  onRedirect,
}) => {
  const classes = useStyles();

  const [activeCell, setActiveCell] = useState(null);

  const PLACEHOLDER_HEIGHT = 18;
  const noValueCount = data.filter(entry => entry.value === 0).length;
  const isEmptyData = noValueCount === data.length;
  const formattedData = prepareChartData(
    data,
    isEmptyData,
    placeholderText,
    hasNotStartedStatus
  );

  const onPieEnter = (_, index) => setActiveCell(index);

  const onPieLeave = () => setActiveCell(null);

  const renderActiveShape = props => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
    } = props;

    const placeholderPartOneCY = cy - PLACEHOLDER_HEIGHT;
    const placeholderPartTwoCY = cy + PLACEHOLDER_HEIGHT / 2;

    return (
      <g
        className={classNames(classes.activeCell, {
          [classes.activeCellInteractive]: isInteractive,
        })}
        onClick={isInteractive ? () => onRedirect(payload) : () => {}}
      >
        <text
          className={classNames(classes.activeCellText, {
            [classes.activeCellPlaceholder]: payload.isPlaceholder,
          })}
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={fill}
        >
          {payload.isPlaceholder ? (
            <>
              <tspan x={cx} y={placeholderPartOneCY}>
                {payload.placeholderText?.partOne}
              </tspan>
              <tspan x={cx} y={placeholderPartTwoCY}>
                {payload.placeholderText?.partTwo}
              </tspan>
            </>
          ) : (
            `${(percent * 100).toFixed(2)}%`
          )}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={innerRadius - 4}
          outerRadius={outerRadius}
          fill={fill}
        />
      </g>
    );
  };

  return (
    <div className={classes.main}>
      <ResponsiveContainer width="100%" height={200}>
        <PieChart>
          <Legend
            content={<CustomLegend hasNoData={isEmptyData} />}
            layout="vertical"
            verticalAlign="middle"
            align="right"
            wrapperStyle={{
              width: 180,
            }}
          />
          <Pie
            activeIndex={activeCell}
            activeShape={renderActiveShape}
            data={formattedData}
            dataKey="value"
            innerRadius="87%"
            outerRadius="100%"
            blendStroke={isEmptyData || noValueCount === data.length - 1}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
            isAnimationActive={false}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

CustomDonutChart.defaultProps = {
  isInteractive: false,
  onRedirect: () => {},
};

CustomDonutChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  placeholderText: PropTypes.shape({
    partOne: PropTypes.string.isRequired,
    partTwo: PropTypes.string.isRequired,
  }).isRequired,
  isInteractive: PropTypes.bool,
  hasNotStartedStatus: PropTypes.bool.isRequired,
  onRedirect: PropTypes.func,
};

export default CustomDonutChart;
